import {
  useImperativeHandle,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { format, isSameMonth, isSameYear } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { current } from "@reduxjs/toolkit";
import _ from "underscore";

import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import { getAllTransactionsByParams } from "../../../Helper/data";
import { GlobalContext } from "../../../GlobalContextWrapper";
import { setStaffData } from "../../../store/slices/staff";
import useStatusHook from "../../../hooks/useStatusHook";
import { Constant } from "../../../Helper";
import store from "../../../store";

const EmployeesFunctions = forwardRef(
  (
    {
      totalPageCount,
      rowData,
      setIsStaffLoading,
      allowToFetch,
      isIncludedInFilterList,
      setIsIncludedInFilterList,
      isFilterOpen,
    },
    _ref
  ) => {
    const dispatch = useDispatch();
    const globalContext = useContext(GlobalContext);
    const internalDsRef = globalContext?.internalDsRef;
    const pageSize = 20;

    //redux
    const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
    const use_global_categories = useSelector(
      (state) => state.boardSlice?.dataSetData?.use_global_categories
    );
    const refreshData = useSelector((state) => state.appSlice?.refreshData);
    const recurring_rules = useSelector(
      (state) => state.globalSlice?.recurring_rules
    );

    const transactionsOverlayStatus = useSelector(
      (state) => state.datasetSlice?.transactionsOverlayStatus
    );
    const scenarioByTitle = useSelector(
      (state) => state.globalSlice.scenarioByTitle
    );
    const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
    const employeeStateTab = useSelector(
      (state) => state.staffSlice?.employeeStateTab
    );
    const staffSearchText = useSelector(
      (state) => state.staffSlice.staffSearchText
    );
    const employeeType = useSelector((state) => state.staffSlice?.employeeType);
    const employeeScenarios = useSelector(
      (state) => state.staffSlice?.employeeScenarios
    );
    const employeeDepartment = useSelector(
      (state) => state.staffSlice?.employeeDepartment
    );
    const datasetAccountList = useSelector(
      (state) => state.boardSlice.datasetAccountList
    );
    const internal_data_source = useMemo(() => {
      return datasetAccountList?.find((o1) => o1.internal_dataset === dataset);
    }, [dataset, datasetAccountList]);

    //state
    const DeferredSearchText = useDeferredTimeout({ value: staffSearchText });

    const AllStaff = useStatusHook("staff");
    const Staff = useMemo(
      () => AllStaff?.data?.results?.filter((o1) => o1?.dataset === dataset),
      [AllStaff?.data, dataset]
    );

    const employee_recurring_rules = useMemo(() => {
      if (Staff) {
        const contactsId = Staff?.filter((o1) =>
          DeferredSearchText?.length > 0
            ? o1?.name
                ?.toLowerCase()
                ?.includes(DeferredSearchText?.toLowerCase())
            : true
        ).map((o1) => o1?.uuid);
        const selectionCategoriesByID =
          store.getState().categorySlice?.selectionCategoriesByID;
        return recurring_rules
          ?.filter(
            (item) =>
              item?.recurring_type === "employee" &&
              item?.contact &&
              (contactsId?.length > 0
                ? contactsId?.includes(item?.contact)
                : true) &&
              (employeeDepartment?.length > 0
                ? employeeDepartment?.includes(item?.category)
                : true) &&
              (employeeType?.length > 0
                ? employeeType?.includes(item?.employee_type)
                : true)
          )
          ?.filter((o1) =>
            use_global_categories
              ? !selectionCategoriesByID?.[o1?.category]?.[0]?.dataset
              : selectionCategoriesByID?.[o1?.category]?.[0]?.dataset ===
                dataset
          )
          .map((o1) => o1?.uuid);
      } else {
        return null;
      }
    }, [
      Staff,
      recurring_rules,
      DeferredSearchText,
      employeeDepartment,
      employeeType,
      use_global_categories,
      dataset,
    ]);

    const employee_states = useMemo(() => {
      if (JSON.stringify(stateByTitle) !== "{}") {
        return Constant.staffState?.map((item) => {
          return stateByTitle?.[item]?.[0]?.uuid;
        });
      } else {
        return null;
      }
    }, [stateByTitle]);

    const contactList = useMemo(() => {
      if (Staff?.length > 0) {
        return Staff?.map((o1) => o1?.uuid);
      } else {
        return [];
      }
    }, [Staff]);

    const EmployeesList = useQuery({
      queryKey: [
        "transactions",
        {
          apiType: "employees",
          dataset,
          contactList,
        },
      ],
      queryFn: ({ signal }) => {
        let param = {
          config: {
            signal: signal,
          },
          dataset: dataset,
          contact: contactList,
          state: employee_states,
        };
        if (!use_global_categories) {
          param.category_dataset = dataset;
        } else {
          param.global_category = true;
        }
        const result = getAllTransactionsByParams(param);
        if (result) {
          return result;
        }
      },
      backgroundFetch: true,
      refetchOnMount: true,
      priority: 3,
      enabled:
        !!dataset &&
        !!employee_states &&
        !!internal_data_source?.active &&
        !!Staff &&
        !transactionsOverlayStatus?.open &&
        contactList?.length > 0 &&
        !AllStaff?.isFetching,
    });

    const loading = EmployeesList?.isFetching || AllStaff?.isFetching;

    const filteredTransactionsByState = useMemo(() => {
      let AllTransactionByState = {
        Planned: [],
        Ignored: [],
        "Written-off": [],
        All: [],
      };
      let alreadyAddedContact = [];
      if (EmployeesList?.data?.length === 0) return AllTransactionByState;
      const filteredTransactions = EmployeesList?.data?.filter(
        (o1) =>
          employee_recurring_rules?.includes(o1.recurring_rule) &&
          (employeeScenarios?.length > 0
            ? employeeScenarios?.includes(
                scenarioByTitle?.[o1?.scenario]?.[0]?.uuid
              )
            : true)
      );

      Object.keys(rowData.current)?.forEach((key) => {
        if (key !== "All") {
          let contactData = filteredTransactions?.filter(
            (o1) =>
              o1.state === key && !alreadyAddedContact?.includes(o1?.contact)
          );

          const contactList = contactData?.map((o1) => o1.contact);
          const uniqueContacts = [...new Set(contactList)];
          alreadyAddedContact = [...alreadyAddedContact, ...uniqueContacts];
          AllTransactionByState[key] = filteredTransactions?.filter((o1) =>
            uniqueContacts?.includes(o1?.contact)
          );
        } else {
          AllTransactionByState["All"] = filteredTransactions;
        }
      });

      return AllTransactionByState;
    }, [
      EmployeesList?.data,
      employeeScenarios,
      employee_recurring_rules,
      rowData,
      scenarioByTitle,
    ]);

    //lifecycle
    useImperativeHandle(
      _ref,
      () => ({
        filteredTransactionsByState,
      }),
      [filteredTransactionsByState]
    );

    useEffect(() => {
      if (!internal_data_source?.active) {
        internalDsRef.current?.enableInternalDS();
      }
    }, [internal_data_source]);

    useDebounce(
      () => {
        if (!AllStaff?.isFetching) {
          setIsStaffLoading(true);
          EmployeesList.refetch();
        }
      },
      300,
      [refreshData],
      true
    );

    //filter update
    useDebounce(
      () => {
        if (!isFilterOpen?.current) {
          let included_array = [];
          if (employeeDepartment?.length > 0) {
            if (!isIncludedInFilterList?.includes("category")) {
              included_array.push("category");
            }
          }
          if (employeeType?.length > 0) {
            if (!isIncludedInFilterList?.includes("employee_type")) {
              included_array.push("employee_type");
            }
          }
          if (employeeScenarios?.length > 0) {
            if (!isIncludedInFilterList?.includes("scenario")) {
              included_array.push("scenario");
            }
          }
          setIsIncludedInFilterList(included_array);
        }
      },
      500,
      [employeeDepartment, employeeType, employeeScenarios],
      true
    );

    //staff list
    useDebounce(
      () => {
        if (loading) {
          setIsStaffLoading(true);
        }
        if (!loading && allowToFetch) {
          totalPageCount.current = Math.ceil(
            (EmployeesList?.data?.count || 1) / pageSize
          );
          const selectionCategoriesByID =
            store.getState().categorySlice?.selectionCategoriesByID;
          // filteredTransactionsByState
          const AllData = filteredTransactionsByState["All"];

          const filtered_employee_recurring_rules = [
            ...new Set(AllData?.map((o1) => o1?.recurring_rule)),
          ];
          const filteredRecurringRule = recurring_rules
            ?.filter((item) =>
              filtered_employee_recurring_rules?.includes(item?.uuid)
            )
            ?.filter((o1) =>
              use_global_categories
                ? !selectionCategoriesByID?.[o1?.category]?.[0]?.dataset
                : selectionCategoriesByID?.[o1?.category]?.[0]?.dataset ===
                  dataset
            );
          const RecurringRulesByContact = _.groupBy(
            filteredRecurringRule,
            ({ contact }) => contact
          );
          let alreadyAddedStaff = [];
          if (rowData.current) {
            Object.keys(rowData?.current)?.forEach((key) => {
              const filterData = filteredTransactionsByState[key];
              const staffList =
                filterData?.length > 0
                  ? [...new Set(filterData?.map((o1) => o1?.contact))]
                  : [];
              const TransactionsByRecurringRule = _.groupBy(
                filterData,
                ({ recurring_rule }) => recurring_rule
              );
              let obj = {};
              let array = [];
              Staff?.forEach((staff) => {
                if (staffList?.includes(staff?.uuid)) {
                  const recurringList = RecurringRulesByContact?.[staff?.uuid];

                  let stateList = [];
                  let scenarioList = [];
                  let roleList = [];
                  let typeList = [];
                  let departmentList = [];

                  let sumCostThisYear = null;
                  let sumCostThisMonth = null;
                  let sumSalaryThisMonth = null;
                  let start_date = null;
                  let end_date = null;
                  let _item = null;
                  recurringList?.forEach((recurringObj) => {
                    const transactions =
                      TransactionsByRecurringRule?.[recurringObj?.uuid] || [];

                    const item =
                      TransactionsByRecurringRule?.[recurringObj?.uuid]?.[0];

                    if (item?.state && !stateList?.includes(item?.state)) {
                      if (item?.state === employeeStateTab) {
                        _item = item;
                        stateList.unshift(item?.state);
                      } else {
                        stateList.push(item?.state);
                        let positionStates = [];
                        if (stateList.includes("Planned")) {
                          positionStates.push("Planned");
                        }
                        if (stateList.includes("Ignored")) {
                          positionStates.push("Ignored");
                        }
                        if (stateList.includes("Written-off")) {
                          positionStates.push("Written-off");
                        }
                        stateList = positionStates;
                      }
                    }
                    if (
                      item?.scenario &&
                      !scenarioList?.includes(item?.scenario)
                    ) {
                      if (item?.state === employeeStateTab) {
                        scenarioList.unshift(item?.scenario);
                      } else {
                        scenarioList.push(item?.scenario);
                      }
                    }
                    if (
                      recurringObj?.employee_role &&
                      !roleList?.includes(recurringObj?.employee_role)
                    ) {
                      roleList.push(recurringObj?.employee_role);
                    }
                    if (
                      recurringObj?.employee_type &&
                      !typeList?.includes(recurringObj?.employee_type)
                    ) {
                      typeList.push(recurringObj?.employee_type);
                    }

                    if (
                      recurringObj?.category &&
                      !departmentList?.includes(recurringObj?.category)
                    ) {
                      if (item?.state === employeeStateTab) {
                        departmentList.unshift(recurringObj?.category);
                      } else {
                        departmentList.push(recurringObj?.category);
                      }
                    }
                    if (key === "All" ? true : item?.state === key) {
                      _item = item;

                      let grossSalary = Math.abs(recurringObj?.value);
                      if (!start_date) {
                        start_date = recurringObj?.start_date;
                      } else {
                        if (
                          start_date &&
                          recurringObj?.start_date &&
                          format(
                            new Date(recurringObj?.start_date),
                            "yyyy-MM-dd"
                          ) < format(new Date(start_date), "yyyy-MM-dd")
                        ) {
                          start_date = recurringObj?.start_date;
                        }
                      }
                      if (!end_date) {
                        end_date = recurringObj?.end_date;
                      } else {
                        if (
                          start_date &&
                          recurringObj?.end_date &&
                          format(
                            new Date(recurringObj?.end_date),
                            "yyyy-MM-dd"
                          ) > format(new Date(end_date), "yyyy-MM-dd")
                        ) {
                          end_date = recurringObj?.end_date;
                        }
                      }

                      if (Number(recurringObj?.salary_type) === 2) {
                        grossSalary = Number(grossSalary) / 12;
                      } else if (Number(recurringObj?.salary_type) === 1) {
                        grossSalary = Number(grossSalary);
                      } else {
                        grossSalary = 0;
                      }

                      const currentMonthData = transactions
                        ?.filter((o1) =>
                          isSameMonth(new Date(o1?.due_date), new Date())
                        )
                        ?.map((o1) => o1?.gross_value);
                      const currentMonthCost = currentMonthData?.reduce(
                        (a, b) => parseFloat(a || 0) + parseFloat(b || 0),
                        0
                      );
                      const currentYearData = transactions
                        ?.filter((o1) =>
                          isSameYear(new Date(o1?.due_date), new Date())
                        )
                        ?.map((o1) => o1?.gross_value);

                      const currentYearCost = currentYearData?.reduce(
                        (a, b) => parseFloat(a || 0) + parseFloat(b || 0),
                        0
                      );

                      sumSalaryThisMonth =
                        sumSalaryThisMonth +
                        (currentMonthData?.length > 0 ? grossSalary : 0);

                      sumCostThisMonth =
                        parseFloat(sumCostThisMonth || 0) + currentMonthCost;

                      sumCostThisYear =
                        parseFloat(sumCostThisYear || 0) + currentYearCost;
                    }
                  });

                  obj = {
                    name: staff?.name,
                    uuid: staff?.uuid,
                    contact: staff?.uuid,
                    salaryThisMonth: Math.abs(sumSalaryThisMonth || 0),
                    costThisMonth: Math.abs(sumCostThisMonth || 0),
                    costThisYear: Math.abs(sumCostThisYear || 0),
                    start_date,
                    end_date,
                    item: _item,
                    TransactionsByRecurringRule,
                    stateList,
                    scenarioList,
                    roleList,
                    typeList,
                    departmentList,
                    recurringList,
                    count: recurringList?.length || 0,
                  };
                  if (
                    (DeferredSearchText?.length > 0
                      ? staff?.name
                          ?.toLowerCase()
                          ?.includes(DeferredSearchText?.toLowerCase())
                      : true) &&
                    (employeeScenarios?.length > 0 && obj?.item?.scenario
                      ? employeeScenarios?.includes(
                          scenarioByTitle?.[obj?.item?.scenario]?.[0]?.uuid
                        )
                      : true)
                  ) {
                    if (
                      key !== "All"
                        ? !alreadyAddedStaff?.includes(obj?.uuid) &&
                          obj.count > 0
                        : true
                    ) {
                      if (key !== "All") {
                        alreadyAddedStaff.push(obj?.uuid);
                      }
                      array.push(obj);
                    }
                  }
                }
              });

              rowData.current[key] = array.sort(
                (a, b) => (b?.grossSalary || 0) - (a?.grossSalary || 0)
              );
            });
          }

          dispatch(setStaffData(rowData.current?.[employeeStateTab] || []));
          setIsStaffLoading(false);
        }
      },
      500,
      [
        loading,
        Staff,
        EmployeesList?.data,
        employee_recurring_rules,
        employeeStateTab,
        employeeDepartment,
        allowToFetch,
        filteredTransactionsByState,
        use_global_categories,
      ],
      true
    );

    return null;
  }
);

export default EmployeesFunctions;
