import {
  Drawer as MuiDrawer,
  IconButton,
  useTheme,
  Divider,
  styled,
  Stack,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { setMainDrawer } from "../../store/slices/settings";
import TabView from "./DatasetHeader/TabView";
import { Constant } from "../../Helper";

const drawerWidth = Constant.DRAWER_WIDTH;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: Constant.DRAWER_WIDTH_CLOSED,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    borderRadius: 0,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MainDrawerView = ({ showHeader, isStartPage }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  //redux
  const isMainDrawerOpen = useSelector(
    (state) => state.settingsSlice?.isMainDrawerOpen
  );

  //functions
  const handleDrawerClose = () => {
    dispatch(setMainDrawer(false));
  };

  useEffect(() => {
    if (isMainDrawerOpen && isStartPage) {
      handleDrawerClose();
    }
  }, [isMainDrawerOpen, isStartPage]);

  if (!showHeader || isStartPage) {
    return null;
  }

  return (
    <Drawer
      variant="permanent"
      open={isMainDrawerOpen}
      sx={{
        display: { xs: "flex", s1650: "none" },
        backgroundColor: `${theme.palette.color.slate[100]} !important`,
        "& .MuiDrawer-paper": {
          backgroundColor: `${theme.palette.color.slate[100]} !important`,
        },
      }}
    >
      <Stack
        direction={"row"}
        py={2.25}
        justifyContent={"flex-end"}
        sx={{
          mt: `${Constant.BANNER_HEIGHT}`,
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon sx={{ color: theme.palette.primary.dark }} />
        </IconButton>
      </Stack>
      <Divider />
      <TabView isMainDrawerOpen={isMainDrawerOpen} />
    </Drawer>
  );
};

export default MainDrawerView;
