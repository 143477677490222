import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Tooltip, useTheme } from "@mui/material";
import Popover from "@mui/material/Popover";
import * as React from "react";

import Translate from "../../hooks/HOC/Translate";

export default function CustomPopoverScratch({
  children,
  button,
  tooltip = "",
  disabled,
  borderRadius,
  mr,
  ml,
  paperStyle,
  sx,
  popupProps = {},
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
}) {
  const theme = useTheme();
  const payload = React.useRef({ anchorOrigin, transformOrigin });
  const triggerRef = React.useRef(null);

  //state

  const calculatePosition = (e, popup_state) => {
    if (triggerRef.current && !disabled) {
      const rect = triggerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      // Dynamic positioning logic
      const vertical = rect.top > windowHeight / 2 ? "top" : "bottom";
      const horizontal = rect.left > windowWidth / 2 ? "right" : "left";
      payload.current = {
        anchorOrigin: {
          vertical,
          horizontal,
        },
        transformOrigin: {
          vertical: vertical === "top" ? "bottom" : "top",
          horizontal,
        },
      };
      popup_state?.open(e?.currentTarget);
    }
  };

  return (
    <PopupState variant="popper">
      {(popup_state) => (
        <>
          <Tooltip
            placement="top"
            title={tooltip ? <Translate i18nkey={tooltip} /> : ""}
          >
            <div
              ref={triggerRef}
              {...bindTrigger(popup_state)}
              onClick={(e) => calculatePosition(e, popup_state)}
              disabled={disabled}
              style={{
                width: "fit-content",
                height: "fit-content",
                display: "flex",
                alignItems: "center",
              }}
            >
              {React?.cloneElement(button, { popup_state })}
            </div>
          </Tooltip>
          <Popover
            {...bindPopover(popup_state)}
            {...popupProps}
            anchorOrigin={payload.current.anchorOrigin}
            transformOrigin={payload.current.transformOrigin}
            container={document.body}
            sx={{
              zIndex: 1303,
              "& .MuiPaper-root": {
                mt: "0.5rem",
                borderRadius: borderRadius
                  ? borderRadius
                  : theme.borderRadius.main,
                ml: ml ? ml : 0,
                mr: mr ? mr : 0,
                boxShadow: theme.boxShadow,
                ...paperStyle,
              },
              ...sx,
            }}
          >
            {React?.cloneElement(children, { popup_state })}
          </Popover>
        </>
      )}
    </PopupState>
  );
}
