import { Box, Tooltip, useTheme } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

import { Constant } from "../../../Helper";

const AddFilterView = ({
  color = "violet",
  isIncludedInFilterList,
  onClick,
  sx,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //function
  const isActive = isIncludedInFilterList?.length > 0;

  return (
    <Tooltip title={t("Add Filter")}   placement="top">
      <Box
        onClick={onClick}
        sx={{
          color: theme.palette.color[color][isActive ? 500 : 400],
          backgroundColor: theme.palette.color[color][100],
          border: `1px dashed ${theme.palette.color[color][400]}`,
          cursor: "pointer",
          borderRadius: theme.borderRadius.borderRadiusXL,
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: "0.9rem",
          lineHeight: "0.9rem",
          height: isActive ? "1.5rem" : Constant.FILTER_HEIGHT,
          px: isActive ? "0.2rem" : "0.5rem",
          ml: "0.75rem",
          display: "flex",
          alignItems: "center",
          "&:hover": {
            backgroundColor: theme.palette.color[color][200],
          },
          ...sx,
        }}
      >
        {isActive ? (
          <AddIcon sx={{ fontSize: "1rem" }} />
        ) : (
          <TuneIcon sx={{ fontSize: "1.1rem", mr: "0.25rem" }} />
        )}
        {isActive ? "" : t("Filter")}
      </Box>
    </Tooltip>
  );
};
export default AddFilterView;
