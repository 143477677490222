import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";

const DropdownBase = ({
  width,
  color,
  disabled,
  popup_state,
  placeholder,
  placeholderIcon,
  display_key,
  selectedItem,
  sx,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isOpen = popup_state?.isOpen;
  let title = selectedItem?.[display_key] || t(placeholder);
  let icon = selectedItem?.icon || placeholderIcon;

  return (
    <Box
      sx={{
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        border: `1px solid ${theme.palette.color?.[color]?.[300]}`,
        px: "0.6rem",
        py: "0.5rem",
        width: width,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
        color: theme.palette.color.slate[600],
        "&:hover": {
          "& svg, p": {
            color: `${disabled ? theme.palette.color.slate[600] : theme.palette.primary.main} !important`,
            borderColor: `${disabled ? "inherit" : theme.palette.primary.main} !important`,
          },
        },
        ...sx,
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          width: "calc(100% - 20px)",
        }}
      >
        {icon}
        <Typography
          variant={"body2"}
          fontWeight={"fontWeightMediumBold"}
          sx={{
            width: "80%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            color: theme.palette.color?.[color]?.[600],
            textAlign: "start",
          }}
        >
          {title}
        </Typography>
      </span>

      <KeyboardArrowDownIcon
        sx={{
          width: "20px",
          display: "flex",
          alignItems: "center",
          color: theme.palette.color?.[color]?.[600],
          transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease-in-out",
          fontSize: "1.4rem",
        }}
      />
    </Box>
  );
};
export default DropdownBase;
