import { Box, TextField, Tooltip, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { de, enUS } from "date-fns/locale";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { useState } from "react";

import { Color } from "../../Helper";

const DateInput = ({
  title,
  value = "",
  onChange,
  error,
  helperText,
  fontSize,
  name = "",
  disabled = false,
  star = false,
  hideTitle = false,
  mb = "1rem",
  borderRadius = 1,
  variant = "outlined",
  placeholder = "",
  label = null,
  likeGoogle = false,
  onBlur = () => {},
  onFocus = () => {},
  tooltip,
  maxdate,
  mindate,
  required = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const profile = useSelector((state) => state.settingsSlice?.profile);

  const [isFocus, setIsFocus] = useState(false);

  const onFocusInput = (e) => {
    setIsFocus(true);
    onFocus(e);
  };

  const onBlurInput = (e) => {
    setIsFocus(false);
    onBlur(e);
  };

  return (
    <>
      {!hideTitle && (
        <Tooltip   placement="top" title={t(`${tooltip}`)}>
          <Typography
            variant="h6"
            component={"h6"}
            sx={{
              display: "flex",
              color: Color.blueGrey900,
              width: "100%",
              textAlign: "left",
              fontWeight: 700,
              mb: "0.25rem",
              fontSize: fontSize ? fontSize : "0.88rem",
            }}
          >
            {" "}
            {title ? title : t("Date")} {star ? "*" : ""}
          </Typography>
        </Tooltip>
      )}
      <Box
        sx={{
          height: "3rem",
          mb: mb,
          width: "100%",
          ".MuiFormControl-root": {
            width: "100%",
          },
          ".MuiOutlinedInput-root": {
            width: "100%",
            fontSize: "1rem",
            fontWeight: 400,
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                backgroundColor: !likeGoogle && "rgba(0,0,0,0.03)",
              },
            },
          },
          ".MuiOutlinedInput-input": {
            p: !likeGoogle && "0.4rem",
            backgroundColor: !likeGoogle && Color.white,
            "&:focus": {
              border: !likeGoogle && `1px solid ${Color.oInputBorderBg}`,
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: likeGoogle
              ? "0 !important"
              : `1px solid ${Color.oInputBorderBg}`,
            borderBottom:
              likeGoogle &&
              isFocus &&
              `1px solid ${theme.palette.color.main} !important`,
          },
          ".MuiInputBase-root": {
            // p: likeGoogle && "0.6rem",
            borderRadius: borderRadius,
            "&:before": {
              borderBottom: likeGoogle && 0,
            },
            backgroundColor:
              likeGoogle && isFocus ? Color.inputBG : "transparent",
            "&:hover": {
              backgroundColor: likeGoogle && Color.inputBG,
              "&:before": {
                borderBottom: likeGoogle && "0 !important",
              },
            },
            "&:focus": {
              backgroundColor: likeGoogle && Color.inputBG,
            },
            "&.Mui-disabled": {
              backgroundColor: Color.theme.grey[50],
            },
          },
        }}
      >
        <Tooltip   placement="top" title={likeGoogle && t(`${tooltip}`)}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={profile?.locale === "de_DE" ? de : enUS}
          >
            <TextField
              id="date"
              required={required}
              disabled={disabled}
              variant={variant}
              type="date"
              size="small"
              name={name}
              value={value || ""}
              onChange={onChange}
              mindate={mindate ?? new Date(dataSetData?.start_date)}
              maxdate={maxdate ?? new Date(dataSetData?.end_date)}
              placeholder={placeholder}
              label={label}
              onFocus={onFocusInput}
              onBlur={onBlurInput}
              helperText={helperText}
              error={error}
              sx={{
                width: "100%",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </LocalizationProvider>
        </Tooltip>
      </Box>
    </>
  );
};
export default DateInput;
