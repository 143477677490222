import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import enUS from "date-fns/locale/en-US";
import de from "date-fns/locale/de";

import { Color } from "../../Helper";

const DateInputPicker = forwardRef(
  (
    {
      title,
      value = "",
      onChange,
      error,
      helperText,
      fontSize = "0.85rem",
      name = "",
      disabled = false,
      star = false,
      hideTitle = false,
      mb = "1rem",
      borderRadius = 1,
      variant = "outlined",
      placeholder = "",
      label = null,
      likeGoogle = false,
      hidePickerBlurEffect = false,
      tooltip,
      minDate,
      maxDate,
      showFullDate = false,
      onBlur = () => {},
      onFocus = () => {},
      sx,
    },
    _ref
  ) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const ValueRef = useRef(null);
    //redux
    const profile = useSelector((state) => state.settingsSlice?.profile);

    //state
    const [isFocus, setIsFocus] = useState(false);
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);

    const onFocusInput = (e) => {
      setIsFocus(true);
      onFocus(e);
    };

    const onBlurInput = (e) => {
      setIsFocus(false);
      onBlur(e, name, profile?.locale);
    };

    const handleDatePickerOpen = () => {
      setDatePickerOpen(true);
    };

    const handleDatePickerClose = () => {
      if (!hidePickerBlurEffect) {
        if (onBlur) onBlur(value, name);
      }
      setDatePickerOpen(false);
    };

    useImperativeHandle(
      _ref,
      () => ({
        isDateOverlayOpen() {
          return isDatePickerOpen;
        },
      }),
      [isDatePickerOpen]
    );

    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          ...sx,
        }}
      >
        {!hideTitle && (
          <Tooltip   placement="top" title={t(`${tooltip}`)}>
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                display: "flex",
                color: theme.palette.color.mainTitle,
                width: "100%",
                textAlign: "left",
                fontWeight: 600,
                mb: "0.25rem",
                fontSize: fontSize,
              }}
            >
              {" "}
              {title ? title : t("Date")} {star ? "*" : ""}
            </Typography>
          </Tooltip>
        )}
        <Box
          ref={_ref}
          sx={{
            height: "3rem",
            mb: mb,
            width: "100%",
            ".MuiFormControl-root": {
              width: "100%",
            },
            "& .MuiInputLabel-root": {
              color: error
                ? theme.palette.error
                : theme.palette.color.mainTitle,
              fontWeight: theme.typography.fontWeightMediumBold,
              fontSize: fontSize,
              transform: "translate(12px, 13px) scale(1)",
              "&[data-shrink=true]": {
                transform: "translate(12px, 4px) scale(1)",
              },
              "&.Mui-disabled": {
                opacity: 0.4,
              },
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.color.mainTitle,
            },
            ".MuiOutlinedInput-root": {
              width: "100%",
              fontSize: fontSize,
              fontWeight: 600,
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  backgroundColor: !likeGoogle && "rgba(0,0,0,0.03)",
                },
              },
            },
            ".MuiOutlinedInput-input": {
              p: !likeGoogle && "0.4rem",
              backgroundColor: !likeGoogle && Color.white,
              "&:focus": {
                border: !likeGoogle && `1px solid ${Color.oInputBorderBg}`,
              },
            },
            "& input": {
              fontSize: "0.9rem !important",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: likeGoogle
                ? "0 !important"
                : `1px solid ${Color.oInputBorderBg}`,
              borderBottom:
                likeGoogle &&
                isFocus &&
                `1px solid ${theme.palette.color.main} !important`,
            },
            ".MuiButtonBase-root": {
              "&.Mui-disabled": {
                opacity: 0.3,
              },
            },
            ".MuiInputBase-root": {
              // p: likeGoogle && "0.4rem",
              borderRadius: borderRadius,
              backgroundColor:
                likeGoogle && isFocus ? Color.inputBG : "transparent",
              "&:before": {
                borderBottom: likeGoogle && "0 !important",
              },
              "&:hover": {
                backgroundColor: likeGoogle && Color.inputBG,
                "&:before": {
                  borderBottom: likeGoogle && "0 !important",
                },
              },
              "&:focus": {
                backgroundColor: likeGoogle && Color.inputBG,
              },
              "&.Mui-disabled": {
                backgroundColor: Color.theme.grey[50],
              },
            },
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={profile?.locale === "de_DE" ? de : enUS}
          >
            <DatePicker
              views={
                showFullDate ? ["year", "month", "day"] : ["year", "month"]
              }
              inputFormat="dd-MM-yyyy"
              minDate={minDate}
              maxDate={maxDate}
              error={error}
              value={value ? new Date(value) : null}
              onOpen={handleDatePickerOpen}
              onClose={handleDatePickerClose}
              disabled={disabled}
              onChange={(value) => {
                ValueRef.current = value;
                onChange && onChange(value, name, profile?.locale);
              }}
              DialogProps={{
                sx: {
                  zIndex: 1303, // Apply custom zIndex for Dialog
                },
              }}
              PopperProps={{
                sx: {
                  zIndex: 1303, // Apply custom zIndex for Popper
                },
              }}
              renderInput={(params) => (
                <Tooltip
                   
                  placement="top"
                  title={likeGoogle && t(`${tooltip}`)}
                >
                  <TextField
                    {...params}
                    id="date"
                    type="date"
                    size="small"
                    disabled={disabled}
                    placeholder={placeholder}
                    label={label}
                    onFocus={onFocusInput}
                    onBlur={onBlurInput}
                    helperText={helperText}
                    error={error}
                    variant={variant}
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Tooltip>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    );
  }
);
export default DateInputPicker;
