import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { TbChartAreaLineFilled, TbCircles, TbPlug } from "react-icons/tb";
import { IoPeopleCircle, IoPeopleCircleOutline } from "react-icons/io5";
import { RiArrowUpDownLine, RiFolderChartLine } from "react-icons/ri";
import { Box, Button, Divider, useTheme } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineDateRange } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { FiLayers } from "react-icons/fi";
import _ from "underscore";

import CategoryFilterView from "../../../../components/Overlay/ThemeFilter/Component/CategoryFilterView";
import SearchFilterView from "../../../../components/Overlay/ThemeFilter/Component/SearchFilterView";
import DateRangePicker from "../../../../components/Overlay/ThemeFilter/Component/DateRangePicker";
import AddFilterPopOver from "../../../../components/Overlay/ThemeFilter/AddFilterPopover";
import AddFilterView from "../../../../components/Overlay/ThemeFilter/AddFilterView";
import FinbanInfoBulb from "../../../../components/FInbanInfo/FinbanInfoBulb";
import TransactionViewSettingPopOver from "./TransactionViewSettingPopOver";
import initialData, { getDefaultFilters } from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ThemeFilter from "../../../../components/Overlay/ThemeFilter";
import Toggle from "../../../../components/ToggleGroupeView/Toggle";
import { updateFilters } from "../../../../store/slices/global";
import { Constant } from "../../../../Helper";
import store from "../../../../store";

const typeOptions = [
  { uuid: "Income", title: "Income", color: "green" },
  { uuid: "Expense", title: "Expense", color: "red" },
];
const sourceOptions = [
  { uuid: "Option_Planning", title: "Option_Planning" },
  { uuid: "Option_Api", title: "Option_Api" },
  { uuid: "Option_Import", title: "Option_Import" },
  { uuid: "Option_External", title: "Option_External" },
];
const dateOptions = [
  {
    value: 1,
    label: "Due",
  },
  {
    value: 2,
    label: "Invoiced",
  },
];
const ListHeader = ({
  hideState = false,
  hideScenario = false,
  hideCostUnit = false,
  hideCategory = false,
  hideSource = false,
  hideDs = false,
  hideSeqType = false,
  hideContact = false,
  optionsType = null,
  width,
  tab,
}) => {
  const dispatch = useDispatch();
  const isFilterOpen = useRef(false);

  //redux state
  const filter_list = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.list?.[tab] || {}
  );
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isFinbanAccount =
    profile?.email?.includes("@finban.io") ||
    profile?.email?.includes("@e-laborat.de");

  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [isIncludedInFilterList, setIsIncludedInFilterList] = useState([]);

  const {
    searchText = "",
    start_date = "",
    end_date = "",
    typeName = ["Expense", "Income"],
    selectedStates = [],
    selectedCostUnits = [],
    selectedScenarios = [],
    selectedContacts = [],
    selectedCategory = [],
    source = Constant.DefaultSource,
    recurring_type = [],
    selectedDataSource = [],
  } = filter_list;

  //function
  const onClose = (_selectedIds) => {
    isFilterOpen.current = false;
    setIsIncludedInFilterList(_selectedIds);
    setAnchorEl(null);
    let remove = {};
    if ((start_date || end_date) && !_selectedIds?.includes("date")) {
      remove.start_date = "";
      remove.end_date = "";
    }
    if (
      !hideState &&
      selectedStates?.length > 0 &&
      !_selectedIds?.includes("state")
    ) {
      remove.selectedStates = [];
    }

    if (
      !hideScenario &&
      selectedScenarios?.length > 0 &&
      !_selectedIds?.includes("scenario")
    ) {
      remove.selectedScenarios = [];
    }
    if (
      !hideCostUnit &&
      selectedCostUnits?.length > 0 &&
      !_selectedIds?.includes("cost_unit")
    ) {
      remove.selectedCostUnits = [];
    }
    if (
      !hideContact &&
      selectedContacts?.length > 0 &&
      !_selectedIds?.includes("contact")
    ) {
      remove.selectedContacts = [];
    }

    if (
      !hideCategory &&
      selectedCategory?.length > 0 &&
      !_selectedIds?.includes("category")
    ) {
      remove.selectedCategory = [];
    }
    if (typeName?.length !== 2 && !_selectedIds?.includes("typeName")) {
      remove.typeName = ["Expense", "Income"];
    }
    if (
      !hideSource &&
      source?.length !== 4 &&
      !_selectedIds?.includes("source")
    ) {
      remove.source = Constant.DefaultSource;
    }
    if (
      !hideSeqType &&
      recurring_type?.length > 0 &&
      !_selectedIds?.includes("recurring_type")
    ) {
      remove.recurring_type = [];
    }
    if (
      !hideDs &&
      selectedDataSource?.length > 0 &&
      !_selectedIds?.includes("ds")
    ) {
      remove.selectedDataSource = [];
    }
    if (Object?.keys(remove)?.length > 0) {
      updateFilterObj(remove);
    }
  };

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      nested_key: tab,
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  const onClickFilter = useCallback((e) => {
    isFilterOpen.current = true;
    setAnchorEl(e.currentTarget);
  }, []);

  const removeFilter = useCallback((key) => {
    setIsIncludedInFilterList((prev) => prev?.filter((item) => item !== key));
  }, []);

  useDebounce(
    () => {
      if (!isFilterOpen.current && filter_list) {
        let array = [];
        let included_array = [...isIncludedInFilterList];

        const add_key = (key) => {
          array.push(key);
          included_array.push(key);
        };
        const remove_key = (key) => {
          array = array?.filter((item) => item !== key);
          included_array = included_array?.filter((item) => item !== key);
        };

        if (start_date || end_date) {
          add_key("date");
        } else {
          remove_key("date");
        }
        if (searchText?.length > 0) {
          add_key("search");
        } else {
          remove_key("search");
        }
        if (selectedStates?.length > 0 && !hideState) {
          add_key("state");
        } else {
          remove_key("state");
        }

        if (selectedScenarios?.length > 0 && !hideScenario) {
          add_key("scenario");
        } else {
          remove_key("scenario");
        }
        // if (selectedCostUnits?.length > 0 && !hideCostUnit) {
        //   add_key("cost_unit");
        // } else {
        remove_key("cost_unit");
        // }
        if (selectedContacts?.length > 0 && !hideContact) {
          add_key("contact");
        } else {
          remove_key("contact");
        }

        if (selectedCategory?.length > 0 && !hideCategory) {
          add_key("category");
        } else {
          remove_key("category");
        }

        if (typeName?.length !== 2) {
          add_key("typeName");
        } else {
          remove_key("typeName");
        }
        if (source?.length !== 4 && !hideSource) {
          add_key("source");
        } else {
          remove_key("source");
        }
        if (selectedDataSource?.length > 0 && !hideDs) {
          add_key("ds");
        } else {
          remove_key("ds");
        }
        if (recurring_type?.length > 0 && !hideSeqType) {
          add_key("recurring_type");
        } else {
          remove_key("recurring_type");
        }

        included_array = Array.from(new Set(included_array));
        setFiltered(array);
        setIsIncludedInFilterList(included_array);
      }
    },
    500,
    [
      filter_list,
      hideCategory,
      hideCostUnit,
      hideContact,
      hideScenario,
      hideSource,
      hideDs,
      hideSeqType,
      hideState,
      optionsType,
      tab,
    ],
    true
  );

  const FilterOptions = useMemo(
    () => [
      {
        uuid: "date",
        title: "add_date_filter",
        icon: <MdOutlineDateRange />,
        hide: false,
      },
      {
        uuid: "state",
        title: "add_state_filter",
        icon: <TbCircles />,
        hide: hideState,
      },
      {
        uuid: "scenario",
        title: "add_scenario_filter",
        icon: <TbChartAreaLineFilled />,
        hide: hideScenario,
      },
      // {
      //   uuid: "cost_unit",
      //   title: "add_cost_unit_filter",
      //   icon: <RiFolderChartLine />,
      //   hide: hideCostUnit,
      // },
      {
        uuid: "category",
        title: "add_category_filter",
        icon: <LocalOfferIcon />,
        hide: hideCategory,
      },
      {
        uuid: "typeName",
        title: "add_typeName_filter",
        icon: <RiArrowUpDownLine />,
        hide: false,
      },
      {
        uuid: "source",
        title: "add_source_filter",
        icon: <FiLayers />,
        hide: hideSource,
      },
      {
        uuid: "ds",
        title: "add_ds_filter",
        icon: <TbPlug />,
        hide: hideDs,
      },
      {
        uuid: "recurring_type",
        title: "add_recurring_type_filter",
        icon: <TbPlug />,
        hide: hideSeqType,
      },
      {
        uuid: "contact",
        title: "add_contact_filter",
        icon: <IoPeopleCircle />,
        hide: hideContact || !isFinbanAccount,
      },
    ],
    [
      isFinbanAccount,
      hideCategory,
      hideContact,
      hideCostUnit,
      hideSeqType,
      hideScenario,
      hideSource,
      hideDs,
      hideState,
    ]
  );

  return (
    <Box
      sx={{
        // width: "fit-content",
        width: width,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <BulkActionView tab={tab} />
        <SearchView tab={tab} />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: "1.2rem",
            opacity: "0.6",
            alignSelf: "center",
            ml: "0.75rem",
          }}
        />
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent={"flex-start"}
          width="fit-content"
          sx={{
            minWidth: "25rem",
            transition: `all 0.3s ease-in-out`,
            zIndex: 2,
            gap: "0.5rem",
            flexWrap: "wrap",
          }}
        >
          {isIncludedInFilterList?.length > 0 ? null : (
            <AddFilterView
              isIncludedInFilterList={isIncludedInFilterList}
              onClick={onClickFilter}
            />
          )}

          {isIncludedInFilterList?.map((item) => {
            if (item === "date") {
              return (
                <DateFilter
                  tab={tab}
                  key="date"
                  filterKey="date"
                  removeFilter={removeFilter}
                />
              );
            }

            if (item === "state") {
              return (
                <StateFilter
                  tab={tab}
                  key="state"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="state"
                  removeFilter={removeFilter}
                  optionsType={optionsType}
                />
              );
            }

            if (item === "scenario") {
              return (
                <ScenarioFilter
                  tab={tab}
                  key="scenario"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="scenario"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "cost_unit") {
              return (
                <CostUnitFilter
                  tab={tab}
                  key="cost_unit"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="cost_unit"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "contact") {
              return (
                <ContactFilter
                  tab={tab}
                  key="contact"
                  filterKey="contact"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  removeFilter={removeFilter}
                />
              );
            }

            if (item === "category") {
              return (
                <CategoryFilter
                  tab={tab}
                  key="category"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="category"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "typeName") {
              return (
                <TypeFilter
                  tab={tab}
                  key="typeName"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="typeName"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "source") {
              return (
                <SourceFilter
                  tab={tab}
                  key={"source"}
                  isFilterOpen={isFilterOpen}
                  filterKey="source"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "ds") {
              return (
                <DsFilter
                  tab={tab}
                  key="ds"
                  isFilterOpen={isFilterOpen}
                  filterKey="ds"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "recurring_type") {
              return (
                <RecurringTypeFilter
                  tab={tab}
                  key={"recurring_type"}
                  isFilterOpen={isFilterOpen}
                  filterKey="recurring_type"
                  removeFilter={removeFilter}
                />
              );
            }
            return null;
          })}

          {isIncludedInFilterList?.length > 0 ? (
            <AddFilterView
              isIncludedInFilterList={isIncludedInFilterList}
              onClick={onClickFilter}
            />
          ) : null}
          <ResetView
            tab={tab}
            filtered={filtered}
            setIsIncludedInFilterList={setIsIncludedInFilterList}
          />
        </Box>
      </div>
      {!!anchorEl ? (
        <AddFilterPopOver
          anchorEl={anchorEl}
          onClose={onClose}
          selectedIds={isIncludedInFilterList}
          options={FilterOptions}
        />
      ) : null}
      <FinbanInfoBulb
        url={"https://finban.io/documentation/list/listen-ansicht-ueberblick/"}
      />
    </Box>
  );
};
export default ListHeader;

const SearchView = ({ tab, color = "slate" }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  //redux
  const listSearchText = useSelector(
    (state) =>
      state.globalSlice.appliedFilterlist?.list?.[tab]?.searchText || ""
  );
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  //state
  const [searchText, setSearchText] = useState(listSearchText);

  useEffect(() => {
    if (location?.state?.highlightSearch) {
      inputRef.current?.focus();
    }
  }, [location]);

  useEffect(() => {
    if (!listSearchText) {
      setSearchText("");
    }
  }, [listSearchText]);

  useEffect(() => {
    if (tab && dataset) {
      setSearchText(listSearchText);
    }
  }, [tab, dataset]);

  useDebounce(
    () => {
      updateFilterObj({ searchText });
    },
    500,
    [searchText]
  );

  //functions
  const onClickClear = () => {
    setSearchText("");
  };

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      nested_key: tab,
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  const onChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <SearchFilterView
      inputRef={inputRef}
      active={searchText?.length > 0}
      value={searchText}
      color={color}
      onClickClear={onClickClear}
      onChange={onChangeSearch}
    />
  );
};

const DateFilter = ({ tab, filterKey, removeFilter }) => {
  const dispatch = useDispatch();

  const filter_list = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.list?.[tab] || {}
  );
  const { start_date = "", end_date = "", date_type = 1 } = filter_list;

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      nested_key: tab,
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  const onClickReset = (e) => {
    updateFilterObj({
      date_type: 1,
      start_date: "",
      end_date: "",
    });
    removeFilter(filterKey);
  };

  const onChangeDateFilters = (e, value) => {
    updateFilterObj({ date_type: value });
  };

  return (
    <DateRangePicker
      start_date={start_date}
      end_date={end_date}
      onOk={updateFilterObj}
      onClickReset={onClickReset}
      showPreset
      headerView={
        <Toggle
          options={dateOptions}
          value={date_type}
          onChange={onChangeDateFilters}
          sx={{
            ml: "9.25rem",
            mb: "0.25rem",
            "& .MuiButtonBase-root": {
              mx: "0px !important",
            },
          }}
        />
      }
      sx={{
        ml: "1rem",
      }}
    />
  );
};

const StateFilter = ({
  tab,
  type = "list",
  _key = "selectedStates",
  isFilterOpen,
  optionsType,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.globalSlice?.state);
  const selectedStates = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[tab]?.[_key] || []
  );

  const options = useMemo(() => {
    let array = [];
    if (optionsType === "Open") {
      array = state?.filter((o1) =>
        Constant?.openPositionsStates.includes(o1?.title)
      );
    } else if (optionsType === "Bank") {
      array = state?.filter((o1) =>
        Constant?.bookedPositionsStates.includes(o1?.title)
      );
    } else {
      const plannedPositions = state?.filter((o1) =>
        Constant?.plannedState.includes(o1?.title)
      );
      const lead_states = state?.filter((o1) =>
        Constant?.leadState.includes(o1?.title)
      );
      const offer_states = state?.filter((o1) =>
        Constant?.offerState.includes(o1?.title)
      );
      const invoice_states = state?.filter((o1) =>
        Constant?.invoiceState.includes(o1?.title)
      );
      const misc_states = state?.filter((o1) =>
        Constant?.miscState.includes(o1?.title)
      );

      const bookedPositions = state?.filter(
        (o1) =>
          Constant?.creditNotes.includes(o1?.title) || o1?.title === "Booked"
      );

      array = [
        {
          uuid: "planned_state_separator",
          title: "planned_state_separator_title",
          isSectionHeader: true,
          translate: true,
        },
      ];
      array = array.concat(plannedPositions);
      array.push({
        uuid: "lead_open_state_separator",
        title: "lead_open_state_separator_title",
        isSectionHeader: true,
        translate: true,
      });
      array = array.concat(lead_states);
      array.push({
        uuid: "offer_open_state_separator",
        title: "offer_open_state_separator_title",
        isSectionHeader: true,
        translate: true,
      });
      array = array.concat(offer_states);
      array.push({
        uuid: "invoice_open_state_separator",
        title: "invoice_open_state_separator_title",
        isSectionHeader: true,
        translate: true,
      });
      array = array.concat(invoice_states);
      array.push({
        uuid: "misc_open_state_separator",
        title: "misc_open_state_separator_title",
        isSectionHeader: true,
        translate: true,
      });
      array = array.concat(misc_states);
      array.push({
        uuid: "booked_state_separator",
        title: "booked_state_separator_title",
        isSectionHeader: true,
        translate: true,
      });
      array = array.concat(bookedPositions);
    }

    return array;
  }, [optionsType, state]);

  const onChangeValue = (value) => {
    dispatch(
      updateFilters({
        parent_key: type,
        nested_key: tab,
        obj: { [_key]: value },
      })
    );
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="state_filter"
      tooltip="tooltip_state_filter"
      icon={<TbCircles style={{ fontSize: "0.9rem", marginTop: "1px" }} />}
      options={options}
      translate
      selectedIds={selectedStates}
      isActive={selectedStates?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const ScenarioFilter = ({
  tab,
  type = "list",
  _key = "selectedScenarios",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const selectedScenarios = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[tab]?.[_key] || []
  );

  const options = useMemo(() => {
    let array = [];
    scenario?.forEach((o1) => {
      if (o1.title === "Base") {
        array.push({ ...o1, translate: true });
      } else {
        array.push(o1);
      }
    });

    return array;
  }, [scenario]);

  const onChangeValue = (value) => {
    dispatch(
      updateFilters({
        parent_key: type,
        nested_key: tab,
        obj: { [_key]: value },
      })
    );
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="scenario_filter"
      tooltip="tooltip_scenario_filter"
      icon={
        <TbChartAreaLineFilled style={{ fontSize: "1rem", marginTop: "1px" }} />
      }
      options={options}
      selectedIds={selectedScenarios}
      isActive={selectedScenarios?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const CostUnitFilter = ({
  tab,
  type = "list",
  _key = "selectedCostUnits",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const costunits = useSelector((state) => state.globalSlice?.costunits);
  const selectedCostUnits = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[tab]?.[_key] || []
  );

  const onChangeValue = (value) => {
    dispatch(
      updateFilters({
        parent_key: type,
        nested_key: tab,
        obj: { [_key]: value },
      })
    );
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  const onClickManage = () => {
    const dataset = store?.getState().boardSlice?.dataSetData?.uuid;
    if (dataset) {
      navigate(`/${initialData.path.organization}/${dataset}/cost-units`);
    }
  };

  return (
    <ThemeFilter
      heading="costunit_filter"
      tooltip="tooltip_costunit_filter"
      icon={
        <RiFolderChartLine style={{ fontSize: "1rem", marginTop: "1px" }} />
      }
      options={costunits}
      selectedIds={selectedCostUnits}
      isActive={selectedCostUnits?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      onClickManage={onClickManage}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const ContactFilter = ({
  tab,
  type = "list",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  const selectedContacts = useSelector(
    (state) =>
      state.globalSlice?.appliedFilterlist?.[type]?.[tab]?.selectedContacts ||
      []
  );

  const onChangeValue = (value) => {
    dispatch(
      updateFilters({
        parent_key: type,
        nested_key: tab,
        obj: { selectedContacts: value },
      })
    );
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  const onClickManage = () => {
    const dataset = store?.getState().boardSlice?.dataSetData?.uuid;
    if (dataset) {
      navigate(
        `/${initialData.path.organization}/${dataset}/"customers/customers-list"`
      );
    }
  };

  return (
    <ThemeFilter
      heading={`contact_filter`}
      tooltip={`contact_filter_tooltip`}
      icon={<IoPeopleCircle style={{ fontSize: "1rem", marginTop: "1px" }} />}
      selectedIds={selectedContacts}
      isActive={selectedContacts?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      onClickManage={onClickManage}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
      searchType="server"
      end_point={"customers"}
      displayKey="name"
      showDefaultColor
      query_options_payload={{
        dataset: dataSetData?.uuid,
        type: [1, 2],
      }}
    />
  );
};

const CategoryFilter = ({
  tab,
  type = "list",
  _key = "selectedCategory",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  //redux
  const selectedCategory = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[tab]?.[_key] || []
  );

  //functions
  const onChangeValue = (value) => {
    dispatch(
      updateFilters({
        parent_key: type,
        nested_key: tab,
        obj: { [_key]: value },
      })
    );
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <CategoryFilterView
      selectedIds={selectedCategory}
      categoryType={3}
      onClickReset={onClickReset}
      onChangeValue={onChangeValue}
      isFilterOpen={isFilterOpen}
    />
  );
};

const TypeFilter = ({
  tab,
  type = "list",
  _key = "typeName",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const typeName = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[tab]?.[_key] || []
  );

  const onChangeValue = (value) => {
    dispatch(
      updateFilters({
        parent_key: type,
        nested_key: tab,
        obj: { [_key]: value },
      })
    );
  };

  const onClickReset = () => {
    onChangeValue(["Expense", "Income"]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="Type Filter"
      tooltip="tooltip_Type_filter"
      icon={
        <RiArrowUpDownLine style={{ fontSize: "1rem", marginTop: "2px" }} />
      }
      options={typeOptions}
      translate
      selectedIds={typeName}
      isActive={typeName?.length !== 2}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const SourceFilter = ({
  tab,
  type = "list",
  _key = "source",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const source = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[tab]?.[_key] || []
  );

  const onChangeValue = (value) => {
    dispatch(
      updateFilters({
        parent_key: type,
        nested_key: tab,
        obj: { [_key]: value },
      })
    );
  };

  const onClickReset = () => {
    onChangeValue(Constant.DefaultSource);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="Source Filter"
      tooltip="tooltip_source_filter"
      icon={<FiLayers style={{ fontSize: "1rem", marginTop: "2px" }} />}
      options={sourceOptions}
      translate
      selectedIds={source}
      isActive={source?.length !== 4}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const RecurringTypeFilter = ({
  type = "list",
  tab,
  _key = "recurring_type",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const recurring_type = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const onChangeValue = (value) => {
    dispatch(
      updateFilters({
        parent_key: type,
        nested_key: tab,
        obj: { [_key]: value },
      })
    );
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="Recurring Type Filter"
      tooltip="tooltip_recurring_type_filter"
      icon={<FiLayers style={{ fontSize: "1rem", marginTop: "2px" }} />}
      options={initialData.recurringTypeTabs}
      translate
      selectedIds={recurring_type}
      isActive={recurring_type?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const DsFilter = ({
  type = "list",
  tab,
  _key = "selectedDataSource",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const optionsById = useRef({});
  const dispatch = useDispatch();

  //state
  const [options, setOptions] = useState([]);

  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const selectedDataSource = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[tab]?.[_key] || []
  );
  useEffect(() => {
    let array = [];
    datasetAccountList?.forEach((o1) => {
      if (!o1?.internal_dataset && o1?.type !== 10) {
        array.push({
          uuid: o1.uuid,
          hideIcon: true,
          title: o1?.title,
        });
      }
    });
    optionsById.current = _.groupBy(array, "uuid");
    setOptions(array);
  }, [datasetAccountList]);

  //functions
  const onChangeValue = (value) => {
    dispatch(
      updateFilters({
        parent_key: type,
        nested_key: tab,
        obj: { [_key]: value },
      })
    );
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="DS Filter"
      tooltip="tooltip_ds_filter"
      icon={<TbPlug style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={options}
      translate
      selectedIds={selectedDataSource}
      isActive={selectedDataSource?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const ResetView = ({
  color = "slate",
  tab,
  filtered,
  setIsIncludedInFilterList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const activeFilter = filtered?.length > 0;
  if (!activeFilter) {
    return null;
  }

  //functions
  const onClickReset = () => {
    setIsIncludedInFilterList([]);
    const defaultFilters = getDefaultFilters();
    updateFilterObj(defaultFilters.list?.[tab]);
  };

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      nested_key: tab,
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  return (
    <Button
      onClick={onClickReset}
      sx={{
        borderRadius: 0,
        border: "none",
        textTransform: "initial",
        color: theme.palette.color[color][500],
        fontWeight: 600,
        ml: "1.5rem",
        mr: "0.5rem",
        lineHeight: "0.875rem",
        "&:hover": {
          backgroundColor: theme.palette.color[color][100],
          textDecoration: "underline",
        },
      }}
    >
      {t("reset_filters")}
    </Button>
  );
};

const BulkActionView = ({ tab }) => {
  const popupStatus5 = useSelector(
    (state) => state?.datasetSlice?.popupStatus5
  );
  if (tab === "bulkUnCatArray") {
    return null;
  }
  return (
    <TransactionViewSettingPopOver
      selectedRowData={popupStatus5?.payload?.selectedRowData}
      resetKanban={popupStatus5?.payload?.resetKanban}
      tab={tab}
    />
  );
};
