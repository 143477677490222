import { Stack, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

import KeyboardSortCutKey from "../KeyboardSortCutKey";
import TailwindButton from "./TailwindButton";
import theme from "../../theme";

const OverlayFooter = ({
  modalType,
  loading,
  onCancel,
  onClickAction,
  textAdd,
  textCancel,
  disable,
  tooltipAdd,
  addIcon,
  cancelIcon,
  sx,
  addButtonSx,
  cancelButtonSx,
  hideAddAction,
  footerView,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={"flex-end"}
      sx={sx}
    >
      {footerView}
      <span style={{ display: "flex", alignItems: "center" }}>
        {onCancel ? (
          <TailwindButton
            disableElevation
            onClick={onCancel}
            text={textCancel ? t(textCancel) : t("Cancel")}
            variant="contained"
            type="edit"
            icon={
              cancelIcon ? (
                cancelIcon
              ) : (
                <KeyboardSortCutKey
                  keys={["ESC"]}
                  theme={theme}
                  action={onCancel}
                  style={{
                    marginLeft: "0.5rem",
                    paddingBlock: "5px",
                  }}
                />
              )
            }
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              ml: "0rem",
              py: "0.35rem",
              px: "0.75rem",
              border: `1px solid ${theme.palette.color.slate[300]}`,
              ...cancelButtonSx,
            }}
          />
        ) : null}
        {!hideAddAction ? (
          <Tooltip title={t(tooltipAdd)} followCursor placement="top">
            <span>
              <TailwindButton
                disableElevation
                disable={disable}
                onClick={onClickAction}
                variant="contained"
                loading={loading}
                color={theme.palette.color.white}
                backgroundColor={theme.palette.color.primary.main}
                text={
                  loading
                    ? ""
                    : textAdd
                      ? t(textAdd)
                      : modalType === "add"
                        ? t("Add")
                        : t("Update")
                }
                icon={
                  loading ? undefined : addIcon ? (
                    addIcon
                  ) : (
                    <KeyboardSortCutKey
                      keys={["Enter"]}
                      theme={theme}
                      action={disable ? undefined : onClickAction}
                      style={{
                        marginLeft: "0.5rem",
                        opacity: disable ? 0.15 : 1,
                        borderColor: theme.palette.color.primary[700],
                        backgroundColor: theme.palette.color.primary[100],
                      }}
                      keyStyle={{
                        color: theme.palette.color.primary[500],
                      }}
                    />
                  )
                }
                sx={{
                  py: loading ? "1.1rem" : "0.35rem",
                  px: "0.75rem",
                  display: "flex",
                  flexDirection: "row-reverse",
                  "&.Mui-disabled": {
                    backgroundColor: theme.palette.color.slate[200],
                    color: theme.palette.color.slate[400],
                  },
                  ...addButtonSx,
                }}
              />
            </span>
          </Tooltip>
        ) : null}
      </span>
    </Stack>
  );
};
export default OverlayFooter;
