import {
  ClickAwayListener,
  useTheme,
  Popper,
  Paper,
  Fade,
  Box,
} from "@mui/material";
import { eachMonthOfInterval, format } from "date-fns";
import { useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getTailwindColor,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import StackCharts from "../../../../components/Charts/StackCharts";
import QuickLinkView from "./Component/QuickLinkView";
import { Constant } from "../../../../Helper";
import EmptyView from "./Component/EmptyView";

const open_state = ["Offer draft", "Offer sent", "Offer accepted"];
const closed_state = ["Offer partially calculated", "Offer calculated"];
const link_state = ["Offer draft", "Offer sent", "Others"];
const expanded_link_state = [
  "Offer accepted",
  "Offer partially calculated",
  "Offer calculated",
  "Offer archived",
  "Offer rejected",
  "All",
  "Offer draft",
  "Offer sent",
  "Close",
];
const state_color = Constant?.defaultState?.["Offer draft"]?.color || "slate";
const OfferStatusWidget = ({ widgetType, widget }) => {
  const overlayRef = useRef(null);
  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const period_start_date = widget?.start_date;
  const period_end_date = widget?.end_date;
  //state
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const multiStatesIds = useMemo(() => {
    let array = [];

    Constant.offerState.forEach((item) => {
      if (stateByTitle?.[item]?.[0]?.uuid) {
        array.push(stateByTitle?.[item]?.[0]?.uuid);
      }
    });
    return array;
  }, [stateByTitle]);

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "offer_status_over_time",
        widget: {
          start_date: widget?.start_date,
          end_date: widget?.end_date,
          scenarios: widget?.scenarios,
        },
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataset,
        group_by: ["state", "category"],
        from_payment_date: widget?.start_date,
        to_payment_date: widget?.end_date,
        multiScenarioIds: widget?.scenarios,
        multiStatesIds,
      };
      if (!use_global_categories) {
        param.category_dataset = dataset;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    priority: 3,
    enabled:
      !!dataset &&
      !!period_end_date &&
      !!period_start_date &&
      multiStatesIds?.length > 0,
  });

  const isFetching =
    WidgetData.isFetching || WidgetData.isLoading || !WidgetData.isSuccess;

  //life cycle method
  useDebounce(
    () => {
      if (
        period_start_date &&
        period_end_date &&
        WidgetData?.data &&
        !isFetching
      ) {
        setLoading(true);
        let dummyData = [];
        let formateString = "yyyy-MM-dd";
        formateString = "MMM-yyyy";
        let dates = eachMonthOfInterval({
          start: new Date(period_start_date),
          end: new Date(period_end_date),
        });

        let periodData = _.groupBy(
          WidgetData?.data?.results || [],
          ({ month }) => format(new Date(month), formateString)
        );

        if (WidgetData?.data?.results?.length > 0) {
          let obj = {};
          // let liquidity = 0;
          dates?.forEach((element) => {
            let date = format(new Date(element), formateString);
            obj = {
              due_date: date,
            };
            const item = periodData?.[date] ?? [];

            let open = item
              ?.filter(
                (o1) =>
                  o1?.state &&
                  open_state?.includes(o1?.state) &&
                  (o1?.category
                    ? !Constant?.excludeCategoryTypes?.includes(
                        selectionCategoriesByID?.[o1?.category]?.[0]
                          ?.category_type
                      )
                    : true)
              )
              ?.reduce(
                (total, entry) =>
                  parseFloat(total) +
                  parseFloat(entry?.outflow ?? 0) +
                  parseFloat(entry?.inflow ?? 0),
                0
              );
            obj.open = open;
            let closed = item
              ?.filter(
                (o1) =>
                  o1?.state &&
                  closed_state?.includes(o1?.state) &&
                  (o1?.category
                    ? !Constant?.excludeCategoryTypes?.includes(
                        selectionCategoriesByID?.[o1?.category]?.[0]
                          ?.category_type
                      )
                    : true)
              )
              ?.reduce(
                (total, entry) =>
                  parseFloat(total) +
                  parseFloat(entry?.outflow ?? 0) +
                  parseFloat(entry?.inflow ?? 0),
                0
              );
            // liquidity = liquidity + closed + open;
            obj.closed = closed;
            // obj.liquidity = liquidity;
            dummyData.push(obj);
          });
        }
        setTimeout(() => {
          setLoading(false);
          setData(dummyData);
        }, 250);
      }
    },
    300,
    [period_start_date, period_end_date, WidgetData?.data, isFetching],
    true
  );

  //functions
  const onClickOthers = () => setAnchorEl(overlayRef.current);

  const onClickAway = () => setAnchorEl(null);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "1rem",
      }}
    >
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data?.length > 0 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "80%",
            }}
          >
            <StackCharts
              data={data}
              barsData={[
                {
                  key: "offer_status_closed_tooltip_label",
                  dataKey: "closed",
                  stackId: "a",
                  fill: getTailwindColor("slate", 300),
                  color: "slate",
                  shade: 300,
                },
                {
                  key: "offer_status_open_tooltip_label",
                  dataKey: "open",
                  stackId: "a",
                  fill: getTailwindColor(state_color, 200),
                  color: state_color,
                  shade: 200,
                },
              ]}
              // linesData={[
              //   {
              //     key: "liquidity",
              //     dataKey: "liquidity",
              //     stroke: theme.palette.color.sky[700],
              //     strokeWidth: 1,
              //     color: "sky",
              //     strokeDasharray: "1 1",
              //     position: 1,
              //     dot: false,
              //     type: "linear",
              //   },
              // ]}
            />
          </div>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: "1.5rem",
              bottom: "1.5rem",
            }}
          >
            <Box
              ref={overlayRef}
              sx={{
                position: "relative",
                pt: "0.75rem",
                alignSelf: "flex-end",
                "& .base-Popper-root": {
                  transform: "translate3d(16px, 32px, 0px) !important",
                  zIndex: 1,
                },
              }}
            >
              <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement={"top-end"}
                transition
                disablePortal
              >
                {({ TransitionProps }) => (
                  <ClickAwayListener onClickAway={onClickAway}>
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper
                        sx={{
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                          width: "32rem",
                          pt: "1rem",
                          px: "1rem",
                          mb: "1rem",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "flex-end",
                        }}
                      >
                        {expanded_link_state?.map((item) => (
                          <QuickLinkView
                            key={item}
                            title={item}
                            state_color={state_color}
                            moreState={Constant.offerState}
                            onClickClose={onClickAway}
                            data={WidgetData?.data?.results || []}
                            start_date={period_start_date}
                            end_date={period_end_date}
                            sx={{
                              mb: "1rem",
                            }}
                          />
                        ))}
                      </Paper>
                    </Fade>
                  </ClickAwayListener>
                )}
              </Popper>
              {link_state?.map((item) => (
                <QuickLinkView
                  key={item}
                  title={item}
                  onClickOthers={onClickOthers}
                  moreState={Constant.offerState}
                  data={WidgetData?.data?.results || []}
                  start_date={period_start_date}
                  end_date={period_end_date}
                />
              ))}
            </Box>
          </span>
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default OfferStatusWidget;
