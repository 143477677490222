import {
  IconButton,
  Typography,
  Container,
  TextField,
  Divider,
  Alert,
  Paper,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useCallback, useRef, useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { styled, useTheme } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import MailIcon from "@mui/icons-material/Mail";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useFormik } from "formik";
import { format } from "date-fns";
import * as Yup from "yup";

import ThemeSearchInput from "./../../../components/ThemeSearchInput";
import SubscriptionBadge from "../../../components/SubscriptionBadge";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../components/ComponentLoader";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import ComingSoonView from "../../../components/ComingSoonView";
import CustomModal from "../../../components/Model/CustomModal";
import useSubscriptions from "../../../hooks/useSubscriptions";
import MenuView from "../../../components/Overlay/MenuView";
import AddButton from "../../../components/AddButton";
import EndPoints from "../../../APICall/EndPoints";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";

const tabOptions = [
  {
    value: 1,
    label: "Users",
  },
  {
    value: 2,
    label: "Groups",
  },
];

const menuOptions = [
  {
    value: 1,
    label: "Resend Activation link",
    icon: <MailIcon />,
  },
  {
    value: 2,
    label: "Deactivate",
    icon: <PersonRemoveIcon />,
  },
  {
    value: 3,
    label: "Activate",
    icon: <PersonAddIcon />,
  },
  {
    value: 4,
    label: "Edit",
    icon: <EditIcon />,
  },
];

const inputStyle = {
  "& .MuiInputBase-root": {
    fontSize: "1rem",
  },
  "& .MuiFormLabel-root": {
    fontSize: "1rem",
  },
};
// activated  means the user's email has already been confirmed by clicking on the activation link
// is_active means: if the user can logins or not (the "Activate" action)
const UserNameCell = (params) => {
  const item = params?.row;
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Stack>
        <Typography variant="subtitle2" fontWeight={"fontWeightMediumBold"}>
          {`${item?.first_name} ${item?.last_name}`}
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight={"fontWeightMedium"}
          color="color.slate.600"
        >
          {item?.email}
        </Typography>
      </Stack>
    </Stack>
  );
};
const UserRoleCell = (params) => {
  const { t } = useTranslation();
  const item = params?.row;
  return (
    <Typography
      variant="subtitle2"
      fontWeight={"fontWeightMedium"}
      color="color.slate.600"
    >
      {item?.is_owner ? t("Owner") : t("Maintainer")}
    </Typography>
  );
};
const StatusCell = (params) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isActive = params?.row?.is_active;
  return (
    <Box
      backgroundColor={isActive ? "color.emerald.100" : "color.red.100"}
      sx={{
        px: 2,
        py: 0.5,
        borderRadius: theme.borderRadius.borderRadiusMD,
      }}
    >
      <Typography
        variant="body2"
        fontWeight={"fontWeightMedium"}
        color={isActive ? "color.emerald.600" : "color.red.600"}
      >
        {isActive ? t("Active") : t("Deactivated")}
      </Typography>
    </Box>
  );
};
const TwoStepCell = (params) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isTwoStep = true;
  return (
    <Box
      backgroundColor={isTwoStep ? "color.emerald.100" : "color.red.100"}
      sx={{
        px: 2,
        py: 0.5,
        borderRadius: theme.borderRadius.borderRadiusMD,
      }}
    >
      <Typography
        variant="body2"
        fontWeight={"fontWeightMedium"}
        color={isTwoStep ? "color.emerald.600" : "color.red.600"}
      >
        {isTwoStep ? t("Enabled") : t("Disabled")}
      </Typography>
    </Box>
  );
};
const JoinedDateCell = (params) => {
  const item = params?.row;
  return (
    <Typography
      variant="subtitle2"
      fontWeight={"fontWeightMedium"}
      color="color.slate.600"
    >
      {format(new Date(item?.creation_date), "dd MMM yyyy, hh:mm a")}
    </Typography>
  );
};
const ActionCell = (params) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cellRef = useRef(null);
  const itemRow = useRef(null);
  const [isSubscriptionValid] = useSubscriptions();

  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  //api
  const resendActivationLink = async (obj) => {
    await APICall(
      "post",
      `${EndPoints.users}${obj?.uuid}/resend-activation/`,
      {},
      { doNotCatchRespond: true }
    ).then((response) => {
      if (response.status === 200 && response?.data?.success) {
        enqueueSnackbar(t("user_activation_link_resent_successfully"), {
          variant: "success",
          autoHideDuration: 2000,
          preventDuplicates: true,
        });
      } else {
        enqueueSnackbar(t("something_went_wrong"), {
          variant: "error",
          autoHideDuration: 4000,
          preventDuplicates: true,
        });
      }
    });
  };

  const deactivateUser = async (obj) => {
    await APICall("delete", `${EndPoints.users}${obj?.uuid}/`, null, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 200 && response?.data) {
        queryClient.invalidateQueries({
          queryKey: ["users"],
        });
      } else {
        enqueueSnackbar(t("something_went_wrong"), {
          variant: "error",
          autoHideDuration: 4000,
          preventDuplicates: true,
        });
      }
    });
  };

  const activateUser = async (obj) => {
    await APICall("POST", `${EndPoints.users}${obj?.uuid}/reactivate/`, null, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 200 && response?.data) {
        queryClient.invalidateQueries({
          queryKey: ["users"],
        });
      } else {
        enqueueSnackbar(t("something_went_wrong"), {
          variant: "error",
          autoHideDuration: 4000,
          preventDuplicates: true,
        });
      }
    });
  };

  const updateUser = async (obj) => {
    setLoading(true);
    await APICall("patch", EndPoints.users + `${obj?.uuid}/`, obj)
      .then((response) => {
        if (response.status === 200 && response.data) {
          queryClient.invalidateQueries({
            queryKey: ["users"],
          });
          setOpen(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //functions
  const handleClose = () => {
    setAnchorElMenu(null);
    setOpen(false);
  };

  const onClickItem = (e, value, item) => {
    handleClose();
    if (value === 1) {
      resendActivationLink(item);
    }
    if (value === 2) {
      deactivateUser(item);
    }
    if (value === 3) {
      if (
        isSubscriptionValid({
          showMessage: true,
          type: "users",
        })
      ) {
        activateUser(item);
      }
    }
    if (value === 4) {
      itemRow.current = item;
      setOpen(true);
    }
  };

  const onOk = ({ payload }) => {
    const item = payload?.item;
    // deleteUserByIdApi(item?.uuid);
  };

  const onClickDelete = async (item) => {
    dispatch(
      setPopupStatus3({
        open: true,
        overlay_type: "delete",
        onConfirm: onOk,
        payload: {
          message: `${t("Are_you_sure_delete_this")} ${t("user")}?`,
          item,
        },
      })
    );
  };

  const onSubmit = (values) => {
    updateUser({
      uuid: itemRow.current?.uuid,
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
    });
  };

  const onClick = () => {
    setAnchorElMenu(cellRef.current);
  };

  return (
    <>
      <div>
        {open && (
          <FormView
            open={open}
            handleClose={handleClose}
            defaultData={itemRow.current}
            onSubmit={onSubmit}
            loadingAdd={loading}
            modalType="edit"
          />
        )}
      </div>

      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={menuOptions}
          handleClose={handleClose}
          onClickItem={onClickItem}
          item={params?.row}
          hideValue={
            params?.row?.is_owner
              ? [1, 3, 2]
              : params?.row?.is_active
                ? params?.row?.activated
                  ? [1, 3]
                  : [3]
                : [2]
          }
        />
      ) : null}
      <IconButton
        ref={cellRef}
        aria-label="more"
        id="long-button"
        aria-haspopup="true"
        onClick={onClick}
        sx={{ background: "transparent !important" }}
      >
        <MoreVertIcon
          sx={{
            color: theme.palette.color.slate[600],
          }}
        />
      </IconButton>
    </>
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeader, .MuiDataGrid-columnSeparator":
    {
      minHeight: `${theme.typography.pxToRem(46)} !important`,
      height: `${theme.typography.pxToRem(46)} !important`,
    },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within ": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.color.violet[50],
  },
  "& .MuiDataGrid-iconButtonContainer": {
    display: "none",
  },

  "& .MuiDataGrid-columnHeaderTitle": {
    color: theme.palette.color.slate[600],
    fontWeight: theme.typography.fontWeightMediumBold,
  },
  "& .MuiDataGrid-virtualScroller": {
    marginTop: `${theme.typography.pxToRem(46)} !important`,
  },
}));

const Users = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [tab, setTab] = useState(tabOptions?.[0]?.value);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "inline-flex",
        flexDirection: "column",
        backgroundColor: "color.appThemeBg",
        position: "relative",
      }}
    >
      <Container
        sx={{
          width: "80%",
          height: "100%",
          display: "inline-flex",
          flexDirection: "column",
          minHeight: "calc(100% - 6rem)",
          overflow: "auto",
          maxWidth: "none",
          p: "0rem !important",
          ml: "90px",
          ...theme.thinScrollBar,
        }}
      >
        <Typography
          variant="h5"
          color="color.slate.700"
          fontWeight={"fontWeightMediumBold"}
          sx={{
            mt: "4rem",
          }}
        >
          {t("Users List")}
        </Typography>
        <Typography
          variant="subtitle2"
          color="color.description"
          fontWeight={"fontWeightMedium"}
          sx={{
            my: "0.5rem",
            mb: "1.5rem",
          }}
        >
          {t("user_list_description")}
        </Typography>
        <Divider
          sx={{
            mb: "1rem",
          }}
        />
        {/* <ThemeTabs options={tabOptions} tab={tab} setTab={setTab} /> */}
        <Paper
          sx={{
            width: "100%",
            maxHeight: "75%",
            overflow: "auto",
            backgroundColor: "color.white",
            alignItems: "center",
            display: "inline-flex",
            flexDirection: "column",
            p: 8,
            borderRadius: theme.borderRadius.main,
            ...theme.thinScrollBar,
          }}
        >
          {tab === 1 ? <UserListView /> : <ComingSoonView />}
        </Paper>
      </Container>
    </Box>
  );
};

export default Users;

const UserListView = () => {
  const { t } = useTranslation();
  const is_owner = useSelector(
    (state) => state.settingsSlice?.profile?.is_owner
  );
  const [rows, setRows] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const columns = [
    {
      field: "userName",
      headerName: t("USER"),
      flex: 1.5,
      renderCell: UserNameCell,
    },
    {
      field: "role",
      headerName: t("ROLE"),
      flex: 0.6,
      renderCell: UserRoleCell,
    },
    {
      field: "is_active",
      headerName: t("STATUS"),
      flex: 0.6,
      renderCell: StatusCell,
    },
    {
      field: "isTwoStep",
      headerName: t("TWO-STEP"),
      flex: 0.5,
      renderCell: TwoStepCell,
    },
    {
      field: "joinedDate",
      headerName: t("JOINED DATE"),
      flex: 1,
      renderCell: JoinedDateCell,
    },
    {
      field: "actions",
      headerName: t("ACTIONS"),
      flex: 0.5,
      renderCell: ActionCell,
    },
  ];

  const getRowId = useCallback((row) => row?.uuid, []);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "100%",
        }}
      >
        <SearchView setRows={setRows} setIsFetching={setIsFetching} />
        <Stack direction={"row"} sx={{ alignItems: "center" }}>
          {is_owner ? (
            <SubscriptionBadge type="users" sx={{ mr: "1rem" }} />
          ) : null}
          {is_owner ? <AddButtonView /> : null}
        </Stack>
      </Stack>
      {isFetching ? (
        <ComponentLoader
          loading
          isSkeleton
          skeletonCount={4}
          placeHolderHeight="4rem"
          skeltonSx={{
            transform: "scale(1,0.9)",
          }}
          sx={{
            mt: "2rem",
          }}
        />
      ) : (
        <>
          {is_owner ? (
            <StyledDataGrid
              rows={rows || []}
              columns={columns}
              getRowId={getRowId}
              disableColumnFilter
              disableColumnSorting
              disableColumnMenu
              disableColumnSelector
              disableMultipleColumnsSorting
              disableSelectionOnClick
              disableRowSelectionOnClick
              disableCellSelectionOnClick
              disableDensitySelector
              autoHeight
              hideFooter
              sx={{ mt: "1rem", border: 0 }}
            />
          ) : (
            <Alert severity="info">{t("maintainer_user_list_info")}</Alert>
          )}
        </>
      )}
    </div>
  );
};

const SearchView = ({ setRows, setIsFetching }) => {
  const { t } = useTranslation();

  const userEmail = useSelector((state) => state.settingsSlice?.profile?.email);
  const is_owner = useSelector(
    (state) => state.settingsSlice?.profile?.is_owner
  );

  const [searchText, setSearchText] = useState("");

  //api
  const getUsers = async () => {
    let result = null;
    await APICall("get", EndPoints.users).then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data.results;
      }
    });
    return result;
  };

  const UsersList = useQuery({
    queryKey: ["users"],
    queryFn: ({ signal }) => {
      const result = getUsers();
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    refetchOnMount: true,
    priority: 0,
  });

  //functions
  const onChangeSearch = (e) => {
    setSearchText(e?.target?.value);
  };

  //lifecycle
  useDebounce(
    () => {
      if (!UsersList?.isFetching && UsersList?.data) {
        if (searchText?.length > 0) {
          setRows(
            UsersList?.data?.filter(
              (item) =>
                item?.first_name
                  ?.toLowerCase()
                  ?.includes(searchText?.toLowerCase()) ||
                item?.last_name
                  ?.toLowerCase()
                  ?.includes(searchText?.toLowerCase())
            )
          );
        } else {
          setRows(UsersList?.data);
        }
      }
    },
    500,
    [UsersList?.data, UsersList?.isFetching, searchText, userEmail],
    true
  );

  useDebounce(
    () => {
      setIsFetching(UsersList?.isFetching);
    },
    200,
    [UsersList?.isFetching],
    true
  );

  if (!is_owner) {
    return null;
  }

  return (
    <ThemeSearchInput
      width="20rem"
      placeholder={t("Search user")}
      inputProps={{
        onChange: onChangeSearch,
        value: searchText,
      }}
    />
  );
};

const AddButtonView = () => {
  const { t } = useTranslation();
  const [isSubscriptionValid] = useSubscriptions();

  const [open, setOpen] = useState(false);

  //api
  const createNewUser = async (obj) => {
    await APICall("post", EndPoints.users, obj).then((response) => {
      if (response.status === 201 && response.data) {
        setOpen(false);
        enqueueSnackbar(t("new user added successfully"), {
          variant: "success",
          autoHideDuration: 5000,
          preventDuplicates: true,
        });
      }
    });
  };

  //functions
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = async () => {
    if (
      isSubscriptionValid({
        showMessage: true,
        type: "users",
      })
    ) {
      setTimeout(() => {
        setOpen(true);
      }, 0);
    }
  };

  const onSubmit = (values) => {
    addUserMutation.mutate(values);
  };

  const addUserMutation = useMutation({
    mutationFn: (obj) => createNewUser(obj),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["users"],
      }),
  });

  return (
    <div>
      <AddButton
        onClick={handleOpen}
        tooltipLabel={t("Add User")}
        label={t("Add User")}
        keyboardShortcuts={["N"]}
      />
      {!!open ? (
        <FormView
          open={open}
          loadingAdd={addUserMutation?.isPending}
          onSubmit={onSubmit}
          handleClose={handleClose}
          modalType="add"
        />
      ) : null}
    </div>
  );
};

const FormView = ({
  open,
  loadingAdd,
  defaultData,
  onSubmit,
  handleClose,
  modalType = "add",
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      first_name: defaultData?.first_name || "",
      last_name: defaultData?.last_name || "",
      email: defaultData?.email || "",
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("Enter a valid email"))
        .required(t("Email is required")),
      first_name: Yup.string().trim().required(t("First Name is required")),
      last_name: Yup.string().trim().required(t("Last Name is required")),
    }),
  });

  return (
    <CustomModal
      open={open}
      hideClose
      loadingAdd={loadingAdd}
      modalType={modalType}
      onAdd={formik?.handleSubmit}
      onClose={handleClose}
      heading={t("User")}
    >
      <Box
        sx={{
          width: "45rem",
          backgroundColor: theme.palette.color.white,
          p: "2.5rem",
          borderRadius: theme.borderRadius.main,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <TextField
              size={"small"}
              autoComplete="given-name"
              name="first_name"
              required
              fullWidth
              id="first_name"
              label={t("First_Name")}
              value={formik.values.first_name}
              onChange={open ? formik.handleChange : undefined}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
              sx={inputStyle}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              required
              fullWidth
              id="last_name"
              label={t("Last_Name")}
              name="last_name"
              autoComplete="family-name"
              value={formik.values.last_name}
              onChange={open ? formik.handleChange : undefined}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
              sx={inputStyle}
              size={"small"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="sign_up_email"
              label={t("Email_Address")}
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={open ? formik.handleChange : undefined}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={inputStyle}
              size={"small"}
              disabled={modalType === "edit"}
            />
          </Grid>
          {modalType === "add" ? (
            <Alert
              severity="info"
              sx={{
                mt: "1.25rem",
                ml: "0.75rem",
              }}
            >
              {t("invite_user_form_mail_sent_info")}
            </Alert>
          ) : null}
        </Grid>
      </Box>
    </CustomModal>
  );
};
