import {
  useImperativeHandle,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Alert, Box, Drawer, Stack, Typography } from "@mui/material";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import TitleIcon from "@mui/icons-material/Title";
import NotesIcon from "@mui/icons-material/Notes";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useSnackbar } from "notistack";
import { set } from "date-fns";
import _ from "underscore";

import DescriptionInput from "../../../../../components/Overlay/DescriptionInput";
import { setContactOverlayStatus } from "../../../../../store/slices/datasets";
import TailwindButton from "../../../../../components/Overlay/TailwindButton";
import TitleInput from "../../../../../components/Overlay/TitleInput";
import { setRefreshData } from "../../../../../store/slices/appmain";
import { GlobalContext } from "../../../../../GlobalContextWrapper";
import DropDown from "../../../../../components/Overlay/DropDown";
import { formatDateToLocal } from "../../../../../Helper/data";
import { Constant } from "../../../../../Helper";

const DrawerModalContactEditForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const isFormUpdated = useRef(false);
  const actionRef = useRef(null);
  const updatedObj = useRef({});
  const globalContext = useContext(GlobalContext);
  const clientFunctionRef = globalContext?.clientFunctionRef;
  //redux
  const contactOverlayStatus = useSelector(
    (state) => state.datasetSlice?.contactOverlayStatus
  );
  const modalType = contactOverlayStatus?.payload?.modalType;

  //state
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({});
  const [error, setError] = useState(null);

  const showSaved = modalType === "edit" && isFormUpdated.current;
  const disabled = false;

  //api
  const getClientApi = async (obj) => {
    const client = await clientFunctionRef.current?.getClientApi(obj);
    if (client) {
      setItem(client);
    } else {
      onClose();
    }
  };

  //functions
  const onClose = async () => {
    if (!global.isInputFocused) {
      dispatch(setContactOverlayStatus(null));
    }
  };

  const updateFormFlag = (obj) => {
    if (obj) {
      updatedObj.current = {
        ...updatedObj.current,
        item: { ...(updatedObj.current?.item || {}), ...obj },
      };
      setItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
    setError({});
    isFormUpdated.current = true;
  };

  const handleDescriptionChange = (e) => {
    let text = e.target.value;
    setItem((prev) => ({ ...prev, note: text }));
  };

  const onBlurNotes = (e) => {
    const obj = {
      note: item?.note,
    };
    global.isInputFocused = false;

    updateFormFlag(obj);
  };

  const onChangeType = (e, value) => {
    const obj = {
      type: value?.value,
    };

    updateFormFlag(obj);
  };

  //lifeCycle
  useEffect(() => {
    if (
      contactOverlayStatus?.open &&
      contactOverlayStatus?.overlay_type === "drawer_modal_contact_edit_form"
    ) {
      updatedObj.current = null;
      isFormUpdated.current = false;
      setOpen(true);
      if (
        contactOverlayStatus?.payload?.item?.uuid &&
        !contactOverlayStatus?.payload?.item?.name &&
        modalType === "edit"
      ) {
        getClientApi({ uuid: contactOverlayStatus?.payload?.item?.uuid });
      } else {
        setItem(contactOverlayStatus?.payload?.item);
      }
    } else {
      updatedObj.current = null;
      isFormUpdated.current = false;
      setOpen(false);
    }
  }, [
    contactOverlayStatus?.open,
    contactOverlayStatus?.overlay_type,
    contactOverlayStatus?.payload?.item,
    modalType,
  ]);

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      transitionDuration={300}
      SlideProps={{
        in: true,
      }}
      PaperProps={{
        sx: { borderRadius: 0 },
      }}
      sx={{
        zIndex: 1303,
        width: "100%",
        position: "relative",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "45rem",
          padding: "2rem",
          pb: "6rem",
          position: "relative",
          overflowY: "auto",
          ...theme.thinScrollBar,
        }}
      >
        <HeaderView theme={theme} showSaved={showSaved} onClose={onClose} />

        <TitleView
          theme={theme}
          item={item}
          setItem={setItem}
          error={error}
          disabled={item?.source_type && item?.source_type !== 1}
          updateFormFlag={updateFormFlag}
          label={t("Title")}
        />
        <TypeView
          t={t}
          theme={theme}
          item={item}
          onChange={onChangeType}
          error={error}
        />
        <NotesView
          theme={theme}
          item={item}
          onChange={handleDescriptionChange}
          onBlur={onBlurNotes}
        />
        <UpdateDatesView theme={theme} item={item} />
        <ActionButton
          ref={actionRef}
          t={t}
          theme={theme}
          item={item}
          modalType={modalType}
          showSaved={showSaved}
          setError={setError}
          onClose={onClose}
        />
      </Box>
    </Drawer>
  );
};
export default DrawerModalContactEditForm;

const HeaderView = ({ theme, showSaved, onClose }) => {
  const { t } = useTranslation();

  return (
    <Typography
      component={"div"}
      sx={{
        height: "3rem",
        textAlign: "center",
        fontSize: "1.1rem",
        fontWeight: 500,
        textTransform: "uppercase",
        display: "flex",
        position: "relative",
        alignItems: "center",
        width: "100%",
        color: theme.palette.color.slate[500],
      }}
    >
      {showSaved ? (
        <Alert
          severity="success"
          sx={{
            boxShadow: "none !important",
            fontSize: "0.8rem",
            minHeight: "2rem",
            padding: "0rem 0.5rem",
            display: "inline-flex",
            alignItems: "center",
            alignSelf: "center",
            "& .MuiAlert-icon": {
              fontSize: "1rem",
              alignItems: "center",
            },
            "& .MuiAlert-message": {
              textAlign: "left",
              py: "0rem",
              display: "inline-flex",
            },
          }}
        >
          {t("Saved")}
        </Alert>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "2rem",
          position: "absolute",
          right: 0,
        }}
      >
        <CloseIcon
          onClick={onClose}
          sx={{
            cursor: "pointer",
            color: theme.palette.color.slate[500],
          }}
        />
      </div>
    </Typography>
  );
};

const TitleView = ({
  theme,
  item,
  setItem,
  onFocus,
  error,
  disabled,
  updateFormFlag,
  ...props
}) => {
  const { t } = useTranslation();
  const onFocusInput = () => {
    global.isInputFocused = true;
    if (onFocus) onFocus();
  };

  const onBlurInput = () => {
    onBlurText();
    setTimeout(() => {
      global.isInputFocused = false;
    }, 0);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };

  const onBlurText = () => {
    const obj = {
      name: item?.name,
    };
    updateFormFlag(obj);
  };

  return (
    <div style={{ display: "flex", marginTop: "1.5rem", width: "100%" }}>
      <TitleIcon
        sx={{
          color: theme.palette.color.blueGrey[700],
          ml: "0.2rem",
          mr: "0.8rem",
          mt: item?.name ? "0.25rem" : "0.75rem",
          fontSize: "1.4rem",
        }}
      />
      <TitleInput
        value={item?.name || ""}
        name="name"
        hideTitle
        onChange={handleEditChange}
        error={Boolean(error?.name)}
        helperText={t(error?.name)}
        disabled={disabled}
        likeGoogle
        variant="filled"
        label={t("Title")}
        placeholder={t("Add title")}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        {...props}
        fontSize={"0.9rem"}
        sx={{
          width: "100%",
        }}
      />
    </div>
  );
};

const NotesView = ({ theme, item, onChange, onBlur }) => {
  const { t } = useTranslation();
  const onFocus = () => {
    global.isInputFocused = true;
  };
  return (
    <div style={{ display: "flex", marginTop: "2rem", width: "100%" }}>
      <NotesIcon
        sx={{
          color: theme.palette.color.blueGrey[700],
          mt: !item?.note ? "0.75rem" : 0,
          ml: "0.2rem",
          mr: "0.8rem",
          fontSize: "1.4rem",
        }}
      />
      <DescriptionInput
        value={item?.note || ""}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        likeGoogle
        variant="filled"
        hideTitle
        fontSize={"0.9rem"}
        label={t("Note_Optional")}
        sx={{ width: "100%" }}
      />
    </div>
  );
};

const TypeView = ({ t, theme, item, onChange, error }) => {
  return (
    <div style={{ display: "flex", marginTop: "2rem", width: "100%" }}>
      <DataUsageIcon
        sx={{
          color: theme.palette.color.blueGrey[700],
          ml: "0.2rem",
          mt:
            item?.type?.toString()?.trim()?.length !== 0
              ? "0.25rem"
              : "0.85rem",
          mr: "1rem",
          fontSize: "1.5rem",
        }}
      />

      <DropDown
        disablePortal
        hideTitle
        fontSize="0.9rem"
        name="cost_unit"
        disabled={item?.source_type && item?.source_type !== 1}
        options={Constant.contact_types_options?.filter((o1) =>
          [1, 2]?.includes(o1.value)
        )}
        value={Constant.contact_types_options_by_value?.[item?.type]?.[0]}
        onChange={onChange}
        getOptionLabel={(option) => t(option?.display_name ?? "")}
        renderOption={(option) => t(option?.display_name ?? "")}
        error={Boolean(error?.type)}
        helperText={t(error?.type)}
        label={t("type")}
        likeGoogle
        variant="filled"
      />
    </div>
  );
};

const UpdateDatesView = ({ theme, item }) => {
  const { t } = useTranslation();

  if (!item?.last_modified_date && !item?.creation_date) return null;
  return (
    <Stack
      direction={"row"}
      alignItems={"start"}
      justifyContent={"end"}
      sx={{
        backgroundColor: theme.palette.color.white,
        zIndex: 6,
        position: "fixed",
        bottom: "5.5rem",
        right: "2rem",
      }}
    >
      <Typography
        variant="overline"
        fontWeight={"fontWeightMedium"}
        color="color.slate.700"
      >
        {t("transaction_updated_date")}:{" "}
        <b>
          {item?.last_modified_date
            ? formatDateToLocal(item?.last_modified_date)
            : ""}
        </b>
      </Typography>
      <Typography
        variant="overline"
        fontWeight={"fontWeightMedium"}
        color="color.slate.700"
        sx={{ ml: "32px" }}
      >
        {t("transaction_created_date")}:{" "}
        <b>
          {item?.creation_date ? formatDateToLocal(item?.creation_date) : ""}
        </b>
      </Typography>
    </Stack>
  );
};

const ActionButton = forwardRef(
  ({ t, theme, item, modalType, showSaved, setError, onClose }, ref) => {
    const dispatch = useDispatch();

    //redux
    const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

    //state
    const [loadingAdd, setLoadingAdd] = useState(false);

    const globalContext = useContext(GlobalContext);
    const clientFunctionRef = globalContext?.clientFunctionRef;

    const onAdd = () => {
      setLoadingAdd(true);
      if (!item?.name || item?.name?.trim() === "") {
        setLoadingAdd(false);
        setError((prev) => ({ ...prev, name: "empty_title_error" }));
        return;
      }
      if (!item?.type) {
        setLoadingAdd(false);
        setError((prev) => ({
          ...prev,
          type: "contact_form_empty_type_error",
        }));
        return;
      }

      if (modalType === "edit") {
        clientFunctionRef?.current?.updateClientByID(item);
      } else {
        clientFunctionRef?.current?.addClientApi({
          ...item,
          state: 1,
          dataset,
        });
      }
      dispatch(setContactOverlayStatus(null));
      setLoadingAdd(false);
      if (showSaved) {
        dispatch(setRefreshData(Date.now()));
      }
    };

    useImperativeHandle(ref, () => ({
      onAdd,
    }));

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          width: "45rem",
          py: "1.5rem",
          backgroundColor: theme.palette.color.FooterBG,
          position: "fixed",
          bottom: 0,
          right: "0",
          zIndex: 6,
        }}
      >
        <TailwindButton
          loading={loadingAdd}
          disable={!showSaved && modalType === "edit"}
          text={modalType === "edit" ? t("Update") : t("Add")}
          sx={{ fontSize: "1rem" }}
          onClick={onAdd}
        />
        <TailwindButton
          type="cancel"
          text={t("Cancel")}
          sx={{ fontSize: "1rem" }}
          onClick={onClose}
        />
      </Box>
    );
  }
);
