import { DialogContent, DialogActions, useTheme, Dialog } from "@mui/material";
import * as React from "react";

import OverlayFooter from "../Overlay/OverlayFooter";
import OverlayHeader from "../Overlay/OverlayHeader";

export default function CustomModal({
  open,
  onClose,
  children,
  hideAction,
  modalType,
  onAdd,
  textAdd,
  heading,
  hideClose = false,
  loadingAdd = false,
  disableADD = false,
  icon = null,
  linkView = null,
  backdropStyle = {},
  dialogContentStyle = {},
  PaperPropsSx = {},
  rootStyle = {},
  footerStyle = {},
  slotProps = {},
  restProps = {},
  backdropClose = true,
  tooltipAdd,
  backgroundColor,
  footerView,
}) {
  const theme = useTheme();
  return (
    <Dialog
      onClose={backdropClose ? onClose : null}
      aria-labelledby="customized-dialog-title"
      open={!!open}
      PaperProps={{
        sx: {
          maxWidth: "none",
          maxHeight: "90vh",
          position: "fixed",
          borderRadius: theme.borderRadius.main,
          boxShadow: theme.boxShadow,
          ...PaperPropsSx,
        },
      }}
      slotProps={{
        backdrop: {
          style: backdropStyle,
        },
        ...slotProps,
      }}
      sx={{
        zIndex: 1303,
        position: "fixed",
        ...rootStyle,
      }}
      {...restProps}
    >
      {heading ? (
        <OverlayHeader
          hideClose={hideClose}
          onClose={onClose}
          subTitle={heading}
          icon={icon}
          linkView={linkView}
        />
      ) : null}

      <DialogContent
        sx={{
          p: 0,
          backgroundColor: backgroundColor || theme.palette.color.white,
          ...theme.thinScrollBar,
          ...dialogContentStyle,
        }}
      >
        {children}
      </DialogContent>
      {!hideAction && (
        <DialogActions
          sx={{
            backgroundColor: theme.palette.color.slate[50],
            py: "1rem",
            px: "1.75rem",
            borderTop: `1px solid ${theme.palette.color.slate[200]}`,
          }}
        >
          <OverlayFooter
            footerView={footerView}
            loading={loadingAdd}
            tooltipAdd={tooltipAdd}
            modalType={modalType}
            onClickAction={disableADD || loadingAdd ? undefined : onAdd}
            onCancel={onClose}
            disable={disableADD}
            textAdd={textAdd}
            sx={{
              width: "100%",
              ...footerStyle
            }}
          />
        </DialogActions>
      )}
    </Dialog>
  );
}
