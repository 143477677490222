import {
  useMediaQuery,
  CardActions,
  CardContent,
  Typography,
  CardMedia,
  Collapse,
  Skeleton,
  useTheme,
  Tooltip,
  Stack,
  alpha,
  Card,
  Chip,
  Box,
  Fab,
} from "@mui/material";
import {
  forwardRef,
  useEffect,
  Fragment,
  useState,
  useMemo,
  useRef,
} from "react";
import { endOfMonth, format, startOfMonth, subDays, subMonths } from "date-fns";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import { IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { MdOutlineFiberNew, MdWatchLater } from "react-icons/md";
import { FaExternalLinkSquareAlt, FaPlug } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { TbSunLow, TbSunset2 } from "react-icons/tb";
import { useQuery } from "@tanstack/react-query";
import { TiWeatherNight } from "react-icons/ti";
import { BsFillTagsFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import { WiSunrise } from "react-icons/wi";
import { GrUpdate } from "react-icons/gr";
import jwt_decode from "jwt-decode";
import moment from "moment";
import _ from "underscore";

import {
  getAllTransactionsByParams,
  getDefaultFilters,
  getFinApiQueryKey,
  isPlanExpired,
  getBookedIds,
  getMuiColor,
} from "../../Helper/data";
import {
  setTransactionsOverlayStatus,
  setPopupStatus2,
} from "../../store/slices/datasets";
import FinbanInfoBulb from "../../components/FInbanInfo/FinbanInfoBulb";
import { setIsOnBoardingOverlayOpen } from "../../store/slices/common";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";
import { updateFilters } from "../../store/slices/global";
import { Color, Constant, Images } from "../../Helper";
import useStatusHook from "../../hooks/useStatusHook";
import Translate from "../../hooks/HOC/Translate";
import initialData from "./../../Helper/data";
import Organizations from "./Organizations";
import Icon from "../../components/Icon";
import BankAccount from "./BankAccount";
import theme from "../../theme";

let spacing = 4;
const QuickStartData = [
  {
    id: 1,
    image: Images.quick_start_insights,
    link: `https://finban.io/documentation/planungsansicht/`,
    title: "liquidity_planning_insights_title_1",
    description: "liquidity_planning_insights_des_1", //
  },
  {
    id: 2,
    image: Images.bank_integrations_insights,
    link: `https://finban.io/documentation/integrationen/`,
    title: "liquidity_planning_insights_title_2",
    description: "liquidity_planning_insights_des_2",
  },
  {
    id: 3,
    image: Images.open_booked_planned_insights,
    link: `https://finban.io/documentation/ist-zahlen-offene-posten-und-planwerte/`,
    title: "liquidity_planning_insights_title_3",
    description: "liquidity_planning_insights_des_3",
  },
  {
    id: 4,
    link: `https://finban.io/liquiditaetsplanung-mit-finban/`,
    image: Images.quick_start_insight_4,
    title: "liquidity_planning_insights_title_4",
    description: "liquidity_planning_insights_des_4",
  },
  {
    id: 5,
    link: `https://finban.io/finban-roadmap/`,
    image: Images.quick_start_insight_5,
    title: "liquidity_planning_insights_title_5",
    description: "liquidity_planning_insights_des_5",
  },
  {
    id: 6,
    link: `https://finban.io/documentation/categories/`,
    image: Images.quick_start_insight_6,
    title: "liquidity_planning_insights_title_6",
    description: "liquidity_planning_insights_des_6",
  },
  {
    id: 7,
    link: `https://finban.io/documentation/ausgaben-planen/`,
    image: Images.quick_start_insight_7,
    title: "liquidity_planning_insights_title_7",
    description: "liquidity_planning_insights_des_7",
  },
  {
    id: 8,
    link: `https://finban.io/documentation/personal/`,
    image: Images.quick_start_insight_8,
    title: "liquidity_planning_insights_title_8",
    description: "liquidity_planning_insights_des_8",
  },
];

const Start = () => {
  let dsViewWidth = 36;
  let orgWidth = 24;
  let width = dsViewWidth;
  const topRef = useRef();
  const bottomRef = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const md = useMediaQuery("(min-width:960px)");
  const s1366 = useMediaQuery("(min-width:1366px)");
  const [show, setShow] = useState(true);
  const [chart_data, setChartData] = useState();

  useEffect(() => {
    dispatch(setPopupStatus2(false));
    dispatch(setTransactionsOverlayStatus(false));
  }, []);

  if (md) {
    dsViewWidth = 42;
    width = dsViewWidth + orgWidth + spacing - 0.5;
  }
  if (s1366) {
    dsViewWidth = 42;
    orgWidth = 48 + 4;
    width = dsViewWidth + orgWidth + spacing - 0.5;
  }

  const onScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    if (
      Math.round(scrollHeight) - Math.round(scrollTop) >=
      clientHeight + 200
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  return (
    <Box
      onScroll={onScroll}
      sx={{
        backgroundColor: theme.palette.color.slate[100],
        // backgroundImage: "url(/bg-001.jpg)",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        // backgroundSize: "cover",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <WelcomeView width={width} topRef={topRef} />

      <Box
        sx={{
          height: "fit-content",
          color: Color.purple,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "start" },
          gap: "4rem",
          mt: "2rem",
        }}
      >
        <BankAccount
          dsViewWidth={dsViewWidth}
          chart_data={chart_data}
          setChartData={setChartData}
        />
        <Organizations
          orgWidth={orgWidth}
          spacing={spacing}
          chart_data={chart_data}
        />
      </Box>

      <InfoGridView orgWidth={orgWidth} width={width} bottomRef={bottomRef} />
      <FabView bottomRef={bottomRef} topRef={topRef} show={show} />
    </Box>
  );
};

export default Start;
const FabView = ({ bottomRef, topRef, show }) => {
  const { t } = useTranslation();

  const onClickScrollDown = () => {
    if (show) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Fab
      onClick={onClickScrollDown}
      variant="extended"
      color="primary"
      size="medium"
      sx={{
        position: "fixed",
        bottom: 23,
        right: 86,
        display: "flex",
        alignItems: "center",
        lineHeight: "normal",
        backgroundColor: show
          ? theme.palette.primary.main
          : theme.palette.primary[100],
        color: show ? theme.palette.color.white : theme.palette.primary.main,
        transition: theme.transitions.create(["all"], {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeInOut,
        }),
        "&:hover": {
          backgroundColor: show
            ? theme.palette.primary[700]
            : theme.palette.primary[200],
        },
      }}
    >
      <IoIosArrowUp
        style={{
          marginLeft: 0,
          marginRight: "0.5rem",
          fontSize: "1.25rem",
          display: "inline-flex",
          transform: show ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease-in-out",
        }}
      />
      {show ? t("Scroll down") : t("Scroll up")}
    </Fab>
  );
};

const WelcomeView = ({ width, topRef }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const profile = useSelector((state) => state.settingsSlice?.profile);
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  let day = moment(subscription?.expiry_date ?? new Date()).diff(
    moment(),
    "days"
  );

  const getData = () => {
    let planText = "";
    let heading = "";
    let showExplorePlan = false;
    let showChoosePlan = false;
    let color = theme.palette.primary.dark;
    let bg = theme.palette.primary.light;
    if (isPlanExpired(subscription)) {
      color = theme.palette.color.red[600];
      bg = theme.palette.color.red[50];
      showChoosePlan = true;
      planText = "";
      if (subscription?.name === "Trial") {
        heading = t("Trial Ended");
      } else {
        heading = t("Plan Ended", {
          subscription: subscription?.name,
        });
      }
    } else {
      if (subscription?.name === "Trial") {
        showExplorePlan = true;
        planText = t("days remain", {
          subscription: subscription?.name,
          day: day + 1,
          day_plural: day > 0 ? t("day_s") : t("day"),
        });
      } else {
        if (day >= 0 && day <= 2) {
          showExplorePlan = true;
          planText = t("days remain", {
            subscription: subscription?.name,
            day: day + 1,
            day_plural: day > 0 ? t("day_s") : t("day"),
          });
        } else {
          planText = t("Your Plan");
        }
      }

      heading = subscription?.name;
    }

    return { planText, color, bg, heading, showChoosePlan, showExplorePlan };
  };

  const onClickLink = () => {
    navigate("/settings/subscription");
  };

  const data = getData();
  const greeting = getGreeting();
  return (
    <Stack
      ref={topRef}
      direction={"row"}
      alignItems={"start"}
      justifyContent={"space-between"}
      sx={{
        my: "2rem",
        width: `${width}rem`,
        height: "fit-content",
      }}
    >
      <Stack sx={{ width: "75%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ "& svg": { fontSize: "1.75rem" } }}
        >
          <Typography
            variant="h5"
            fontWeight={"fontWeightBold"}
            color="color.slate.800"
            sx={{ mr: "0.75rem" }}
          >
            {`${greeting?.text ? t(greeting?.text) : ""}, ${profile?.first_name} `}
          </Typography>
          {greeting?.icon}
        </Stack>
        <TodoTask />
      </Stack>

      {data?.showChoosePlan ? (
        <Stack
          direction={"row"}
          alignItems={"start"}
          sx={{
            display: { xs: "none", small: "flex" },
          }}
        >
          <Stack>
            {data?.heading ? (
              <Chip
                label={data?.heading}
                sx={{
                  backgroundColor: data?.bg,
                  fontWeight: theme.typography.fontWeightMediumBold,
                  p: 1.5,
                  pt: 2,
                  height: "auto",
                  width: "fit-content",
                  color: data?.color,
                  "& .MuiChip-label": {
                    fontSize: "0.7rem",
                    lineHeight: "0.7rem",
                    px: 1,
                  },
                }}
              />
            ) : null}
            <Typography
              variant="caption"
              fontWeight={
                data?.heading ? "fontWeightMediumBold" : "fontWeightBold"
              }
              sx={{
                color: theme.palette.color.slate[700],
                mx: 1.25,
                mt: data?.heading ? 1 : 0,
              }}
            >
              {data?.planText}
            </Typography>
          </Stack>
          <Chip
            onClick={onClickLink}
            label={
              data?.showChoosePlan
                ? t("Choose Plan")
                : data?.showExplorePlan
                  ? t("Explore Plan")
                  : t("Change Plan")
            }
            sx={{
              color: data?.showChoosePlan
                ? theme.palette.color.red[600]
                : theme.palette.color.white,
              backgroundColor: data?.showChoosePlan
                ? theme.palette.color.red[50]
                : theme.palette.primary.dark,
              fontWeight: theme.typography.fontWeightMedium,
              p: 1.5,
              ml: "1rem",
              height: "auto",
              width: "fit-content",
              border: data?.showChoosePlan
                ? `1px solid ${theme.palette.color.red[600]}`
                : "none",
              "&:hover": {
                backgroundColor: data?.showChoosePlan
                  ? theme.palette.color.red[100]
                  : theme.palette.primary.darkHover,
              },
              "& .MuiChip-label": {
                px: 3,
                fontWeight: theme.typography.fontWeightMediumBold,
              },
            }}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};

const InfoGridView = ({ orgWidth, width, bottomRef }) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{ mt: "5rem", width: { xs: `${orgWidth}rem`, md: `${width}rem` } }}
    >
      <Typography
        fontWeight={"fontWeightMediumBold"}
        color="color.slate.900"
        sx={{ fontSize: "1.15rem" }}
      >
        {t("start_page_widgets_title", {
          tOptions: {
            interpolation: { escapeValue: true },
          },
        })}
      </Typography>
      <Typography variant="caption">{t("start_page_widgets_desc")}</Typography>
      <Box
        sx={{
          mt: "2rem",
          mb: "8rem",
          display: "flex",
          gap: `${spacing}rem`,
          flexWrap: "wrap",
          width: "fit-content",
          justifyContent: { xs: "center", md: "start" },
          alignItems: "baseline",
        }}
      >
        {QuickStartData?.map((item, index) => (
          <ImgMediaCard
            key={item?.id}
            item={item}
            ref={index === QuickStartData?.length - 1 ? bottomRef : null}
          />
        ))}
      </Box>
    </Stack>
  );
};

const ImgMediaCard = forwardRef(({ item }, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Card
      ref={ref}
      sx={{
        position: "relative",
        width: { xs: "100%", md: "20.5rem", s1366: "21.25rem" },
        boxShadow: theme.boxShadow,
        "&:Hover": {
          outline: `1px solid ${theme.palette.color.slate[200]}`,
        },
      }}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        height="230"
        image={item.image}
        sx={{
          objectFit: "cover",
        }}
      />
      <CardContent sx={{ height: "130px" }}>
        <Typography
          gutterBottom
          variant="subtitle1"
          fontWeight={"fontWeightMediumBold"}
          component="div"
        >
          {t(item?.title)}
        </Typography>
        <Typography variant="caption" color="color.description">
          {t(item?.description)}
        </Typography>
      </CardContent>
      <CardActions sx={{ left: "0.5rem", bottom: "0rem" }}>
        <FinbanInfoBulb
          variant="text"
          url={item?.link}
          text={"Read More"}
          icon={<FaExternalLinkSquareAlt />}
          isDirect
          hideIcon
          sx={{
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: theme.palette.color.orange[50],
            },
          }}
        />
      </CardActions>
    </Card>
  );
});

const getGreeting = () => {
  let text = "";
  let icon = null;
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    text = "Good morning";
    icon = <WiSunrise />;
  } else if (currentHour < 18) {
    text = "Good afternoon";
    icon = <TbSunLow />;
  } else if (currentHour < 22) {
    text = "Good evening";
    icon = <TbSunset2 />;
  } else {
    text = "Good night";
    icon = <TiWeatherNight />;
  }
  return { text, icon };
};

const TodoTask = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  //redux
  const dataSetList = useSelector((state) => state.boardSlice.dataSetList);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const refreshToken = useSelector((state) => state.auth?.refreshToken);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const stateByTitle = useSelector((state) => state.globalSlice?.stateByTitle);
  const onBoardingList = useSelector(
    (state) => state?.globalSlice?.onBoardingList
  );
  const testLoading = useSelector((state) => state.appSlice.testLoading);
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const locale = useSelector((state) => state.settingsSlice?.profile?.locale);
  const email = useSelector((state) => state.settingsSlice?.profile?.email);

  //state
  const [isExpanded, setIsExpanded] = useState(false);
  const [show, setShow] = useState(false);

  const Booked_ids = getBookedIds();
  const today = new Date();
  const startDate = startOfMonth(subMonths(today, 2));
  const endDate = endOfMonth(subMonths(today, 1));
  const InvoiceStates =
    JSON.stringify(stateByTitle) !== "{}"
      ? Constant.openPositionsStates?.map(
          (state) => stateByTitle?.[state]?.[0]?.uuid
        )
      : null;

  const loginDate = useMemo(() => {
    if (refreshToken) {
      const { exp } = jwt_decode(refreshToken);
      if (exp) {
        return subDays(new Date(exp * 1000), 5);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [refreshToken]);

  const Latest_Transactions_All = useQuery({
    queryKey: [
      "transactions",
      {
        apiType: "Latest_Transactions_All",
      },
    ],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
        },
        order_by: "-due_date",
        is_reconciled: false,
        from_payment_date: loginDate,
        to_payment_date: today,
      };

      const result = getAllTransactionsByParams(params);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: !!isAllHeaderApiFetched && !!loginDate,
    priority: 3,
  });
  const Late_Transactions_All = useQuery({
    queryKey: [
      "transactions",
      {
        apiType: "Late_Transactions_All",
      },
    ],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
        },
        order_by: "-due_date",
        is_reconciled: false,
        to_payment_date: today,
        state: InvoiceStates,
      };

      const result = getAllTransactionsByParams(params);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: !!isAllHeaderApiFetched && !!loginDate && !!InvoiceStates,
    priority: 3,
  });
  const past_Two_Month_Transactions_All = useQuery({
    queryKey: [
      "transactions",
      {
        apiType: "past_Two_Month_Transactions_All",
      },
    ],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
        },
        order_by: "-due_date",
        is_reconciled: false,
        from_payment_date: format(startDate, "yyyy-MM-dd"),
        to_payment_date: format(endDate, "yyyy-MM-dd"),
        state: Booked_ids,
      };

      const result = getAllTransactionsByParams(params);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled:
      !!isAllHeaderApiFetched &&
      !!startDate &&
      !!endDate &&
      !!Booked_ids?.length > 0,
    priority: 3,
  });

  const DataSetsTransactions = useMemo(() => {
    if (
      Latest_Transactions_All?.data &&
      dataSource?.length > 0 &&
      dataSetList?.length > 0
    ) {
      let obj = {};
      let data = {};
      dataSource?.forEach((ds) => {
        obj[ds?.uuid] = ds?.datasets?.map((o1) => o1?.dataset);
      });
      Latest_Transactions_All?.data?.forEach((o1) => {
        if (o1?.data_source && obj?.[o1?.data_source]) {
          obj[o1?.data_source]?.forEach((dataset) => {
            if (data?.[dataset]?.length > 0) {
              data[dataset].push(o1);
            } else {
              data[dataset] = [];
              data[dataset].push(o1);
            }
          });
        }
      });
      return data;
    } else {
      return {};
    }
  }, [Latest_Transactions_All?.data, dataSource, dataSetList]);

  const DataSetsLateTransactions = useMemo(() => {
    if (
      Late_Transactions_All?.data &&
      dataSource?.length > 0 &&
      dataSetList?.length > 0
    ) {
      let obj = {};
      let data = {};
      dataSource?.forEach((ds) => {
        obj[ds?.uuid] = ds?.datasets?.map((o1) => o1?.dataset);
      });
      Late_Transactions_All?.data?.forEach((o1) => {
        if (o1?.data_source && obj?.[o1?.data_source]) {
          obj[o1?.data_source]?.forEach((dataset) => {
            if (data?.[dataset]?.length > 0) {
              data[dataset].push(o1);
            } else {
              data[dataset] = [];
              data[dataset].push(o1);
            }
          });
        }
      });
      return data;
    } else {
      return {};
    }
  }, [Late_Transactions_All?.data, dataSource, dataSetList]);

  const DataSetsPastTwoMonthIncomeTransactions = useMemo(() => {
    if (
      past_Two_Month_Transactions_All?.data &&
      dataSource?.length > 0 &&
      dataSetList?.length > 0
    ) {
      let obj = {};
      let data = {};
      dataSource?.forEach((ds) => {
        obj[ds?.uuid] = ds?.datasets?.map((o1) => o1?.dataset);
      });
      past_Two_Month_Transactions_All?.data?.forEach((o1) => {
        if (o1?.data_source && obj?.[o1?.data_source] && o1.gross_value >= 0) {
          obj[o1?.data_source]?.forEach((dataset) => {
            if (data?.[dataset]?.length > 0) {
              data[dataset].push(o1);
            } else {
              data[dataset] = [];
              data[dataset].push(o1);
            }
          });
        }
      });
      return data;
    } else {
      return {};
    }
  }, [past_Two_Month_Transactions_All?.data, dataSource, dataSetList]);

  const DataSetsPastTwoMonthExpenseTransactions = useMemo(() => {
    if (
      past_Two_Month_Transactions_All?.data &&
      dataSource?.length > 0 &&
      dataSetList?.length > 0
    ) {
      let obj = {};
      let data = {};
      dataSource?.forEach((ds) => {
        obj[ds?.uuid] = ds?.datasets?.map((o1) => o1?.dataset);
      });
      past_Two_Month_Transactions_All?.data?.forEach((o1) => {
        if (o1?.data_source && obj?.[o1?.data_source] && o1.gross_value < 0) {
          obj[o1?.data_source]?.forEach((dataset) => {
            if (data?.[dataset]?.length > 0) {
              data[dataset].push(o1);
            } else {
              data[dataset] = [];
              data[dataset].push(o1);
            }
          });
        }
      });
      return data;
    } else {
      return {};
    }
  }, [past_Two_Month_Transactions_All?.data, dataSource, dataSetList]);

  const dataByOrg = useMemo(() => {
    let obj = {};
    dataSetList?.forEach((dataset) => {
      obj[dataset?.uuid] = {};
      const data = {
        categorize: DataSetsTransactions?.[dataset?.uuid]?.filter(
          (o1) => !o1.category
        ),
        new: DataSetsTransactions?.[dataset?.uuid],
        late: DataSetsLateTransactions?.[dataset?.uuid],
        income: DataSetsPastTwoMonthIncomeTransactions?.[dataset?.uuid],
        expense: DataSetsPastTwoMonthExpenseTransactions?.[dataset?.uuid],
      };

      Object.keys(data).forEach((key) => {
        if (data[key]?.length > 0) {
          const filterData = data?.[key]?.filter((o1) =>
            !o1.category
              ? true
              : dataset?.use_global_categories
                ? !selectionCategoriesByID?.[o1?.category]?.[0]?.dataset
                : selectionCategoriesByID?.[o1?.category]?.[0]?.dataset ===
                  dataset?.uuid
          );
          obj[dataset?.uuid][key] = filterData;
        }
      });
    });
    return obj;
  }, [
    DataSetsLateTransactions,
    DataSetsPastTwoMonthExpenseTransactions,
    DataSetsPastTwoMonthIncomeTransactions,
    DataSetsTransactions,
    dataSetList,
    selectionCategoriesByID,
  ]);

  const OnbTask = useMemo(() => {
    let data = null;
    if (email?.includes("@finban.io")) {
      for (let i = 0; i <= onBoardingList?.length; i++) {
        const item = onBoardingList?.[i];
        if (item?.checked) {
          continue;
        }
        if (item?.children?.length > 0) {
          for (let j = 0; j <= item?.children?.length; j++) {
            const children = item?.children?.[j];
            if (!children?.checked) {
              data = children;
              break;
            }
          }
        }
        if (data) {
          break;
        }
      }
    }
    return data;
  }, [email, onBoardingList]);

  const dataSetListById = useMemo(() => {
    if (dataSetList?.length > 0) {
      return _.groupBy(dataSetList, ({ uuid }) => uuid);
    }
  }, [dataSetList]);

  const Loading =
    !isAllHeaderApiFetched ||
    testLoading ||
    past_Two_Month_Transactions_All?.isLoading ||
    past_Two_Month_Transactions_All?.isFetching ||
    Latest_Transactions_All?.isLoading ||
    Latest_Transactions_All?.isFetching ||
    Late_Transactions_All?.isLoading ||
    Late_Transactions_All?.isFetching;

  //functions
  const checkVisibility = () => {
    const container = containerRef.current;
    const containerRect = container?.getBoundingClientRect();
    let count = 0;

    container?.childNodes?.forEach((child, index) => {
      const rect = child.getBoundingClientRect();
      if (
        rect.top >= containerRect.top &&
        rect.bottom <= containerRect.bottom
      ) {
        count++;
      }
    });
    setShow(container?.childNodes?.length > count);
  };

  const onClick = ({ count, dataset, text, type }) => {
    let path = "all";
    const defaultFilter = getDefaultFilters();

    let obj = {
      ...defaultFilter?.list,
    };
    if (type === "new" || type === "categorize") {
      obj.start_date = format(new Date(loginDate), "yyyy-MM-dd");
      obj.end_date = format(new Date(), "yyyy-MM-dd");
      if (type === "categorize") {
        path = "bulk";
      }
    } else {
      path = "late";
      obj.selectedStates = Constant.openPositionsStates;
    }

    navigate(
      `/${initialData?.path?.organization}/${dataset?.uuid}/list/${path}`
    );
    setTimeout(() => {
      dispatch(
        updateFilters({
          parent_key: "list",
          obj: obj,
        })
      );
    }, 1000);
  };

  const onClickShowAll = () => {
    setIsExpanded((prev) => !prev);
  };

  const onClickOnbTask = () => {
    if (OnbTask) {
      dispatch(setIsOnBoardingOverlayOpen(true));
    }
  };

  useDebounce(
    () => {
      if (!Loading) {
        checkVisibility(); // Initial check
      }
    },
    300,
    [dataByOrg, Loading],
    true
  );

  if (Loading) {
    return (
      <Skeleton
        animation="wave"
        variant="rounded"
        height={"8rem"}
        sx={{
          borderRadius: theme.borderRadius.borderRadiusLG,
          backgroundColor: `${theme.palette.color.slate[200]} !important`,
          mt: "1rem",
        }}
      />
    );
  }
  if (
    JSON.stringify(DataSetsTransactions) === "{}" &&
    JSON.stringify(DataSetsLateTransactions) === "{}"
  ) {
    return null;
  }
  return (
    <Stack sx={{ mt: "1rem" }}>
      <Typography
        variant="subtitle2"
        fontWeight={"fontWeightMediumBold"}
        color="color.slate.500"
        sx={{ mb: "0.5rem" }}
      >
        {t("start_page_todo_desc")}
      </Typography>
      <Collapse orientation="vertical" in={isExpanded} collapsedSize={"8rem"}>
        <Stack
          ref={containerRef}
          direction={"row"}
          sx={{
            width: "100%",
            height: isExpanded ? "100%" : "8rem",
            flexWrap: "wrap",
            p: "1rem",
            gap: "1rem",
            backgroundColor: theme.palette.color.slate[50],
            border: `1px dashed ${theme.palette.color.slate[300]}`,
            borderRadius: theme.borderRadius.borderRadiusLG,
          }}
        >
          {OnbTask ? (
            <CardItem
              onClick={onClickOnbTask}
              text={`${t("Quick Start")} - ${locale === "de_DE" ? OnbTask?.title : OnbTask?.title_en}`}
              sx={{
                backgroundColor: alpha(theme.palette.primary.light, 0.5),
                "& .MuiTypography-root": {
                  color: theme.palette.primary.main,
                },
                "& svg": {
                  color: `${theme.palette.primary.main} !important`,
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                },
              }}
            />
          ) : null}
          {dataByOrg &&
            Object.keys(dataByOrg)?.map((org) => {
              const dataset = dataSetListById?.[org]?.[0];
              return (
                <Fragment key={org}>
                  {Object.keys(dataByOrg?.[org])?.map((key) => {
                    const data = dataByOrg?.[org]?.[key];

                    if (key === "new" && data?.length > 0) {
                      return (
                        <CardItem
                          key={`${org}-${key}`}
                          onClick={onClick}
                          count={data?.length}
                          dataset={dataset}
                          type={"new"}
                          text={
                            <Translate i18nkey={"new_transactions_in_org"} />
                          }
                          icon={
                            <MdOutlineFiberNew style={{ fontSize: "1.3rem" }} />
                          }
                          iconColor={"sky"}
                        />
                      );
                    }
                    if (key === "categorize" && data?.length > 0) {
                      return (
                        <CardItem
                          key={`${org}-${key}`}
                          onClick={onClick}
                          count={data?.length}
                          dataset={dataset}
                          type={"categorize"}
                          text={
                            <Translate
                              i18nkey={"uncategorised_transactions_in_org"}
                            />
                          }
                          icon={<BsFillTagsFill style={{ fontSize: "1rem" }} />}
                          iconColor={"sky"}
                        />
                      );
                    }
                    if (key === "late") {
                      return (
                        <CardItem
                          key={`${org}-${key}`}
                          onClick={onClick}
                          count={data?.length}
                          dataset={dataset}
                          type={"late"}
                          text={
                            <Translate i18nkey={"late_transactions_in_org"} />
                          }
                          icon={<MdWatchLater style={{ fontSize: "1rem" }} />}
                          iconColor={"red"}
                        />
                      );
                    }
                    if (key === "income") {
                      return (
                        <IncomeExpenseBadges
                          key={`${org}-${key}`}
                          type="Income"
                          dataset={dataset}
                          data={data}
                        />
                      );
                    }
                    if (key === "expense") {
                      return (
                        <IncomeExpenseBadges
                          key={`${org}-${key}`}
                          type="Expense"
                          dataset={dataset}
                          data={data}
                        />
                      );
                    }
                    return null;
                  })}
                </Fragment>
              );
            })}

          {dataSource?.map((item) => {
            return <DSWidget key={item?.uuid} ds={item} />;
          })}
        </Stack>
      </Collapse>
      {show ? (
        <Typography
          variant="caption"
          fontWeight={"fontWeightMediumBold"}
          onClick={onClickShowAll}
          sx={{
            width: "fit-content",
            cursor: "pointer",
            px: "0.75rem",
            py: "0.35rem",
            mt: "0.5rem",
            color: theme.palette.color.slate[600],
            backgroundColor: theme.palette.color.slate[200],
            borderRadius: theme.borderRadius.main,
            display: "flex",
            alignItems: "center",
          }}
        >
          {!isExpanded ? t("Show More") : t("Show Less")}
        </Typography>
      ) : null}
    </Stack>
  );
};

const CardItem = ({
  iconColor,
  icon,
  count,
  text,
  dataset,
  type,
  postIcon,
  onClick,
  tooltip,
  sx,
}) => {
  const theme = useTheme();
  const onClickItem = () => {
    onClick({ count, dataset, text, type });
  };

  return (
    <Tooltip followCursor   title={tooltip}>
      <Box
        onClick={onClickItem}
        sx={{
          height: "2.6rem",
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          backgroundColor: alpha(theme.palette.color.slate[200], 0.25),
          borderRadius: theme.borderRadius.main,
          py: "0.5rem",
          px: "0.75rem",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: theme.palette.color.slate[200],
          },
          ...sx,
        }}
      >
        {icon ? <BadgeItem color={iconColor} label={icon} /> : null}
        {count ? <BadgeItem label={<b>{count}</b>} /> : null}

        <Typography
          variant="subtitle2"
          fontWeight={"fontWeightMedium"}
          color={"color.slate.500"}
          sx={{
            mr: "0.5rem",
          }}
        >
          {text}
        </Typography>
        {dataset ? <BadgeItem label={<b>{dataset?.title}</b>} /> : null}
        {postIcon}
        <IoIosArrowForward
          size={"1rem"}
          style={{ marginInline: 0, color: "inherit" }}
        />
      </Box>
    </Tooltip>
  );
};

const BadgeItem = ({
  label,
  color = "slate",
  rounded,
  isLight = true,
  transparentBadge,
  style,
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        fontSize: rounded ? "0.65rem" : "0.8rem",
        height: rounded ? "1.4rem" : "1.6rem",
        minWidth: "1.6rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        backgroundColor: theme.palette.color[color][isLight ? 200 : 500],
        borderRadius: rounded
          ? theme.borderRadius.borderRadiusXXL
          : theme.borderRadius.main,
        marginRight: transparentBadge ? 0 : "0.5rem",
        paddingInline: rounded ? "0.45rem" : "0.35rem",
        color: isLight
          ? theme.palette.color[color][500]
          : theme.palette.color.white,
        ...style,
      }}
    >
      {label}
    </div>
  );
};

const IncomeExpenseBadges = ({ data, dataset, type = "Income" }) => {
  const navigate = useNavigate();
  const onClickExpense = ({ dataset }) => {
    navigate(`/${initialData?.path?.organization}/${dataset?.uuid}/table`);
  };
  const prevMonthStartDate = startOfMonth(subMonths(new Date(), 1));
  const prevMonthEndDate = endOfMonth(subMonths(new Date(), 1));
  const pastMonthStartDate = startOfMonth(subMonths(new Date(), 2));
  const pastMonthEndDate = endOfMonth(subMonths(new Date(), 2));
  const prevData = data?.filter(
    (o1) =>
      (type === "Income" ? o1.gross_value >= 0 : o1.gross_value < 0) &&
      new Date(o1.due_date) >= prevMonthStartDate &&
      new Date(o1.due_date) <= prevMonthEndDate
  );
  const pastData = data?.filter(
    (o1) =>
      (type === "Income" ? o1.gross_value >= 0 : o1.gross_value < 0) &&
      new Date(o1.due_date) >= pastMonthStartDate &&
      new Date(o1.due_date) <= pastMonthEndDate
  );

  const prevTotal = Math.abs(
    prevData?.reduce((sum, acc) => (sum += parseFloat(acc?.gross_value)), 0)
  );
  const pastTotal = Math.abs(
    pastData?.reduce((sum, acc) => (sum += parseFloat(acc?.gross_value)), 0)
  );

  let isExpenseGrown = false;
  if (prevTotal > pastTotal) {
    isExpenseGrown = true;
  }
  const label = `start_page_todo_badge_${isExpenseGrown ? "higher" : "lesser"}_${type}`;

  const difference = prevTotal - pastTotal;
  const percentageDifference = (Math.abs(difference) / pastTotal) * 100;

  if (
    isNaN(percentageDifference) ||
    pastTotal === 0 ||
    prevTotal === 0 ||
    (prevData?.length === 0 && pastData?.length === 0) ||
    Math.abs(difference) < 100
  ) {
    return null;
  }

  return (
    <CardItem
      onClick={onClickExpense}
      dataset={dataset}
      type={type}
      tooltip={
        <Translate
          values={{
            percentage: parseFloat(percentageDifference || 0)?.toFixed(1),
          }}
          i18nkey={`${label}_tooltip`}
        />
      }
      iconColor={
        type === "Income"
          ? isExpenseGrown
            ? "green"
            : "red"
          : isExpenseGrown
            ? "red"
            : "green"
      }
      icon={
        isExpenseGrown ? (
          <FaArrowTrendUp style={{ fontSize: "1rem" }} />
        ) : (
          <FaArrowTrendDown style={{ fontSize: "1rem" }} />
        )
      }
      text={
        <Translate
          values={{
            percentage: parseFloat(percentageDifference || 0)?.toFixed(1),
          }}
          i18nkey={label}
        />
      }
    />
  );
};

const DSWidget = ({ ds }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let color = useRef("red");
  let title = useRef();
  let tooltip = useRef("");
  let icon = useRef(undefined);
  let severity = useRef("info");

  //redux
  const accountByDS = useSelector((state) => state.globalSlice.accountByDS);
  const isFirstFetchDs = useSelector(
    (state) => state.globalSlice.isFirstFetchDs
  );
  const transactionSystemByName = useSelector(
    (state) => state.globalSlice.transactionSystemByName
  );
  const isDsUuidLoading = useSelector(
    (state) => state.globalSlice.isDsUuidLoading
  );
  const account = accountByDS?.[ds?.uuid]?.[0];
  const lexOfficeId = transactionSystemByName?.["Lexoffice"]?.[0].uuid;

  const FinApiQueryKey = getFinApiQueryKey({ data_source: ds?.uuid });

  const IntegrationsQueryKey = [
    "integrations",
    {
      data_source: ds?.uuid,
      apiType: "data_source_external_connections",
    },
  ];

  const data_source_by_uuid_finapi_connections = useStatusHook(null, {
    queryKey: FinApiQueryKey,
  });
  const data_source_by_uuid_external_connections = useStatusHook(null, {
    queryKey: IntegrationsQueryKey,
  });

  const isLoading =
    data_source_by_uuid_external_connections?.isFetching ||
    data_source_by_uuid_finapi_connections?.isFetching ||
    isDsUuidLoading === ds?.uuid;

  const onClick = (e) => {
    navigate(`/settings/Integrations`, { state: { isActive: true } });
  };

  if (ds && ds?.type === 10) {
    return null;
  }

  if (ds && ds?.type === 12) {
    if (
      account &&
      ds.last_sync_date &&
      data_source_by_uuid_finapi_connections?.data
    ) {
      if (
        data_source_by_uuid_finapi_connections?.data === "COMPLETED" ||
        data_source_by_uuid_finapi_connections?.data === "UPDATED"
      ) {
        return null;
      } else if (
        !ds?.is_connected &&
        data_source_by_uuid_finapi_connections?.data === "DISCONNECTED"
      ) {
        return null;
      } else if (
        !data_source_by_uuid_finapi_connections?.data ||
        data_source_by_uuid_finapi_connections?.data === "UNAUTHORIZED_ACCESS"
      ) {
        title.current = "connection_state_unauthorized_access";
        tooltip.current = t("connection_state_unauthorized_access_tooltip");
        color.current = "red";
        severity.current = "error";
        icon.current = null;
      } else {
        tooltip.current = t("connection_state_update_tooltip");
        title.current = "connection_state_update";
        color.current = "blue";
        severity.current = "info";
        icon.current = (
          <Icon
            icon={<GrUpdate />}
            fontSize={"0.8rem"}
            stroke={`${getMuiColor(color.current, 700)} !important`}
            color={`${getMuiColor(color.current, 700)} !important`}
            style={{
              paddingLeft: "0.25rem",
            }}
            path={{
              strokeWidth: 3,
            }}
          ></Icon>
        );
      }
    } else if (
      account &&
      !ds.last_sync_date &&
      isFirstFetchDs !== 0 &&
      global.isFirstFetchUuid?.includes(ds.uuid)
    ) {
      return null;
    } else if (!account) {
      return null;
    }
  }

  if (ds && ds?.type === 19) {
    if (data_source_by_uuid_external_connections?.data === "UPDATED") {
      return null;
    } else if (
      !data_source_by_uuid_external_connections?.data ||
      data_source_by_uuid_external_connections?.data === "UNAUTHORIZED_ACCESS"
    ) {
      title.current = "connection_state_unauthorized_access";
      tooltip.current = t("connection_state_unauthorized_access_tooltip");
      color.current = "red";
      severity.current = "error";
      icon.current = null;
    } else {
      if (lexOfficeId === ds?.transaction_system) {
        if (ds.state === 1) {
          tooltip.current = t("integrations_connection_state_update_tooltip");
          title.current = "connection_state_update";
          color.current = "blue";
          severity.current = "info";
          icon.current = (
            <Icon
              icon={<GrUpdate />}
              fontSize={"0.8rem"}
              stroke={`${getMuiColor(color.current, 700)} !important`}
              color={`${getMuiColor(color.current, 700)} !important`}
              style={{
                paddingLeft: "0.25rem",
              }}
              path={{
                strokeWidth: 3,
              }}
            ></Icon>
          );
        }
        if (ds.state === 3) {
          return null;
        }
      } else {
        return null;
      }
    }
    if (
      !ds.is_connected &&
      isFirstFetchDs !== 0 &&
      global.isFirstFetchUuid?.includes(ds?.uuid)
    ) {
      return null;
    }
  }

  if (ds?.state === 2 || ds.type === 1) {
    return null;
  }

  return (
    <CardItem
      key={ds?.uuid}
      onClick={onClick}
      postIcon={
        <Tooltip   placement="top" title={tooltip.current} followCursor>
          <span>
            <Button
              variant="outlined"
              size="small"
              onClick={onClick}
              loading={
                (isFirstFetchDs !== 0 &&
                  global?.isFirstFetchUuid?.includes(ds?.uuid)) ||
                isLoading
              }
              loadingPosition={
                isFirstFetchDs !== 0 &&
                global?.isFirstFetchUuid?.includes(ds?.uuid)
                  ? "start"
                  : "center"
              }
              startIcon={!isLoading ? icon.current : undefined}
              disableElevation
              disableRipple
              color="primary"
              sx={{
                height: "1.8rem",
                fontSize: "0.7rem",
                lineHeight: "inherit",
                minWidth: "8rem",
                width: "fit-content",
                borderRadius: theme.borderRadius.main,
                color: `${getMuiColor(color.current, 700)} !important`,
                backgroundColor: getMuiColor(color.current, 50),
                fontWeight: 600,
                border: "none",
                textTransform: "initial",
                mr: "0.5rem",
                "& .MuiCircularProgress-root": {
                  color: getMuiColor(color.current, 700),
                },

                "&: hover": {
                  backgroundColor: `${getMuiColor(color.current, 50)} !important`,
                  border: "none",
                },
              }}
            >
              {!isLoading && t(title.current)}
            </Button>
          </span>
        </Tooltip>
      }
      type={"data_source"}
      text={
        <Translate i18nkey={ds?.title || account?.bank_details?.title || ""} />
      }
      icon={<FaPlug style={{ fontSize: "1rem" }} />}
      iconColor="sky"
      sx={{ pr: "0.5rem" }}
    />
  );
};
