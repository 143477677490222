import {
  CircularProgress,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  ListItem,
  Popover,
  alpha,
  List,
  Box,
} from "@mui/material";
import {
  eachMonthOfInterval,
  endOfMonth,
  addMonths,
  subMonths,
  format,
} from "date-fns";
import { TbChartAreaLineFilled, TbCircles, TbLayoutGrid } from "react-icons/tb";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useDispatch, useSelector } from "react-redux";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import LoopIcon from "@mui/icons-material/Loop";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  updateBatchRecurringRules,
  getRecurringTransactions,
  deleteBatchTransactions,
  updateBatchTransactions,
  cloneBatchTransactions,
  addBatchTransactions,
  getTransactionByUrl,
  getRecurDateArray,
} from "../../../../Helper/data";
import {
  setRefreshColumnData,
  setStageLoadingText,
  setRefreshData,
  setLoading,
} from "../../../../store/slices/appmain";
import {
  setPopupStatus2,
  setPopupStatus3,
  setPopupStatus4,
  setPopupStatus,
} from "../../../../store/slices/datasets";
import { setRecurring_rules } from "../../../../store/slices/global";
import useSubscriptions from "../../../../hooks/useSubscriptions";
import { Color, Constant, Fonts } from "../../../../Helper";
import Icon from "../../../../components/Icon";

const actionListOption = [
  { uuid: 9, title: "Reconcile transactions", icon: <SyncAltIcon /> },
  { uuid: 3, title: "Duplicate to next Month", icon: <ContentCopyIcon /> },
  { uuid: 4, title: "Duplicate to Date", icon: <ContentCopyIcon /> },
  { uuid: 2, title: "Duplicate", icon: <ContentCopyIcon /> },
  {
    uuid: 10,
    title: "Add Cost Unit",
    icon: (
      <Icon icon={<TbLayoutGrid />} fontSize={"1.3rem"} color="inherit"></Icon>
    ),
  },
  { uuid: 8, title: "Recur all included Transactions", icon: <LoopIcon /> },
  { uuid: 5, title: "Categorize", icon: <LocalOfferIcon /> },
  { uuid: 6, title: "Set Date", icon: <CalendarMonthIcon /> },
  {
    uuid: 7,
    title: "Set State",
    icon: (
      <Icon icon={<TbCircles />} fontSize={"1.3rem"} color="inherit"></Icon>
    ),
  },
  {
    uuid: 12,
    title: "Set Scenario",
    icon: (
      <Icon
        icon={<TbChartAreaLineFilled />}
        fontSize={"1.3rem"}
        color="inherit"
      ></Icon>
    ),
  },
  { uuid: 1, title: "Delete", icon: <DeleteIcon /> },
];
const actionListOption2 = [
  { uuid: 11, title: "Change range", icon: <DateRangeIcon /> },
  {
    uuid: 10,
    title: "Add Cost Unit",
    icon: (
      <Icon icon={<TbLayoutGrid />} fontSize={"1.3rem"} color="inherit"></Icon>
    ),
  },
  {
    uuid: 7,
    title: "Set State",
    icon: (
      <Icon icon={<TbCircles />} fontSize={"1.3rem"} color="inherit"></Icon>
    ),
  },
  {
    uuid: 12,
    title: "Set Scenario",
    icon: (
      <Icon
        icon={<TbChartAreaLineFilled />}
        fontSize={"1.3rem"}
        color="inherit"
      ></Icon>
    ),
  },
  { uuid: 5, title: "Categorize", icon: <LocalOfferIcon /> },
  { uuid: 1, title: "Delete", icon: <DeleteIcon /> },
];

const GroupSettingsModal = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isSubscriptionValid] = useSubscriptions();

  let groupedFilterData = useRef([]);
  let groupedCardItem = useRef([]);
  let payload = useRef([]);
  let form_type = useRef(null);

  //redux
  const state = useSelector((state) => state.globalSlice?.state);
  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);
  const recurring_rulesById = useSelector(
    (state) => state.globalSlice.recurring_rulesById
  );
  const recurring_rules = useSelector(
    (state) => state.globalSlice.recurring_rules
  );

  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );

  //state
  const [loader, setLoader] = useState(false);
  const [settingOptions, setSettingOption] = useState(null);
  const [actionList, setActionList] = useState(actionListOption);

  // useEffect
  useEffect(() => {
    if (popupStatus2?.open) {
      payload.current = popupStatus2;
      onOpenSettingsOverlay();
    }
  }, [popupStatus2?.open]);

  //api
  const deleteBatch = async (uuids) => {
    dispatch(setLoading(true));
    const isDeleted = await deleteBatchTransactions(uuids);
    if (isDeleted) {
      global.allowFetch = { Inflow: [], Outflow: [] };
      dispatch(
        setRefreshData({
          key: [
            "monthly",
            "transaction_daily_chart",
            "transaction_monthly_chart",
          ],
        })
      );
      dispatch(setPopupStatus3(null));
      enqueueSnackbar("Cards Deleted Successfully ");
    }
    dispatch(setLoading(false));
  };

  const cloneBatch = async (array) => {
    dispatch(setLoading(true));
    const response = await cloneBatchTransactions(array);
    global.allowFetch = { Inflow: [], Outflow: [] };
    let updatedNormalCards = false;

    if (appliedFilterlist?.kanban?.groupType === "scenarios") {
      let normalCardScenarios = getNormalCardsScenarios();
      updatedNormalCards = response.find((o1) =>
        normalCardScenarios?.includes(o1.scenario)
      );
    } else {
      let normalCardStates = getNormalCardsStates();
      updatedNormalCards = response.find((o1) =>
        normalCardStates?.includes(o1.state)
      );
    }
    dispatch(setLoading(false));
    dispatch(
      setRefreshData({
        key: [
          "monthly",
          "transaction_daily_chart",
          "transaction_monthly_chart",
        ],
      })
    );
    if (!!updatedNormalCards) {
      let dates = response.map((o1) => o1.due_date);
      dispatch(
        setRefreshColumnData({
          boardType: [payload.current?.type],
          date: dates,
        })
      );
    }

    enqueueSnackbar("Cards Cloned Successfully ");
  };

  const updateBatch = async (array, title = null) => {
    let response = await updateBatchTransactions(array);
    if (response) {
      let key = [
        "monthly",
        "transaction_daily_chart",
        "transaction_monthly_chart",
      ];
      dispatch(setLoading(false));
      dispatch(setStageLoadingText(null));
      dispatch(
        setRefreshData({
          key: key,
        })
      );

      if (
        title &&
        (appliedFilterlist?.kanban?.groupType === "scenarios" &&
        form_type.current === "scenario"
          ? appliedFilterlist?.kanban?.selectedScenarios?.includes(title) &&
            !appliedFilterlist?.kanban?.scenariosValue[
              popupStatus2?.type
            ]?.includes(title)
          : appliedFilterlist?.kanban?.groupType === "states" &&
              form_type.current === "state"
            ? appliedFilterlist?.kanban?.selectedStates?.includes(title) &&
              !appliedFilterlist?.kanban?.stateValue[
                popupStatus2?.type
              ]?.includes(title)
            : false)
      ) {
        dispatch(
          setRefreshColumnData({
            boardType: [payload.current?.type],
            date: response.map((o1) => o1.due_date),
          })
        );
      }
    }
  };

  //functions
  const closeCategoryOverlay = () => {
    dispatch(setPopupStatus4(null));
  };

  const handleClosePopOver = () => {
    dispatch(
      setPopupStatus2({
        open: false,
        anchorEl: null,
        from: "card_setting",
      })
    );
    setLoader(true);
  };

  const getNormalCardsScenarios = () => {
    return scenario
      ?.filter(
        (o1) =>
          appliedFilterlist?.kanban?.selectedScenarios?.includes(o1.title) &&
          appliedFilterlist?.kanban?.scenariosValue &&
          !appliedFilterlist?.kanban?.scenariosValue[
            popupStatus2?.type
          ]?.includes(o1.title)
      )
      .map((o1) => o1.title);
  };

  const getNormalCardsStates = () => {
    return state
      ?.filter(
        (o1) =>
          appliedFilterlist?.kanban?.selectedStates?.includes(o1.title) &&
          appliedFilterlist?.kanban?.stateValue &&
          !appliedFilterlist?.kanban?.stateValue[popupStatus2?.type]?.includes(
            o1.title
          )
      )
      .map((o1) => o1.title);
  };

  const onOpenSettingsOverlay = async () => {
    setLoader(true);
    let item = popupStatus2?.payload?.item;
    // let transactions = popupStatus2?.payload?.transactions;
    groupedFilterData.current = [];
    groupedCardItem.current = null;

    if (appliedFilterlist?.kanban) {
      let type = item.type === "income" ? "Inflow" : "Outflow";
      let stateValue = state
        ?.filter(
          (o1) =>
            appliedFilterlist?.kanban?.stateValue &&
            appliedFilterlist?.kanban?.stateValue[type]?.includes(o1.title)
        )
        .map((o1) => o1.uuid);

      let scenariosValue = scenario
        ?.filter(
          (o1) =>
            appliedFilterlist?.kanban?.scenariosValue &&
            appliedFilterlist?.kanban?.scenariosValue[type]?.includes(o1.title)
        )
        .map((o1) => o1.uuid);

      let url = "&is_split=false";
      if (appliedFilterlist?.kanban?.groupType === "scenarios") {
        scenariosValue?.forEach((o1) => (url = url + `&scenario=${o1}`));
      } else {
        stateValue?.forEach((o1) => (url = url + `&state=${o1}`));
      }
      if (item.type === "income") {
        url = url + "&min_gross_value=0";
      } else {
        url = url + "&max_gross_value=-0.001";
      }
      if (item?.date?.date) {
        let start_date = item?.date?.date;
        let end_date = format(
          endOfMonth(new Date(item?.date?.date)),
          "yyyy-MM-dd"
        );
        url =
          url +
          `&from_date=${start_date}&to_date=${end_date}&missing_date=false`;
      } else {
        url = url + "&missing_date=true";
      }

      if (typeof item.id === "object") {
        let allIds = [];
        if (item?.showSingleCategory) {
          allIds = item?.id;
        } else {
          let twoLevelId = selectionCategories?.filter(
            (o1) => item.id?.includes(o1.value) || item.id?.includes(o1.parent)
          );
          let level2Ids = twoLevelId?.map((o1) => o1.value);
          let level2parentId = twoLevelId
            ?.filter((o1) => o1.parent)
            ?.map((o1) => o1.value);
          let threeLevelId = selectionCategories
            ?.filter((o1) => level2parentId?.includes(o1.parent))
            ?.map((o1) => o1.value);
          allIds = [...level2Ids, ...threeLevelId];
        }
        allIds?.filter((o1) => (url = url + `&category=${o1}`));
      } else if (item.id === "groupedInflow" || item.id === "groupedOutflow") {
        url = url + "&categorized=true";
      } else if (
        item.id === "groupedInflowAll" ||
        item.id === "groupedOutflowAll"
      ) {
        url = url + "&categorized=unknown";
      } else {
        url = url + "&categorized=false";
      }

      groupedFilterData.current = await getTransactionByUrl(
        dataSetData?.uuid,
        url
      );
      let foundNormalTransaction = groupedFilterData.current?.filter(
        (o1) => !o1.recurring_rule
      );
      let foundSequenceTransaction = groupedFilterData.current?.filter(
        (o1) => o1.recurring_rule
      );
      let obj = {
        normal: foundNormalTransaction?.length,
        sequence: foundSequenceTransaction?.length,
        normal_type: foundNormalTransaction.map((o1) => o1.type),
        sequence_type: foundSequenceTransaction.map((o1) => o1.type),
      };
      setSettingOption(obj);
      let isInvoiced = Object.keys(item?.count)?.some((key, i) => {
        if (key.includes("Invoice") || key.includes("Open")) {
          return item?.count[key] > 0;
        } else {
          return false;
        }
      });
      if (item?.count?.Booked > 0 || obj.normal_type.includes(1)) {
        setActionList(
          actionListOption?.filter(
            (o1) =>
              o1.uuid === 2 ||
              o1.uuid === 3 ||
              o1.uuid === 4 ||
              o1.uuid === 5 ||
              o1.uuid === 8 ||
              o1.uuid === 10 ||
              (isInvoiced && o1.uuid === 9)
          )
        );
      } else {
        setActionList(actionListOption?.filter((o1) => o1.uuid !== 9));
      }
    }
    groupedCardItem.current = item;
    setLoader(false);
  };

  const onClickListItem = (e, uuid, fromSequence = false) => {
    if (
      uuid === 2 ||
      uuid === 3 ||
      uuid === 4 ||
      uuid === 8 ||
      uuid === 9 ||
      uuid === 10 ||
      uuid === 11 ||
      uuid === 12
    ) {
      if (!isSubscriptionValid({ showMessage: true })) {
        return null;
      }
    }
    if (uuid !== 5 && uuid !== 8) {
      handleClosePopOver();
    }
    if (uuid === 1) {
      dispatch(
        setPopupStatus3({
          id: "simple-popper",
          open: true,
          overlay_type: "delete",
          onConfirm: onOkDelete,
          payload: {
            title: t("Attention"),
            message: t(
              "Are you sure that you want to delete this transaction?"
            ),
            confirmText: t("Ok"),
            hideCancel: true,
            fromSequence: fromSequence,
          },
        })
      );
    }
    if (uuid === 2) {
      dispatch(setLoading(true));
      let array = [];
      groupedFilterData.current?.forEach((item) => {
        if (!item?.recurring_rule) {
          array.push({
            uuid: item.uuid,
            gross_value: item.gross_value,
            state: Constant?.bookedPositionsStates.includes(item.state)
              ? "Open"
              : item.state,
            title: `Copy of ${item?.title}`,
            scenario: item?.scenario,
            tax: item?.tax,
            contact: item?.contact,
            category: item?.category,
            due_date: item.due_date,
            invoice_date: item.invoice_date,
            cost_unit: item.cost_unit,
            source: 1,
            data_source:
              item.source === 1
                ? item.data_source
                : dataSetData?.internal_data_source,
          });
        }
      });
      cloneBatch(array);
    }
    if (uuid === 3) {
      dispatch(setLoading(true));
      let array = [];
      groupedFilterData.current?.forEach((item) => {
        if (!item?.recurring_rule) {
          array.push({
            uuid: item.uuid,
            gross_value: item.gross_value,
            title: `Copy of ${item?.title}`,
            scenario: item.scenario,
            state: Constant?.bookedPositionsStates.includes(item.state)
              ? "Open"
              : item.state,
            category: item?.category,
            due_date: item.due_date
              ? format(addMonths(new Date(item?.due_date), 1), "yyyy-MM-dd")
              : format(new Date(dataSetData?.start_date), "yyyy-MM-dd"),
            invoice_date: item.invoice_date,
            cost_unit: item.cost_unit,
            source: 1,
            data_source:
              item.source === 1
                ? item.data_source
                : dataSetData?.internal_data_source,
          });
        }
      });
      cloneBatch(array);
    }
    if (uuid === 5) {
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: e.currentTarget,
          overlay_type: "category",
          payload: {
            type: groupedCardItem.current?.type === "income" ? 1 : 2,
            onClickCategoryTitle: onClickCategoryTitle,
            fromSequence: fromSequence,
          },
        })
      );
    }
    if (uuid === 8) {
      let normalCardStates = getNormalCardsStates();
      dispatch(
        setPopupStatus({
          id: "simple-popper",
          from: "column_add_recurrence",
          open: true,
          anchorEl: e.currentTarget,
          type: payload.current.type,
          payload: {
            date: groupedFilterData.current
              ? groupedFilterData.current[0]?.due_date
              : null,
            columnData: groupedFilterData.current,
            normalCardStates: normalCardStates,
          },
        })
      );
    }
    if (uuid === 9) {
      dispatch(
        setPopupStatus({
          id: "simple-popper",
          from: "column_reconcile",
          open: true,
          anchorEl: e.currentTarget,
          payload: {
            figureType: groupedCardItem.current.type,
            modalType: "edit",
            id: groupedCardItem.current.id,
            date: groupedCardItem.current?.date.date,
            type: groupedCardItem.current.type,
            withUrl: true,
            title: t("Reconciliation"),
          },
        })
      );
    }

    if (
      uuid === 4 ||
      uuid === 7 ||
      uuid === 6 ||
      uuid === 11 ||
      uuid === 10 ||
      uuid === 12
    ) {
      if (uuid === 4) {
        form_type.current = "clone_at_date";
      }
      if (uuid === 6) {
        form_type.current = "due_date";
      }
      if (uuid === 7) {
        form_type.current = "state";
      }
      if (uuid === 10) {
        form_type.current = "cost_unit";
      }
      if (uuid === 11) {
        form_type.current = "date_range";
      }
      if (uuid === 12) {
        form_type.current = "scenario";
      }
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: true,
          overlay_type: "form",
          payload: {
            type: form_type.current,
            onSave: onSaveForm,
            groupedCard: groupedCardItem.current,
            fromSequence: fromSequence,
          },
        })
      );
    }
  };

  const onOkDelete = async ({ fromSequence }) => {
    dispatch(setLoading(true));
    dispatch(setPopupStatus3(null));
    if (fromSequence) {
      let recurringData = groupedFilterData.current?.filter(
        (o1) => o1.recurring_rule
      );
      let recurringIds = [
        ...new Set(recurringData?.map((item) => item.recurring_rule)),
      ];
      let oldRecurData = await getRecurringTransactions(
        dataSetData?.uuid,
        recurringIds
      );
      let uuids = oldRecurData?.map((o1) => o1.recurring_rule && o1.uuid);
      deleteBatch(uuids);
    } else {
      let uuids = groupedFilterData.current?.map(
        (o1) => !o1.recurring_rule && o1.uuid
      );

      deleteBatch(uuids);
    }
  };

  const onClickCategoryTitle = async (e, item, filters, { fromSequence }) => {
    if (fromSequence) {
      dispatch(setStageLoadingText("common_process_loader_text"));
    } else {
      dispatch(setLoading(true));
    }
    closeCategoryOverlay();
    handleClosePopOver();
    if (fromSequence) {
      let recurringData = groupedFilterData.current?.filter(
        (o1) => o1.recurring_rule
      );
      let recurringIds = [
        ...new Set(recurringData?.map((item) => item.recurring_rule)),
      ];
      let oldRecurData = await getRecurringTransactions(
        dataSetData?.uuid,
        recurringIds
      );

      let payload = [];
      oldRecurData?.forEach((element) => {
        payload.push({
          uuid: element?.uuid,
          category: item?.uuid === `unCategorized_category` ? null : item?.uuid,
        });
      });
      updateBatch(payload);
    } else {
      let array = [];
      groupedFilterData.current?.forEach((o1) => {
        if (!o1?.recurring_rule) {
          let obj = {
            uuid: o1.uuid,
            category:
              item?.uuid === `unCategorized_category` ? null : item?.uuid,
          };
          array.push(obj);
        }
      });
      updateBatch(array);
    }
  };

  const onSaveForm = async (ele, fromSequence) => {
    let formData = {};
    if (form_type.current === "cost_unit") {
      formData = {
        [form_type.current]: ele.uuid,
      };
    }
    if (form_type.current === "state" || form_type.current === "scenario") {
      formData = {
        [form_type.current]: ele.title,
      };
    }
    if (
      form_type.current === "clone_at_date" ||
      form_type.current === "invoice_date" ||
      form_type.current === "due_date"
    ) {
      formData = {
        [form_type.current === "invoice_date" ? form_type.current : "due_date"]:
          ele[
            form_type.current === "invoice_date"
              ? form_type.current
              : "due_date"
          ],
      };
    }
    if (form_type.current === "date_range") {
      formData = ele;
    }

    closeCategoryOverlay();
    if (fromSequence) {
      dispatch(setStageLoadingText("common_process_loader_text"));
    } else {
      dispatch(setLoading(true));
    }
    if (form_type.current === "state") {
      if (fromSequence) {
        let recurringData = groupedFilterData.current?.filter(
          (o1) => o1.recurring_rule
        );
        let recurringIds = [
          ...new Set(recurringData?.map((item) => item.recurring_rule)),
        ];

        let oldRecurData = await getRecurringTransactions(
          dataSetData?.uuid,
          recurringIds
        );
        let array = [];
        oldRecurData?.forEach((element) => {
          array.push({
            uuid: element?.uuid,
            ...formData,
          });
        });
        updateBatch(array, formData[form_type.current]);
      } else {
        let array = [];
        groupedFilterData.current?.forEach((o1, index) => {
          if (!o1?.recurring_rule) {
            let obj = {
              uuid: o1.uuid,
              ...formData,
            };
            array.push(obj);
          }
        });
        updateBatch(array, formData[form_type.current]);
      }
    } else if (form_type.current === "scenario") {
      if (fromSequence) {
        let recurringData = groupedFilterData.current?.filter(
          (o1) => o1.recurring_rule
        );
        let recurringIds = [
          ...new Set(recurringData?.map((item) => item.recurring_rule)),
        ];

        let oldRecurData = await getRecurringTransactions(
          dataSetData?.uuid,
          recurringIds
        );
        let array = [];
        oldRecurData?.forEach((element) => {
          array.push({
            uuid: element?.uuid,
            ...formData,
          });
        });
        updateBatch(array, formData[form_type.current]);
      } else {
        let array = [];
        groupedFilterData.current?.forEach((o1, index) => {
          if (!o1?.recurring_rule) {
            let obj = {
              uuid: o1.uuid,
              ...formData,
            };
            array.push(obj);
          }
        });
        updateBatch(array, formData[form_type.current]);
      }
    } else if (form_type.current === "clone_at_date") {
      let array = [];
      groupedFilterData.current.forEach((item) => {
        if (!item?.recurring_rule) {
          array.push({
            uuid: item.uuid,
            gross_value: item.gross_value,
            tax: item.tax,
            title: `Copy of ${item?.title}`,
            state: Constant?.bookedPositionsStates.includes(item.state)
              ? "Open"
              : item.state,
            contact: item?.contact,
            scenario: item?.scenario,
            category: item?.category,
            ...formData,
            invoice_date: item.invoice_date,
            cost_unit: item.cost_unit,
            source: 1,
            data_source:
              item.source === 1
                ? item.data_source
                : dataSetData?.internal_data_source,
          });
        }
      });
      cloneBatch(array);
    } else if (form_type.current === "due_date") {
      let array = [];
      groupedFilterData.current?.forEach((o1) => {
        if (!o1?.recurring_rule) {
          let obj = {
            uuid: o1.uuid,
            ...formData,
          };
          array.push(obj);
        }
      });
      updateBatch(array);
    } else if (form_type.current === "cost_unit") {
      if (fromSequence) {
        let recurringData = groupedFilterData.current?.filter(
          (o1) => o1.recurring_rule
        );
        let recurringIds = [
          ...new Set(recurringData?.map((item) => item.recurring_rule)),
        ];

        let oldRecurData = await getRecurringTransactions(
          dataSetData?.uuid,
          recurringIds
        );
        let array = [];
        oldRecurData?.forEach((o1) => {
          let obj = {
            uuid: o1.uuid,
            ...formData,
          };
          array.push(obj);
        });
        updateBatch(array);
      } else {
        let array = [];
        groupedFilterData.current?.forEach((o1, index) => {
          if (!o1?.recurring_rule) {
            let obj = {
              uuid: o1.uuid,
              ...formData,
            };
            array.push(obj);
          }
        });
        updateBatch(array);
      }
    } else if (form_type.current === "date_range") {
      onDateRangeChange(formData);
    }
  };

  //check here advance recurring seq process  need to be update
  const onDateRangeChange = async (selected) => {
    let updateRecurringRulesData = [];
    let updateData = [];
    let addData = [];
    let deleteData = [];
    let groupedMonthlyTransactions = {};
    let recurringData = groupedFilterData.current?.filter(
      (o1) => o1.recurring_rule
    );
    let recurringIds = [
      ...new Set(recurringData?.map((item) => item.recurring_rule)),
    ];
    let oldRecurData = await getRecurringTransactions(
      dataSetData?.uuid,
      recurringIds
    );

    recurringIds.forEach((id) => {
      let recurring_Obj = recurring_rulesById?.[id]?.[0];
      if (recurring_Obj) {
        updateRecurringRulesData.push({
          uuid: id,
          start_date: selected?.start_date,
          end_date: selected?.end_date,
          name: recurring_Obj?.name,
        });

        let {
          recurring_type,
          source,
          average,
          category,
          users,
          average_value,
          conversion,
          value,
          payment_default,
          discount,
          capacity,
          workers,
          working_hours,
          holiday,
          average_vacation_day,
          average_sick_day,
          multiplier,
        } = recurring_Obj;

        let gross_value =
          recurring_type === "simple" ? Number(value) : Number(average_value);
        let payUsersNumber = users ? Number(users) : 0;
        if (recurring_type === "simple") {
          let disc = 0;
          if (discount && Number(discount) !== 0) {
            disc = (gross_value * discount) / 100;
          }
          gross_value = gross_value - disc;
        }
        if (recurring_type === "advanced") {
          let Divide = average === 1 ? average : average + 1;
          let startDate = new Date(subMonths(new Date(), Divide));
          let endDate = new Date(subMonths(new Date(), 1));

          let result = eachMonthOfInterval({
            start: startDate,
            end: endDate,
          });

          let total = 0;

          if (source === 2) {
            result?.forEach((element) => {
              let date = format(element, "yyyy-MM");
              let monthData = groupedMonthlyTransactions[date];
              total =
                total +
                Math.abs(
                  monthData
                    ? monthData
                        ?.filter((o1) =>
                          category ? o1.category === category : !o1.category
                        )
                        ?.reduce(
                          (total, item) =>
                            parseFloat(total) +
                            parseFloat(
                              groupedCardItem.current?.gross_value >= 0
                                ? item?.inflow ?? 0
                                : item?.outflow ?? 0
                            ),
                          0
                        )
                    : 0
                );
            });
          }
          if (source === 3) {
            result?.forEach((element) => {
              let date = format(element, "yyyy-MM");
              let monthData = groupedMonthlyTransactions[date];
              total =
                total +
                Math.abs(
                  monthData
                    ? monthData
                        ?.filter((o1) => o1.state === "Booked" && o1?.inflow)
                        ?.reduce(
                          (total, item) =>
                            parseFloat(total) + parseFloat(item?.inflow ?? 0),
                          0
                        )
                    : 0
                );
            });
          }
          if (source === 4) {
            result?.forEach((element) => {
              let date = format(element, "yyyy-MM");
              let monthData = groupedMonthlyTransactions[date];
              total =
                total +
                Math.abs(
                  monthData
                    ? monthData
                        ?.filter((o1) => o1.state === "Booked" && o1?.outflow)
                        ?.reduce(
                          (total, item) =>
                            parseFloat(total) + parseFloat(item?.outflow ?? 0),
                          0
                        )
                    : 0
                );
            });
          }
          let multiply = 1;
          let finalValue = total / Divide;
          if (multiplier && Number(multiplier) !== 0) {
            multiply = multiplier / 100;
          }
          gross_value = Number(finalValue) * Number(multiply);
        }
        if (recurring_type === "subscription") {
          let user = payUsersNumber;
          let disc = 0;
          if (discount && Number(discount) !== 0) {
            disc = (average_value * discount) / 100;
          }
          gross_value = user * (average_value - disc);
        }
        if (recurring_type === "shop") {
          let conv = 1;
          let disc = 0;
          if (discount && Number(discount) !== 0) {
            disc = (average_value * discount) / 100;
          }
          if (conversion && Number(conversion) !== 0) {
            conv = conversion / 100;
          }
          gross_value = users * (average_value - disc) * conv;
        }
        if (recurring_type === "client" || recurring_type === "products") {
          let disc = 0;
          let dif = capacity;

          if (discount && Number(discount) !== 0) {
            disc = (average_value * discount) / 100;
          }
          if (payment_default && Number(payment_default) !== 0) {
            dif = capacity - (capacity * payment_default) / 100;
          }
          gross_value = (average_value - disc) * dif;
        }
        if (recurring_type === "time") {
          let disc = 0;
          let day = 365;
          if (discount && Number(discount) !== 0) {
            disc = (average_value * discount) / 100;
          }
          if (holiday && Number(holiday) !== 0) {
            day = day - holiday;
          }
          if (average_vacation_day && Number(average_vacation_day) !== 0) {
            day = day - average_vacation_day;
          }
          if (average_sick_day && Number(average_sick_day) !== 0) {
            day = day - average_sick_day;
          }
          let discountedValue = average_value - disc;
          let calculatedValue = day * working_hours;
          gross_value = (calculatedValue * discountedValue * workers) / 12;
          if (payment_default && Number(payment_default) !== 0) {
            gross_value = gross_value - (gross_value * payment_default) / 100;
          }
        }
        if (groupedCardItem.current?.gross_value < 0) {
          gross_value = String(gross_value)?.includes("-")
            ? gross_value
            : -gross_value;
        }
        let filterData = oldRecurData?.filter((o1) => o1.recurring_rule === id);
        let array = createRecurArray(
          recurring_Obj,
          id,
          gross_value,
          payUsersNumber,
          true,
          filterData[0],
          selected?.start_date,
          selected?.end_date
        );
        let data_source = filterData[0]?.data_source;
        array?.forEach((element) => {
          let filterObj = filterData[0];
          if (filterObj) {
            updateData.push({ ...element, uuid: filterObj?.uuid });
            filterData.splice(0, 1);
          } else {
            addData.push({ ...element, data_source: data_source });
          }
        });
        if (filterData?.length > 0) {
          deleteData = filterData?.map((o1) => o1.uuid);
        }
      }
    });

    let updateRecurResponse = [];
    if (addData?.length > 0) {
      await addBatchTransactions(addData);
    }
    if (updateData?.length > 0) {
      await updateBatchTransactions(updateData);
    }
    if (deleteData?.length > 0) {
      await deleteBatchTransactions(deleteData);
    }
    if (updateRecurringRulesData?.length > 0) {
      updateRecurResponse = await updateBatchRecurringRules(
        updateRecurringRulesData
      );
      let data = [...recurring_rules];
      updateRecurResponse.forEach((element) => {
        let index = data.findIndex((o1) => o1.uuid === element.uuid);
        if (index > -1) {
          data[index] = { ...data[index], ...element };
        }
      });
      dispatch(setRecurring_rules(data));
    }

    global.allowFetch = { Inflow: [], Outflow: [] };
    dispatch(setStageLoadingText(null));
    dispatch(setLoading(false));
    dispatch(
      setRefreshData({
        key: [
          "daily",
          "monthly",
          "transaction_daily_chart",
          "transaction_monthly_chart",
        ],
      })
    );
  };

  const createRecurArray = (
    recurring_Obj,
    recurring_rule,
    gross_value,
    payUsersNumber,
    skip = false,
    cardItem,
    start_date,
    end_date
  ) => {
    let {
      recurring_type,
      repetition,
      average_value,
      users_growth,
      churn,
      conversion,
      new_user_per_month,
      growth,
      tax,
    } = recurring_Obj;
    let dateArray = getRecurDateArray(repetition, start_date, end_date);
    if (!skip) {
      dateArray.splice(0, 1);
    }

    let array = [];
    dateArray.forEach((item) => {
      // if (recurring_type === "advanced") {
      //   let grow = 0;
      //   if (growth && Number(growth) !== 0) {
      //     grow = Number(gross_value) + Number(gross_value * growth) / 100;
      //   }
      //   gross_value = Number(gross_value) + Number(grow);
      // }
      if (recurring_type === "subscription") {
        let user = payUsersNumber;
        if (users_growth && Number(users_growth) !== 0) {
          new_user_per_month =
            Number(new_user_per_month) +
            Number(new_user_per_month * users_growth) / 100;
        }
        user = Number(payUsersNumber) + Number(new_user_per_month);

        if (churn && Number(churn) !== 0) {
          user = user - (user * churn) / 100;
        }
        payUsersNumber = user;
        gross_value = user * average_value;
      }
      if (recurring_type === "shop") {
        let conv = 1;
        if (users_growth && Number(users_growth) !== 0) {
          payUsersNumber =
            payUsersNumber + (payUsersNumber * users_growth) / 100;
        }
        if (conversion && Number(conversion) !== 0) {
          conv = conversion / 100;
        }
        gross_value = payUsersNumber * average_value * conv;
      }
      if (
        recurring_type === "simple" ||
        recurring_type === "advanced" ||
        recurring_type === "client" ||
        recurring_type === "products" ||
        recurring_type === "time"
      ) {
        let grow = 0;
        if (growth && Number(growth) !== 0) {
          grow = (gross_value * growth) / 100;
        }
        gross_value = gross_value + grow;
      }
      if (groupedCardItem.current?.gross_value < 0) {
        gross_value = String(gross_value)?.includes("-")
          ? gross_value
          : -gross_value;
      }
      array.push({
        recurring_rule: recurring_rule,
        title: cardItem?.title || "(No title)",
        gross_value: parseFloat(gross_value)?.toFixed(2),
        note: cardItem?.note || "",
        position: 1001,
        source: cardItem?.source || "1",
        tax: tax,
        contact: cardItem?.contact,
        cost_unit: cardItem?.cost_unit,
        data_source: cardItem?.data_source,
        state: cardItem?.state,
        scenario: cardItem?.scenario,
        category: cardItem?.category,
        due_date: format(item, "yyyy-MM-dd"),
        currency: cardItem?.currency,
        invoice_date: cardItem?.invoice_date,
      });
    });
    return array;
  };

  return (
    <Popover
      id={popupStatus2?.id}
      open={popupStatus2?.open}
      anchorEl={popupStatus2?.anchorEl}
      onClose={handleClosePopOver}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: 1.5,
            boxShadow: Constant.OverlayBoxShadow,
          },
        },
      }}
    >
      <Box
        sx={{
          minWidth: "23rem",
          minHeight: "12rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loader ? (
          <CircularProgress thickness={3} size={20} />
        ) : (
          <>
            {settingOptions?.normal ? (
              <>
                <Typography
                  sx={{
                    alignSelf: "baseline",
                    fontSize: "1.3rem",
                    fontWeight: 600,
                    fontFamily: Fonts.Text,
                    mt: "1rem",
                    ml: "1.3rem",
                  }}
                >
                  {t("Transaction actions")} ({settingOptions?.normal})
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "fit-content",
                    minHeight: 50,
                    width: "fit-content",
                    minWidth: 300,
                    mb: "1rem",
                    position: "relative",
                  }}
                >
                  {actionList?.map((item) => {
                    return (
                      <ListItem
                        key={item?.uuid}
                        disableGutters
                        disablePadding
                        onClick={(e) => onClickListItem(e, item.uuid)}
                      >
                        <ListItemButton
                          sx={{
                            fontFamily: Fonts?.Text,
                            color: "inherit",
                            "&:hover": {
                              backgroundColor: alpha(Color.themeColor, 0.1),
                              color: alpha(Color.themeColor, 0.9),
                              "& span": {
                                fontWeight: 500,
                                color: alpha(Color.themeColor, 0.9),
                              },
                              "& svg": {
                                color: alpha(Color.themeColor, 0.9),
                              },
                            },
                          }}
                        >
                          <ListItemIcon>{item?.icon}</ListItemIcon>
                          <ListItemText primary={item?.title} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : null}
            {settingOptions?.sequence ? (
              <>
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: 600,
                    fontFamily: Fonts.Text,
                    alignSelf: "baseline",
                    mt: "1rem",
                    ml: "1.3rem",
                  }}
                >
                  {t("Sequence actions")} ({settingOptions?.sequence})
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "fit-content",
                    minHeight: 50,
                    width: "fit-content",
                    minWidth: 300,
                    position: "relative",
                  }}
                >
                  {actionListOption2?.map((item) => {
                    if (
                      settingOptions?.sequence_type.includes(1) &&
                      (item.uuid === 7 || item.uuid === 12)
                    ) {
                      return null;
                    }
                    return (
                      <ListItem
                        key={item?.uuid}
                        disableGutters
                        disablePadding
                        onClick={(e) => onClickListItem(e, item.uuid, true)}
                      >
                        <ListItemButton
                          sx={{
                            fontFamily: Fonts?.Text,
                            color: "inherit",
                            "&:hover": {
                              backgroundColor: alpha(Color.themeColor, 0.1),
                              color: alpha(Color.themeColor, 0.9),
                              "& span": {
                                fontWeight: 500,
                                color: alpha(Color.themeColor, 0.9),
                              },
                              "& svg": {
                                color: alpha(Color.themeColor, 0.9),
                              },
                            },
                          }}
                        >
                          <ListItemIcon>{item?.icon}</ListItemIcon>
                          <ListItemText primary={item?.title} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : null}
          </>
        )}
      </Box>
    </Popover>
  );
};

export default GroupSettingsModal;
