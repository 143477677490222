import {
  startOfQuarter,
  isSameQuarter,
  startOfMonth,
  isSameMonth,
  startOfWeek,
  subQuarters,
  endOfMonth,
  isSameWeek,
  addMonths,
  subMonths,
  subWeeks,
  getDate,
  setDate,
  format,
} from "date-fns";
import {
  useImperativeHandle,
  useDeferredValue,
  useCallback,
  forwardRef,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import {
  LinearProgress,
  useMediaQuery,
  useTheme,
  Button,
  Stack,
  alpha,
  Box,
} from "@mui/material";
import { TbBuildingBank, TbPercentage } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { TfiExchangeVertical } from "react-icons/tfi";
import { MdOutlineShowChart } from "react-icons/md";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { FaArrowUp } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  formatDateInQuarter,
  formatDateToLocal,
  getTailwindColor,
  formatDateInWeek,
  isBookedStates,
  getGridColumn,
  getCellInfo,
  getRowsCell,
} from "../../../../Helper/data";
import {
  getStartingLiquidityValue,
  getStartOfCustomQuarter,
  getEndOfCustomQuarter,
  isSameCustomQuarter,
  getMonthlyValue,
  collectUUIDs,
} from "../../../../Helper/functions";
import EnableInternalDSFunctions from "../../../../components/Header/DatasetHeader/EnableInternalDSFunctions";
import useUpdateEffect from "../../../../hooks/4-useUpdateEffect/useUpdateEffect";
import { setPlanningTableData } from "../../../../store/slices/global";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { setCategories } from "../../../../store/slices/category";
import { setSearchText } from "../../../../store/slices/board";
import { Color, Constant, Fonts } from "../../../../Helper";
import useStatusHook from "../../../../hooks/useStatusHook";
import EndPoints from "../../../../APICall/EndPoints";
import initialData from "./../../../../Helper/data";
import Icon from "../../../../components/Icon";
import { queryClient } from "../../../../App";
import APICall from "../../../../APICall";
import Table from "./TableGrid/Table";
import store from "../../../../store";
import theme from "../../../../theme";

const TableView = () => {
  const commonRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const s2300 = useMediaQuery("(min-width:2300px)");

  let borderRadius = 1;
  let rowHeight = Constant.table_row_height;
  let gridWidth = Constant.column_width;
  let spacing = Constant.start_column_extra_width;
  if (s2300) {
    gridWidth = Constant.column_width_2300;
    spacing = Constant.start_column_extra_width_2300;
    rowHeight = Constant.table_row_height_2300;
  }
  // if (s2220) {
  //   gridWidth = Constant.column_width_2200;
  //   spacing = Constant.start_column_extra_width_2200;
  //   rowHeight = 38;
  // }
  // if (s2420) {
  //   gridWidth = Constant.column_width_2400;
  //   spacing = Constant.start_column_extra_width_2400;
  //   rowHeight = 38;
  // }
  // if (s2550) {
  //   gridWidth = Constant.column_width_2500;
  //   spacing = Constant.start_column_extra_width_2500;
  //   rowHeight = 38;
  // }

  //redux
  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );
  const end_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.end_date
  );
  const tableType = useSelector((state) => state.boardSlice?.tableType);

  //state
  const [data, setData] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);

  //query
  const gridColumn = useMemo(() => {
    return getGridColumn({
      stickyColumn: ["column-start"],
      showColumnEnd: false,
      gridWidth,
      tableType,
      start_date,
      end_date,
    });
  }, [end_date, gridWidth, tableType, start_date]);

  useEffect(() => {
    dispatch(setPlanningTableData(data));
  }, [data]);

  //functions
  const onCellChanged = (cell) => {
    commonRef.current?.onCellChanged(cell);
  };

  const onClickRefresh = () => {
    commonRef.current?.getCategories();
  };

  const getRefreshText = useCallback(() => {
    setTimeout(() => {
      return t("Refresh");
    }, 500);
  }, [t]);

  return (
    <Box
      sx={{
        width: "fit-content",
        height: "fit-content",
        marginLeft: "28px",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <CommonFunctions
          ref={commonRef}
          data={data}
          setData={setData}
          gridColumn={gridColumn}
          gridWidth={gridWidth}
          rowHeight={rowHeight}
          spacing={spacing}
          borderRadius={borderRadius}
          selectedCell={selectedCell}
          setSelectedCell={setSelectedCell}
          setTableLoading={setTableLoading}
          tableLoading={tableLoading}
          tableType={tableType}
        />
        {/* {tableLoading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100vw"}
            height={"40rem"}
          />
        ) : null} */}
        {!data && !tableLoading ? (
          <div style={{ textAlign: "center", width: "100vw", height: "40rem" }}>
            <Button
              onClick={onClickRefresh}
              variant="text"
              sx={{
                textTransform: "initial",
                fontSize: "1rem",
                mt: "3rem",
              }}
            >
              {getRefreshText()}
            </Button>
          </div>
        ) : null}

        <HeaderTable
          data={data}
          key="header"
          name="header"
          gridColumn={gridColumn}
          gridWidth={gridWidth}
          rowHeight={rowHeight}
          spacing={spacing}
          borderRadius={0}
          selectedCell={selectedCell}
          setSelectedCell={setSelectedCell}
          tableType={tableType}
          tableLoading={tableLoading}
        />
        <div style={{ height: "0.25rem" }}>
          {tableLoading ? (
            <LinearProgress sx={{ color: theme.palette.primary.main }} />
          ) : null}
        </div>
        {data ? (
          <Table
            rows={data?.["start_liquidity"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"start_liquidity"}
            key={"start_liquidity"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginTop: "1.75rem",
              marginBottom: "2rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["net_change"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"net_change"}
            key={"net_change"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginBottom: "2rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["inflow"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"inflow"}
            key={"inflow"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            onCellChanged={onCellChanged}
            style={{
              marginBottom: "2rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["outflow"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"outflow"}
            key={"outflow"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            onCellChanged={onCellChanged}
            style={{
              marginBottom: "2rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["VAT_Imputation"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"VAT_Imputation"}
            key={"VAT_Imputation"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginBottom: "0.5rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["vat"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"vat"}
            key={"vat"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginBottom: "1.75rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["end_liquidity"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"end_liquidity"}
            key={"end_liquidity"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginBottom: "4rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
      </div>
    </Box>
  );
};

export default TableView;

const HeaderSearchInput = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const searchText = useSelector((state) => state.boardSlice?.searchText);

  const onChange = (e) => {
    dispatch(setSearchText(e.target.value));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& input::placeholder": {
          color: Color.tailwind.grey[400],
        },
      }}
    >
      <SearchIcon
        sx={{
          position: "absolute",
          left: "8px",
          zIndex: 11,
          color: Color.tailwind.slate[400],
        }}
      />
      <input
        type={"text"}
        placeholder={t("Category Search")}
        value={searchText}
        onChange={onChange}
        autoFocus
        style={{
          width: "calc(100% - 8px)",
          height: "calc(100% - 8px)",
          margin: "2px",
          paddingLeft: "28px",
          borderRadius: "4px",
          outline: `1px solid ${
            searchText?.length > 0
              ? Color.tailwind.purple[500]
              : Color.tailwind.slate[200]
          }`,
          fontFamily: Fonts.Text,
          fontSize: "12px",
          textAlign: "left",
        }}
      />
    </Box>
  );
};

const CustomIcon = ({
  backgroundColor = theme.palette.primary.main,
  iconColor = Color.white,
  iconSize = "25px",
  icon,
  iconStyle,
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        borderRadius: "4px",
        backgroundColor: backgroundColor,
        position: "relative",
        width: "18px",
        height: "18px",
      }}
    >
      <Icon
        icon={icon}
        fontSize={iconSize}
        color={iconColor}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: `translate(-50%, -50%)`,
          ...iconStyle,
        }}
      ></Icon>
    </div>
  );
};

const HeaderTable = ({
  gridColumn,
  data,
  gridWidth,
  rowHeight,
  spacing,
  tableType,
  name = "header",
  borderRadius,
  selectedCell,
  setSelectedCell,
  tableLoading,
}) => {
  const theme = useTheme();

  const [rows, setRows] = useState([]);

  const profile = useSelector((state) => state.settingsSlice?.profile);

  const transaction_monthly_chart = useStatusHook("transaction_monthly_chart");

  const pastTransaction_monthly_statistic = useStatusHook(
    "Transaction_monthly_statistic"
  );

  useDebounce(
    () => {
      if (gridColumn?.length && !tableLoading) {
        let array = [];
        let headerArray = [];

        gridColumn?.forEach((columnElement) => {
          if (columnElement?.item) {
            const value =
              tableType === "quarterly"
                ? formatDateInQuarter(new Date(columnElement?.item))
                : tableType === "weekly"
                  ? formatDateInWeek(new Date(columnElement?.item))
                  : formatDateToLocal(new Date(columnElement?.item), "MMM yy");
            const isCurrentMonth =
              tableType === "quarterly"
                ? isSameQuarter(new Date(), new Date(columnElement?.item))
                : tableType === "weekly"
                  ? isSameWeek(new Date(), new Date(columnElement?.item))
                  : isSameMonth(new Date(), new Date(columnElement?.item));

            headerArray.push({
              item: {
                isCurrentMonth,
                date: columnElement?.item,
                value,
              },
              fontWeight: isCurrentMonth ? 800 : 500,
              fontSize: "13px",
              // textTransform: "uppercase",
              color: isCurrentMonth ? "violet" : undefined,
              borderColor: isCurrentMonth
                ? theme.palette.primary.dark
                : undefined,
              borderBottomWidth: isCurrentMonth ? "3px" : undefined,
              borderTopWidth: "0px",
              borderRightWidth: "0px",
            });
          }
        });

        array.push({
          rowId: "header",
          rowHeight,
          mergeCell: true,
          isHeader: true,
          parentRowId: null,
          disableSelection: true,
          rowBackgroundColor: theme.palette.color.appThemeBg,
          position: "fixed",
          cells: getRowsCell({
            cellStart: {
              item: {
                uuid: "header",
                value: "header",
              },
              customCellRender: (props) => <HeaderSearchInput {...props} />,
              gridWidth: gridWidth + spacing,
              fontWeight: 700,
              isColumnStartCell: true,
              borderTopWidth: "0px",
              borderLeftWidth: "0px",
              zIndex: 12,
            },
            data: headerArray,
          }),
        });

        setRows([...array]);
      }
    },
    1000,
    [
      gridColumn,
      gridWidth,
      rowHeight,
      spacing,
      tableType,
      tableLoading,
      profile,
      theme.palette.primary.dark,
      theme.palette.color.appThemeBg,
    ]
  );

  if (
    !data?.["inflow"] &&
    (transaction_monthly_chart?.isFetching ||
      pastTransaction_monthly_statistic?.isFetching)
  ) {
    return null;
  }

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{ position: "sticky", top: "0px", zIndex: 15 }}
    >
      <Table
        rows={rows}
        columns={gridColumn}
        borderRadius={borderRadius}
        name={name}
        key={name}
        selectedCell={selectedCell}
        setSelectedCell={setSelectedCell}
      />
    </Stack>
  );
};

const CommonFunctions = forwardRef(
  (
    {
      gridColumn,
      gridWidth,
      rowHeight,
      spacing,
      setData,
      tableLoading,
      setTableLoading,
      tableType,
    },
    _ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const internalDsRef = useRef(null);

    //redux
    const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
    const selectionCategoriesByID = useSelector(
      (state) => state.categorySlice.selectionCategoriesByID
    );

    const highlightedScenarios = useSelector(
      (state) => state.boardSlice?.highlightedScenarios
    );
    const refreshData = useSelector((state) => state.appSlice?.refreshData);
    const state = useSelector((state) => state.globalSlice?.state);
    const scenario = useSelector((state) => state.globalSlice?.scenario);
    const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
    const scenario_color =
      scenarioById?.[highlightedScenarios?.[0]]?.[0]?.color;
    const _scenario_ = scenarioById?.[highlightedScenarios?.[0]]?.[0];
    const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
    const searchText = useSelector((state) => state.boardSlice?.searchText);

    const isFirstLoginOverlayOpen = useSelector(
      (state) => state.globalSlice.isFirstLoginOverlayOpen
    );
    const isAccountSelectOverlayOpen = useSelector(
      (state) => state.globalSlice.isAccountSelectOverlayOpen
    );
    const isAllHeaderApiFetched = useSelector(
      (state) => state.commonSlice.isAllHeaderApiFetched
    );
    const use_global_categories = useSelector(
      (state) => state.boardSlice?.dataSetData?.use_global_categories
    );
    const _selectionCategories = useSelector(
      (state) => state.categorySlice?.selectionCategories
    );

    const selectionCategories = useMemo(() => {
      return _selectionCategories?.filter((o1) =>
        use_global_categories ? !o1?.dataset : o1?.dataset === dataSetData?.uuid
      );
    }, [_selectionCategories, dataSetData?.uuid, use_global_categories]);

    const AllStaff = useStatusHook("staff");

    const Staff = useMemo(
      () =>
        AllStaff?.data?.results?.filter(
          (o1) => o1?.dataset === dataSetData?.uuid
        ),
      [AllStaff?.data, dataSetData?.uuid]
    );
    const getConnectedScenarios = ({ _scenario }) => {
      let array = [];
      array.push(_scenario?.uuid);
      function checkScenario(ref_scenario) {
        if (ref_scenario) {
          let _ref_scenario = scenarioById?.[ref_scenario]?.[0];
          array.push(_ref_scenario?.uuid);
          checkScenario(_ref_scenario?.reference);
        }
      }
      checkScenario(_scenario?.reference);
      return array;
    };

    const selected_scenario_uuid = useMemo(() => {
      if (highlightedScenarios?.[0]) {
        const _scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];
        return getConnectedScenarios({ _scenario });
      } else {
        return [];
      }
    }, [highlightedScenarios, scenarioById]);

    const selectionsCategoryUuidCollections = useMemo(() => {
      let data = {};
      selectionCategories?.forEach((o1) => {
        let uuids = [];
        const category = selectionCategoriesByID?.[o1?.uuid]?.[0];
        collectUUIDs(category, uuids);
        data[o1?.uuid] = uuids;
      });
      return data;
    }, [selectionCategories, selectionCategoriesByID]);

    //state
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);

    // const from_date = useDeferredValue(
    //   subMonths(new Date(dataSetData?.start_date), 6)
    // );
    const from_date = useDeferredValue(dataSetData?.start_date);
    const to_date = useDeferredValue(dataSetData?.end_date);
    const multiScenarioIds = useDeferredValue(selected_scenario_uuid);

    //query
    const table_data = useQuery({
      queryKey: [
        "transactions",
        {
          dataset: dataSetData?.uuid,
          apiType: "monthly",
          from_payment_date: from_date,
          to_payment_date: to_date,
          multiScenarioIds,
        },
      ],
      queryFn: () => {
        let param = {
          type: "monthly",
          dataset: dataSetData?.uuid,
          from_payment_date: from_date,
          to_payment_date: to_date,
          multiScenarioIds,
        };
        if (!dataSetData.use_global_categories) {
          param.category_dataset = dataSetData?.uuid;
        } else {
          param.global_category = true;
        }
        const result = getStatisticsDataWithParams(param);

        if (result) {
          return result;
        }
      },
      backgroundFetch: true,
      // staleTime: 30000,
      enabled:
        isAllHeaderApiFetched &&
        !isFirstLoginOverlayOpen &&
        multiScenarioIds?.length > 0 &&
        !!multiScenarioIds?.[0] &&
        !isAccountSelectOverlayOpen &&
        !!selected_scenario_uuid?.[0] &&
        !!dataSetData?.uuid,
      priority: 5,
    });

    const transaction_monthly_chart = useStatusHook(
      "transaction_monthly_chart"
    );

    const pastTransaction_monthly_statistic = useStatusHook(
      "Transaction_monthly_statistic"
    );

    const monthlyTransactions = table_data?.data?.results
      ? [
          ...(pastTransaction_monthly_statistic?.data?.results || []),
          ...(table_data?.data?.results || []),
        ]
      : [];
    const groupedMonthlyTransactions = useMemo(() => {
      const tableGroupedData = _.groupBy(
        table_data?.data?.results || [],
        ({ month }) => month
      );
      const pastGroupedMonthlyTransactions = _.groupBy(
        pastTransaction_monthly_statistic?.data?.results || [],
        ({ month }) => month
      );
      // console.log(
      //   "current dates:",
      //   formatDateToLocal(from_date, "yyyy-MM-dd"),
      //   formatDateToLocal(to_date, "yyyy-MM-dd")
      // );

      return {
        ...pastGroupedMonthlyTransactions,
        ...tableGroupedData,
      };
    }, [
      table_data?.data?.results,
      pastTransaction_monthly_statistic?.data?.results,
    ]);

    //lifecycle
    useImperativeHandle(_ref, () => ({
      onCellChanged,
      getCategories,
    }));

    useUpdateEffect(() => {
      global.allowFetch = { Inflow: [], Outflow: [] };
      let options = {
        predicate: (query) => query.queryKey[0] === "transactions",
      };
      queryClient.resetQueries(options);
    }, [refreshData]);

    useEffect(() => {
      setTableLoading(
        isCategoryLoading ||
          table_data?.isFetching ||
          table_data?.isLoading ||
          !table_data?.isSuccess ||
          transaction_monthly_chart?.isFetching ||
          pastTransaction_monthly_statistic?.isFetching
      );
    }, [
      isCategoryLoading,
      pastTransaction_monthly_statistic?.isFetching,
      table_data?.isFetching,
      table_data?.isLoading,
      table_data?.isSuccess,
      transaction_monthly_chart?.isFetching,
    ]);

    useDebounce(
      () => {
        let startLiqArray = [];
        let netChangeArray = [];
        let endLiqArray = [];
        let vatArray = [];
        let vatChargeArray = [];

        let startLiqNumberArray = [];
        let netChangeNumberArray = [];
        let endLiqNumberArray = [];
        let vatNumberArray = [];
        let vatChargeNumberArray = [];
        let LiquidityTotal = 0;
        const isBase = _scenario_?.title === "Base";

        if (
          !AllStaff?.isFetching &&
          !!selected_scenario_uuid?.[0] &&
          !tableLoading &&
          gridColumn?.length
        ) {
          const recurring_rules =
            store.getState()?.globalSlice?.recurring_rules;
          const staffListContacts = Staff?.map((o1) => o1?.uuid);
          const staffData = recurring_rules?.filter(
            (o1) =>
              o1.recurring_type === "employee" &&
              o1.contact &&
              staffListContacts?.includes(o1.contact)
          );
          const loanData = recurring_rules?.filter(
            (o1) =>
              o1.recurring_type === "loan" || o1.recurring_type === "leasing"
          );
          const groupByCategoryStaffData = _.groupBy(
            staffData,
            (item) => item?.transaction_category || "unCategorized"
          );
          const groupByCategoryLoanData = _.groupBy(
            loanData,
            (item) => item?.transaction_category || "unCategorized"
          );
          const currentMonth =
            tableType === "quarterly"
              ? format(startOfQuarter(new Date()), "yyyy-MM-dd")
              : tableType === "weekly"
                ? format(startOfWeek(new Date()), "yyyy-MM-dd")
                : format(new Date(), "yyyy-MM");

          let booked_balances =
            transaction_monthly_chart?.data?.booked_balances;
          const groupByMonthBookedBalances = _.groupBy(
            booked_balances,
            ({ month }) => month
          );

          const business_start_date = dataSetData?.business_start_date;

          let inflow = buildTable({
            income_expense_type: 1,
            categoryType: 1,
            business_start_date,
            name: "inflow",
            title: t("table_inflow"),
            total_key: "inflow",
            count_key: "inflow_count",
            searchText,
            unCategorizedRowId: "uncategorizedInflow",
            tableType,
          });

          let outflow = buildTable({
            income_expense_type: 2,
            business_start_date,
            categoryType: 2,
            name: "outflow",
            title: t("table_outflow"),
            total_key: "outflow",
            count_key: "outflow_count",
            searchText,
            unCategorizedRowId: "uncategorizedOutflow",
            groupByCategoryStaffData,
            groupByCategoryLoanData,
            tableType,
          });

          let prev_booked_balance = 0;
          let booked_balance = 0;
          let vatTotal = 0;
          gridColumn?.forEach((columnElement, index) => {
            if (index !== 0) {
              let _inflow = inflow?.[0]?.cells?.[index];
              let _outflow = outflow?.[0]?.cells?.[index];

              let monthEndTotal = 0;
              let vat_scenarios = _outflow?.item?.vat_scenarios;
              let monthEndVatTotal = _inflow?.item?.vat + _outflow?.item?.vat;
              let monthEndVatChargeTotal = _outflow?.item?.vat_charge || 0;
              let netChange = _inflow?.item?.value + _outflow?.item?.value;
              let relNetChange =
                _inflow?.item?.relValue + _outflow?.item?.relValue;

              const monthDate = format(
                new Date(columnElement?.item),
                tableType === "weekly" || tableType === "quarterly"
                  ? "yyyy-MM-dd"
                  : "yyyy-MM"
              );
              const currentDate =
                tableType === "weekly" || tableType === "quarterly"
                  ? format(new Date(), "yyyy-MM-dd")
                  : format(new Date(), "yyyy-MM");
              const prevMonthDate =
                tableType === "quarterly"
                  ? format(
                      subQuarters(new Date(columnElement?.item), 1),
                      "yyyy-MM-dd"
                    )
                  : tableType === "weekly"
                    ? format(
                        subWeeks(new Date(columnElement?.item), 1),
                        "yyyy-MM-dd"
                      )
                    : format(
                        subMonths(new Date(columnElement?.item), 1),
                        "yyyy-MM"
                      );

              const isPastMonth = monthDate < currentMonth;
              const isCurrentMonth = monthDate === currentMonth;
              const isFutureMonth = monthDate > currentMonth;
              const subtractVat =
                advanceVat?.enabled &&
                (monthDate > currentMonth ||
                  (monthDate === currentMonth &&
                    getDate(new Date()) <= Number(advanceVat?.due_day ?? 1)));
              const prev_balance =
                groupByMonthBookedBalances?.[
                  prevMonthDate > currentMonth ? currentDate : prevMonthDate
                ]?.[0];
              const balance =
                groupByMonthBookedBalances?.[
                  isFutureMonth ? currentDate : monthDate
                ]?.[0];

              prev_booked_balance =
                prev_balance?.booked_balance + prev_balance?.ignored_balance ||
                prev_booked_balance;

              booked_balance =
                balance?.booked_balance + balance?.ignored_balance ||
                booked_balance;
              if (index === 1) {
                let startValue = getStartingLiquidityValue({
                  monthlyTransactions,
                  selected_scenario_uuid,
                  business_start_date,
                  date: monthDate,
                  selectionCategories,
                  selectionCategoriesByID,
                  groupedMonthlyTransactions,
                  advanceVat,
                  isTable: true,
                });
                LiquidityTotal = startValue?.LiquidityTotal;
                vatTotal = startValue?.VatChargeTotal;

                if (isFutureMonth) {
                  LiquidityTotal =
                    (LiquidityTotal || 0) + (booked_balance || 0);
                }
                if (subtractVat) {
                  LiquidityTotal = (LiquidityTotal || 0) - (vatTotal || 0);
                }
              }
              let startTotal =
                (LiquidityTotal ?? 0) +
                (isFutureMonth ? 0 : prev_booked_balance ?? 0);
              startLiqNumberArray.push({
                item: {
                  isCurrentMonth,
                  date: monthDate,
                  type: "numeric",
                  value: startTotal,
                },
                fontWeight: isCurrentMonth ? 700 : 500,
                customCell: true,
                showFontColor: true,
                backgroundColor: alpha(
                  getTailwindColor(_scenario_?.color || "slate", 100),
                  isCurrentMonth ? 1 : 0.25
                ),
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              });

              // if (isPastMonth) {
              //   relNetChange = booked_balance - prev_booked_balance;
              //   // netChange = booked_balance - prev_booked_balance;
              // }

              if (!isPastMonth) {
                monthEndTotal = Number(relNetChange || 0);
              }

              let obj = {
                isCurrentMonth,
                date: columnElement?.item,
                value: 0,
                type: "numeric",
              };
              LiquidityTotal =
                (isCurrentMonth ? booked_balance ?? 0 : 0) +
                (LiquidityTotal ?? 0) +
                (monthEndTotal ?? 0);

              let endTotal =
                (LiquidityTotal ?? 0) + (isPastMonth ? booked_balance ?? 0 : 0);

              // if (isCurrentMonth) {
              //   console.log(
              //     "🚀 / relNetChange:",
              //     monthDate,
              //     startTotal,
              //     endTotal,
              //     LiquidityTotal,
              //     relNetChange,
              //     // prev_booked_balance,
              //     booked_balance,
              //     subtractVat,
              //     vatTotal
              //   );
              // }

              netChangeNumberArray.push({
                ..._inflow,
                borderColor: getTailwindColor(_scenario_?.color, 200),
                backgroundColor: alpha(
                  getTailwindColor(_scenario_?.color || "slate", 100),
                  isCurrentMonth ? 1 : 0.25
                ),
                color: netChange < 0 ? "red" : "green",
                clickable: false,
                showCursor: false,
                item: {
                  ..._inflow?.item,
                  value: netChange,
                  value2: `${t("absolute_net_change_tooltip")}: ${parseFloat((booked_balance || 0) - (prev_booked_balance || 0))?.toFixed(2)}`,
                  //relative_net_change_tooltip
                },
              });
              monthEndVatTotal = -monthEndVatTotal;

              vatChargeNumberArray.push({
                item: {
                  ...obj,
                  value: subtractVat ? monthEndVatChargeTotal : 0,
                  scenarios: vat_scenarios,
                  type: "numeric",
                },
                fontStyle: !subtractVat ? "italic" : "inherit",
                showFontColor: true,
                subtractVat,
                hidePercentage: true,
                fontWeight: isCurrentMonth ? 700 : 500,
                customCell: true,
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
                backgroundColor: getTailwindColor(
                  isBase ? "zinc" : _scenario_?.color || "slate",
                  isCurrentMonth ? 100 : 50
                ),
              });
              endLiqNumberArray.push({
                item: {
                  ...obj,
                  value: endTotal,
                },
                fontWeight: isCurrentMonth ? 700 : 500,
                customCell: true,
                showFontColor: true,
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
                backgroundColor: alpha(
                  getTailwindColor(_scenario_?.color || "slate", 100),
                  isCurrentMonth ? 1 : 0.25
                ),
              });
              vatNumberArray.push({
                item: {
                  ...obj,
                  value: monthEndVatTotal,
                },
                fontWeight: isCurrentMonth ? 700 : 500,
                customCell: true,
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
                backgroundColor: getTailwindColor(
                  isBase ? "zinc" : _scenario_?.color || "slate",
                  isCurrentMonth ? 100 : 50
                ),
              });
              // console.log(
              //   "🚀 / / LiquidityTotal:",
              //   monthDate,
              //   monthEndTotal,
              //   netChange,
              //   subtractVat,
              //   monthEndVatTotal,
              //   monthEndVatChargeTotal
              // );
            }
          });

          netChangeArray.push({
            rowId: "net_change",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "net_change",
                  value: t("Net Change"),
                  icon: (
                    <CustomIcon
                      icon={<TfiExchangeVertical />}
                      iconSize="14px"
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconStyle={{
                        "& svg": {
                          strokeWidth: "1px",
                        },
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                clickable: false,
                backgroundColor: scenario_color
                  ? getTailwindColor(scenario_color, 50)
                  : "inherit",
                color: scenario_color ? scenario_color : "slate",
                borderColor: scenario_color
                  ? getTailwindColor(scenario_color, 200)
                  : "slate",
              },
              data: netChangeNumberArray,
            }),
          });
          endLiqArray.push({
            rowId: "end_liquidity",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "end_liquidity",
                  value: t("Liquidity End"),
                  icon: (
                    <CustomIcon
                      icon={<MdOutlineShowChart />}
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconSize="22px"
                      iconStyle={{
                        transform: `translate(-50%, -50%) rotate(10deg)`,
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                backgroundColor: getTailwindColor(_scenario_?.color, 50),
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              },
              data: endLiqNumberArray,
            }),
          });
          vatArray.push({
            rowId: "vat",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "vat",
                  value: t("VAT"),
                  value2: t("VAT_tooltip"),
                  icon: (
                    <CustomIcon
                      icon={<TbPercentage />}
                      iconSize="14px"
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconStyle={{
                        "& svg": {
                          strokeWidth: "3.5px",
                        },
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                backgroundColor: getTailwindColor(
                  isBase ? "zinc" : _scenario_?.color || "slate",
                  50
                ),
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              },
              data: vatNumberArray,
            }),
          });
          startLiqArray.push({
            rowId: "start_liquidity",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "start_liquidity",
                  value: t("Liquidity Start"),
                  icon: (
                    <CustomIcon
                      icon={<MdOutlineShowChart />}
                      iconSize="22px"
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconStyle={{
                        transform: `translate(-50%, -50%) rotate(10deg)`,
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                backgroundColor: getTailwindColor(_scenario_?.color, 50),
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              },
              data: startLiqNumberArray,
            }),
          });
          vatChargeArray.push({
            rowId: "VAT_Imputation",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "VAT_Imputation",
                  value: t("VAT_Imputation"),
                  value2: t("VAT_Imputation_tooltip"),
                  link: "/settings/vats",
                  icon: (
                    <CustomIcon
                      icon={<TbPercentage />}
                      iconSize="14px"
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconStyle={{
                        "& svg": {
                          strokeWidth: "3.5px",
                        },
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                backgroundColor: getTailwindColor(
                  isBase ? "zinc" : _scenario_?.color || "slate",
                  50
                ),
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              },
              data: vatChargeNumberArray,
            }),
          });

          setData((prev) => ({
            ...prev,
            vat: [...vatArray],
            start_liquidity: [...startLiqArray],
            end_liquidity: [...endLiqArray],
            VAT_Imputation: [...vatChargeArray],
            inflow: [...inflow],
            outflow: [...outflow],
            net_change: [...netChangeArray],
          }));
        }
      },
      1000,
      [
        AllStaff?.isFetching,
        gridWidth,
        gridColumn,
        tableLoading,
        tableType,
        advanceVat,
        groupedMonthlyTransactions,
        scenario,
        state,
        refreshData?.updated,
        highlightedScenarios,
        searchText,
        dataSetData?.business_start_date,
        selectionsCategoryUuidCollections,
        Staff,
        t,
      ],
      true
    );

    //api
    const getCategories = async () => {
      // let endUrl = "";
      // if (!use_global_categories) {
      //   endUrl = `?dataset=${dataset}`;
      // } else {
      //   endUrl = `?is_global=true`;
      // }
      setIsCategoryLoading(true);
      await APICall("get", EndPoints.category)
        .then((response) => {
          if (response.status === 200 && response.data) {
            dispatch(
              setCategories({ data: response.data.results, type: "all" })
            );
            setIsCategoryLoading(false);
            table_data?.refetch();
          }
        })
        .finally(() => {
          setIsCategoryLoading(false);
        });
    };

    const updateCardByID = async (id, obj) => {
      await APICall("patch", EndPoints.transactions + `${id}/`, obj).then(
        (response) => {
          if (response.status === 200 && response.data) {
            resetKanban();
          }
        }
      );
    };

    const addCard = async (obj) => {
      await APICall("post", EndPoints.transactions, obj).then((response) => {
        if (response.status === 201 && response.data) {
          resetKanban();
        }
      });
    };

    //functions
    const onCellChanged = (cell) => {
      if (cell) {
        const scenario_title =
          scenarioById?.[highlightedScenarios?.[0]]?.[0]?.title;
        let value = cell?.value;
        if (value) {
          if (cell?.cell?.item?.income_expense_type === 2) {
            if (!cell?.value?.toString()?.includes("-")) {
              value = -cell?.value;
            }
          } else {
            if (cell?.value?.toString()?.includes("-")) {
              value = Math.abs(cell?.value);
            }
          }

          if (cell?.uuid) {
            let obj = {
              gross_value: value || 0,
            };
            if (scenario_title) {
              obj.scenario = scenario_title;
            }

            updateCardByID(cell.uuid, obj);
          } else {
            let category = null;
            if (
              cell?.rowId === "uncategorized" ||
              cell?.rowId === "uncategorizedInflow" ||
              cell?.rowId === "uncategorizedOutflow"
            ) {
              category = null;
            } else {
              category = cell.rowId;
            }
            let categoryTitle = "unCategorized";
            let tax = null;
            if (category) {
              categoryTitle =
                selectionCategoriesByID && selectionCategoriesByID[category]
                  ? selectionCategoriesByID[category][0].label
                  : "unCategorized";
              tax = selectionCategoriesByID?.[category]?.[0].tax;
            }
            const title = `Planning Value - ${format(
              new Date(cell?.cell?.item?.date),
              "MMMM yy"
            )} - ${categoryTitle}`;
            let obj = {
              title: title,
              dataset: dataSetData?.uuid,
              state: "Planned",
              scenario: scenario_title || "Base",
              note: "",
              due_date: format(new Date(cell?.cell?.item?.date), "yyyy-MM-dd"),
              gross_value: value || 0,
              category: category,
              tax: tax,
              invoice_date: null,
              source: 1,
              data_source: dataSetData?.internal_data_source,
              position: 1001,
            };
            internalDsRef.current?.enableInternalDS();
            addCard(obj);
          }
        }
      }
    };

    const resetKanban = async () => {
      global.allowFetch = { Inflow: [], Outflow: [] };
      let options = {
        predicate: (query) =>
          query.queryKey[0] === "transactions" &&
          query.queryKey[1]?.dataset === dataSetData?.uuid,
      };
      queryClient.invalidateQueries(options);
    };

    const buildTable = ({
      income_expense_type,
      business_start_date,
      categoryType,
      name,
      title,
      total_key,
      count_key,
      searchText,
      unCategorizedRowId,
      groupByCategoryStaffData,
      groupByCategoryLoanData,
      tableType,
    }) => {
      let array = [];
      let inflowArray = [];
      let inflowUnCatArray = [];
      let categoryNumberObj = {};
      const isBase = _scenario_?.title === "Base";

      const color = income_expense_type === 1 ? "green" : "red";
      if (gridColumn?.length && selectionCategories) {
        const currentMonth =
          tableType === "quarterly"
            ? format(startOfQuarter(new Date()), "yyyy-MM-dd")
            : tableType === "weekly"
              ? format(startOfWeek(new Date()), "yyyy-MM-dd")
              : format(new Date(), "yyyy-MM");
        const categoriesToCheck = selectionCategories.filter(
          (element) =>
            element.visible &&
            element.type === categoryType &&
            (searchText
              ? element.label.toLowerCase().includes(searchText.toLowerCase())
              : true)
        );
        gridColumn?.forEach((columnElement) => {
          if (columnElement?.item) {
            const monthDate = format(
              new Date(columnElement?.item),
              tableType === "weekly" || tableType === "quarterly"
                ? "yyyy-MM-dd"
                : "yyyy-MM"
            );

            const statsData = groupedMonthlyTransactions?.[monthDate] ?? [];
            const monthData = statsData?.filter(
              (o1) =>
                !Constant?.calculationExcludeStates2.includes(o1?.state) &&
                selected_scenario_uuid?.includes(o1?.scenario_uuid)
            );

            let monthEndTotal = 0;
            let relBookedTotal = 0;
            let monthEndVatTotal = 0;
            let monthEndVatChargeTotal = 0;
            let categoryWisePastTotal = {};
            let pastTotal = null;
            let startVatDate = null;
            let endVatDate = null;
            let isVatPayMentMonth = false;
            let vat_pay_date = 0;
            let vatData = [];

            const isPastMonth = monthDate < currentMonth;
            const isCurrentMonth = monthDate === currentMonth;
            const isFutureMonth = monthDate > currentMonth;

            let isVatPast = false;
            let isVatCurrent = false;
            let isVatFuture = false;
            if (advanceVat && advanceVat?.enabled) {
              const isMonthly =
                String(advanceVat?.frequency) === "1" &&
                !advanceVat?.permanent_extension;
              const isMonthlyExtended =
                String(advanceVat?.frequency) === "1" &&
                advanceVat?.permanent_extension;
              const isQuarterly =
                String(advanceVat?.frequency) === "2" &&
                !advanceVat?.permanent_extension;
              const isQuarterlyExtended =
                String(advanceVat?.frequency) === "2" &&
                advanceVat?.permanent_extension;
              if (isMonthly || isMonthlyExtended) {
                const prevMonth = subMonths(
                  startOfMonth(new Date(monthDate)),
                  isMonthly ? 1 : 2
                );
                const currentStartDate = format(
                  startOfMonth(new Date()),
                  "yyyy-MM"
                );
                const currentEndDate = format(
                  endOfMonth(new Date()),
                  "yyyy-MM"
                );
                startVatDate = format(startOfMonth(prevMonth), "yyyy-MM");
                endVatDate = format(endOfMonth(prevMonth), "yyyy-MM");
                vat_pay_date = format(
                  addMonths(
                    startOfMonth(new Date(endVatDate)),
                    isMonthly ? 1 : 2
                  ),
                  "yyyy-MM"
                );
                isVatPast = endVatDate < currentStartDate;
                isVatFuture = startVatDate > currentEndDate;
                isVatCurrent = isSameMonth(new Date(startVatDate), new Date());
              }

              if (isQuarterly || isQuarterlyExtended) {
                const currentQuarterStartDate = format(
                  getStartOfCustomQuarter(
                    new Date(monthDate),
                    business_start_date
                  ),
                  "yyyy-MM"
                );
                const currentStartDate = format(
                  getStartOfCustomQuarter(new Date(), business_start_date),
                  "yyyy-MM"
                );

                const currentEndDate = format(
                  getEndOfCustomQuarter(currentStartDate),
                  "yyyy-MM"
                );
                const prevQuarterStart = subMonths(
                  new Date(currentQuarterStartDate),
                  3
                );
                const prevQuarterEnd = getEndOfCustomQuarter(prevQuarterStart);

                // Q1: January - March
                // Q2: April - June
                // Q3: July - September
                // Q4: October - December

                startVatDate = format(new Date(prevQuarterStart), "yyyy-MM");
                endVatDate = format(new Date(prevQuarterEnd), "yyyy-MM");
                vat_pay_date = format(
                  addMonths(
                    startOfMonth(new Date(endVatDate)),
                    isQuarterly ? 1 : 2
                  ),
                  "yyyy-MM"
                );
                isVatPast = endVatDate < currentStartDate;
                isVatFuture = startVatDate > currentEndDate;
                isVatCurrent = isSameCustomQuarter(
                  new Date(startVatDate),
                  new Date(),
                  business_start_date
                );

                // console.log(
                //   "🚀  isVatPayMentMonth:",
                //   monthDate,
                //   vat_pay_date,
                //   // income_expense_type,
                //   isVatPayMentMonth,
                //   currentQuarterStartDate,
                //   // currentStartDate,
                //   // currentEndDate,
                //   isVatPast,
                //   isVatCurrent,
                //   isVatFuture,
                //   // vatData,
                //   startVatDate,
                //   endVatDate
                // );
              }

              isVatPayMentMonth = isSameMonth(
                new Date(monthDate),
                new Date(vat_pay_date)
              );
              if (isVatPayMentMonth) {
                vatData = monthlyTransactions?.filter(
                  (item) =>
                    item?.month >= startVatDate &&
                    item?.month <= endVatDate &&
                    (item?.category
                      ? !Constant?.excludeCategoryTypes?.includes(
                          selectionCategoriesByID?.[item?.category]?.[0]
                            ?.category_type
                        )
                      : true) &&
                    selected_scenario_uuid?.includes(item.scenario_uuid) &&
                    !Constant?.calculationExcludeStates2.includes(item?.state)
                );
              }
            }

            const subtractVat =
              advanceVat?.enabled &&
              (monthDate > currentMonth ||
                (monthDate === currentMonth &&
                  getDate(new Date()) <= Number(advanceVat?.due_day ?? 1)));
            if (isPastMonth) {
              const past_booked_data = monthData?.filter(
                (item) =>
                  !Constant?.excludeCategoryTypes?.includes(
                    selectionCategoriesByID?.[item?.category]?.[0]
                      ?.category_type
                  ) && Constant?.bookedStates?.includes(item.state)
              );
              const past_booked_total = past_booked_data?.reduce(
                (total, item) =>
                  parseFloat(total) + parseFloat(item?.[name] ?? 0),
                0
              );
              const past_booked_vat_total = past_booked_data?.reduce(
                (total, item) =>
                  parseFloat(total) + parseFloat(item?.[`vat_${name}`] ?? 0),
                0
              );
              monthEndTotal = past_booked_total;
              monthEndVatTotal = past_booked_vat_total;
            }
            if (isCurrentMonth) {
              const allData = monthlyTransactions.filter(
                (item) =>
                  item.month < currentMonth &&
                  (item?.category
                    ? !Constant?.excludeCategoryTypes?.includes(
                        selectionCategoriesByID?.[item?.category]?.[0]
                          ?.category_type
                      )
                    : true) &&
                  selected_scenario_uuid?.includes(item.scenario_uuid) &&
                  !Constant.bookedStates.includes(item.state) &&
                  !Constant.plannedState.includes(item.state) &&
                  !Constant?.calculationExcludeStates.includes(item?.state)
              );
              const data = monthData?.filter((item) =>
                item?.category
                  ? !Constant?.excludeCategoryTypes?.includes(
                      selectionCategoriesByID?.[item?.category]?.[0]
                        ?.category_type
                    )
                  : true
              );

              const monthTotal = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data,
                type: categoryType,
                time: "current",
                allData,
                isTable: true,
                isCheck: true,
              });

              relBookedTotal = monthTotal?.currentBookedTotal;
              monthEndTotal = monthTotal?.total || 0;
              monthEndVatTotal = monthTotal?.vat || 0;
              categoryWisePastTotal = monthTotal?.categoryWisePastTotal;
              pastTotal = categoryWisePastTotal?.["unCategorized"] || 0;
            }
            if (isFutureMonth) {
              const data = monthData?.filter((item) =>
                item?.category
                  ? !Constant?.excludeCategoryTypes?.includes(
                      selectionCategoriesByID?.[item?.category]?.[0]
                        ?.category_type
                    )
                  : true
              );
              const monthTotal = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data,
                type: categoryType,
                time: "future",
              });
              monthEndTotal = monthTotal?.total || 0;
              monthEndVatTotal = monthTotal?.vat || 0;
            }
            let vat_scenarios = [];

            if (isVatPast && isVatPayMentMonth) {
              const Vdata = vatData?.filter((item) =>
                isBookedStates({ state: item?.state })
              );
              vat_scenarios = [
                ...new Set(
                  vatData?.map(
                    (o1) => o1.scenario !== "Base" && o1.scenario_uuid
                  )
                ),
              ];
              monthEndVatChargeTotal = Vdata?.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(item?.vat_outflow ?? 0) +
                  parseFloat(item?.vat_inflow ?? 0),
                0
              );
            }
            if (isVatCurrent && isVatPayMentMonth) {
              vat_scenarios = [
                ...new Set(
                  vatData?.map(
                    (o1) => o1.scenario !== "Base" && o1.scenario_uuid
                  )
                ),
              ];
              const allData = monthlyTransactions.filter(
                (item) =>
                  item?.month < startVatDate &&
                  selected_scenario_uuid?.includes(item.scenario_uuid) &&
                  !Constant.bookedStates.includes(item.state) &&
                  !Constant.plannedState.includes(item.state) &&
                  !Constant?.calculationExcludeStates2.includes(item?.state)
              );
              const currentInflowVat = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data: vatData,
                type: 1,
                time: "current",
                calculation_type: "vat",
                allData,
                isCheck: true,
              });

              const currentOutflowVat = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data: vatData,
                type: 2,
                time: "current",
                calculation_type: "vat",
                allData,
              });
              // console.log(
              //   "🚀 / currentInflowVat:",
              //   currentInflowVat,
              //   currentOutflowVat
              // );

              monthEndVatChargeTotal =
                (currentInflowVat?.vat || 0) + (currentOutflowVat?.vat || 0);
            }
            if (isVatFuture && isVatPayMentMonth) {
              if (income_expense_type === 1) {
                vat_scenarios = [
                  ...new Set(
                    vatData?.map(
                      (o1) => o1.scenario !== "Base" && o1.scenario_uuid
                    )
                  ),
                ];
              }

              const futureInflowVat = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data: vatData,
                type: 1,
                time: "future",
                calculation_type: "vat",
              });
              const futureOutflowVat = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data: vatData,
                type: 2,
                time: "future",
                calculation_type: "vat",
              });
              monthEndVatChargeTotal =
                (futureInflowVat?.vat || 0) + (futureOutflowVat?.vat || 0);
            }
            monthEndVatChargeTotal = -monthEndVatChargeTotal;

            const obj = {
              isPastMonth,
              isFutureMonth,
              isCurrentMonth,
              date: columnElement?.item,
              count: 0,
              value: 0,
              type: "numeric",
              income_expense_type,
              startLiqCurrentMonth: isCurrentMonth,
            };
            const inflow =
              monthEndTotal +
              (income_expense_type === 1 &&
              monthEndVatChargeTotal > 0 &&
              subtractVat
                ? monthEndVatChargeTotal
                : 0) +
              (income_expense_type === 2 &&
              monthEndVatChargeTotal <= 0 &&
              subtractVat
                ? monthEndVatChargeTotal
                : 0);
            // if (isCurrentMonth) {
            //   console.log(
            //     "🚀/---> isCurrentMonth:",
            //     monthDate,
            //     income_expense_type,
            //     // monthEndTotal,
            //     // monthEndVatChargeTotal,
            //     inflow,
            //     relBookedTotal,
            //     inflow - relBookedTotal
            //   );
            // }
            let { total: inflowArrayTotal } = getCellInfo({
              data: monthData,
              total_key: total_key,
              count_key: count_key,
              isPastMonth,
              isFutureMonth,
              isCurrentMonth,
              highlightedScenarios,
              pastTotal,
              category: null,
              doNotIncludeCalculationExcludeCategory: true,
            });
            // if (
            //   isPastMonth &&
            //   monthData?.length > 0 &&
            //   income_expense_type === 1
            // ) {
            //   console.log(
            //     "🚀  monthData:",
            //     monthDate,
            //     inflowArrayTotal,
            //     inflow
            //   );
            // }
            inflowArray.push({
              item: {
                ...obj,
                total: inflowArrayTotal,
                value: inflow,
                // relValue: inflow,
                relValue: inflow - relBookedTotal, //booked will come from balance , so remove real booked value
                vat: monthEndVatTotal,
                vat_charge: monthEndVatChargeTotal,
                vat_scenarios,
              },
              fontWeight: isCurrentMonth ? 700 : 500,
              customCell: true,
              editable: false,
              showCursor: true,
              color: inflow === 0 ? color : inflow > 0 ? "green" : "red",
              borderColor: getTailwindColor(color, 200),
              backgroundColor: getTailwindColor(
                isBase ? color : _scenario_?.color || "slate",
                isCurrentMonth ? 100 : 50
              ),
            });

            const unCategoryData = monthData.filter((o1) => !o1.category);

            let {
              total,
              count,
              value,
              isPlanned,
              percentageChange,
              bookedValue,
              openValue,
              plannedValue,
              compareValue,
              compareCount,
              compareTotal,
              plannedUuid,
              scenarios,
              isEditable,
              is_note_user_edited,
              is_note_available,
              is_note_highlighted,
            } = getCellInfo({
              data: unCategoryData,
              total_key: total_key,
              count_key: count_key,
              isPastMonth,
              isFutureMonth,
              isCurrentMonth,
              highlightedScenarios,
              pastTotal,
              category: null,
            });

            inflowUnCatArray.push({
              item: {
                ...obj,
                count: count ?? 0,
                value: !value || value === 0 ? "" : value,
                pastTotal,
                total: total,
                percentageChange:
                  count.plannedCount >= 1 ? percentageChange : null,
                bookedValue,
                openValue,
                plannedValue,
                compareValue,
                compareCount,
                compareTotal,
                scenarios,
                uuid: plannedUuid?.[total_key],
              },
              isPlanned,
              is_note_user_edited,
              is_note_available,
              is_note_highlighted,
              editable: isEditable,
              // clickable: !isSinglePlanned,
              clickable: true,
              fontWeight:
                (isPlanned && count.plannedCount > 1) || isCurrentMonth
                  ? 700
                  : 500,
              customCell: true,
              fontStyle:
                isPlanned && count.plannedCount >= 1 && !isPastMonth
                  ? "italic"
                  : "normal",
              backgroundColor: isPastMonth
                ? Color.white
                : scenarios?.some((o1) => highlightedScenarios?.includes(o1))
                  ? getTailwindColor(
                      _scenario_?.color || "slate",
                      isCurrentMonth ? 100 : 50
                    )
                  : isCurrentMonth
                    ? getTailwindColor("slate", 50)
                    : Color.white,
            });

            categoriesToCheck?.forEach((element) => {
              const categories =
                selectionsCategoryUuidCollections?.[element.value];
              const categoryData = monthData.filter((o1) =>
                categories.includes(o1.category)
              );
              if (isCurrentMonth) {
                let all_past_total = 0;
                categories.forEach((o1) => {
                  all_past_total =
                    all_past_total + categoryWisePastTotal?.[o1] || 0;
                });
                pastTotal = all_past_total;
              }

              let {
                total,
                count,
                value,
                isPlanned,
                percentageChange,
                bookedValue,
                openValue,
                plannedValue,
                compareValue,
                compareCount,
                compareTotal,
                plannedUuid,
                scenarios,
                isEditable,
                is_note_user_edited,
                is_note_available,
                is_note_highlighted,
                isExcludedCategory,
              } = getCellInfo({
                data: categoryData,
                total_key: total_key,
                count_key: count_key,
                isPastMonth,
                isFutureMonth,
                isCurrentMonth,
                highlightedScenarios,
                pastTotal,
                category: element,
              });

              if (!categoryNumberObj[element?.value]) {
                categoryNumberObj[element?.value] = [];
              }
              categoryNumberObj[element?.value]?.push({
                item: {
                  ...obj,
                  value,
                  pastTotal,
                  count: count,
                  total: total,
                  percentageChange:
                    count.plannedCount >= 1 ? percentageChange : null,
                  bookedValue,
                  openValue,
                  plannedValue,
                  compareValue,
                  compareCount,
                  compareTotal,
                  uuid: plannedUuid?.[total_key],
                  scenarios,
                },
                isPlanned,
                isExcludedCategory,
                is_note_user_edited,
                is_note_available,
                is_note_highlighted,
                editable: isEditable,
                // clickable: !isSinglePlanned,
                clickable: true,
                fontWeight:
                  (isPlanned && count.plannedCount > 1) || isCurrentMonth
                    ? 700
                    : 500,
                customCell: true,
                fontStyle:
                  isPlanned && count.plannedCount >= 1 && !isPastMonth
                    ? "italic"
                    : "normal",
                color:
                  value > 0 && income_expense_type === 2
                    ? "green"
                    : value < 0 && income_expense_type === 1
                      ? "red"
                      : "slate",
                backgroundColor: isPastMonth
                  ? Color.white
                  : scenarios?.some((o1) => highlightedScenarios?.includes(o1))
                    ? getTailwindColor(
                        _scenario_?.color || "slate",
                        isCurrentMonth ? 100 : 50
                      )
                    : isCurrentMonth
                      ? getTailwindColor("slate", 50)
                      : Color.white,
              });
            });
          }
        });
        const dataset = store?.getState()?.boardSlice?.dataSetData?.uuid;
        array.push({
          rowId: name,
          rowHeight,
          mergeCell: true,
          isHeader: true,
          hasChildren: true,
          parentRowId: null,
          color: color,
          cells: getRowsCell({
            cellStart: {
              item: {
                uuid: name,
                value: t(title),
                link: `/${initialData?.path?.organization}/${dataset}/categories`,
                icon: (
                  <CustomIcon
                    icon={<FaArrowUp />}
                    iconSize="14px"
                    backgroundColor={
                      name === "inflow"
                        ? theme?.palette?.color?.green[500]
                        : theme?.palette?.color?.red[500]
                    }
                    iconStyle={{
                      transform: `translate(-50%, -50%) rotate(${
                        name === "inflow" ? 45 : 135
                      }deg)`,
                    }}
                  />
                ),
              },
              gridWidth: gridWidth + spacing,
              fontWeight: 600,
              clickable: true,
              isColumnStartCell: true,
              backgroundColor: getTailwindColor(color, 100),
              borderColor: getTailwindColor(color, 200),
            },
            data: inflowArray,
          }),
        });
        if (
          searchText
            ? "UnCategorized".toLowerCase().includes(searchText?.toLowerCase())
            : true
        ) {
          array.push({
            rowId: unCategorizedRowId,
            rowHeight,
            mergeCell: true,
            hasChildren: false,
            parentRowId: name,
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: unCategorizedRowId,
                  value: t("Uncategorized"),
                  income_expense_type,
                },
                backgroundColor: getTailwindColor(color, 50),
                clickable: true,
                isSticky: true,
                isHover: true,
                gridWidth: gridWidth + spacing,
                isColumnStartCell: true,
                borderColor: getTailwindColor(color, 200),
              },
              data: inflowUnCatArray,
            }),
          });
        }

        categoriesToCheck?.forEach((element) => {
          const parentCategory =
            selectionCategoriesByID?.[element?.parent]?.[0];

          const isStaff = foundIcon({
            data: groupByCategoryStaffData,
            category: element,
          });
          const isLoan = foundIcon({
            data: groupByCategoryLoanData,
            category: element,
          });
          let postIcon = [];
          if (Boolean(isStaff)) {
            postIcon.push({
              icon: <IoPeople />,
              tooltip: "table_cell_staff_icon_tooltip",
            });
          }
          if (Boolean(isLoan)) {
            postIcon.push({
              icon: <TbBuildingBank />,
              tooltip: "table_cell_loan_icon_tooltip",
            });
          }
          array.push({
            rowId: element?.value,
            rowHeight,
            mergeCell: true,
            parentRowId: element?.parent ? element?.parent : name,
            hasChildren: element?.hasChildren,
            depth: element?.depth,
            isLastCategoryChildCell:
              parentCategory?.children?.[parentCategory?.children?.length - 1]
                ?.value === element?.value,
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: element?.value,
                  value: element?.immutable
                    ? t(element?.label)
                    : element?.label,
                  value2: Constant.CategoryType?.[element?.category_type]
                    ? `${t("category_type")}: ${t(Constant.CategoryType?.[element?.category_type])}`
                    : "",
                  income_expense_type,
                  postIcon,
                },
                backgroundColor: getTailwindColor(color, 50),
                isSticky: true,
                isHover: true,
                isColumnStartCell: true,
                gridWidth: gridWidth + spacing,
                clickable: true,
                borderColor: getTailwindColor(color, 200),
                patternColor: color,
              },
              data: categoryNumberObj[element?.value],
            }),
          });
        });

        setData((prev) => {
          return {
            ...prev,
            [name]: inflowArray,
          };
        });
        return array;
      }
      return array;
    };

    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <EnableInternalDSFunctions ref={internalDsRef} />
      </div>
    );
  }
);

const foundIcon = ({ category, data }) => {
  let isFound = data?.[category?.value]?.[0];

  if (!isFound && category?.children?.length > 0) {
    for (let i = 0; i < category?.children?.length; i++) {
      const ele = category?.children?.[i];
      isFound = data?.[ele?.value]?.[0];

      if (isFound) {
        break;
      }
      if (!isFound && ele?.children?.length > 0) {
        for (let j = 0; j < ele?.children?.length; j++) {
          const sub = ele?.children?.[j];
          isFound = data?.[sub?.value]?.[0];

          if (isFound) {
            break;
          }
        }
      }

      if (isFound) {
        break;
      }
    }
  }
  return isFound;
};
