import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import React, { forwardRef } from "react";
import Stack from "@mui/material/Stack";

import { truncate } from "../../../Helper/data";
import RadioCircle from "../../RadioCircle";

const DropdownOverlayItem = forwardRef(
  (
    {
      titleCount,
      item,
      display_key,
      theme,
      isActive,
      color,
      onClick,
      hideRadioButtons,
      handleClickMore,
      primaryItemView,
      secondaryItemView,
    },
    _ref
  ) => {
    return (
      <Stack
        ref={_ref}
        key={item?.uuid}
        onClick={(e) => onClick(e, item)}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          color: theme.palette.color?.[color]?.[600],
          borderRadius: 1,
          px: "1rem",
          py: "0.5rem",
          cursor: "pointer",
          width: "100%",
          backgroundColor: isActive
            ? theme.palette.color?.[color]?.[100]
            : "transparent",
          "&:hover": {
            backgroundColor:
              theme.palette.color?.[color]?.[isActive ? 100 : 50],
          },
        }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          {!hideRadioButtons ? (
            <RadioCircle checked={isActive} size={18} color={color} />
          ) : null}
          <Typography
            variant={"subtitle2"}
            fontWeight={"fontWeightMediumBold"}
            sx={{ ml: "1.5rem" }}
          >
            {truncate(item?.[display_key] || "", titleCount)}
          </Typography>
          {!!primaryItemView
            ? primaryItemView({ theme, color, isActive, display_key, item })
            : null}
        </Stack>
        {handleClickMore ? (
          <Stack direction={"row"} alignItems={"center"}>
            {!!secondaryItemView
              ? secondaryItemView({ theme, color, isActive, display_key, item })
              : null}

            <IconButton
              aria-label="more"
              id="long-button"
              aria-haspopup="true"
              onClick={(e) =>
                handleClickMore(e, {
                  theme,
                  color,
                  isActive,
                  display_key,
                  item,
                })
              }
              sx={{ background: "transparent !important", p: 0 }}
            >
              <MoreVertIcon
                sx={{
                  background: "transparent !important",
                  color: theme.palette.color.slate[600],
                }}
              />
            </IconButton>
          </Stack>
        ) : null}
      </Stack>
    );
  }
);

export default DropdownOverlayItem;
