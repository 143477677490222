import { useState } from "react";

import useUpdateEffect from "../../../hooks/4-useUpdateEffect/useUpdateEffect";
import CustomPopoverScratch from "../../PopOver/CustomPopover";
import DropdownOverlay from "./DropdownOverlay";
import DropdownBase from "./DropdownBase";

const CustomDropdown = ({
  width = "12rem",
  color = "slate",
  disabled,
  placeholder,
  placeholderIcon,
  create_new_text,
  display_key,
  searchType,
  end_point,
  hideRadioButtons,
  defaultOptions,
  menuOptions,
  payload,
  value,
  onChange,
  onClickMenuItem,
  primaryItemView,
  secondaryItemView,
  customOpenAddForm,
  query_options_payload = {},
  dropdownBaseSX,
  titleCount,
  key1,
  key2,
}) => {
  const [selectedItem, setSelectedItem] = useState(value);

  useUpdateEffect(() => {
    if (onChange) {
      onChange({ selectedItem, payload });
    }
  }, [selectedItem]);

  return (
    <CustomPopoverScratch
      disabled={disabled}
      button={
        <DropdownBase
          disabled={disabled}
          width={width}
          color={color}
          placeholder={placeholder}
          placeholderIcon={placeholderIcon}
          display_key={display_key}
          selectedItem={selectedItem}
          sx={dropdownBaseSX}
        />
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      paperStyle={{
        borderRadius: "0.5rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
      }}
    >
      <DropdownOverlay
        color={color}
        defaultOptions={defaultOptions}
        menuOptions={menuOptions}
        selectedItem={selectedItem}
        searchType={searchType}
        display_key={display_key}
        end_point={end_point}
        hideRadioButtons={hideRadioButtons}
        setSelectedItem={setSelectedItem}
        create_new_text={create_new_text}
        onClickMenuItem={onClickMenuItem}
        primaryItemView={primaryItemView}
        secondaryItemView={secondaryItemView}
        customOpenAddForm={customOpenAddForm}
        query_options_payload={query_options_payload}
        titleCount={titleCount}
        key1={key1}
        key2={key2}
      />
    </CustomPopoverScratch>
  );
};

export default CustomDropdown;
