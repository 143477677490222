import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import * as React from "react";

import TailwindButton from "../Overlay/TailwindButton";
import { Color, Fonts } from "../../Helper";

export default function DeleteConfirmationModal({
  open,
  onClose,
  onOk,
  message = "",
  title,
  cancel,
  confirm,
  hideDelete = false,
  children = null,
  loading = false,
  type,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const ActionPanel = () => {
    return (
      <>
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            fontWeight: "600",
            color: Color.black,
          }}
        >
          {title ? title : t("Delete")}
        </Typography>
        <Box
          sx={{
            my: "1.5rem",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              color: "#555",
              fontFamily: Fonts.Text,
            }}
          >
            {message}
          </Typography>
          {children}
        </Box>
        <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TailwindButton
            text={loading ? undefined : confirm ? confirm : t("Confirm")}
            backgroundColor={
              type === "delete"
                ? Color.tailwind.red[500]
                : Color.tailwind.green[500]
            }
            onClick={onOk}
            loading={loading}
            sx={{
              ml: 0,
              py: loading ? "1.2rem" : "0.75rem",
              "& .MuiLoadingButton-loadingIndicator": {
                color:
                  type === "delete"
                    ? theme.palette.color.red[500]
                    : theme.palette.color.green[500],
              },
            }}
          />
          {!hideDelete && (
            <TailwindButton
              type="cancel"
              text={cancel ? cancel : t("Cancel")}
              onClick={onClose}
            />
          )}
        </Box>
      </>
    );
  };

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 1320 }}
      PaperProps={{
        sx: {
          minWidth: "45rem",
          maxWidth: "50rem",
          padding: "1.5rem",
          borderRadius: theme.borderRadius.main,
          boxShadow: theme.boxShadow,
        },
      }}
    >
      <ActionPanel />
    </Dialog>
  );
}
