import { IoCloseCircleOutline } from "react-icons/io5";
import { Button, Stack, Tooltip } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getTailwindColor, truncate } from "../Helper/data";

const CategoryChip = ({
  disabled,
  onClick,
  highlighColor,
  customColor,
  color = "#475569", //slate600
  label,
  height = "1.7rem",
  width = "fit-content",
  maxWidth = "auto",
  fontSize = "0.7rem",
  backgroundShade = 900, //50
  borderShade = 600, //700
  colorShade = 600, //700
  fontWeight = 500,
  borderRadius = 0,
  borderType = "solid",
  borderWidth = "3px",
  style,
  labelStyle,
  tooltipPlacement = "top",
  showTooltip = false,
  categoryId,
  showDelete = false,
  onDelete,
  noCategoryLabel = "Set Category",
  noCategoryLabel_tooltip = "Set Category",
  hideNoCategoryColor,
  count,
  detailed = false,
  hideBG = false,
  isBankCategory,
  isSuggestion,
  postView,
}) => {
  const { t } = useTranslation();
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const selectionBankCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionBankCategoriesByID
  );

  const _selectionsCategoryByID = isBankCategory
    ? selectionBankCategoriesByID
    : selectionCategoriesByID;

  const categoryLabel = useMemo(() => {
    let catLabel = "";
    if (detailed) {
      let cardCategoryParent = null;
      let rootCat = null;
      let cardCategory = _selectionsCategoryByID[categoryId]
        ? _selectionsCategoryByID[categoryId][0]
        : null;

      if (cardCategory?.parent) {
        cardCategoryParent = _selectionsCategoryByID[cardCategory?.parent]
          ? _selectionsCategoryByID[cardCategory?.parent][0]
          : null;
      }
      if (cardCategoryParent?.parent) {
        rootCat = _selectionsCategoryByID[cardCategoryParent?.parent]
          ? _selectionsCategoryByID[cardCategoryParent?.parent][0]
          : null;
      }

      catLabel = categoryId
        ? truncate(cardCategory?.label)
        : truncate("Uncategorized");
      if (cardCategoryParent) {
        catLabel =
          truncate(cardCategoryParent?.label, 12) +
          "/" +
          truncate(cardCategory?.label, 12);
      }
      if (rootCat) {
        catLabel =
          "../" +
          truncate(cardCategoryParent?.label, 12) +
          "/" +
          truncate(cardCategory?.label, 12);
      }
    }

    return catLabel;
  }, [categoryId, _selectionsCategoryByID, detailed]);

  const category = _selectionsCategoryByID?.[categoryId]?.[0];

  label = categoryId
    ? detailed
      ? categoryLabel
      : category?.immutable
        ? t(category?.label)
        : category?.label
    : t(noCategoryLabel);
  const _color =
    categoryId || hideNoCategoryColor ? category?.color || "#475569" : color;
  const categoryColor = getTailwindColor(
    categoryId || hideNoCategoryColor
      ? highlighColor
        ? customColor || _color
        : "white"
      : "pink",
    (categoryId || hideNoCategoryColor) && !highlighColor ? 1000 : colorShade
  );
  let tooltip = categoryId
    ? category?.label
    : t(noCategoryLabel_tooltip || noCategoryLabel);
  let title = count ? truncate(label, count) : label;

  const onDeleteIcon = useCallback((e) => {
    e?.stopPropagation();
    if (onDelete) onDelete(e);
  }, []);

  const onClickButton = useCallback((e) => {
    if (onClick) {
      e?.stopPropagation();
      onClick(e);
    }
  }, []);

  return (
    <Tooltip
      title={showTooltip ? tooltip : ""}
      placement={tooltipPlacement}
      followCursor
    >
      <Stack
        sx={{
          width,
          height,
          backgroundColor: "#fff",
          borderRadius,
          ...labelStyle,
          ...style,
        }}
      >
        <Button
          disabled={disabled}
          onClick={onClickButton}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: width === "fit-content" ? "flex-start" : "center",
            position: "relative",
            gap: "0.5rem",
            minWidth: "auto",
            maxWidth: maxWidth,
            width: "100%",
            height: "100%",
            paddingInline: "0.5rem",
            borderRadius,
            color: `${categoryColor} !important`,
            backgroundColor: hideBG
              ? "transparent"
              : getTailwindColor(
                  categoryId || hideNoCategoryColor ? _color : "#fff",
                  backgroundShade
                ),
            border:
              (categoryId || hideNoCategoryColor) && !isSuggestion
                ? 0
                : `${borderWidth} ${borderType} ${getTailwindColor(
                    categoryId || hideNoCategoryColor ? _color : "pink",
                    borderShade
                  )}`,

            "&:hover": {
              "& #close-icon": {
                display: "block !important",
              },
              "& #category-title": {
                maxWidth: showDelete ? "90% !important" : "100%",
              },

              backgroundColor: hideBG
                ? "transparent"
                : getTailwindColor(
                    categoryId || hideNoCategoryColor ? _color : "#fff",
                    backgroundShade === 50
                      ? backgroundShade + 150
                      : backgroundShade + 100
                  ),
            },
          }}
        >
          <span
            id="category-title"
            style={{
              textAlign: "left",
              fontWeight,
              fontSize,
              width: "fit-content",
              maxWidth: "100%",
              lineHeight: fontSize,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              color: `${categoryColor} !important`,
            }}
          >
            {title}
          </span>
          {postView}
          {showDelete && (
            <IoCloseCircleOutline
              id="close-icon"
              onClick={onDeleteIcon}
              style={{
                display: "none",
                fontSize: "1rem",
                position: "absolute",
                right: "3px",
                // top: "4px",
                color: categoryColor,
              }}
            />
          )}
        </Button>
      </Stack>
    </Tooltip>
  );
};
export default React.memo(CategoryChip);
