import { useCallback, useContext, useState } from "react";
import { PiMagicWandFill } from "react-icons/pi";
import EditIcon from "@mui/icons-material/Edit";

import { GlobalContext } from "../GlobalContextWrapper";
import TailwindButton from "./Overlay/TailwindButton";
import EndPoints from "../APICall/EndPoints";
import { truncate } from "../Helper/data";
import APICall from "../APICall";

const TransactionRuleButton = ({
  t,
  theme,
  transaction,
  truncateCount = 100,
  onClickPreCallback,
  callback,
  sx,
}) => {
  const globalContext = useContext(GlobalContext);
  const rulesRef = globalContext?.rulesRef;
  const [isFetching, setIsFetching] = useState(false);

  //api
  const getRulesByIdApi = useCallback(async (id, signal) => {
    setIsFetching(true);
    let data = null;
    await APICall("get", EndPoints.transactions_rules + `${id}/`, null, {
      signal,
    })
      .then((response) => {
        if (response.status === 200 && response) {
          data = response?.data;
          setIsFetching(false);
        }
      })
      .catch(() => {
        setIsFetching(false);
      });
    return data;
  }, []);

  const onClick = async () => {
    if (onClickPreCallback) {
      onClickPreCallback();
    }
    if (transaction?.last_modified_rule) {
      const rule = await getRulesByIdApi(transaction?.last_modified_rule);
      rulesRef.current?.onOpenRule({
        modalType: "edit",
        rule,
        callback,
      });
    } else {
      let transactionsRow = {
        transaction_type: transaction?.income_expense_type,
        category: transaction?.category,
        title: transaction?.title,
        note: transaction?.note,
      };
      rulesRef.current?.onOpenRule({
        modalType: "add",
        transactionsRow,
        callback,
      });
    }
  };

  return (
    <TailwindButton
      loading={isFetching}
      variant="outlined"
      onClick={onClick}
      icon={
        transaction?.last_modified_rule ? (
          <EditIcon style={{ fontSize: "1.1rem" }} />
        ) : (
          <PiMagicWandFill style={{ fontSize: "1.1rem" }} />
        )
      }
      text={
        transaction?.last_modified_rule
          ? truncate(t("edit_rule"), truncateCount)
          : truncate(t("add_rule"), truncateCount)
      }
      color={theme.palette.color.white}
      sx={{
        ml: 0,
        backgroundImage: transaction?.last_modified_rule
          ? undefined
          : `linear-gradient(to bottom right, #6101D1, #9D4AFF)`,
        backgroundColor: transaction?.last_modified_rule
          ? theme.palette.color.slate[50]
          : "transparent",
        color: transaction?.last_modified_rule
          ? theme.palette.color.slate[400]
          : theme.palette.color.white,
        gap: "0.5rem",
        border: `3px solid ${
          transaction?.last_modified_rule
            ? theme.palette.color.slate[400]
            : theme.palette?.primary.main
        }`,
        "&:hover": {
          backgroundImage: transaction?.last_modified_rule
            ? undefined
            : `linear-gradient(to bottom right, #6101D1, #9D4AFF)`,
          border: `3px solid ${
            transaction?.last_modified_rule
              ? theme.palette.color.slate[400]
              : theme.palette?.primary.main
          }`,
        },
        ...sx,
      }}
    />
  );
};

export default TransactionRuleButton;
