import { Tooltip, useTheme } from "@mui/material";

const ToolTipWrapper = ({ children, title, slotProps }) => {
  const theme = useTheme();
  return (
    <Tooltip
      placement="top"
      title={title}
      // leaveDelay={2000000000}
      slotProps={{
        tooltip: {
          sx: {
            borderRadius: theme.borderRadius.main,
            padding: "0rem",
          },
        },
        ...slotProps,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default ToolTipWrapper;
