import { Button, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { useContext } from "react";

import { GlobalContext } from "../../../GlobalContextWrapper";
import KeyboardSortCutKey from "../../KeyboardSortCutKey";

const LiveSearch = ({ hide }) => {
  const theme = useTheme();
  const s1650 = useMediaQuery("(min-width:1650px)");
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const liveSearchRef = globalContext?.liveSearchRef;
  const isStartPage =
    window?.location?.pathname?.includes("/dashboard") ||
    window?.location?.pathname === "/";
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  const isCenter = (!s1650 && !dataSetData?.uuid) || isStartPage;
  if (hide || isStartPage) return null;
  const onClick = () => {
    liveSearchRef?.current?.handleOpen();
  };

  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        position: isCenter ? "fixed" : "unset",
        top: "unset",
        left: isCenter ? "50%" : "unset",
        transform: isCenter ? "translate(-50%, 0)" : "unset",
        padding: "0.5rem 0.75rem",
        mr: "0.5rem",
        height: "2.5rem",
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        boxShadow: `${theme.boxShadow} !important`,
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
        border: `2px solid transparent`,
        "&:active": {
          border: `2px solid ${theme.palette.primary.main}`,
        },
        "&:hover": {
          border: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.color.slate[50],
        },
      }}
    >
      <IoSearch
        style={{
          color: theme.palette.color.slate[600],
          fontSize: "1.2rem",
        }}
      />
      <Typography
        variant="body2"
        fontWeight={"fontWeightMediumBold"}
        color="color.slate.700"
        sx={{
          fontSize: "0.875rem",
        }}
      >
        {t("quick_action_live_search")}
      </Typography>
      <KeyboardSortCutKey theme={theme} keys={["CTRL", "K"]} action={onClick} />
    </Button>
  );
};

export default LiveSearch;
