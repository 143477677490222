import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import { Constant } from "../../../Helper";
import BoardHeader from "./BoardHeader";
import TableView from "./Table";
import Chart from "./Chart";

const Planning = () => {
  const theme = useTheme();
  const s2300 = useMediaQuery("(min-width:2300px)");
  // const s2220 = useMediaQuery("(min-width:2220px)");
  // const s2420 = useMediaQuery("(min-width:2420px)");
  // const s2550 = useMediaQuery("(min-width:2550px)");

  let gridWidth = Constant.column_width;
  let spacing = Constant.start_column_extra_width;
  if (s2300) {
    gridWidth = Constant.column_width_2300;
    spacing = Constant.start_column_extra_width_2300;
  }
  // if (s2220) {
  //   gridWidth = Constant.column_width_2200;
  //   spacing = Constant.start_column_extra_width_2200;
  //   rowHeight = 38;
  // }
  // if (s2420) {
  //   gridWidth = Constant.column_width_2400;
  //   spacing = Constant.start_column_extra_width_2400;
  //   rowHeight = 38;
  // }
  // if (s2550) {
  //   gridWidth = Constant.column_width_2500;
  //   spacing = Constant.start_column_extra_width_2500;
  //   rowHeight = 38;
  // }

  const isMainDrawerOpen = useSelector(
    (state) => state.settingsSlice?.isMainDrawerOpen
  );
  const isKanbanView = useSelector((state) => state.boardSlice.isKanbanView);

  return (
    <Box
      className={`kanban_1_step`}
      sx={{
        height: `calc(100vh - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT})`,
        pt: "15px",
        backgroundColor: theme.palette.color.appThemeBg,
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <BoardHeader />
      <Box
        id={"cardContainer"}
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          justifyContent: isKanbanView ? "space-between" : "flex-start",
          alignItems: "flex-start",
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: {
            xs: isMainDrawerOpen
              ? Constant?.DRAWER_WIDTH
              : Constant?.DRAWER_WIDTH_CLOSED,
            s1650: 0,
          },
          overflow: "auto",
          marginTop: "15px",
          transition: `all 0.5s ease`,
          height: `calc(100vh - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT} - 4.5rem)`,
        }}
      >
        <Chart
          type={"kanban"}
          column_width={gridWidth}
          start_column_extra_width={spacing}
        />
        <TableView />
      </Box>
    </Box>
  );
};

export default Planning;
