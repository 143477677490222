import {
  createFilterOptions,
  CircularProgress,
  Autocomplete,
  Typography,
  TextField,
  useTheme,
  Tooltip,
  Paper,
  Box,
} from "@mui/material";
import {
  useImperativeHandle,
  forwardRef,
  useState,
  Fragment,
  useRef,
} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, lighten, darken } from "@mui/system";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useTranslation } from "react-i18next";

import { Color } from "../../Helper";

const filter = createFilterOptions();
const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
const DropDown = forwardRef(
  (
    {
      height,
      title,
      options = [],
      hideTitle = false,
      value = "",
      onChange,
      error,
      helperText,
      placeholder = null,
      fontSize = "0.9rem",
      getOptionLabel = (option) => option?.title ?? "",
      renderOption = (option) => option?.title ?? "",
      disableClearable = false,
      disabled = false,
      star = false,
      name,
      disablePortal = false,
      tooltip = "",
      tooltipHTML = "",
      placement = "top",
      mb = "1rem",
      variant = "outlined",
      label = null,
      likeGoogle = false,
      multiple = false,
      renderTags = null,
      limitTags = 2,
      freeSolo = false,
      showManage = false,
      showReset = false,
      manageText = "manage",
      noteText,
      onClickLink,
      onClickReset,
      sx,
      loading,
      createDynamicText,
      dropdown_input_id = "combobox-input",
      ...props
    },
    _ref
  ) => {
    const theme = useTheme();
    const inputValue = useRef();
    const pickerRef = useRef(null);
    const { t } = useTranslation();
    const [isFocus, setIsFocus] = useState(false);

    const onFocus = () => {
      setIsFocus(true);
    };

    const onBlur = () => {
      setIsFocus(false);
    };

    const CustomPaperComponent = (props) => {
      const isIncludedInList =
        noteText && inputValue.current
          ? options
              ?.map(
                (o1) => o1?.display_name || o1?.title || o1?.label || o1?.name
              )
              ?.includes(inputValue.current)
          : true;

      return (
        <Paper {...props}>
          {noteText && isIncludedInList ? (
            <Typography
              onMouseDown={onClickLink}
              sx={{
                fontSize: "0.85rem !important",
                fontWeight: 600,
                mt: "0.5rem",
                ml: "1rem",
                color: theme.palette.color.slate[700],
              }}
            >
              {t(noteText)}
            </Typography>
          ) : null}
          {props.children}
          {showManage ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                my: "0.5rem",
                mx: "1rem",
              }}
            >
              <Typography
                onMouseDown={onClickLink}
                sx={{
                  fontSize: "0.8rem !important",
                  fontWeight: 600,
                  mt: "0.5rem",
                  cursor: "pointer",
                  color: theme.palette.color.main,
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {t(manageText)}
              </Typography>
              {showReset ? (
                <Typography
                  onClick={onClickReset}
                  sx={{
                    fontSize: "0.8rem !important",
                    fontWeight: 600,
                    ml: "1rem",
                    mt: "0.5rem",
                    cursor: "pointer",
                    color: theme.palette.color.main,
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {t("Reset Filter")}
                </Typography>
              ) : null}
            </Box>
          ) : null}
        </Paper>
      );
    };

    const renderGroup = (params) => {
      return (
        <li key={params?.key}>
          {params?.group ? <GroupHeader>{params?.group}</GroupHeader> : null}
          <GroupItems>{params.children}</GroupItems>
        </li>
      );
    };

    useImperativeHandle(_ref, () => ({
      focusDropDown() {
        pickerRef.current.focus();
      },
    }));

    return (
      <Box
        ref={_ref}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            backgroundColor: Color.white,
          },
          "& .MuiFormLabel-root": {
            width: "77%",
            textAlign: "left",
          },
          "& .MuiInputLabel-root": {
            color: error ? theme.palette.error : theme.palette.color.mainTitle,
            fontWeight: theme.typography.fontWeightMediumBold,
            fontSize: fontSize,
            transform: "translate(12px, 13px) scale(1)",
            "&[data-shrink=true]": {
              transform: "translate(12px, 4px) scale(1)",
            },
            "&.Mui-disabled": {
              opacity: 0.4,
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${Color.oInputBorderBg}`,
          },
          "& input": {
            fontSize: "0.9rem !important",
          },
          "& .MuiFormHelperText-root": {
            lineHeight: "0.66",
          },

          ...sx,
        }}
      >
        {!hideTitle && (
          <Tooltip
            placement={placement}
            title={tooltipHTML ? tooltipHTML : t(`${tooltip}`)}
          >
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                color: theme.palette.color.mainTitle,
                fontWeight: 600,
                mb: "0.25rem",
                textAlign: "left",
                fontSize: fontSize,
                cursor: "default",
              }}
            >
              {" "}
              {title ? title : t("Title")}
              {star ? " *" : ""}
            </Typography>
          </Tooltip>
        )}
        <Tooltip
          placement={placement}
          title={tooltipHTML ? tooltipHTML : t(`${tooltip}`)}
        >
          <Autocomplete
            ref={pickerRef}
            id={dropdown_input_id}
            multiple={multiple}
            freeSolo={freeSolo}
            limitTags={limitTags}
            disableClearable={disableClearable}
            size="small"
            disablePortal={disablePortal}
            disabled={disabled}
            options={options}
            value={value}
            name={name}
            onChange={(e, newValue) => onChange(e, newValue, name)}
            defaultValue={value}
            renderTags={renderTags}
            renderGroup={renderGroup}
            PaperComponent={CustomPaperComponent}
            slotProps={{
              popper: {
                sx: {
                  zIndex: 1303,
                },
              },
            }}
            sx={{
              width: "100%",
              height: height,
              mb: mb,
              "& input": {
                height: "1.55em",
                fontSize: "1rem",
              },
              ".MuiInputBase-root": {
                borderRadius: 1,
                backgroundColor:
                  likeGoogle && isFocus ? Color.inputBG : "transparent",
                "&:before": {
                  borderBottom: likeGoogle && 0,
                },
                "&:hover": {
                  backgroundColor: likeGoogle && Color.inputBG,
                  "&:before": {
                    borderBottom: likeGoogle && "0 !important",
                  },
                },
                "&:focus": {
                  backgroundColor: likeGoogle && Color.inputBG,
                },
                "&.Mui-disabled": {
                  backgroundColor: Color.theme.grey[50],
                },
              },
            }}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(renderOption(option), inputValue);
              const parts = parse(renderOption(option), matches);
              const isAddText =
                createDynamicText &&
                option?.display_name?.includes(
                  t(`${t("Click here to add", { inputText: inputValue })}`)
                );

              return (
                <li
                  {...props}
                  style={{
                    backgroundColor:
                      inputValue === renderOption(option) && parts[0]?.highlight
                        ? Color.theme.deepPurple[50]
                        : null,
                  }}
                  key={
                    option?.uuid ||
                    option?.value ||
                    option?.code ||
                    option?.market ||
                    option
                  }
                  name={name}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      height: "1.5rem",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {option?.icon ? (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "fit-content",
                          height: "fit-content",
                          marginRight: "0.5rem",
                        }}
                      >
                        {option?.icon}
                      </span>
                    ) : null}

                    {parts?.map((part, index) => (
                      <span
                        key={index}
                        name={name}
                        style={{
                          fontWeight:
                            (part.highlight &&
                              inputValue === renderOption(option)) ||
                            isAddText
                              ? 600
                              : 400,
                          fontSize: "0.9rem",
                          lineHeight: "0.9rem",
                          color:
                            inputValue === renderOption(option)
                              ? Color.theme.deepPurple[700]
                              : isAddText
                                ? Color.tailwind.slate[700]
                                : Color.black,
                        }}
                      >
                        {part?.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
            renderInput={(params) => {
              inputValue.current = params?.inputProps?.value;
              return (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  label={label}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  error={Boolean(error)}
                  helperText={helperText}
                  variant={variant}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        <div
                          className="MuiAutocomplete-endAdornment"
                          style={{
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            right: "9px",
                            top: "30%",
                            opacity: disabled ? 0.3 : 1,
                          }}
                        >
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          <KeyboardArrowDownIcon
                            // {...params?.InputProps?.endAdornment?.props}
                            sx={{
                              color: theme.palette.color?.slate[600],
                              transform: params.inputProps?.["aria-expanded"]
                                ? "rotate(-180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s ease-in-out",
                              fontSize: "1.4rem",
                            }}
                          />
                        </div>
                        {/* {params.InputProps.endAdornment} */}
                      </Fragment>
                    ),
                  }}
                />
              );
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (createDynamicText) {
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options?.some(
                  (option) =>
                    inputValue === (option?.display_name || option?.name)
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    display_name: `${t("Click here to add", { inputText: inputValue })}`,
                    name: `${t("Click here to add", { inputText: inputValue })}`,
                  });
                }
              }

              return filtered;
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue && createDynamicText) {
                return option.inputValue;
              }
              // Regular option
              return (
                option?.display_name ||
                option?.title ||
                option?.label ||
                option?.name ||
                option?.language ||
                ""
              );
            }}
            {...props}
          />
        </Tooltip>
      </Box>
    );
  }
);
export default DropDown;
