import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { Chip, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { memo, useContext } from "react";

import { setContactOverlayStatus } from "../../store/slices/datasets";
import CustomDropdown from "./DropDownView/CustomDropdown";
import { GlobalContext } from "../../GlobalContextWrapper";
import { Constant } from "../../Helper";

const menuOptions = [
  {
    value: 3,
    label: "Edit",
    icon: <EditIcon />,
  },
  {
    value: 2,
    label: "Delete",
    icon: <DeleteIcon />,
  },
];

const ContactDropDown = ({
  value,
  onChange,
  payload,
  extraProps,
  width = "17rem",
  placeholder = "select_contact",
  disabled,
}) => {
  const dispatch = useDispatch();
  const globalContext = useContext(GlobalContext);
  const clientFunctionRef = globalContext?.clientFunctionRef;

  //function
  const customOpenAddForm = (e, payload) => {
    dispatch(
      setContactOverlayStatus({
        open: true,
        overlay_type: "drawer_modal_contact_edit_form",
        anchor: "right",
        payload,
      })
    );
  };

  const onClickMenuItem = (e, value, item, option, handleClose) => {
    if (value === 2) {
      handleClose();
      clientFunctionRef?.current?.deleteClientApi({
        uuid: item?.item?.uuid,
        type: item?.item?.type,
      });
    }
    if (value === 3) {
      handleClose();
      dispatch(
        setContactOverlayStatus({
          open: true,
          overlay_type: "drawer_modal_contact_edit_form",
          anchor: "right",
          payload: {
            modalType: "edit",
            item: item?.item,
          },
        })
      );
    }
  };

  return (
    <CustomDropdown
      create_new_text="create_new_contact"
      menuOptions={menuOptions}
      onClickMenuItem={onClickMenuItem}
      placeholder={placeholder}
      searchType="server"
      end_point="customers"
      display_key="name"
      hideRadioButtons
      value={value} //value={{ uuid: item?.value, name: item?.value }}
      onChange={onChange}
      payload={payload}
      // primaryItemView={(params) => <PrimaryItemView {...params} />}
      secondaryItemView={(params) => <SecondaryItemView {...params} />}
      width={width}
      disabled={disabled}
      titleCount={35}
      dropdownBaseSX={{
        border: "none",
      }}
      customOpenAddForm={customOpenAddForm}
      key1={"contact_selection_no_data_text_01"}
      key2={"contact_selection_no_data_text_02"}
      {...extraProps}
    />
  );
};

export default ContactDropDown;

const SecondaryItemView = memo((params) => {
  const { t } = useTranslation();
  if (!Constant.ContactType?.[params?.item?.type]) {
    return null;
  }
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Chip
        label={t(Constant?.ContactType[params?.item?.type])}
        sx={{
          ml: "1.5rem",
          color: "color.slate.700",
          backgroundColor: "color.slate.100",
          height: "fit-content",
          width: "fit-content",
          p: "0.5rem",
          py: "0.25rem",
          borderRadius: params?.theme?.borderRadius?.main,
          "& .MuiChip-label": {
            px: 0,
            fontSize: "0.7rem",
            fontWeight: params?.theme?.typography?.fontWeightMediumBold,
          },
        }}
      />
    </Stack>
  );
});
