import {
  CircularProgress,
  ListItemText,
  Typography,
  InputBase,
  Checkbox,
  ListItem,
  Divider,
  Tooltip,
  alpha,
  Grid,
  List,
  Box,
} from "@mui/material";
import {
  startOfMonth,
  endOfMonth,
  subMonths,
  addDays,
  subDays,
  format,
} from "date-fns";
import {
  useDeferredValue,
  forwardRef,
  useEffect,
  useState,
  useMemo,
} from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import SearchIcon from "@mui/icons-material/Search";
import LoopIcon from "@mui/icons-material/Loop";
import { useTranslation } from "react-i18next";
import { FixedSizeList } from "react-window";

import {
  getAllTransactionsByParams,
  calculateSimilarity,
  thinScrollbarStyle,
  getRecurDateArray,
  formatAmount,
  remToPx,
} from "../../Helper/data";
import { setRecurring_rules } from "../../store/slices/global";
import { Color, Constant, Fonts } from "../../Helper";
import OverlayHeader from "../Overlay/OverlayHeader";
import EndPoints from "../../APICall/EndPoints";
import CustomModal from "../Model/CustomModal";
import useWidth from "../../hooks/useWidth";
import CategoryChip from "../CategoryChip";
import StateChip from "../StateChip";
import APICall from "../../APICall";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ReconcileModel = ({
  open,
  setOpen,
  itemToBeReconcile,
  isTransactionFormUpdated, //for general approach
  setDataUpdated, //for transaction list view
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentWidth = useWidth();

  //redux state
  const recurring_rules = useSelector(
    (state) => state.globalSlice?.recurring_rules
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const state = useSelector((state) => state.globalSlice?.state);
  const stateByTitle = useSelector((state) => state.globalSlice?.stateByTitle);

  const recurring_rulesById = useSelector(
    (state) => state.globalSlice.recurring_rulesById
  );
  //state
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const deferredSearchQuery = useDeferredValue(searchValue, {
    timeoutMs: 1000,
  });

  let openStates = useMemo(() => {
    let _states = [];
    if (itemToBeReconcile.state === "Booked") {
      state?.forEach((o1) => {
        if (
          Constant?.openPositionsStates.includes(o1.title) &&
          !Constant?.calculationExcludeStates.includes(o1.title)
        ) {
          _states.push(o1.uuid);
        }
      });
    }
    return _states;
  }, [itemToBeReconcile.state, state]);
  let bookedStates = useMemo(() => {
    let _states = [];
    state?.forEach((o1) => {
      if (
        Constant?.bookedPositionsStates.includes(o1.title) &&
        !Constant?.calculationExcludeStates2.includes(o1.title)
      ) {
        _states.push(o1.uuid);
      }
    });
    return _states;
  }, [state]);

  //life cycle method
  useEffect(() => {
    if (itemToBeReconcile) {
      let params = {
        dataset: dataSetData?.uuid,
        is_reconciled: false,
        reconciled: false,
        order_by: "-due_date",
        pageSize: 200,
        state: itemToBeReconcile.state === "Booked" ? openStates : bookedStates,
      };
      if (itemToBeReconcile?.gross_value >= 0) {
        params.min_gross_value = "0";
      }
      if (itemToBeReconcile?.gross_value < 0) {
        params.max_gross_value = "-0.001";
      }

      if (deferredSearchQuery?.length > 0) {
        params.title = deferredSearchQuery;
        getReconcileDataFiltered(params);
      } else {
        params.startDate = format(
          startOfMonth(subMonths(new Date(itemToBeReconcile?.due_date), 2)),
          "yyy-MM-dd"
        );
        params.endDate = format(
          endOfMonth(subMonths(new Date(itemToBeReconcile?.due_date), -2)),
          "yyy-MM-dd"
        );
        if (itemToBeReconcile?.category) {
          params.category = [itemToBeReconcile?.category];
        }
        getReconcileData(params);
      }
    }
  }, [
    itemToBeReconcile,
    deferredSearchQuery,
    dataSetData?.uuid,
    openStates,
    bookedStates,
  ]);

  //api
  const getReconcileDataFiltered = async (params) => {
    setLoader(true);
    let data = await getAllTransactionsByParams(params);
    if (data) {
      setData([...data]);
    }
    setLoader(false);
  };

  const getReconcileData = async (params) => {
    if (params) {
      setLoader(true);
      let data =
        itemToBeReconcile.state !== "Booked" ? [itemToBeReconcile] : [];
      if (itemToBeReconcile.state === "Booked") {
        data = await getAllTransactionsByParams(params);
      }

      const parsedDates = data?.map((o1) => new Date(o1.due_date));
      let highestDate = parsedDates[0];
      let lowestDate = parsedDates[0];
      parsedDates.forEach((date) => {
        if (date > highestDate) {
          highestDate = date;
        }
        if (date < lowestDate) {
          lowestDate = date;
        }
      });

      if (stateByTitle && lowestDate && highestDate) {
        let bookedParams = {
          ...params,
          reconciled: false,
          state: bookedStates,
          startDate: format(subDays(lowestDate, 30), "yyyy-MM-dd"),
          endDate: format(addDays(highestDate, 30), "yyyy-MM-dd"),
        };

        let bookedData =
          itemToBeReconcile.state === "Booked" ? [itemToBeReconcile] : [];
        if (itemToBeReconcile.state !== "Booked") {
          bookedData = await getAllTransactionsByParams(bookedParams);
        }

        let array = [];
        if (itemToBeReconcile.state !== "Booked") {
          array = findMostSimilarItems(data, bookedData);
        } else {
          array = findMostSimilarItems(bookedData, data);
        }
        array.sort((a, b) => (a.similarity > b.similarity ? -1 : 1));

        setData([...array]);
      } else {
        setData([]);
      }
      setLoader(false);
    }
  };

  const updateRecurringRules = async (id, obj) => {
    await APICall("patch", EndPoints.recurring_rules + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          let data = [...recurring_rules];
          let index = recurring_rules?.findIndex((o1) => o1.uuid === id);
          if (index > -1) {
            data[index] = response.data;
          }
          dispatch(setRecurring_rules(data));
        }
      }
    );
  };

  const updateCardByID = async (id, obj) => {
    await APICall("patch", EndPoints.transactions + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          if (isTransactionFormUpdated) {
            isTransactionFormUpdated.current = true;
          }
          if (setDataUpdated) {
            setDataUpdated(true);
          }
        }
      }
    );
  };

  //functions
  function findMostSimilarItems(array1, array2) {
    const result = [];
    let maxSimilarity = 0;
    let mostSimilarItem = null;
    for (const item1 of array1) {
      maxSimilarity = 0;
      mostSimilarItem = null;

      for (const item2 of array2) {
        const {
          similarity,
          title_similarity,
          value_similarity,
          date_similarity,
        } = calculateSimilarity(item1, item2, "overlay");

        if (similarity > maxSimilarity) {
          maxSimilarity = similarity;
          if (
            date_similarity >= 0 &&
            ((title_similarity > 0.6 && value_similarity >= 0.5) ||
              value_similarity === 1)
          ) {
            mostSimilarItem = item2;
          }
        }
      }
      if (mostSimilarItem?.uuid) {
        let main = {
          uuid: mostSimilarItem?.uuid || null,
          due_date: mostSimilarItem?.due_date || null,
          title: mostSimilarItem?.title || null,
          note: mostSimilarItem?.note || null,
          gross_value: mostSimilarItem?.gross_value || null,
          income_expense_type: mostSimilarItem?.income_expense_type || null,
          state: mostSimilarItem?.state || null,
          scenario: mostSimilarItem?.scenario || null,
          category: mostSimilarItem?.category || null,
          similarity: maxSimilarity,
        };
        result.push(main);
      }
    }

    return result;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const onSaveRule = () => {
    let selectedData = data?.find((o1) => o1.checked);
    if (selectedData) {
      let id = null;
      let obj = null;
      if (itemToBeReconcile.state === "Booked") {
        id = itemToBeReconcile?.uuid;
        obj = {
          reconciled: selectedData?.uuid,
          category: itemToBeReconcile?.category
            ? itemToBeReconcile?.category
            : selectedData?.category,
        };
        if (selectedData?.recurring_rule) {
          let recurring_Obj =
            recurring_rulesById?.[selectedData?.recurring_rule]?.[0];
          let isFirstRecurrence =
            recurring_Obj &&
            format(new Date(recurring_Obj?.start_date), "yyyy-MM") ===
              format(new Date(selectedData?.due_date), "yyyy-MM");

          if (recurring_Obj && !isFirstRecurrence) {
            setError(t("reconcile_button_mid_sep_tooltip"));
            return;
          }

          if (recurring_Obj && isFirstRecurrence) {
            let dateArray = getRecurDateArray(
              recurring_Obj?.repetition,
              recurring_Obj?.start_date,
              recurring_Obj?.end_date
            );

            updateRecurringRules(selectedData?.recurring_rule, {
              start_date: format(dateArray[1], "yyyy-MM-dd"),
            });
          }
        }
      } else {
        id = selectedData?.uuid;
        obj = {
          reconciled: itemToBeReconcile?.uuid,
          category: selectedData?.category
            ? selectedData?.category
            : itemToBeReconcile?.category,
        };
      }
      updateCardByID(id, obj);
      handleClose();
    } else {
      setError(t("please select one transaction to be reconciled"));
    }
  };

  const onClickCheckBox = (id) => {
    if (error) setError(null);
    let updatedItemList = data.map((element) => {
      if (element.uuid === id) {
        return { ...element, checked: !element.checked };
      }
      return { ...element, checked: false };
    });
    setData(updatedItemList);
  };

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //render functions
  const ListView = ({ index, style }) => {
    let item = data[index];
    return (
      <ListItem
        style={style}
        sx={{
          pl: 0,
          pr: 0.5,
          "& .MuiTypography-root": {
            textOverflow: "ellipsis",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "0.8rem",
            fontFamily: Fonts.Text,
            fontWeight: 500,
          },
        }}
      >
        <Grid item xs={1} sx={{ textAlign: "left" }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            onChange={() => onClickCheckBox(item.uuid)}
            checked={item?.checked ?? false}
            sx={{
              "&.MuiCheckbox-root": {
                p: 0,
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={0.5}
          sx={{
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
              fontSize: "1.2rem",
            },
          }}
        >
          {item?.recurring_rule ? (
            recurring_rulesById[item?.recurring_rule] &&
            recurring_rulesById[item?.recurring_rule][0] ? (
              recurring_rulesById[item?.recurring_rule][0]?.icon
            ) : (
              <LoopIcon />
            )
          ) : null}
        </Grid>
        <Grid item xs={2}>
          <ListItemText primary={item?.due_date} />
        </Grid>
        <Grid item xs={4.5}>
          <Tooltip title={item?.title} placement="top"   followCursor>
            <ListItemText
              primary={item?.title}
              sx={{
                width: "15rem",
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <StateChip
            income_expense_type={item?.income_expense_type}
            title={item?.state}
            hideBorder
            hideIcon
            width="5.5rem"
            height="1.6rem"
            fontSize="0.7rem"
          />
        </Grid>
        <Grid item xs={3}>
          <ListItemText
            sx={{ textAlign: "right" }}
            primary={formatAmount({
              amount: String(item?.gross_value ?? 0),
            })}
          />
        </Grid>
      </ListItem>
    );
  };

  const InnerView = ({ title, value, color = Color.black }) => {
    const { t } = useTranslation();
    return (
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{ fontSize: "1rem", fontWeight: 400, fontFamily: Fonts.Text }}
        >
          {t(title)}
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 800,
            fontFamily: Fonts.Text,
            color: color,
          }}
        >
          {formatAmount({
            amount: String(value ?? 0),
          })}
        </Typography>
      </Box>
    );
  };

  const Header = () => {
    let selectedData = data?.find((o1) => o1.checked);
    return (
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
          backgroundColor: Color.tailwind.slate[100],
          height: "9.375rem",
          py: "1rem",
          px: "3rem",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            height: "100%",
            width: "50%",
            position: "relative",
          }}
        >
          <Typography
            sx={{ fontSize: "1rem", fontWeight: 700, fontFamily: Fonts.Text }}
          >
            {itemToBeReconcile?.title}
          </Typography>
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {itemToBeReconcile?.category && (
              <CategoryChip
                categoryId={itemToBeReconcile?.category}
                count={20}
                noCategoryLabel={"Uncategorized"}
              />
            )}
            <StateChip
              title={itemToBeReconcile?.state}
              income_expense_type={itemToBeReconcile?.income_expense_type}
              hideBorder
              hideIcon
              width="5.5rem"
              height="1.6rem"
              fontSize="0.7rem"
              sx={{
                ml: itemToBeReconcile?.category ? 2 : 0,
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              color: Color.blueGrey500,
            }}
          >
            {itemToBeReconcile?.due_date}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            width: "50%",
          }}
        >
          <InnerView
            key="1"
            title="Amount to be reconciled"
            value={itemToBeReconcile?.gross_value}
            color={Color.green}
          />
          <InnerView
            key="2"
            title="Selected Transactions"
            value={selectedData?.gross_value ?? 0}
            color={Color.black}
          />
          <InnerView
            key="3"
            title="Difference"
            value={(
              parseFloat(itemToBeReconcile?.gross_value) -
              parseFloat(selectedData?.gross_value ?? 0.0)
            ).toFixed(2)}
            color={Color.red}
          />
        </Box>
      </Box>
    );
  };

  const searchView = () => {
    return (
      <Box
        sx={{
          position: "relative",
          borderRadius: 1,
          backgroundColor: alpha(Color.white, 0.15),
          width: "100%",
          mt: "1rem",
          "&:hover": {
            backgroundColor: alpha(Color.white, 0.25),
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SearchIcon />
        </Box>
        <InputBase
          value={searchValue}
          onChange={onChangeSearch}
          placeholder={t("Search")}
          inputProps={{ "aria-label": "search" }}
          sx={{
            color: "inherit",
            pl: "2rem",
            "& .MuiInputBase-input": {
              width: "100%",
            },
          }}
        />
      </Box>
    );
  };

  const ITEM_SIZE = remToPx(currentWidth, 3);
  const innerElementType = forwardRef(({ style, ...rest }, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        height: `${parseFloat(style.height)}px`,
      }}
      {...rest}
    />
  ));

  return (
    <CustomModal
      open={open}
      hideClose
      onClose={handleClose}
      onAdd={onSaveRule}
      textAdd="Reconcile"
      rootStyle={{
        "& .MuiDialogContent-root": {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
          height: "fit-content",
          backgroundColor: Color.BodyBG,
          minWidth: "50rem",
          overflowY: "hidden",
        }}
      >
        <OverlayHeader
          subTitle={t("Reconcile open items")}
          onClose={handleClose}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            backgroundColor: Color.BodyBG,
          }}
        >
          <Header />

          <Grid
            container
            spacing={2}
            elevation={1}
            sx={{
              ml: 0,
              mt: "1rem",
              px: "3rem",
              display: "inline",
              width: "100%",
            }}
          >
            <Typography
              variant="div"
              sx={{ fontSize: "1rem", fontWeight: 700, fontFamily: Fonts.Text }}
            >
              {t("Select the operation to be reconciled")}{" "}
              {error && (
                <Typography
                  variant="span"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: 400,
                    fontFamily: Fonts.Text,
                    color: Color.red,
                    ml: "1rem",
                  }}
                >
                  {error}
                </Typography>
              )}
            </Typography>
            {searchView()}
            <Divider flexItem sx={{ mt: "1rem" }} />

            <List
              sx={{
                padding: "0px",
                minHeight: "30rem",
                "& .task-list": {
                  ...thinScrollbarStyle,
                },
              }}
            >
              {loader ? (
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                    mt: "5rem",
                  }}
                >
                  <CircularProgress thickness={3} size={20} />
                </Box>
              ) : data.length > 0 ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <FixedSizeList
                      innerElementType={innerElementType}
                      itemCount={data?.length || 0}
                      itemSize={ITEM_SIZE}
                      height={height}
                      width={width}
                      className="task-list"
                    >
                      {ListView}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              ) : (
                <Typography
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "1rem",
                    fontFamily: Fonts.Text,
                    color: Color.grey,
                    height: "100%",
                    width: "100%",
                    mt: "5rem",
                  }}
                >
                  {t("No_Data_Found")}
                </Typography>
              )}
            </List>
          </Grid>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ReconcileModel;
