import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import React, { useRef } from "react";

import CategoryTooltip from "../../components/Charts/CategoryTooltip";

const CustomLabel = (props) => {
  const {
    cx,
    cy,
    midAngle,
    outerRadius,
    categoryName,
    share,
    color,
    index,
    previousLabels,
  } = props;

  const RADIAN = Math.PI / 180;

  const labelRadius = outerRadius + 40;
  const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
  const y = cy + labelRadius * Math.sin(-midAngle * RADIAN);

  let verticalOffset = 0;
  if (index === 0 && previousLabels?.current) {
    previousLabels.current = [];
  }

  let adjustedX = x;
  let adjustedY = y + verticalOffset;

  // Adjust until no overlap
  let overlap = true;
  while (overlap) {
    overlap = false;
    for (let i = 0; i < previousLabels.current.length; i++) {
      const prevY = previousLabels.current[i].prevY;
      if (Math.abs(prevY - adjustedY) < 12) {
        // Adjust separation distance
        verticalOffset += 12; // Increase spacing
        adjustedY = y + verticalOffset;
        overlap = true;
      }
    }
  }

  if (previousLabels) {
    previousLabels.current = [
      ...previousLabels.current,
      { prevX: adjustedX, prevY: adjustedY },
    ];
  }

  const startX = cx + outerRadius * Math.cos(-midAngle * RADIAN);
  const startY = cy + outerRadius * Math.sin(-midAngle * RADIAN);
  const endX = adjustedX;
  const endY = adjustedY;

  return (
    <g>
      <path
        d={`M${startX},${startY} L${endX},${endY}`}
        stroke={color}
        fill="none"
      />
      <text
        x={endX + (adjustedX > cx ? 6 : -6)}
        y={endY}
        fill={color}
        textAnchor={adjustedX > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={12}
        fontWeight="bold"
      >
        {`${categoryName}: ${share}`}
      </text>
    </g>
  );
};

const PieCharts = React.memo(
  ({ data, chartProps }) => {
    const previousLabels = useRef([]);
    const previousLabelsX = useRef([]);

    return (
      <ResponsiveContainer id="recharts-surface" width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={
              <CustomLabel
                previousLabels={previousLabels}
                previousLabelsX={previousLabelsX}
              />
            }
            outerRadius={100}
            dataKey="gross_value"
            {...chartProps}
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip content={<CategoryTooltip data={data} />} />
        </PieChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => prev.data === next.data
);
export default PieCharts;
