import { Trans, useTranslation } from "react-i18next";

let components = { b: <b />, i: <i />, strong: <strong />, p: <p /> };
const Translate = ({ i18nkey, values, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Trans components={components} i18nkey={i18nkey} values={values} {...rest}>
      {t(i18nkey)}
    </Trans>
  );
};

export default Translate;
