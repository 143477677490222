import React, { useState, useEffect, useRef, useCallback } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Tooltip } from "@mui/material";
import styled from "@emotion/styled";

import useUpdateEffect from "../hooks/4-useUpdateEffect/useUpdateEffect";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useKeyPress from "../hooks/useKeyPress";
import theme from "../theme";

const InputWrapper = styled("span", {
  shouldForwardProp: (props) =>
    props !== "isInputActive" && props !== "readable",
})(({ theme, isInputActive, readable }) => ({
  outline: "none !important",
  border: `1px solid ${isInputActive && !readable ? theme.palette.color.slate[500] : "transparent"}`,
  display: "inline-flex",
  alignItems: "center",
  borderRadius: theme.borderRadius.main,
  "& input": {
    borderRadius: "inherit",
    padding: "0.25rem",
    paddingInline: "0.5rem",
    border: "none !important",
    outline: "none !important",
  },
  "& #edit-icon": {
    fontSize: "1rem",
    display: "none",
    position: "absolute",
    right: "4px",
    top: "4px",
  },

  "& .inline-text_copy--active, & .inline-text_input--active": {
    font: "inherit",
    color: "inherit",
    texAlign: "inherit",
    background: "none",
    outline: "none",
  },
  "& .inline-text_copy": {
    position: "relative",
    display: "inline",
    alignItems: "center",
    border: "none",
    width: "fit-content",
    height: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: "0.25rem",
    paddingLeft: "0.5rem",
    paddingRight: "1.4rem",
    borderRadius: "inherit",
  },
  "& .inline-text_input--active": {
    border: "none !important",
    outline: "none !important",
    boxSizing: "border-box",
  },
  "& .inline-text_input--active::placeholder": {
    color: theme.palette.color.slate[400],
  },
  "& .inline-text_copy--hidden,& .inline-text_input--hidden": {
    display: "none",
  },
  "&:hover, &:active, &:focus": {
    "& #edit-icon": {
      display: "flex",
    },
    // "& .inline-text_copy": {
    //   paddingRight: "1rem",
    // },
    cursor: readable ? "default" : "pointer",
    backgroundColor: readable
      ? "transparent"
      : isInputActive
        ? theme.palette.color.white
        : theme.palette.color.slate[50],
    borderColor: readable
      ? "transparent"
      : theme.palette.color.slate[isInputActive ? 500 : 300],
  },
}));
function InlineEdit(props) {
  const { t } = useTranslation();
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(props.text);
  const [inputWidth, setInputWidth] = useState("auto");

  const wrapperRef = useRef(null);
  const textRef = useRef(null);
  const inputRef = useRef(null);

  const enter = useKeyPress("Enter");
  const esc = useKeyPress("Escape");

  const { onSetText } = props;

  // check to see if the user clicked outside of this component
  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) {
      if (inputValue?.trim("")?.length > 100) {
        enqueueSnackbar(t("title_should_be_less_than_100_char"), {
          variant: "error",
          autoHideDuration: 5000,
          preventDuplicate: true,
        });
        return;
      }
      if (
        inputValue?.trim("")?.length > 0 &&
        inputValue !== props?.item?.title
      ) {
        onSetText({ value: inputValue, item: props?.item });
      } else {
        setInputValue(props?.item?.title);
      }
      setIsInputActive(false);
    }
  });

  const onEnter = useCallback(() => {
    if (enter) {
      if (inputValue?.trim("")?.length > 100) {
        enqueueSnackbar(t("title_should_be_less_than_100_char"), {
          variant: "error",
          autoHideDuration: 5000,
          preventDuplicate: true,
        });
        return;
      }
      if (
        inputValue?.trim("")?.length > 0 &&
        inputValue !== props?.item?.title
      ) {
        onSetText({ value: inputValue, item: props?.item });
      } else {
        setInputValue(props?.item?.title);
      }
      setIsInputActive(false);
    }
  }, [enter, inputValue, onSetText, props?.item]);

  const onEsc = useCallback(() => {
    if (esc) {
      setInputValue(props?.text);
      setIsInputActive(false);
    }
  }, [esc, props?.text]);

  useUpdateEffect(() => {
    setInputValue(props?.text);
  }, [props?.text]);

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (isInputActive) {
      inputRef.current.focus();
    }
  }, [isInputActive]);

  useEffect(() => {
    if (isInputActive) {
      // if Enter is pressed, save the text and close the editor
      onEnter();
      // if Escape is pressed, revert the text and close the editor
      onEsc();
    }
  }, [onEnter, onEsc, isInputActive]); // watch the Enter and Escape key presses

  const handleInputChange = useCallback(
    (event) => {
      setInputValue(event.target.value);
    },
    [setInputValue]
  );

  const handleSpanClick = useCallback(
    (e) => {
      e?.stopPropagation();
      if (!props?.readable && !isInputActive) {
        setInputWidth(textRef?.current?.offsetWidth);
        setIsInputActive(true);
      }
    },
    //inputValue needed in dependency to calculate width real time
    [props?.readable, inputValue, isInputActive]
  );

  const handleOnBlur = useCallback(
    (e) => {
      if (props?.onBlur) {
        props?.onBlur({ e, value: inputValue, item: props?.item });
      }
    },
    [inputValue, props]
  );

  return (
    <Tooltip title={t(props?.tooltip || "")} placement="top">
      <InputWrapper
        ref={wrapperRef}
        isInputActive={isInputActive}
        readable={props?.readable}
        style={{
          maxWidth: props?.maxWidth,
          height: props?.height,
        }}
      >
        <span
          ref={textRef}
          onClick={handleSpanClick}
          className={`inline-text_copy inline-text_copy--${
            !isInputActive || props?.readable ? "active" : "hidden"
          }`}
          style={props?.textStyle}
        >
          {inputValue}
          <EditIcon id="edit-icon" />
        </span>
        {props.type === "area" ? (
          <textarea
            onClick={handleSpanClick}
            name="roleExplanation"
            value={inputValue}
            onFocus={props?.onFocus}
            onBlur={props?.onBlur}
            onChange={handleInputChange}
            rows="5"
            className={`inline-text_input inline-text_input--${
              isInputActive ? "active" : "hidden"
            }`}
            ref={inputRef}
            // set the width to the input length multiplied by the x height
            // it's not quite right but gets it close
            style={{
              ...props?.style,
              ...props?.textStyle,
              backgroundColor: isInputActive ? "#fff" : null,
              fontFamily: theme.typography.fontFamily,
            }}
          >
            This role is for facility managers and holds the highest permissions
            in the application.
          </textarea>
        ) : (
          <input
            onClick={handleSpanClick}
            ref={inputRef}
            // set the width to the input length multiplied by the x height
            // it's not quite right but gets it close
            style={{
              ...props?.style,
              ...props?.textStyle,
              backgroundColor: isInputActive ? "#fff" : null,
              fontFamily: theme.typography.fontFamily,
              width: inputWidth,
              // Math.ceil(inputValue.length) + "ch"
            }}
            value={inputValue}
            onFocus={props?.onFocus}
            onBlur={handleOnBlur}
            onChange={handleInputChange}
            className={`inline-text_input inline-text_input--${
              isInputActive ? "active" : "hidden"
            }`}
          />
        )}
      </InputWrapper>
    </Tooltip>
  );
}

export default InlineEdit;
