import {
  eachMonthOfInterval,
  startOfQuarter,
  endOfQuarter,
  startOfMonth,
  isSameMonth,
  subQuarters,
  endOfMonth,
  addMonths,
  isSameDay,
  subDays,
  getDate,
} from "date-fns";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import subMonths from "date-fns/subMonths";
import format from "date-fns/format";
import _ from "underscore";

import {
  getStartingLiquidityValue,
  getStartOfCustomQuarter,
  getEndOfCustomQuarter,
  getDataSetDateRange,
} from "../Helper/functions";
import { Color, Constant } from "../Helper";

const getConnectedScenariosUuid = ({ scenarioById, _scenario }) => {
  let array = [];
  array.push(_scenario?.uuid);
  function checkScenario(ref_scenario) {
    if (ref_scenario) {
      const _ref_scenario = scenarioById?.[ref_scenario]?.[0];
      array.push(_ref_scenario?.uuid);
      checkScenario(_ref_scenario?.reference);
    }
  }
  checkScenario(_scenario?.reference);
  return array;
};

const getConnectedScenarios = ({ scenarioById, _scenario }) => {
  let array = [];
  array.push(_scenario?.title);
  function checkScenario(ref_scenario) {
    if (ref_scenario) {
      const _ref_scenario = scenarioById?.[ref_scenario]?.[0];
      array.push(_ref_scenario?.title);
      checkScenario(_ref_scenario?.reference);
    }
  }
  checkScenario(_scenario?.reference);
  return array;
};

//chart
export const generateMonthlyChart = (props) => {
  if (props?.data) {
    let data = JSON.parse(props?.data);
    let {
      Limit,
      advanceVat,
      selectionCategoriesByID,
      scenario,
      transaction_monthly_chart,
      start_date,
      end_date,
      booked_balances,
      Transaction_monthly_statistic,
      includedScenarios,
      includedStates,
      scenarioById,
      selectionCategories,
      business_start_date,
      isDataset,
      tableType,
      column_count = 0,
    } = data;

    let dummyChartData = [];
    const obj = getDataSetDateRange({
      tableType,
      start_date,
      column_count: column_count + 1,
    });

    let dataSetData = {
      business_start_date,
      start_date: obj?.start_date,
      end_date: obj?.end_date,
    };

    if (transaction_monthly_chart && selectionCategoriesByID && start_date) {
      const groupedMonthlyTransactions = _.groupBy(
        Transaction_monthly_statistic,
        (item) => item?.month
      );
      const groupByMonthData = _.groupBy(
        transaction_monthly_chart,
        (item) => item?.month
      );
      const groupByMonthBookedBalances = _.groupBy(
        booked_balances,
        ({ month }) => month
      );

      const filteredScenarios = scenario?.filter((o1) =>
        includedScenarios?.includes(o1.title)
      );
      const currentMonth = format(new Date(), "yyyy-MM");
      const currentDate = format(new Date(), "yyyy-MM-dd");

      const result = eachMonthOfInterval({
        start: new Date(isDataset ? dataSetData?.start_date : start_date),
        end: new Date(isDataset ? dataSetData?.end_date : end_date),
      });

      let LiquidityTotal = {};
      let vat_total = {};
      let scenario_list_uuid = {};
      let scenario_list_title = {};
      let netChange = {};
      let prev_booked_balance = 0;
      let booked_balance = 0;

      filteredScenarios?.forEach((scenarioElement) => {
        const key = scenarioElement?.title;
        let scenarioArrayUuid = getConnectedScenariosUuid({
          scenarioById,
          _scenario: scenarioElement,
        });
        let scenarioArrayTitle = getConnectedScenarios({
          scenarioById,
          _scenario: scenarioElement,
        });
        scenario_list_uuid[key] = scenarioArrayUuid;
        scenario_list_title[key] = scenarioArrayTitle;
      });
      // console.log(
      //   " scenario_list_title",
      //   filteredScenarios,
      //   scenario_list_uuid,
      //   scenario_list_title
      // );

      result?.forEach((date, index) => {
        let monthDate = format(date, "yyyy-MM");
        let prevMonthDate = format(subMonths(date, 1), "yyyy-MM");
        let monthData = groupByMonthData?.[monthDate]?.[0];

        let isFuture = monthDate >= currentMonth;
        const isPastMonth = monthDate < currentMonth;
        const isCurrentMonth = monthDate === currentMonth;
        const isFutureMonth = monthDate > currentMonth;

        let subtractVat =
          advanceVat?.enabled &&
          (monthDate > currentMonth ||
            (monthDate === currentMonth &&
              getDate(new Date()) <= Number(advanceVat?.due_day ?? 1)));
        const prev_balance =
          groupByMonthBookedBalances?.[
            prevMonthDate > currentMonth ? currentMonth : prevMonthDate
          ]?.[0];

        const balance =
          groupByMonthBookedBalances?.[
            isFutureMonth ? currentDate : monthDate
          ]?.[0];

        prev_booked_balance =
          prev_balance?.booked_balance + prev_balance?.ignored_balance ||
          prev_booked_balance;

        booked_balance =
          balance?.booked_balance + balance?.ignored_balance || booked_balance;

        let vatData = [];
        let bars_data = {};
        if (isFuture) {
          bars_data = {
            income_type_open_bar:
              (monthData?.["income_type_open"] || 0) +
              (monthData?.["income_type_open_late"] || 0),
            expense_type_open_bar:
              (monthData?.["expense_type_open"] || 0) +
              (monthData?.["expense_type_open_late"] || 0),
          };
        }

        if (monthData) {
          bars_data = {
            ...bars_data,
            ...(monthData
              ? Object.keys(monthData)
                  .filter((key) =>
                    ["-scenario-", "_type_"].some((o1) => key.includes(o1))
                  )
                  .reduce((result, key) => {
                    result[key] = (isPastMonth ? key.includes("booked") : true)
                      ? monthData[key]
                      : 0;
                    return result;
                  }, {})
              : {}),
          };
        }
        let startVatDate = null;
        let endVatDate = null;
        let isVatPayMentMonth = false;
        let vat_pay_date = 0;

        if (advanceVat && advanceVat?.enabled) {
          const isMonthly =
            String(advanceVat?.frequency) === "1" &&
            !advanceVat?.permanent_extension;
          const isMonthlyExtended =
            String(advanceVat?.frequency) === "1" &&
            advanceVat?.permanent_extension;
          const isQuarterly =
            String(advanceVat?.frequency) === "2" &&
            !advanceVat?.permanent_extension;
          const isQuarterlyExtended =
            String(advanceVat?.frequency) === "2" &&
            advanceVat?.permanent_extension;
          if (isMonthly || isMonthlyExtended) {
            const prevMonth = subMonths(
              startOfMonth(new Date(monthDate)),
              isMonthly ? 1 : 2
            );

            startVatDate = format(startOfMonth(prevMonth), "yyyy-MM");
            endVatDate = format(endOfMonth(prevMonth), "yyyy-MM");
            vat_pay_date = format(
              addMonths(startOfMonth(new Date(endVatDate)), isMonthly ? 1 : 2),
              "yyyy-MM"
            );
          }

          if (isQuarterly || isQuarterlyExtended) {
            const currentQuarterStartDate = format(
              getStartOfCustomQuarter(new Date(monthDate), business_start_date),
              "yyyy-MM"
            );

            const prevQuarterStart = subMonths(
              new Date(currentQuarterStartDate),
              isQuarterly ? 3 : 4
            );
            const prevQuarterEnd = getEndOfCustomQuarter(prevQuarterStart);

            // Q1: January - March
            // Q2: April - June
            // Q3: July - September
            // Q4: October - December

            startVatDate = format(new Date(prevQuarterStart), "yyyy-MM");
            endVatDate = format(new Date(prevQuarterEnd), "yyyy-MM");
            vat_pay_date = format(
              addMonths(
                startOfMonth(new Date(endVatDate)),
                isQuarterly ? 1 : 2
              ),
              "yyyy-MM"
            );
          }

          isVatPayMentMonth = isSameMonth(
            new Date(monthDate),
            new Date(vat_pay_date)
          );
          if (isVatPayMentMonth) {
            vatData = transaction_monthly_chart?.filter(
              (item) =>
                item?.month >= startVatDate &&
                item?.month <= endVatDate &&
                !Constant?.calculationExcludeStates2.includes(item?.state)
            );

            vatData?.forEach((item) => {
              filteredScenarios?.forEach((scenarioElement) => {
                const key = scenarioElement?.title;
                if (item[`vat_type_booked_${key}`]) {
                  item[`vat_type_${key}`] = item[`vat_type_booked_${key}`] || 0;
                }
                if (!item[`vat_type_${key}`]) {
                  scenario_list_title[key].forEach((_scenario, i) => {
                    if (item[`vat_type_${_scenario}`]) {
                      item[`vat_type_${key}`] =
                        item[`vat_type_${_scenario}`] || 0;
                    }
                  });
                }
              });
            });
          }
        }

        if (index === 0) {
          filteredScenarios?.forEach((scenarioElement) => {
            const key = scenarioElement?.title;

            let startValue = getStartingLiquidityValue({
              monthlyTransactions: Transaction_monthly_statistic,
              selected_scenario_uuid: scenario_list_uuid?.[key],
              date: monthDate,
              business_start_date,
              selectionCategories,
              selectionCategoriesByID,
              groupedMonthlyTransactions,
              advanceVat,
              isChart: true,
            });

            LiquidityTotal[key] = startValue?.LiquidityTotal || 0;
            vat_total[key] = startValue?.VatChargeTotal || 0;
          });
          // console.log(
          //   "🚀 / chart / LiquidityTotal:",
          //   monthDate,
          //   vatDate,
          //   LiquidityTotal,
          //   vat_total
          // );
        }

        let updatedKeyData = {
          ...monthData,
        };
        let monthEndTotal = {};
        let monthEndVatTotal = {};
        let monthEndVatChargeTotal = {};

        filteredScenarios?.forEach((scenarioElement) => {
          const key = scenarioElement?.title;

          bars_data = {
            ...bars_data,
            [`liquidity_start_${key}`]:
              Number(LiquidityTotal?.[key] || 0) +
              (prev_booked_balance ?? 0) -
              Number(vat_total?.[key] || 0),
          };
          if (monthData?.[`vat_type_booked_${key}`]) {
            updatedKeyData[`vat_type_${key}`] =
              updatedKeyData?.[`vat_type_booked_${key}`] || 0;
          }
          if (monthData?.[`type_booked_${key}`]) {
            updatedKeyData[`type_${key}`] =
              updatedKeyData?.[`type_booked_${key}`] || 0;
          }
          if (!updatedKeyData[`type_${key}`]) {
            scenario_list_title[key].forEach((_scenario, i) => {
              if (updatedKeyData[`type_${_scenario}`]) {
                updatedKeyData[`type_${key}`] =
                  updatedKeyData?.[`type_${_scenario}`] || 0;
              }
            });
          }

          if (isPastMonth) {
            monthEndVatTotal[key] =
              Number(monthEndVatTotal?.[key] || 0) +
              Number(updatedKeyData?.[`vat_type_${key}`] || 0);
          }
          if (isCurrentMonth) {
            monthEndVatTotal[key] =
              Number(monthEndVatTotal?.[key] || 0) +
              Number(updatedKeyData?.[`vat_type_${key}`] || 0);

            monthEndTotal[key] =
              Number(monthEndTotal?.[key] || 0) +
              Number(updatedKeyData?.[`type_${key}`] || 0);
          }
          if (isFutureMonth) {
            monthEndTotal[key] =
              Number(monthEndTotal?.[key] || 0) +
              Number(updatedKeyData?.[`type_${key}`] || 0);
            monthEndVatTotal[key] =
              Number(monthEndVatTotal?.[key] || 0) +
              Number(updatedKeyData?.[`vat_type_${key}`] || 0);
          }
          vatData?.forEach((item) => {
            monthEndVatChargeTotal[key] =
              Number(monthEndVatChargeTotal?.[key] || 0) +
              Number(item?.[`vat_type_${key}`] || 0);
          });
        });

        let VAT = monthEndVatTotal?.["Base"] || 0;
        let scenario_vat = monthEndVatChargeTotal?.["Base"] || 0;

        let obj = {
          name: monthDate,
          payload: [],
        };
        let position = 2;
        if (advanceVat && advanceVat?.enabled) {
          obj["VAT"] = -VAT;
          obj["VAT_Imputation"] = subtractVat ? -scenario_vat : 0;
          position++;
          obj.payload.push({
            name: "VAT",
            key: "VAT",
            amount: -VAT,
            stroke: Color.pink,
            position: position,
          });
          position++;
          obj.payload.push({
            name: "VAT_Imputation",
            key: "VAT",
            amount: subtractVat ? -scenario_vat : 0,
            stroke: Color.pink,
            position: position,
          });
        }
        // if (isCurrentMonth) {
        //   console.log(
        //     "month===>",
        //     monthDate,
        //     // vatDate,
        //     subtractVat,
        //     updatedKeyData,
        //     // vatData,
        //     booked_balance,
        //     { ...LiquidityTotal },
        //     { ...vat_total },
        //     { ...monthEndTotal },
        //     { ...monthEndVatChargeTotal },
        //     { ...netChange }
        //     // { ...monthEndVatTotal },
        //   );
        // }

        filteredScenarios?.forEach((scenarioElement) => {
          const key = scenarioElement?.title;
          // let startValue =
          //   Number(LiquidityTotal?.[key] || 0) + (prev_booked_balance || 0);

          LiquidityTotal[key] =
            Number(LiquidityTotal?.[key] || 0) +
            Number(monthEndTotal?.[key] || 0);
          // let endValue =
          //   Number(LiquidityTotal?.[key] || 0) + (booked_balance || 0);

          vat_total[key] =
            Number(vat_total?.[key] || 0) +
            (subtractVat ? Number(monthEndVatChargeTotal?.[key] || 0) : 0);

          if (isPastMonth) {
            // netChange[key] = endValue - startValue;
            netChange[key] =
              (updatedKeyData?.[`type_${key}`] || 0) +
              (updatedKeyData?.[`income_type_booked`] || 0) +
              (updatedKeyData?.[`expense_type_booked`] || 0);
          }
          if (isCurrentMonth) {
            // in backend logic for the current period:
            // if booked_inflow + open_inflow > planned_inflow:
            // type_Base += open_inflow + past_open_inflow
            // else:
            // type_Base += planned_inflow + past_open_inflow - booked_inflow

            // if booked_outflow + open_outflow < planned_outflow:
            // type_Base += open_outflow + past_open_outflow
            // else:
            // type_Base += planned_outflow + past_open_outflow - booked_outflow
            netChange[key] =
              (updatedKeyData?.[`type_${key}`] || 0) +
              (updatedKeyData?.[`income_type_booked`] || 0) +
              (updatedKeyData?.[`expense_type_booked`] || 0) -
              (subtractVat ? Number(monthEndVatChargeTotal?.[key] || 0) : 0);
          }
          if (isFutureMonth) {
            netChange[key] =
              (updatedKeyData?.[`type_${key}`] || 0) -
              (subtractVat ? Number(monthEndVatChargeTotal?.[key] || 0) : 0);
          }

          let total = 0;
          position++;
          total = bars_data[`liquidity_start_${key}`] + netChange[key];

          obj[`VAT_${key}`] = -monthEndVatTotal?.[key] || 0;
          obj[`VAT_Imputation_${key}`] = subtractVat
            ? -monthEndVatChargeTotal?.[key] || 0
            : 0;
          if (new Date(monthDate) >= new Date(dataSetData?.end_date)) {
            obj[`VAT_${key}`] = 0;
            obj[`VAT_Imputation_${key}`] = 0;
          }
          obj[key] = total;

          bars_data = {
            ...bars_data,
            [`liquidity_end_${key}`]: obj[key] || 0,
            [`liquidity_net_change_${key}`]: netChange[key],
          };
          if (key === "Base") {
            if (monthDate <= currentMonth) {
              obj["Base_solid"] = bars_data?.[`liquidity_start_${key}`] || 0;
            }
          }
          let monthly_amount = 0;
          if (monthData && monthData[`type_booked_${key}`]) {
            updatedKeyData[`type_${key}`] =
              monthData[`type_booked_${key}`] || 0;
          }

          monthly_amount = updatedKeyData
            ? updatedKeyData[`type_${key}`] || 0
            : 0;
          obj.payload.push({
            name: key,
            key: key,
            amount: obj[key],
            monthly_amount: monthly_amount,
            [`liquidity_start_${key}`]: bars_data[`liquidity_start_${key}`],
            [`liquidity_end_${key}`]: bars_data[`liquidity_end_${key}`],
            stroke: scenarioElement.color,
            position: position,
          });
        });

        obj = {
          ...obj,
          ...bars_data,
        };

        if (Limit?.length > 0) {
          let totalOF = 0;
          Limit.forEach((element) => {
            totalOF = parseInt(element?.limit ?? 0) + parseInt(totalOF);
            position++;
          });
          let title = "Limit";
          obj[title] = totalOF > 0 ? -totalOF : totalOF;
          obj.payload.push({
            name: "Limit",
            key: title,
            amount: totalOF,
            stroke: Color.tailwind.red[500],
            position: position,
          });
        }
        dummyChartData.push(obj);
      });
    }
    return JSON.stringify({
      statesKey: includedStates,
      scenariosKey: includedScenarios,
      data: dummyChartData,
    });
  }
};

export const generateDailyChart = (props) => {
  if (props?.data) {
    let data = JSON.parse(props.data);
    let {
      transaction_daily_chart,
      transaction_monthly_chart,
      Transaction_monthly_statistic,
      booked_balances,
      selectionCategoriesByID,
      scenario,
      start_date,
      end_date,
      Limit,
      advanceVat,
      includedScenarios, //needed
      includedStates, //needed
      scenarioById,
      selectionCategories,
      business_start_date,
      isDataset,
      tableType,
      column_count,
    } = data;
    let dummyChartData = [];
    let dataSetData = {
      start_date: start_date,
      end_date: end_date,
      business_start_date: business_start_date,
    };

    if (
      transaction_monthly_chart &&
      transaction_daily_chart &&
      selectionCategoriesByID &&
      start_date
    ) {
      const groupedMonthlyTransactions = _.groupBy(
        Transaction_monthly_statistic,
        (item) => item?.month
      );
      const groupByDayData = _.groupBy(
        transaction_daily_chart,
        ({ date }) => date
      );
      const groupByDayBookedBalances = _.groupBy(
        booked_balances,
        ({ date }) => date
      );

      const filteredScenarios = scenario?.filter((o1) =>
        includedScenarios?.includes(o1.title)
      );
      const currentMonth = format(new Date(), "yyyy-MM");
      const currentDate = format(new Date(), "yyyy-MM-dd");

      const result = eachDayOfInterval({
        start: new Date(isDataset ? dataSetData?.start_date : start_date),
        end: new Date(isDataset ? dataSetData?.end_date : end_date),
      });
      let LiquidityTotal = {};
      let vat_total = {};
      let scenario_list_uuid = {};
      let scenario_list_title = {};
      let netChange = {};
      let prev_booked_balance = 0;
      let booked_balance = 0;

      filteredScenarios?.forEach((scenarioElement) => {
        const key = scenarioElement?.title;
        let scenarioArrayUuid = getConnectedScenariosUuid({
          scenarioById,
          _scenario: scenarioElement,
        });
        let scenarioArrayTitle = getConnectedScenarios({
          scenarioById,
          _scenario: scenarioElement,
        });
        scenario_list_uuid[key] = scenarioArrayUuid;
        scenario_list_title[key] = scenarioArrayTitle;
      });
      // let runCurrentVatOnce = {};
      result?.forEach((dateElement, index) => {
        let dayDate = format(new Date(dateElement), "yyyy-MM-dd");
        let monthDate = format(new Date(dateElement), "yyyy-MM");
        let dayData = groupByDayData?.[dayDate]?.[0];
        let prevDayDate = format(
          subDays(new Date(dateElement), 1),
          "yyyy-MM-dd"
        );

        let isFuture = dayDate >= currentDate;
        const isPastMonth = dayDate < currentDate;
        const isCurrentMonth = dayDate === currentDate;
        const isFutureMonth = dayDate > currentDate;
        let subtractVat =
          advanceVat?.enabled &&
          (dayDate > currentDate ||
            (dayDate === currentDate &&
              getDate(new Date()) <= Number(advanceVat?.due_day ?? 1)));

        const prev_balance =
          groupByDayBookedBalances?.[
            prevDayDate > currentMonth ? currentDate : prevDayDate
          ]?.[0];
        const balance =
          groupByDayBookedBalances?.[
            isFutureMonth ? currentDate : dayDate
          ]?.[0];
        prev_booked_balance =
          prev_balance?.booked_balance + prev_balance?.ignored_balance ||
          prev_booked_balance;

        booked_balance =
          balance?.booked_balance + balance?.ignored_balance || booked_balance;

        let vatData = [];
        let bars_data = {};
        if (isFuture) {
          bars_data = {
            income_type_open_bar:
              (dayData?.["income_type_open"] || 0) +
              (dayData?.["income_type_open_late"] || 0),
            expense_type_open_bar:
              (dayData?.["expense_type_open"] || 0) +
              (dayData?.["expense_type_open_late"] || 0),
          };
        }
        if (dayData) {
          bars_data = {
            ...bars_data,
            ...(dayData
              ? Object.keys(dayData)
                  .filter((key) =>
                    ["-scenario-", "_type_"].some((o1) => key.includes(o1))
                  )
                  .reduce((result, key) => {
                    result[key] = (isPastMonth ? key.includes("booked") : true)
                      ? dayData[key]
                      : 0;
                    return result;
                  }, {})
              : {}),
          };
        }
        let startVatDate = null;
        let endVatDate = null;
        let isVatPayMentMonth = false;
        let vat_pay_date = 0;
        if (advanceVat && advanceVat?.enabled) {
          const isMonthly =
            String(advanceVat?.frequency) === "1" &&
            !advanceVat?.permanent_extension;
          const isMonthlyExtended =
            String(advanceVat?.frequency) === "1" &&
            advanceVat?.permanent_extension;
          const isQuarterly =
            String(advanceVat?.frequency) === "2" &&
            !advanceVat?.permanent_extension;
          const isQuarterlyExtended =
            String(advanceVat?.frequency) === "2" &&
            advanceVat?.permanent_extension;
          if (isMonthly) {
            const prevMonth = subMonths(startOfMonth(new Date(monthDate)), 1);

            startVatDate = format(startOfMonth(prevMonth), "yyyy-MM");
            endVatDate = format(endOfMonth(prevMonth), "yyyy-MM");
            vat_pay_date = format(
              addMonths(startOfMonth(new Date(endVatDate)), 1),
              "yyyy-MM-dd"
            );
          }
          if (isMonthlyExtended) {
            const prevMonth = subMonths(startOfMonth(new Date(monthDate)), 2);

            startVatDate = format(startOfMonth(prevMonth), "yyyy-MM");
            endVatDate = format(endOfMonth(prevMonth), "yyyy-MM");
            vat_pay_date = format(
              addMonths(startOfMonth(new Date(endVatDate)), 2),
              "yyyy-MM-dd"
            );
          }
          if (isQuarterly) {
            const prevQuarter = subQuarters(
              startOfMonth(new Date(monthDate)),
              1
            );
            startVatDate = format(startOfQuarter(prevQuarter), "yyyy-MM");
            endVatDate = format(endOfQuarter(prevQuarter), "yyyy-MM");
            vat_pay_date = format(
              addMonths(startOfMonth(new Date(endVatDate)), 1),
              "yyyy-MM-dd"
            );
          }
          if (isQuarterlyExtended) {
            const prevQuarter = subQuarters(
              startOfMonth(new Date(monthDate)),
              1
            );
            startVatDate = format(startOfQuarter(prevQuarter), "yyyy-MM");
            endVatDate = format(endOfQuarter(prevQuarter), "yyyy-MM");
            vat_pay_date = format(
              addMonths(startOfMonth(new Date(endVatDate)), 2),
              "yyyy-MM-dd"
            );
          }

          isVatPayMentMonth = isSameDay(
            new Date(dayDate),
            new Date(vat_pay_date)
          );
          if (isVatPayMentMonth) {
            vatData = transaction_monthly_chart?.filter(
              (item) =>
                item?.month >= startVatDate &&
                item?.month <= endVatDate &&
                !Constant?.calculationExcludeStates2.includes(item?.state)
            );
            vatData?.forEach((item) => {
              filteredScenarios?.forEach((scenarioElement) => {
                const key = scenarioElement?.title;
                if (item[`vat_type_booked_${key}`]) {
                  item[`vat_type_${key}`] = item[`vat_type_booked_${key}`] || 0;
                }
                if (!item[`vat_type_${key}`]) {
                  scenario_list_title[key].forEach((_scenario, i) => {
                    if (item[`vat_type_${_scenario}`]) {
                      item[`vat_type_${key}`] =
                        item[`vat_type_${_scenario}`] || 0;
                    }
                  });
                }
              });
            });
          }
        }

        if (index === 0) {
          filteredScenarios?.forEach((scenarioElement) => {
            const key = scenarioElement?.title;
            let startValue = getStartingLiquidityValue({
              monthlyTransactions: Transaction_monthly_statistic,
              selected_scenario_uuid: scenario_list_uuid?.[key],
              date: monthDate,
              business_start_date,
              selectionCategories,
              selectionCategoriesByID,
              groupedMonthlyTransactions,
              advanceVat,
            });

            LiquidityTotal[key] = startValue?.LiquidityTotal || 0;
            vat_total[key] = startValue?.VatChargeTotal || 0;
          });
        }

        let updatedKeyData = {
          ...dayData,
        };
        let monthEndTotal = {};
        let monthEndVatTotal = {};
        let monthEndVatChargeTotal = {};

        filteredScenarios?.forEach((scenarioElement) => {
          const key = scenarioElement?.title;

          bars_data = {
            ...bars_data,
            [`liquidity_start_${key}`]:
              Number(LiquidityTotal?.[key] || 0) +
              (prev_booked_balance ?? 0) -
              Number(vat_total?.[key] || 0),
          };
          if (dayData?.[`vat_type_booked_${key}`]) {
            updatedKeyData[`vat_type_${key}`] =
              updatedKeyData?.[`vat_type_booked_${key}`] || 0;
          }
          if (dayData?.[`type_booked_${key}`]) {
            updatedKeyData[`type_${key}`] =
              updatedKeyData?.[`type_booked_${key}`] || 0;
          }
          if (!updatedKeyData[`type_${key}`]) {
            scenario_list_title[key].forEach((_scenario, i) => {
              if (updatedKeyData[`type_${_scenario}`]) {
                updatedKeyData[`type_${key}`] =
                  updatedKeyData[`type_${_scenario}`] || 0;
              }
            });
          }

          if (isPastMonth) {
            monthEndVatTotal[key] =
              Number(monthEndVatTotal?.[key] || 0) +
              Number(updatedKeyData?.[`vat_type_${key}`] || 0);
          }
          if (isCurrentMonth) {
            monthEndTotal[key] =
              Number(monthEndTotal?.[key] || 0) +
              Number(updatedKeyData?.[`type_${key}`] || 0);
            monthEndVatTotal[key] =
              Number(monthEndVatTotal?.[key] || 0) +
              Number(updatedKeyData?.[`vat_type_${key}`] || 0);
          }
          if (isFutureMonth) {
            monthEndTotal[key] =
              Number(monthEndTotal?.[key] || 0) +
              Number(updatedKeyData?.[`type_${key}`] || 0);
            monthEndVatTotal[key] =
              Number(monthEndVatTotal?.[key] || 0) +
              Number(updatedKeyData?.[`vat_type_${key}`] || 0);
          }
          vatData?.forEach((item) => {
            monthEndVatChargeTotal[key] =
              Number(monthEndVatChargeTotal?.[key] || 0) +
              Number(item?.[`vat_type_${key}`] || 0);
          });
        });

        let VAT = monthEndVatTotal?.["Base"] || 0;
        let scenario_vat = monthEndVatChargeTotal?.["Base"] || 0;
        let obj = {
          name: dayDate,
          payload: [],
        };
        let position = 2;
        if (advanceVat && advanceVat?.enabled) {
          obj["VAT"] = -VAT;
          obj["VAT_Imputation"] = subtractVat ? -scenario_vat : 0;

          position++;
          obj.payload.push({
            name: "VAT",
            key: "VAT",
            amount: -VAT,
            stroke: Color.pink,
            position: position,
          });
          position++;
          obj.payload.push({
            name: "VAT_Imputation",
            key: "VAT",
            amount: subtractVat ? -scenario_vat : 0,
            stroke: Color.pink,
            position: position,
          });
        }
        filteredScenarios?.forEach((scenarioElement) => {
          const key = scenarioElement?.title;
          let startValue =
            Number(LiquidityTotal?.[key] || 0) + (prev_booked_balance || 0);

          LiquidityTotal[key] =
            Number(LiquidityTotal?.[key] || 0) +
            Number(monthEndTotal?.[key] || 0);
          let endValue =
            Number(LiquidityTotal?.[key] || 0) + (booked_balance || 0);

          vat_total[key] =
            Number(vat_total?.[key] || 0) +
            (subtractVat ? Number(monthEndVatChargeTotal?.[key] || 0) : 0);

          if (isPastMonth) {
            netChange[key] = endValue - startValue;
          }
          if (isCurrentMonth) {
            netChange[key] =
              (updatedKeyData?.[`type_${key}`] || 0) +
              (updatedKeyData?.[`income_type_booked`] || 0) +
              (updatedKeyData?.[`expense_type_booked`] || 0) -
              (subtractVat ? monthEndVatChargeTotal?.[key] : 0);
          }
          if (isFutureMonth) {
            netChange[key] =
              (updatedKeyData?.[`type_${key}`] || 0) -
              (subtractVat ? monthEndVatChargeTotal?.[key] : 0);
          }

          let total = 0;
          position++;
          total = bars_data[`liquidity_start_${key}`] + netChange[key];
          obj[`VAT_${key}`] = -monthEndVatTotal?.[key] || 0;
          obj[`VAT_Imputation_${key}`] = subtractVat
            ? -monthEndVatChargeTotal?.[key] || 0
            : 0;
          if (
            new Date(dayDate) >= addMonths(new Date(start_date), column_count)
          ) {
            obj[`VAT_${key}`] = 0;
            obj[`VAT_Imputation_${key}`] = 0;
          }
          obj[key] = total;
          bars_data = {
            ...bars_data,
            [`liquidity_end_${key}`]: obj[key] || 0,
            [`liquidity_net_change_${key}`]: netChange[key],
          };
          if (key === "Base") {
            if (dayDate <= currentDate) {
              obj["Base_solid"] = bars_data?.[`liquidity_start_${key}`] || 0;
            }
          }

          let monthly_amount = 0;
          if (dayData && dayData[`type_booked_${key}`]) {
            updatedKeyData[`type_${key}`] = dayData[`type_booked_${key}`] || 0;
          }

          monthly_amount = updatedKeyData
            ? updatedKeyData[`type_${key}`] || 0
            : 0;
          obj.payload.push({
            name: key,
            key: key,
            amount: obj[key],
            monthly_amount: monthly_amount,
            [`liquidity_start_${key}`]: bars_data[`liquidity_start_${key}`],
            [`liquidity_end_${key}`]: bars_data[`liquidity_end_${key}`],
            stroke: scenarioElement.color,
            position: position,
          });
        });

        obj = {
          ...obj,
          ...bars_data,
        };
        if (Limit?.length > 0) {
          let totalOF = 0;
          Limit.forEach((element) => {
            totalOF = parseInt(element?.limit ?? 0) + parseInt(totalOF);
            position++;
          });
          let title = "Limit";
          obj[title] = totalOF > 0 ? -totalOF : totalOF;
          obj.payload.push({
            name: "Limit",
            key: title,
            amount: totalOF,
            stroke: Color.theme.red[500],
            position: position,
          });
        }
        dummyChartData.push(obj);
      });
    }
    return JSON.stringify({
      statesKey: includedStates,
      scenariosKey: includedScenarios,
      data: dummyChartData,
    });
  }
};

onmessage = (e) => {
  if (e.data) {
    let { data, type } = e.data;
    // console.log("🚀 worker -->", e.data);
    if (type === "updateComposedMonthlyChart") {
      // console.log(`🚀 monthly chart worker -->start`);
      // const t0 = performance.now();
      let result = generateMonthlyChart({ data });
      // let result = updateMonthlyChart({ data });
      // const t1 = performance.now();
      // console.log(` monthly chart worker took --> ${t1 - t0} ms.`);
      postMessage({ type, data: result });
    }
    if (type === "updateComposedDailyChart") {
      // console.log(`🚀 daily chart worker -->start`);
      // const t0 = performance.now();
      let result = generateDailyChart({ data });
      // let result = updateDailyChart({ data });
      // const t1 = performance.now();
      // console.log(`daily chart worker took --> ${t1 - t0} ms.`);
      postMessage({ type, data: result });
    }
  }
};
