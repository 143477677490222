import MuiButton, { LoadingButtonOwnProps } from "@mui/lab/LoadingButton";
import { Tooltip, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { forwardRef } from "react";

type ThemeButtonType = "add" | "action" | "manage" | "manage2" | "default";

interface ComponentProps extends LoadingButtonOwnProps {
  onClick?: () => void;
  icon?: undefined | React.ReactNode;
  endIcon?: undefined | React.ReactNode;
  tooltip?: undefined | string;
  label?: string;
  sx?: any;
  theme?: any;
  width?: string | "fit-content";
  isHighlighted?: boolean;
  buttontype?: ThemeButtonType;
}

const Button = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    prop !== "isHighlighted" && prop !== "buttontype",
})(({ theme, width, isHighlighted, buttontype }: ComponentProps) => ({
  backgroundColor: isHighlighted
    ? theme?.palette?.primary.main
    : theme?.palette?.color?.slate[buttontype === "manage2" ? 100 : 200],
  color: isHighlighted
    ? theme?.palette?.color?.white
    : theme?.palette?.color?.slate[600],
  borderRadius: theme.borderRadius.main,
  fontWeight:
    buttontype === "manage2" || buttontype === "default"
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightBold,
  fontSize: "0.8rem",
  lineHeight: "1.25rem",
  width: width,
  paddingInline: "0.75rem",
  height: "2.2rem",
  "&  svg": {
    fontSize: "1.3rem",
    marginRight: buttontype === "default" ? 0 : "0.5rem",
    color: isHighlighted
      ? theme?.palette?.color?.white
      : theme?.palette?.color?.slate[600],
  },
  "&: hover": {
    backgroundColor: isHighlighted
      ? theme?.palette?.primary?.dark
      : theme?.palette?.color?.slate[buttontype === "manage2" ? 200 : 300],
  },
}));
const ActionButton = forwardRef(
  (
    {
      onClick,
      icon,
      endIcon,
      tooltip,
      label,
      buttontype,
      ...props
    }: ComponentProps,
    ref: any
  ) => {
    const { t } = useTranslation();
    return (
      <Tooltip placement={"top"} title={tooltip ? t(tooltip) : tooltip}>
        <Button onClick={onClick} buttontype={buttontype} ref={ref} {...props}>
          {icon}
          {label ? t(label) : null}
          {endIcon}
        </Button>
      </Tooltip>
    );
  }
);

export default ActionButton;
