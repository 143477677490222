import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  useTheme,
  ListItem,
  Divider,
  Stack,
  List,
} from "@mui/material";
import { useImperativeHandle, useCallback, forwardRef, useRef } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { LoadingButton } from "@mui/lab";

import RenderIfVisible from "../../../hooks/HOC/RenderIfVisible";
import ComponentLoader from "../../ComponentLoader";
import { Constant } from "../../../Helper";

const FilterOverlay = forwardRef(
  (
    {
      selectedIds,
      options,
      anchorEl,
      translate,
      color = "slate",
      displayKey = "title",
      onChangeValue,
      onClickManage,
      onClickReset,
      searchType,
      searchText,
      setSearchText,
      isFetching,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
      showDefaultColor
    },
    _ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const observer = useRef(null);

    const onChangeSearchText = (e) => {
      setSearchText(e.target.value);
    };

    const onClearSearchText = () => {
      setSearchText("");
    };

    const onClickItem = useCallback(
      (item) => {
        const isIncludedInFilter = selectedIds?.includes(item?.uuid);
        let changedIds = [];
        let isAddedNew = false;
        if (isIncludedInFilter) {
          isAddedNew = false;
          changedIds = selectedIds?.filter((o1) => o1 !== item?.uuid);
        } else {
          isAddedNew = true;
          changedIds = [...selectedIds, item?.uuid];
        }
        if (onChangeValue) {
          onChangeValue(changedIds, isAddedNew);
        }
      },
      [onChangeValue, selectedIds]
    );

    const observerElem = useCallback(
      (node) => {
        if (isFetching || searchType !== "server") return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0]?.isIntersecting && hasNextPage) {
            // fetchNextPage();
          }
        });
        if (node) observer.current?.observe(node);
      },
      [hasNextPage, isFetching, searchType]
    );

    useImperativeHandle(
      _ref,
      () => ({
        onClickItem,
      }),
      [onClickItem]
    );

    if (!anchorEl) {
      return null;
    }
    return (
      <Stack>
        <Stack direction="row" alignItems="center" sx={{ mt: "0.5rem" }}>
          <TextField
            id="outlined-size-small"
            size="small"
            name="name"
            autoComplete="off"
            value={searchText}
            onChange={onChangeSearchText}
            placeholder={t("Search")}
            sx={{
              width: "100%",
              fontWeight: theme.typography.fontWeightMedium,
              margin: "0px 0.5rem",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputBase-input": {
                fontSize: "0.9rem",
                py: "0.3rem",
                pl: "1rem",
                color: theme.palette.color[color][500],
                fontWeight: theme.typography.fontWeightMedium,
              },
            }}
          />
          <ClearIcon
            onClick={onClearSearchText}
            sx={{
              display: searchText?.length > 0 ? "block" : "none",
              mr: "0.9rem",
              fontSize: "1.1rem",
              cursor: "pointer",
            }}
          />
        </Stack>

        <Divider />
        <List
          dense
          sx={{
            width: "25rem",
            maxWidth: "100vw",
            maxHeight: "50vh",
            overflowY: "auto",
            backgroundColor: "background.paper",
            ...theme.thinScrollBar,
            "& .MuiListItemText-root span": {
              fontFamily: theme.typography.fontFamily,
              fontWeight: theme.typography.fontWeightMedium,
            },
            "& .MuiListItemButton-root": {
              mx: "0.4rem",
              borderRadius: theme.borderRadius.borderRadiusXL,
            },
            "& .MuiListItemSecondaryAction-root": {
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          {!isFetchingNextPage && (isFetching || options?.length === 0) ? (
            <ComponentLoader
              loading={isFetching}
              key2="filter_no_data_text_02"
              sx={{ width: "82%", mx: "auto" }}
            />
          ) : (
            options?.map((item, index) => {
              if (item?.hide) {
                return null;
              }
              const title =
                translate || item?.translate
                  ? t(item?.[displayKey])
                  : item?.[displayKey];
              const isSelected = selectedIds?.includes(item?.uuid);
              if (
                searchType === "client" &&
                searchText &&
                (item?.isSectionHeader ||
                  !title?.toLowerCase()?.includes(searchText?.toLowerCase()))
              ) {
                return null;
              }

              return (
                <RenderIfVisible
                  key={item?.uuid}
                  initialVisible={index < 15}
                  defaultHeight={"2rem"}
                  visibleOffset={300}
                  stayRendered={false}
                  rootElement="section"
                  placeholderElement="div"
                >
                  <ItemView
                    key={item?.uuid}
                    item={item}
                    title={title}
                    theme={theme}
                    color={color}
                    isSelected={isSelected}
                    onClickItem={onClickItem}
                    iconColor={
                      item?.icon||showDefaultColor
                        ? theme?.palette?.color?.[item?.color || color]?.[500]
                        : item?.fullColor ||
                          theme?.palette?.color?.[
                            item?.color ||
                              Constant.Random_Color?.[Math.floor(index % 18)] ||
                              "slate"
                          ]?.[500]
                    }
                  />
                </RenderIfVisible>
              );
            })
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "2rem",
            }}
          >
            {searchType === "server" && !isFetching && hasNextPage ? (
              <LoadingButton
                ref={observerElem}
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                disabled={isFetching}
                sx={{
                  width: "fit-content",
                  mt: "1rem",
                  minHeight: "2rem",
                  height: "2rem",
                  color: theme.palette.color?.[color]?.[600],
                  backgroundColor: theme.palette.color?.[color]?.[50],
                  "&:hover": {
                    backgroundColor: theme.palette.color?.[color]?.[100],
                  },
                }}
              >
                {t("Load More")}
              </LoadingButton>
            ) : null}
          </div>
        </List>

        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            my: onClickManage || onClickReset ? "1rem" : 0,
            mx: "1rem",
          }}
        >
          {onClickManage ? (
            <Typography
              onClick={onClickManage}
              sx={{
                fontSize: "0.8rem !important",
                fontWeight: 600,
                mt: "0.5rem",
                cursor: "pointer",
                color: theme.palette.color[color][600],
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t("Manage")}
            </Typography>
          ) : null}
          {onClickReset ? (
            <Typography
              onClick={onClickReset}
              sx={{
                fontSize: "0.8rem !important",
                fontWeight: 600,

                ml: "1rem",
                mt: "0.5rem",
                cursor: "pointer",
                color: theme.palette.color[color][600],
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t("Reset Filter")}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
    );
  }
);

export default FilterOverlay;

const ItemView = ({
  item,
  title,
  theme,
  iconColor,
  color,
  isSelected,
  onClickItem,
}) => {
  const id = item?.uuid;

  if (item?.isSectionHeader) {
    return (
      <ListItem
        key={id}
        disablePadding
        sx={{
          mt: "0.5rem",
          mb: "0.25rem",
        }}
      >
        <Typography
          key={id}
          variant="body2"
          color="color.slate.400"
          fontWeight={"fontWeightMedium"}
          sx={{ ml: "1.75rem" }}
        >
          {title}
        </Typography>
      </ListItem>
    );
  }
  return (
    <ListItem
      key={id}
      secondaryAction={
        isSelected ? (
          <FaCheckCircle
            color={
              theme.palette.color[color][
                isSelected && item?.isRequired ? 200 : 500
              ]
            }
          />
        ) : null
      }
      disablePadding
    >
      <ListItemButton
        disabled={item?.disabled || (isSelected && item?.isRequired)}
        onClick={
          isSelected && item?.isRequired ? undefined : () => onClickItem(item)
        }
        sx={{
          height: "2rem",
          "&.MuiListItemButton-root.Mui-disabled": {
            cursor: "not-allowed",
            pointerEvents: "auto",
            backgroundColor: "transparent",
          },
        }}
      >
        {item?.hideIcon ? null : (
          <ListItemIcon
            sx={{
              minWidth: "40px",
              "& svg": {
                fontSize: "1.1rem",
                color: iconColor,
              },
            }}
          >
            {item?.icon || <FiberManualRecordIcon />}
          </ListItemIcon>
        )}
        <ListItemText id={id} primary={title} />
      </ListItemButton>
    </ListItem>
  );
};
