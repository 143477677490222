import { useTranslation } from "react-i18next";
import { BsPersonVcard } from "react-icons/bs";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Box } from "@mui/material";

import { buildUrlFromParams, getStaffQueryKey } from "../../Helper/data";
import useSubscriptions from "../../hooks/useSubscriptions";
import EndPoints from "../../APICall/EndPoints";
import DropDown from "../Overlay/DropDown";
import { queryClient } from "../../App";
import { Color } from "../../Helper";
import APICall from "../../APICall";
import store from "../../store";
import Icon from "../Icon";

const StaffInputView = ({
  showError,
  cardItem,
  disabled,
  setCardItem,
  recurring_Obj,
  setRecurring_Obj,
  updateRecurrenceFlag,
  modalType,
  hideEditFormTabBar,
}) => {
  const { t } = useTranslation();
  const [isSubscriptionValid] = useSubscriptions();
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    getStaffList();
  }, []);

  //api
  const getContacts = async (params) => {
    let result = null;
    await APICall("get", EndPoints.customers + buildUrlFromParams(params)).then(
      (response) => {
        if (response.status === 200 && response.data) {
          result = response.data;
        }
      }
    );
    return result;
  };

  const addContactApi = async (obj) => {
    let data = null;
    await APICall("post", EndPoints.customers, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 201 && response.data) {
        data = response.data;
        enqueueSnackbar(t("new_staff_created_successfully"), {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        if (response.data?.name?.[0]) {
          enqueueSnackbar(t("info_staff_already_existing_name"), {
            variant: "info",
            autoHideDuration: 5000,
          });
        }
      }
    });
    return data;
  };

  const getStaffList = async () => {
    const StaffList = await queryClient.fetchQuery({
      queryKey: getStaffQueryKey(),
      queryFn: ({ signal }) => {
        const result = getContacts({
          type: 3,
        });
        if (result) {
          return result;
        }
      },
      backgroundFetch: true,
    });
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;
    setOptions(
      StaffList?.results?.filter((o1) => o1?.dataset === dataSetData?.uuid)
    );
  };

  const onChangeDropDown = async (e, value) => {
    updateRecurrenceFlag();

    if (typeof value !== "string" && value && value.inputValue) {
      if (isSubscriptionValid({ showMessage: true, type: "employee" })) {
        const dataSetData = store?.getState()?.boardSlice?.dataSetData;
        const _contact = await addContactApi({
          name: value?.inputValue,
          type: 3,
          state: 1,
          dataset: dataSetData?.uuid,
        });
        await getStaffList();
        updateTransactionTitle(_contact?.name, _contact?.uuid);
        setRecurring_Obj({
          ...recurring_Obj,
          contact: _contact?.uuid,
          name: _contact?.name,
        });
      }
    } else {
      setRecurring_Obj({
        ...recurring_Obj,
        contact: value?.uuid,
        name: value?.name,
      });
      updateTransactionTitle(value?.name, value?.uuid);
    }
  };

  const updateTransactionTitle = (name, contact) => {
    const recurring_rules = store.getState().globalSlice?.recurring_rules;

    const filterData = recurring_rules?.filter(
      (o1) => o1.contact && o1?.contact === contact
    );
    if (modalType === "add" || filterData?.length <= 1 || !contact) {
      setCardItem({ ...cardItem, title: name });
    }
  };
  const name =
    options?.find((o1) => o1.uuid === recurring_Obj?.contact)?.name || null;
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "start",
        mt: hideEditFormTabBar ? 0 : "1rem",
        width: "106.5%",
        ml: "-1.75rem",
      }}
    >
      <Icon
        icon={<BsPersonVcard />}
        fontSize={"1.3rem"}
        color={Color.theme.blueGrey[700]}
        style={{
          marginRight: "1rem",
          marginLeft: "0.2rem",
          marginTop: name ? "0.25rem" : "0.75rem",
        }}
      />
      <DropDown
        dropdown_input_id="employee-input"
        disablePortal
        disabled={disabled}
        name="contact"
        value={name}
        fontSize={"0.9rem"}
        options={options}
        renderOption={(option) => `${option?.name}` ?? ""}
        getOptionLabel={(option) => option?.name ?? ""}
        error={showError && !Boolean(recurring_Obj?.contact)}
        hideTitle
        likeGoogle
        label={t("Employee Name")}
        variant="filled"
        mb={0}
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        onChange={onChangeDropDown}
        createDynamicText
        noteText="+ add new by typing name"
        sx={{
          width: "100%",
        }}
      />
    </Box>
  );
};

export default StaffInputView;
