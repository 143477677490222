import { createSlice } from "@reduxjs/toolkit";

import { resetAllState } from "./reset";

const initialState = {
  errorMessage: "",
  isAllHeaderApiFetched: false,
  isOnBoardingOverlayOpen: false,
  overlay: null,
  isDevMode: false,
  isMaintenanceMode: false,
  isPageNotFound: false,
  liveSearchText: "",
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setIsAllHeaderApiFetched: (state, action) => {
      state.isAllHeaderApiFetched = action.payload;
    },
    setIsOnBoardingOverlayOpen: (state, action) => {
      state.isOnBoardingOverlayOpen = action.payload;
    },
    setOverlay: (state, action) => {
      state.overlay = action.payload;
    },
    toggleDevMode: (state, action) => {
      state.isDevMode = !state.isDevMode;
    },
    setMaintenanceMode: (state, action) => {
      state.isMaintenanceMode = action.payload;
    },
    setPageNotFound: (state, action) => {
      state.isPageNotFound = action.payload;
    },
    setLiveSearchText: (state, action) => {
      state.liveSearchText = action.payload;
    },
  },
});
export const {
  setIsAllHeaderApiFetched,
  setIsOnBoardingOverlayOpen,
  setOverlay,
  toggleDevMode,
  setMaintenanceMode,
  setPageNotFound,
  setLiveSearchText,
} = commonSlice.actions;
export default commonSlice;
