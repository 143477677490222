import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getAllSubCategoryIds,
  thinScrollbarStyle,
  formatAmount,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import { setPopupStatus2 } from "../../../../store/slices/datasets";
import EmptyView from "./Component/EmptyView";
import { Constant } from "../../../../Helper";

const EurWidget = ({ widgetType, widget }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  //state
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  //functions
  const onClickTitle = (item) => {
    dispatch(
      setPopupStatus2({
        open: true,
        overlay_type: "drawer_modal",
        anchor: "right",
        payload: {
          overlayTab: "category",
          cell: {
            rowId: item.uuid,
            start_date: widget?.start_date,
            end_date: widget?.end_date,
            multiState: widget?.states,
            multiScenario: widget?.scenarios,
            withUrl: true,
            transaction_type: item.type === 1 ? "income" : "expense",
            doNotMergeSequences: true,
          },
        },
      })
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        ...thinScrollbarStyle,
      }}
    >
      <LogicFunctions
        widget={widget}
        setData={setData}
        setIsFetching={setIsFetching}
        setLoading={setLoading}
      />
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data ? (
        <div
          id="contentToPrint"
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        >
          <ListItem
            title="widget_info_eur_profit"
            boldText
            borderBottom
            borderType="solid"
            showColor
            value={data?.Profit}
            fontSize="1.5rem"
            rx={{ mb: "3rem" }}
          />
          <ListItem
            title="Income"
            boldText
            borderBottom
            borderType="solid"
            showColor
            fontSize="1.5rem"
            value={data?.income}
          />

          <ListItem
            title="widget_info_eur_text_01"
            value={data?.Revenue}
            borderBottom
          />
          <ListItem
            title="widget_info_eur_text_02"
            value={data?.OI_subject_to_VAT}
            borderBottom
            hideDivider
          />
          <ListItem
            title="widget_info_eur_text_03"
            value={data?.OI_not_subject_to_VAT}
            borderBottom
          />

          <ListItem
            title="widget_info_eur_text_04"
            value={data?.Sales_tax_received}
            hideDivider
          />
          <ListItem
            title="widget_info_eur_text_05"
            value={data?.Sales_tax_refunded}
            borderBottom
          />

          <Box sx={{ mb: "3rem" }}>
            {data?.categoryArrayInflow?.length > 0 &&
              data?.categoryArrayInflow?.map((item) => (
                <ListItem
                  key={item?.uuid}
                  title={item?.title}
                  value={item?.value}
                  clickable
                  boldText
                  borderBottom
                  onClick={() => onClickTitle(item)}
                />
              ))}
          </Box>

          <ListItem
            title="Expense"
            value={data?.expense}
            boldText
            borderBottom
            showColor
            borderType="solid"
            fontSize="1.5rem"
          />
          <ListItem
            title="widget_info_eur_text_06"
            value={data?.Input_tax_paid}
            hideDivider
          />
          <ListItem
            title="widget_info_eur_text_07"
            value={data?.Vat_paid_to_tax_office}
            borderBottom
          />
          <Box sx={{ mb: "2rem" }}>
            {data?.categoryArrayOutflow?.length > 0 &&
              data?.categoryArrayOutflow?.map((item) => (
                <ListItem
                  key={item?.uuid}
                  title={item?.title}
                  value={item?.value}
                  clickable
                  boldText
                  borderBottom
                  onClick={() => onClickTitle(item)}
                />
              ))}
          </Box>
          <Stack
            className="contentToShow"
            direction={"row"}
            alignItems={"center"}
            justifyContent={"end"}
            sx={{ display: "none" }}
          >
            <Typography
              variant="body2"
              sx={{ color: theme.palette.primary.main }}
            >
              {t("Created with finban.io")}
            </Typography>
          </Stack>
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </Box>
  );
};

export default EurWidget;

const ListItem = ({
  title,
  value,
  boldText = false,
  showColor = false,
  rx = null,
  onClick = null,
  clickable = false,
  borderType = "dashed",
  borderTop = true,
  borderBottom = false,
  hideDivider = false,
  hideAmount = false,
  fontSize = "1rem",
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      {!hideDivider && !borderBottom && borderTop ? (
        <div
          style={{
            height: "1px",
            borderTop: `${
              borderType === "solid" ? "2px" : "1px"
            } ${borderType} lightGrey`,
          }}
        />
      ) : null}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: "0.5rem",
        }}
      >
        <Typography
          onClick={onClick}
          sx={{
            fontSize: fontSize,
            fontWeight: boldText ? (showColor ? 600 : 700) : 500,
            cursor: clickable && "pointer",
            textDecoration: clickable && "underline",
          }}
        >
          {t(title)}
        </Typography>
        {!hideAmount ? (
          <Typography
            sx={{
              fontSize: fontSize,
              fontWeight: boldText ? 700 : 500,
              color: showColor
                ? value >= 0
                  ? theme.palette.color.green[500]
                  : theme.palette.color.red[500]
                : theme.palette.color.black,
            }}
          >
            {formatAmount({
              amount: String(parseFloat(value ?? 0).toFixed(2)),
            })}
          </Typography>
        ) : null}
      </Box>
      {!hideDivider && borderBottom ? (
        <div
          style={{
            height: "1px",
            borderBottom: `${
              borderType === "solid" ? "2px" : "1px"
            } ${borderType} lightGrey`,
          }}
        />
      ) : null}
      <Box sx={rx}></Box>
    </>
  );
};

const LogicFunctions = ({ setData, setIsFetching, setLoading, widget }) => {
  //redux
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);

  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === dataset
    );
  }, [_selectionCategories, dataset, use_global_categories]);

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data",
        widget_uuid: widget?.uuid,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: widget?.start_date,
        to_payment_date: widget?.end_date,
        multiStatesIds: widget?.states,
        multiCategoryIds: FilteredWidgetCategories,
        multiScenarioIds: widget?.scenarios,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: false,
    priority: 3,
  });

  const FilteredWidgetCategories = useMemo(() => {
    let array = [];
    widget?.categories?.forEach((cat) => {
      getAllSubCategoryIds({ array, id: cat });
    });
    return array;
  }, [widget?.categories]);

  //lifeCycle method
  useDebounce(
    () => {
      WidgetData.refetch();
    },
    500,
    [
      refreshData,
      widget?.states,
      widget?.scenarios,
      FilteredWidgetCategories,
      widget?.start_date,
      widget?.end_date,
    ],
    true
  );

  useEffect(() => {
    setIsFetching(WidgetData?.isFetching);
  }, [WidgetData?.isFetching]);

  useEffect(() => {
    if (!WidgetData?.isFetching && WidgetData?.data) {
      generateBalanceSheet();
    }
  }, [WidgetData?.data, WidgetData?.isFetching]);

  const generateBalanceSheet = () => {
    setLoading(true);
    const bookedData = (WidgetData?.data?.results || [])?.filter((item) =>
      item?.category
        ? !Constant?.excludeCategoryTypes?.includes(
            selectionCategoriesByID?.[item?.category]?.[0]?.category_type
          )
        : true
    );
    // let bookedData = _data?.filter((o1) => o1.state === "Booked");
    if (bookedData?.length > 0) {
      let privateExpenseCategoryIds = selectionCategories
        ?.filter((o1) => o1.type === 2 && o1.type_of_cost === "private")
        ?.map((o1) => o1.value);
      let taxExpenseCategories = selectionCategories?.filter(
        (o1) => o1.type_of_cost === "tax"
      );
      let taxExpenseCategoryIds = taxExpenseCategories?.map((o1) => o1.value);

      let OI_subject_to_VAT = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            item.inflow_count > 0 && item.vat_inflow ? item?.net_inflow : 0
          ),
        0
      );
      let OI_not_subject_to_VAT = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            item.inflow_count > 0 && !item.vat_inflow ? item?.net_inflow : 0
          ),
        0
      );

      let income = bookedData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.inflow ?? 0),
        0
      );
      let expense = bookedData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
        0
      );
      let Revenue = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category ===
                "revenue"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );
      let total_OI = bookedData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.inflow ?? 0),
        0
      );
      let total_OE = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            !privateExpenseCategoryIds?.includes(item?.category)
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );
      let Input_tax_paid = bookedData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.vat_outflow ?? 0),
        0
      );
      let Sales_tax_received = bookedData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.vat_inflow ?? 0),
        0
      );
      let Sales_tax_refunded = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category ===
                "tax"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );
      let Vat_paid_to_tax_office = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category ===
                "tax"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let Cost = bookedData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.net_outflow ?? 0),
        0
      );

      let gropedMonthlyDataByCategory = _.groupBy(bookedData, ({ category }) =>
        category ? category : "uncategorized"
      );
      let categoriesDataInflow = [
        { value: "uncategorized", label: "Uncategorized Inflow", type: 1 },
        ...selectionCategories?.filter((o1) => o1.type === 1),
      ];
      let categoriesDataOutflow = [
        {
          value: "uncategorized",
          label: "Uncategorized Outflow",
          type: 2,
        },
        ...selectionCategories?.filter((o1) => o1.type === 2),
      ];
      let categoryArrayInflow = [];
      categoriesDataInflow?.forEach((o1) => {
        let data = gropedMonthlyDataByCategory[o1.value];
        if (data?.length && !taxExpenseCategoryIds?.includes(o1?.value)) {
          if (o1.value === "uncategorized") {
            let check = data?.find((item) => item.inflow_count > 0);
            if (check) {
              categoryArrayInflow.push({
                uuid: o1.value,
                title: o1.label,
                type: o1.type,
                value: data?.reduce(
                  (total, item) =>
                    parseFloat(total) + parseFloat(item?.inflow ?? 0),
                  0
                ),
              });
            }
          } else {
            categoryArrayInflow.push({
              uuid: o1.value,
              title: o1.label,
              type: o1.type,
              value: data?.reduce(
                (total, item) =>
                  parseFloat(total) + parseFloat(item?.value ?? 0),
                0
              ),
            });
          }
        }
      });
      let categoryArrayOutflow = [];
      categoriesDataOutflow?.forEach((o1) => {
        let data = gropedMonthlyDataByCategory[o1.value];
        if (data?.length && !taxExpenseCategoryIds?.includes(o1?.value)) {
          if (o1.value === "uncategorized") {
            let check = data?.find((item) => item.outflow_count > 0);
            if (check) {
              categoryArrayOutflow.push({
                uuid: o1.value,
                title: o1.label,
                type: o1.type,
                value: data?.reduce(
                  (total, item) =>
                    parseFloat(total) + parseFloat(item?.outflow ?? 0),
                  0
                ),
              });
            }
          } else {
            categoryArrayOutflow.push({
              uuid: o1.value,
              title: o1.label,
              type: o1.type,
              value: data?.reduce(
                (total, item) =>
                  parseFloat(total) + parseFloat(item?.value ?? 0),
                0
              ),
            });
          }
        }
      });

      setData((prev) => ({
        ...(prev || {}),
        OI_subject_to_VAT: OI_subject_to_VAT,
        OI_not_subject_to_VAT: OI_not_subject_to_VAT,
        Revenue: Revenue,
        income: income,
        expense: expense,
        Profit: income + expense,
        Sales_tax_received: Sales_tax_received,
        Sales_tax_refunded: Sales_tax_refunded,
        Input_tax_paid: Input_tax_paid,
        Vat_paid_to_tax_office: Vat_paid_to_tax_office,
        total_OI: total_OI,
        total_OE: total_OE,
        Cost: Cost,
        categoryArrayInflow: categoryArrayInflow,
        categoryArrayOutflow: categoryArrayOutflow,
      }));
    } else {
      setData(null);
    }
    setLoading(false);
  };

  return <></>;
};
