import { useEffect, useMemo, useRef, useState } from "react";
import { eachMonthOfInterval, format } from "date-fns";
import { ClickAwayListener, Popper } from "@mui/base";
import { useQuery } from "@tanstack/react-query";
import { Box, Fade, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getTailwindColor,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import StackCharts from "../../../../components/Charts/StackCharts";
import QuickLinkView from "./Component/QuickLinkView";
import { Constant } from "../../../../Helper";
import EmptyView from "./Component/EmptyView";

const open_state = ["Lead - potential"];
const closed_state = ["Lead - won"];
const link_state = ["Lead - potential", "Lead - won", "Others"];
const expanded_link_state = [
  "",
  "Lead - lost",
  "All",
  "Lead - potential",
  "Lead - won",
  "Close",
];
const state_color = Constant?.defaultState?.["Lead - won"]?.color || "slate";

const LeadStatusWidget = ({ widgetType, widget }) => {
  //state
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "1rem",
      }}
    >
      <LogicFunctions
        widget={widget}
        setData={setData}
        setRawData={setRawData}
        setLoading={setLoading}
        setIsFetching={setIsFetching}
        widgetType={widgetType}
      />
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data?.length > 0 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "80%",
            }}
          >
            <StackCharts
              data={data}
              hideDefault
              barsData={[
                {
                  key: "lead_status_paid_tooltip_label",
                  dataKey: "closed",
                  stackId: "a",
                  fill: getTailwindColor(state_color, 500),
                  color: state_color,
                  shade: 500,
                },
                {
                  key: "lead_status_open_tooltip_label",
                  dataKey: "open",
                  stackId: "a",
                  fill: getTailwindColor(state_color, 200),
                  color: state_color,
                  shade: 200,
                },
              ]}
            />
          </div>
          <QuickLinks rawData={rawData} widget={widget} />
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default LeadStatusWidget;

const LogicFunctions = ({
  setData,
  setLoading,
  setIsFetching,
  setRawData,
  widget,
}) => {
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const multiStatesIds = useMemo(() => {
    let array = [];
    Constant.leadState.forEach((item) => {
      if (stateByTitle?.[item]?.[0]?.uuid) {
        array.push(stateByTitle?.[item]?.[0]?.uuid);
      }
    });
    return array;
  }, [stateByTitle]);

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "lead_status_over_time",
        widget: {
          start_date: widget?.start_date,
          end_date: widget?.end_date,
          scenarios: widget?.scenarios,
        },
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: widget?.start_date,
        to_payment_date: widget?.end_date,
        multiScenarioIds: widget?.scenarios,
        group_by: ["state", "category"],
        multiStatesIds,
      };
      if (!dataSetData?.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    priority: 3,
    enabled:
      !!dataSetData?.uuid &&
      !!widget?.end_date &&
      !!widget?.start_date &&
      multiStatesIds?.length > 0,
  });

  const isFetching =
    WidgetData.isFetching || WidgetData.isLoading || !WidgetData.isSuccess;

  //lifeCycle method
  useEffect(() => {
    setIsFetching(isFetching);
    if (isFetching) {
      setLoading(true);
    }
  }, [isFetching]);

  //life cycle method
  useDebounce(
    () => {
      if (
        widget?.start_date &&
        widget?.end_date &&
        WidgetData?.data &&
        !isFetching
      ) {
        let dummyData = [];
        setLoading(true);
        let formateString = "yyyy-MM-dd";

        formateString = "MMM-yyyy";
        let dates = eachMonthOfInterval({
          start: new Date(widget?.start_date),
          end: new Date(widget?.end_date),
        });

        let periodData = _.groupBy(
          WidgetData?.data?.results || [],
          ({ month }) => format(new Date(month), formateString)
        );
        let obj = {};
        if (WidgetData?.data?.results?.length > 0) {
          dates?.forEach((element) => {
            let date = format(new Date(element), formateString);
            obj = {
              due_date: date,
            };

            const item = periodData?.[date] ?? [];

            let open = item
              ?.filter(
                (o1) =>
                  o1?.state &&
                  open_state?.includes(o1?.state) &&
                  (o1?.category
                    ? !Constant?.excludeCategoryTypes?.includes(
                        selectionCategoriesByID?.[o1?.category]?.[0]
                          ?.category_type
                      )
                    : true)
              )
              ?.reduce(
                (total, entry) =>
                  parseFloat(total) +
                  parseFloat(entry?.outflow ?? 0) +
                  parseFloat(entry?.inflow ?? 0),
                0
              );
            obj.open = open;

            let closed = item
              ?.filter(
                (o1) =>
                  o1?.state &&
                  closed_state?.includes(o1?.state) &&
                  (o1?.category
                    ? !Constant?.excludeCategoryTypes?.includes(
                        selectionCategoriesByID?.[o1?.category]?.[0]
                          ?.category_type
                      )
                    : true)
              )
              ?.reduce(
                (total, entry) =>
                  parseFloat(total) +
                  parseFloat(entry?.outflow ?? 0) +
                  parseFloat(entry?.inflow ?? 0),
                0
              );
            obj.closed = closed;
            dummyData.push(obj);
          });
        }
        setTimeout(() => {
          setLoading(false);
          setData(dummyData);
          setRawData([...(WidgetData?.data?.results || [])]);
        }, 250);
      }
    },
    300,
    [widget?.start_date, widget?.end_date, WidgetData?.data],
    true
  );

  return null;
};

const QuickLinks = ({ rawData, widget }) => {
  const overlayRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  //functions
  const onClickOthers = () => setAnchorEl(overlayRef.current);

  const onClickAway = () => setAnchorEl(null);

  return (
    <span
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        right: "1.5rem",
        bottom: "1.5rem",
      }}
    >
      <Box
        ref={overlayRef}
        sx={{
          pt: "0.75rem",
          position: "relative",
          "& .base-Popper-root": {
            transform: "translate3d(16px, 32px, 0px) !important",
            zIndex: 1,
          },
        }}
      >
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement={"top-end"}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={onClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  elevation={1}
                  sx={{
                    width: "32rem",
                    pt: "1rem",
                    px: "1rem",
                    mb: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  {expanded_link_state?.map((item) => (
                    <QuickLinkView
                      key={item}
                      title={item}
                      state_color={state_color}
                      moreState={Constant.leadState}
                      data={rawData}
                      onClickClose={onClickAway}
                      start_date={widget?.start_date}
                      end_date={widget?.end_date}
                      sx={{
                        mb: "1rem",
                      }}
                    />
                  ))}
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
        {link_state?.map((item) => (
          <QuickLinkView
            key={item}
            title={item}
            start_date={widget?.start_date}
            end_date={widget?.end_date}
            onClickOthers={onClickOthers}
            moreState={Constant.leadState}
            data={rawData}
          />
        ))}
      </Box>
    </span>
  );
};
