import TitleIcon from "@mui/icons-material/Title";
import { useTranslation } from "react-i18next";

import TitleInput from "../Overlay/TitleInput";
import { Color } from "../../Helper";

const TitleView = ({
  cardItem,
  onFocus,
  error,
  disabled,
  setCardItem,
  setError,
  modalType,
  updateFlag,
  showError,
  tab,
  ...props
}) => {
  const { t } = useTranslation();
  const onFocusInput = () => {
    global.isInputFocused = true;

    if (onFocus) onFocus();
  };

  const onBlurInput = () => {
    onBlurText();
    setTimeout(() => {
      global.isInputFocused = false;
    }, 500);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setCardItem({ ...cardItem, [name]: value });
    let errorText = null;
    if (name === "title" && value.trim() === "") {
      errorText = "empty_title_error";
    }
    setError({ ...error, [name]: errorText });
    if (errorText) {
      return;
    }
  };

  const onBlurText = () => {
    const obj = {
      title: cardItem.title,
      note: cardItem.note,
    };
    if (cardItem?.title?.trim() === "") {
      setError({ ...error, title: "empty_title_error" });
      return;
    }
    updateFlag(obj);
  };

  return (
    <div style={{ display: "flex", marginTop: "1.5rem", width: "100%" }}>
      <TitleIcon
        sx={{
          color: Color.theme.blueGrey[700],
          ml: "0.2rem",
          mr: "0.8rem",
          mt: cardItem?.title ? "0.25rem" : "0.75rem",
          fontSize: "1.4rem",
        }}
      />
      <TitleInput
        value={cardItem?.title || ""}
        name="title"
        hideTitle
        onChange={handleEditChange}
        error={
          Boolean(error?.title) ||
          (tab === "employee" &&
            showError &&
            (!cardItem?.title || cardItem?.title?.trim() === ""))
        }
        disabled={disabled}
        likeGoogle
        variant="filled"
        label={t("Title")}
        placeholder={t("Add title")}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        {...props}
        fontSize={"0.9rem"}
        sx={{
          width: "100%",
        }}
      />
    </div>
  );
};

export default TitleView;
