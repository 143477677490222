import {
  MdOutlineKeyboardCommandKey,
  MdOutlineKeyboardReturn,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { ImShift } from "react-icons/im";

/**
 * @typedef {Object} KeyboardSortCutKeyProps
 * @property {string[]} keys
 * @property {{ palette: { color: { grey: string[], white: string, slate: string[] } }, borderRadius: { main: string } }} theme
 * @property {() => void} action
 * @property {(event: KeyboardEvent) => boolean} allowKeysToTrigger
 * @property {React.CSSProperties|undefined} [style]
 * @property {React.CSSProperties|undefined} [keyStyle]
 * @property {boolean} [hide]
 */
// aria-hidden="true": Makes inactive items invisible to screen readers.
// tabIndex={-1}: Prevents inactive items from being keyboard-focusable.
// visibility: hidden: Hides visually but keeps the element in the DOM.
const KeyboardSortCutKey = ({
  keys = [],
  allowKeysToTrigger,
  theme,
  action,
  style = {},
  keyStyle = {},
  hide = false,
}) => {
  const keyRef = useRef(null);
  const locale = useSelector((state) => state.settingsSlice?.profile?.locale);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!hide && (!keyRef.current || !isElementIntractable(keyRef.current))) {
        return;
      }

      const metaKey = {
        CTRL: event.metaKey || event.ctrlKey, // Meta (Mac) or Ctrl (Windows/Linux)
        SHIFT: event.shiftKey,
        "⌃": event.altKey,
      };
      const mappedKey = {
        ...metaKey,
        "↑": event.key === "ArrowUp",
        "↓": event.key === "ArrowDown",
        "←": event.key === "ArrowLeft",
        "→": event.key === "ArrowRight",
        Enter: event.key === "Enter",
        Esc: event.key === "Escape",
      };
      const isInputField =
        ["input", "textarea", "select"].includes(
          event.target.tagName.toLowerCase()
        ) ||
        event.target.isContentEditable ||
        event.target.closest("iframe");

      let isCombinationMatch = false;

      if (keys?.length === 3) {
        if (
          mappedKey?.[keys[0]] &&
          mappedKey?.[keys[1]] &&
          event.key?.toLowerCase() === keys[2]?.toLowerCase()
        ) {
          isCombinationMatch = true;
        }
      } else if (keys?.length === 2) {
        if (
          mappedKey?.[keys[0]] &&
          Object.keys(metaKey)?.every((key) =>
            keys?.[0] === key ? mappedKey?.[key] : !mappedKey?.[key]
          ) &&
          event.key?.toLowerCase() === keys[1]?.toLowerCase()
        ) {
          isCombinationMatch = true;
        }
      } else {
        if (
          !event.metaKey &&
          !event.ctrlKey &&
          !event.altKey &&
          !event.shiftKey &&
          event.key?.toLowerCase() === keys?.[0]?.toLowerCase()
        ) {
          isCombinationMatch = true;
        }
      }

      if (
        isCombinationMatch &&
        (allowKeysToTrigger && allowKeysToTrigger(event) ? true : !isInputField)
      ) {
        event.preventDefault();
        action(event);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [keys, action, allowKeysToTrigger, hide]);
  if (hide) return null;
  return (
    <kbd
      ref={keyRef}
      style={{
        padding: "3px 6px",
        border: `1px solid ${theme.palette.color.grey[300]}`,
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        color: theme.palette.color.slate?.[500],
        fontFamily: "monospace",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
        height: "1.375rem",
        ...style,
      }}
    >
      {keys?.map((key, index) => {
        const isMac = navigator.userAgentData
          ? navigator.userAgentData.platform === "macOS"
          : navigator.userAgent.toLowerCase().includes("mac");
        const isGerman = locale === "de_DE";
        const isMetaKey = keys?.length > 1 && index === 0;
        let _key = key;
        if (isMetaKey) {
          if (isMac) {
            _key = (
              <MdOutlineKeyboardCommandKey
                style={{ fontSize: "inherit", color: "inherit" }}
              />
            );
          } else if (isGerman) {
            _key = "Strg";
          } else {
            _key = "Ctrl";
          }
        }
        if (key === "Enter") {
          _key = (
            <MdOutlineKeyboardReturn
              style={{ fontSize: "inherit", color: "inherit" }}
            />
          );
        }
        if (key === "SHIFT") {
          _key = <ImShift style={{ fontSize: "inherit", color: "inherit" }} />;
        }
        return (
          <span
            key={key}
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
              fontSize: "0.8rem",
              lineHeight: "normal",
              textTransform: "capitalize",
              fontWeight: 500,
              marginLeft:
                keys?.length > 1 && index === keys?.length - 1 ? "4px" : 0,
              ...keyStyle,
            }}
          >
            {_key}
          </span>
        );
      })}
    </kbd>
  );
};

export default KeyboardSortCutKey;
const isElementIntractable = (element) => {
  if (!element) return false;

  // Check visibility in DOM
  const isVisible =
    !!element.offsetParent &&
    getComputedStyle(element).visibility !== "hidden" &&
    getComputedStyle(element).display !== "none" &&
    getComputedStyle(element).opacity !== "0" &&
    getComputedStyle(element).pointerEvents !== "none";

  if (!isVisible) return false;

  // Check if element is in viewport and not hidden by overlays
  const rect = element.getBoundingClientRect();
  const inViewport =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  // Check if the element is overlapped by another element
  const topElement = document.elementFromPoint(
    rect.left + rect.width / 2,
    rect.top + rect.height / 2
  );

  const isOnTop = topElement === element || element.contains(topElement);

  return isVisible && inViewport && isOnTop;
};
