import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, keyframes, Tooltip, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import * as React from "react";

import { Color } from "../../Helper";

const rotateOpen = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
`;
const rotateClose = keyframes`
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;
const CustomPopover = React.forwardRef((props, ref) => {
  let {
    title,
    children,
    width,
    borderRadius,
    icon = false,
    justifyContent,
    color,
    border,
    titleIcon = null,
    iconSize,
    ml,
    mr,
    paperStyle = null,
    iconStyle = null,
    buttonStyle = null,
    hideDownIcon = false,
    showRight = false,
    type = "default",
    anchorEl = null,
    button = null,
    onClose,
    onClick,
    onDoubleClick,
    tooltip = "",
    hoverBG = Color.navMainHoverBg,
    PaperProps = undefined,
    sxStyle = null,
    iconView = (
      <AddIcon
        sx={{
          backgroundColor: Color.white,
          color: Color.themeColor,
          fontSize: "1.3rem",
          borderRadius: 1,
          cursor: "pointer",
        }}
      />
    ),
  } = props;

  if (type === "anchor") {
    return (
      <>
        {button ? (
          <Tooltip title={tooltip} disableFocusListener placement="top">
            <Box
              ref={ref}
              onClick={onClick ? onClick : undefined}
              onDoubleClick={onDoubleClick}
              sx={buttonStyle}
            >
              {button}
            </Box>
          </Tooltip>
        ) : icon ? (
          <Tooltip title={tooltip} disableFocusListener placement="top">
            <Box
              ref={ref}
              onClick={onClick ? onClick : undefined}
              sx={{
                p: "0.75rem",
                display: "inline-flex",
                alignItem: "center",
                JustifyContent: "center",
                "&:hover": {
                  borderRadius: 10,
                  opacity: 1,
                  backgroundColor: hoverBG,
                },
                ...iconStyle,
              }}
            >
              {iconView}
            </Box>
          </Tooltip>
        ) : (
          <Tooltip title={tooltip} disableFocusListener placement="top">
            <Button
              ref={ref}
              variant="contained"
              disableElevation
              onClick={onClick}
              sx={{
                display: "flex",
                alignItem: "center",
                cursor: "pointer",
                borderRadius: borderRadius ? borderRadius : "0.875rem",
                border: border
                  ? border
                  : `1px solid ${Color.autoCompleteBorder}`,
                p: "0.25rem",
                pl: justifyContent ? 1 : null,
                width: width ? width : "5rem",
                justifyContent: justifyContent ? justifyContent : "flex-end",
                fontWeight: 500,
                fontSize: "0.8rem",
                color: color ? color : Color.greyText,
                height: "max-content",
                textTransform: "capitalize",
                backgroundColor: Color.hederEleBg,
                "&:hover": {
                  backgroundColor: Color.white,
                },
                ...buttonStyle,
              }}
            >
              {titleIcon}
              {title}
              {!hideDownIcon && (
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: iconSize ? iconSize : "1.1rem",
                    transform: !Boolean(anchorEl)
                      ? "rotate(0deg)"
                      : "rotate(-180deg)",
                    animation: `${
                      !Boolean(anchorEl) ? rotateClose : rotateOpen
                    } 300ms linear`,
                  }}
                />
              )}
            </Button>
          </Tooltip>
        )}
        <Popover
          id={Boolean(anchorEl) ? "simple-popover" : undefined}
          open={Boolean(anchorEl)}
          container={document.body}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: showRight ? "left" : "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: showRight ? "left" : "right",
          }}
          PaperProps={PaperProps}
          sx={{
            zIndex: 1303,
            '& > *[tabindex="-1"]:not([role="presentation"])': {
              outline: "none !important",
            },
            '& [role="dialog"]': {
              outline: "none !important",
            },
            ...sxStyle,
            "& .MuiPopover-paper": {
              mt: "0.5rem",
              position: "fixed",
              ml: ml ? ml : 0,
              mr: mr ? mr : 0,
              padding: "0.25rem",
              ...paperStyle,
            },
          }}
        >
          {" "}
          {children}
        </Popover>
      </>
    );
  } else {
    return (
      <PopupState
        variant="popper"
        ref={ref}
        id="demo-mutiple-checkbox"
        popupId="demo-popup-popover"
      >
        {(popupState) => {
          return (
            <Box>
              {icon ? (
                <Box
                  {...bindTrigger(popupState)}
                  sx={{
                    p: "0.75rem",
                    display: "inline-flex",
                    alignItem: "center",
                    JustifyContent: "center",
                    "&:hover": {
                      borderRadius: 10,
                      opacity: 1,
                      backgroundColor: Color.navMainHoverBg,
                    },
                  }}
                >
                  {iconView}
                </Box>
              ) : (
                <Button
                  ref={ref}
                  variant="contained"
                  disableElevation
                  {...bindTrigger(popupState)}
                  sx={{
                    display: "flex",
                    alignItem: "center",
                    cursor: "pointer",
                    borderRadius: borderRadius ? borderRadius : 2,
                    border: border
                      ? border
                      : `1px solid ${Color.autoCompleteBorder}`,
                    p: "0.25rem",
                    pl: justifyContent ? 1 : null,
                    width: width ? width : "5rem",
                    justifyContent: justifyContent
                      ? justifyContent
                      : "flex-end",
                    fontWeight: 500,
                    fontSize: "0.8rem",
                    color: color ? color : Color.greyText,
                    height: "max-content",
                    textTransform: "capitalize",
                    backgroundColor: Color.white,
                    "&:hover": {
                      backgroundColor: Color.white,
                    },
                    ...buttonStyle,
                  }}
                >
                  {titleIcon}
                  {title}
                  {!hideDownIcon && (
                    <KeyboardArrowDownIcon
                      sx={{
                        fontSize: iconSize ? iconSize : "1.1rem",
                        transform: !popupState?.isOpen
                          ? "rotate(0deg)"
                          : "rotate(-180deg)",
                        animation: `${
                          !popupState?.isOpen ? rotateClose : rotateOpen
                        } 300ms linear`,
                      }}
                    />
                  )}
                </Button>
              )}
              <Popover
                {...bindPopover(popupState)}
                container={document.body}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: showRight ? "left" : "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: showRight ? "left" : "right",
                }}
                sx={{
                  zIndex: 1303,
                  '& > *[tabindex="-1"]:not([role="presentation"])': {
                    outline: "none !important",
                  },
                  '& [role="dialog"]': {
                    outline: "none !important",
                  },
                  "& .MuiPopover-paper": {
                    mt: "0.5rem",
                    ml: ml ? ml : 0,
                    mr: mr ? mr : 0,
                    padding: "0.25rem",
                    ...paperStyle,
                  },
                }}
              >
                {children}
              </Popover>
            </Box>
          );
        }}
      </PopupState>
    );
  }
});
export default CustomPopover;
