import Backdrop from "@mui/material/Backdrop";
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { PropTypes } from "prop-types";

export default function Loader({ loading }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (loading) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [loading]);

  return (
    <Modal
      open={open}
      closeAfterTransition
      slotProps={{
        backdrop: {
          open: open,
          component: Backdrop,
          timeout: 500,
        },
      }}
      sx={{
        zIndex: 9998,
      }}
    >
      <></>
    </Modal>
  );
}
Backdrop.defaultProps = {
  open: false,
};
Backdrop.propTypes = {
  open: PropTypes.bool.isRequired,
};
