import {
  startOfMonth,
  startOfYear,
  endOfMonth,
  addMonths,
  endOfYear,
  subMonths,
  format,
} from "date-fns";
import {
  TbChartAreaLineFilled,
  TbBuildingBank,
  TbChartBubble,
  TbFileInvoice,
  TbList,
} from "react-icons/tb";
import { IoReaderOutline, IoPeopleCircle, IoPeople } from "react-icons/io5";
import { GrDocumentStore, GrMoney } from "react-icons/gr";
import { LuSquareChartGantt } from "react-icons/lu";
import { RiFolderChartLine } from "react-icons/ri";
import { FaRegPaperPlane } from "react-icons/fa";
import { BsFillTagsFill } from "react-icons/bs";
import { BiBuildings } from "react-icons/bi";
import _ from "underscore";

import theme from "../theme";
import Images from "./Image";

const CurrencyPrefix = {
  EUR: "€", //1
  USD: "$", //2
  GBP: "£", //3
  CHF: "₣", //4
};
const transaction_type_options = {
  1: {
    uuid: 1,
    title: "Income",
  },
  2: { uuid: 2, title: "Expense" },
};
const TransactionRuleLevelOptions = [
  {
    label: "level_radio_title_1",
    des: "level_radio_des_1",
    value: 0,
    // value: "All",
  },
  {
    label: "level_radio_title_2",
    des: "level_radio_des_2",
    value: 1,
    // value: "Organization",
  },
  {
    label: "level_radio_title_3",
    des: "level_radio_des_3",
    value: 2,
    // value: "Integration",
  },
];
const ContactType = {
  0: "contact_type_other",
  1: "contact_type_customer",
  2: "contact_type_supplier",
  3: "contact_type_employee",
};
const contact_types_options = [
  {
    value: 0,
    display_name: "Other",
  },
  {
    value: 1,
    display_name: "Customer",
  },
  {
    value: 2,
    display_name: "Supplier",
  },
  {
    value: 3,
    display_name: "Employee",
  },
];
const contact_types_options_by_value = _.groupBy(
  contact_types_options,
  "value"
);
const IntegrationsSource = {
  1: "Type_Manual",
  2: "Type_Import",
  11: "Type_Plaid_API",
  12: "Type_FinAPI",
  19: "Type_External_Transaction_System",
};

const DefaultSource = [
  "Option_Planning",
  "Option_Api",
  "Option_Import",
  "Option_External",
];
const StateSource = [
  {
    uuid: 1,
    title: "All",
  },
  {
    uuid: 2,
    title: "Only Open",
  },
];
const IntegrationsDataTypesOptions = [
  {
    icon: <GrMoney />,
    uuid: "deal",
    title: "integration_data_type_deal",
  },
  {
    icon: <GrDocumentStore />,
    uuid: "offer",
    title: "integration_data_type_offer",
  },
  {
    icon: <TbFileInvoice />,
    uuid: "invoice",
    title: "integration_data_type_invoice",
  },
  {
    icon: <TbFileInvoice />,
    uuid: "purchase_invoice",
    title: "integration_data_type_purchase_invoice",
  },
  {
    icon: <GrDocumentStore />,
    uuid: "quotation",
    title: "integration_data_type_quotation",
  },
];
const IntegrationsDataTypes = {
  Lexoffice: IntegrationsDataTypesOptions?.filter((o1) =>
    ["invoice", "quotation"].includes(o1?.uuid)
  ),
  SevDesk: IntegrationsDataTypesOptions?.filter((o1) =>
    ["offer", "invoice"].includes(o1?.uuid)
  ),
  Moco: IntegrationsDataTypesOptions?.filter((o1) =>
    ["deal", "offer", "invoice", "purchase_invoice"].includes(o1?.uuid)
  ),
  BuchhaltungsButler: IntegrationsDataTypesOptions?.filter((o1) =>
    ["offer", "invoice"].includes(o1?.uuid)
  ),
  easybill: IntegrationsDataTypesOptions?.filter((o1) =>
    ["deal", "offer", "invoice", "purchase_invoice"].includes(o1?.uuid)
  ),
  default: IntegrationsDataTypesOptions,
};
const DefaultSourceByName = {
  Option_Planning: 1, //manual
  Option_Api: 2, //bank
  Option_Import: 3,
  Option_External: 4, //integrations
};
const FinbanMappingFields = {
  id: "",
  title: "",
  gross_value: "",
  due_date: "",
  state: "",
  note: "",
  tax: "",
  tax_value: "",
  category: "",
  scenario: "",
  cost_unit: "",
};

const BANKING = [
  {
    id: 1,
    type: 12,
    integration_type: 1,
    name: "FinApi_Account",
    title: "FinApi_Account_title",
    des: "FinApi_Account_des",
    state: "FinApi_Account_state",
    img: Images.transparent?.TinkTransparentLogo2,

    imgStyle: {
      width: "4rem",
      height: "4rem",
    },
    icon: <BiBuildings />,
  },
];
const ACCOUNTING = [
  {
    id: 1,
    type: 19,
    integration_type: 2,
    name: "Lexoffice",
    title: "Lexware Office",
    des: "Lexoffice_des",
    state: "Lexoffice_state",
    img: Images.transparent?.LexOfficeTransparentLogo,
    imgStyle: {
      width: "3rem",
      height: "3rem",
    },
    link: "https://www.lexoffice.de/",
  },
  {
    id: 2,
    type: 19,
    integration_type: 2,
    name: "SevDesk",
    title: "SevDesk",
    des: "SevDesk_des",
    state: "SevDesk_state",
    img: Images.transparent?.SevDeskTransparentLogo,
  },
  {
    id: 3,
    type: 19,
    integration_type: 2,
    name: "BuchhaltungsButler",
    title: "BuchhaltungsButler",
    des: "BuchhaltungsButler_des",
    state: "BuchhaltungsButler_state",
    img: Images.transparent?.BuchhaltungsButlerTransparentLogo,
    imgStyle: {
      width: "3rem",
      height: "3rem",
    },
    link: "https://app.buchhaltungsbutler.de",
  },

  {
    id: 4,
    type: 19,
    integration_type: 2,
    name: "Moco",
    title: "MOCO",
    des: "Moco_des",
    state: "Moco_state",
    img: Images.transparent?.MocoTransparentLogo,
    link: "https://www.mocoapp.com/",
  },
  {
    id: 5,
    type: 19,
    integration_type: 2,
    name: "easybill",
    title: "easybill",
    des: "easybill_des",
    state: "easybill_state",
    img: Images.transparent?.easybillTransparentLogo,
    link: "https://www.easybill.de/",
  },
  {
    id: 6,
    type: 19,
    integration_type: 2,
    name: "GetMyInvoices",
    title: "GetMyInvoices",
    des: "getMyInvoices_des",
    state: "getMyInvoices_state",
    img: Images.transparent?.getMyInvoicesTransparentLogo,
    imgStyle: {
      width: "9rem",
    },
  },
  {
    id: 7,
    type: 19,
    integration_type: 2,
    name: "Wave",
    title: "Wave",
    des: "Wave_des",
    state: "Wave_state",
    img: Images.transparent?.WaveTransparentLogo,
    disabled: true,
  },
  {
    id: 8,
    type: 19,
    integration_type: 2,
    name: "Billomat",
    title: "Billomat",
    des: "Billomat_des",
    state: "Billomat_state",
    img: Images.transparent?.BillomatTransparentLogo,
    disabled: true,
  },
  {
    id: 9,
    type: 19,
    integration_type: 2,
    name: "Fastbill",
    title: "Fastbill",
    des: "Fastbill_des",
    state: "Fastbill_state",
    img: Images.transparent?.FastbillTransparentLogo,
    disabled: true,
  },
  {
    id: 10,
    type: 19,
    integration_type: 2,
    name: "paperwork",
    title: "paperwork",
    des: "paperwork_des",
    state: "paperwork_state",
    img: Images.transparent?.PaperWorkTransparentLogo,
    imgStyle: {
      width: "6rem",
    },
    disabled: true,
  },
  {
    id: 11,
    type: 19,
    integration_type: 2,
    name: "orgaMAX",
    title: "orgaMAX",
    des: "orgaMAX_des",
    state: "orgaMAX_state",
    img: Images.transparent?.OrgamaxTransparentLogo,
    disabled: true,
  },
  {
    id: 12,
    type: 19,
    integration_type: 2,
    name: "Weclapp",
    title: "Weclapp",
    des: "Weclapp_des",
    state: "Weclapp_state",
    img: Images.transparent?.WeclappTransparentLogo,
    disabled: true,
  },
  {
    id: 13,
    type: 19,
    integration_type: 2,
    name: "Sage",
    title: "Sage",
    des: "Sage_des",
    state: "Sage_state",
    img: Images.transparent?.SageTransparentLogo,
    disabled: true,

    imgStyle: {
      height: "3rem",
    },
  },
  {
    id: 14,
    type: 19,
    integration_type: 2,
    name: "Xentral",
    title: "Xentral",
    des: "Xentral_des",
    state: "Xentral_state",
    img: Images.transparent?.XentralTransparentLogo,
    disabled: true,
  },
  {
    id: 15,
    type: 19,
    integration_type: 2,
    name: "BILLBEE",
    title: "BILLBEE",
    des: "BILLBEE_des",
    state: "BILLBEE_state",
    img: Images.transparent?.billbeeTransparentLogo,
    disabled: true,
  },
  {
    id: 16,
    type: 19,
    integration_type: 2,
    name: "NetSuite",
    title: "NetSuite",
    des: "NetSuite_des",
    state: "NetSuite_state",
    img: Images.transparent?.NetsuiteTransparentLogo,
    disabled: true,

    imgStyle: {
      height: "3rem",
    },
  },
  {
    id: 17,
    type: 19,
    integration_type: 2,
    name: "Xero",
    title: "Xero",
    des: "Xero_des",
    state: "Xero_state",
    img: Images.transparent?.XeroTransparentLogo,
    hide: true,
    imgStyle: {
      height: "3.5em",
    },
  },
  {
    id: 18,
    type: 19,
    integration_type: 2,
    name: "QuickBooks",
    title: "QuickBooks",
    hide: true,
    des: "QuickBooks_des",
    state: "QuickBooks_state",
    img: Images.transparent?.QuickBooksTransparentLogo,
    imgStyle: {
      height: "3rem",
    },
  },
  {
    id: 19,
    type: 19,
    integration_type: 2,
    name: "Zoho Books",
    title: "ZOHO Booking",
    des: "ZOHO Booking_des",
    state: "ZOHO Booking_state",
    hide: true,
    img: Images.transparent?.ZOHOBookingTransparentLogo,
    imgStyle: {
      height: "3rem",
    },
  },
  {
    id: 20,
    type: 19,
    integration_type: 2,
    name: "Zoho Invoice",
    title: "ZOHO Invoice",
    des: "ZOHO Invoice_des",
    hide: true,
    state: "ZOHO Invoice_state",
    img: Images.transparent?.ZOHOInvoiceTransparentLogo,
    imgStyle: {
      width: "7rem",
      height: "3rem",
    },
  },
];
const EXPENSE_AND_CARD_MANAGEMENT = [
  {
    id: 1,
    type: 1,
    integration_type: 3,
    name: "Pleo",
    title: "Pleo",
    des: "Pleo_des",
    state: "Pleo_state",
    img: Images.transparent?.PleoTransparentLogo,
    imgStyle: {
      height: "3rem",
    },
    disabled: true,
  },
  {
    id: 2,
    type: 1,
    integration_type: 3,
    name: "Pliant",
    title: "Pliant",
    des: "Pliant_des",
    state: "Pliant_state",
    img: Images.transparent?.PliantTransparentLogo,
    disabled: true,
  },
  {
    id: 3,
    type: 1,
    integration_type: 3,
    name: "moss",
    title: "moss",
    des: "moss_des",
    state: "moss_state",
    img: Images.transparent?.MossTransparentLogo,
    disabled: true,
  },
  {
    id: 4,
    type: 1,
    integration_type: 3,
    name: "Spendesk",
    title: "Spendesk",
    des: "Spendesk_des",
    state: "Spendesk_state",
    img: Images.transparent?.SpendeskTransparentLogo,
    disabled: true,
  },
];
const CRM = [
  {
    id: 1,
    type: 19,
    integration_type: 4,
    name: "Pipedrive",
    title: "Pipedrive",
    des: "Pipedrive_des",
    state: "Pipedrive_state",
    img: Images.transparent?.PipedriveTransparentLogo,
    imgStyle: {
      height: "3rem",
    },
  },
  {
    id: 2,
    type: 19,
    integration_type: 4,
    name: "HubSpot",
    title: "HubSpot",
    des: "HubSpot_des",
    state: "HubSpot_state",
    img: Images.transparent?.HubSpotTransparentLogo,
  },
  {
    id: 3,
    type: 19,
    integration_type: 4,
    name: "Attio",
    title: "Attio",
    des: "Attio_des",
    state: "Attio_state",
    img: Images.transparent?.AttioTransparentLogo,
    imgStyle: {
      height: "3rem",
    },
    disabled: true,
  },
  {
    id: 4,
    type: 19,
    integration_type: 4,
    name: "ActiveCampaign",
    title: "ActiveCampaign",
    des: "ActiveCampaign_des",
    state: "ActiveCampaign_state",
    img: Images.transparent?.ActiveCampaignTransparentLogo,
    disabled: true,
  },
  {
    id: 5,
    type: 19,
    integration_type: 4,
    name: "Close",
    title: "Close",
    des: "Close_des",
    state: "Close_state",
    img: Images.transparent?.CloseTransparentLogo,
    disabled: true,
  },
  {
    id: 6,
    type: 19,
    integration_type: 4,
    name: "HighLevel",
    title: "HighLevel",
    des: "HighLevel_des",
    state: "HighLevel_state",
    img: Images.transparent?.HighLevelTransparentLogo,
    disabled: true,
  },
  {
    id: 7,
    type: 19,
    integration_type: 4,
    name: "Insightly",
    title: "Insightly",
    des: "Insightly_des",
    state: "Insightly_state",
    img: Images.transparent?.InsightlyTransparentLogo,
    disabled: true,
  },
  {
    id: 8,
    type: 19,
    integration_type: 4,
    name: "Salesforce",
    title: "Salesforce",
    des: "Salesforce_des",
    state: "Salesforce_state",
    img: Images.transparent?.SalesforceTransparentLogo,
    disabled: true,
  },
  {
    id: 9,
    type: 19,
    integration_type: 4,
    name: "Bullhorn",
    title: "Bullhorn",
    des: "Bullhorn_des",
    state: "Bullhorn_state",
    img: Images.transparent?.BullhornTransparentLogo,
    disabled: true,
  },
  {
    id: 10,
    type: 19,
    integration_type: 4,
    name: "Outreach",
    title: "Outreach",
    des: "Outreach_des",
    state: "Outreach_state",
    img: Images.transparent?.OutreachTransparentLogo,
    imgStyle: {
      height: "3rem",
    },
    disabled: true,
  },
  {
    id: 11,
    type: 19,
    integration_type: 4,
    name: "Zoho CRM",
    title: "ZOHO CRM",
    des: "ZOHO CRM_des",
    state: "ZOHO CRM_state",
    img: Images.transparent?.ZOHOCRMTransparentLogo,
    hide: true,
  },
  {
    id: 12,
    type: 19,
    integration_type: 4,
    name: "Affinity",
    title: "Affinity",
    des: "Affinity_des",
    state: "Affinity_state",
    img: Images.transparent?.ZOHOCRMTransparentLogo,
    disabled: true,
  },
];
const E_COMMERCE = [
  {
    id: 1,
    integration_type: 5,
    type: 2,
    name: "PayPal",
    title: "PayPal",
    des: "Paypal_des",
    state: "Paypal_state",
    img: Images.transparent?.PaypalTransparentLogo,
    imgStyle: {
      height: "3rem",
    },
    hideSwitch: true,
  },
  {
    id: 2,
    integration_type: 5,
    type: 2,
    name: "Stripe",
    title: "Stripe",
    des: "Stripe_des",
    state: "Stripe_state",
    img: Images.transparent?.StripeTransparentLogo,
    disabled: true,
  },
  {
    id: 3,
    integration_type: 5,
    type: 19,
    name: "Shopify",
    title: "Shopify",
    des: "Shopify_des",
    state: "Shopify_state",
    img: Images.transparent?.ShopifyTransparentLogo,
    imgStyle: {
      height: "3rem",
    },
    hide: true,
  },
  {
    id: 4,
    integration_type: 5,
    type: 2,
    name: "mollie",
    title: "mollie",
    des: "mollie_des",
    state: "mollie_state",
    img: Images.transparent?.mollieTransparentLogo,
    disabled: true,
  },
];
const HR_SYSTEMS = [
  {
    id: 1,
    integration_type: 6,
    type: 2,
    name: "Personio",
    title: "Personio",
    des: "Personio_des",
    state: "Personio_state",
    img: Images.transparent?.PersonioTransparentLogo,
    imgStyle: {
      height: "3.5rem",
    },
    disabled: true,
  },
  // {
  //   id: 2,
  //   integration_type: 6,
  //   type: 2,
  //   name: "Share",
  //   title: "Share",
  //   des: "Share_des",
  //   state: "Share_state",
  //   img: Images.transparent?.ShareTransparentLogo,
  //   imgStyle: {
  //     height: "3.5rem",
  //   },
  //   disabled: true,
  // },
  {
    id: 3,
    integration_type: 6,
    type: 2,
    name: "Hibob",
    title: "Hibob",
    des: "Hibob_des",
    state: "Hibob_state",
    img: Images.transparent?.HibobTransparentLogo,
    disabled: true,
  },
  {
    id: 4,
    integration_type: 6,
    type: 2,
    name: "Workday",
    title: "Workday",
    des: "Workday_des",
    state: "Workday_state",
    img: Images.transparent?.WorkdayTransparentLogo,
    imgStyle: {
      height: "3.5rem",
    },
    disabled: true,
  },
];
const SPREADSHEET = [
  {
    id: 1,
    type: 19,
    integration_type: 7,
    name: "Google Sheets API",
    title: "Google Sheets",
    des: "Google_Sheets_des",
    state: "Google_Sheets_state",
    img: Images.transparent?.googleSheetsTransparentLogo,
    exampleLink:
      "https://docs.google.com/spreadsheets/d/1fbLGLaSXOi4OqKTdO5-YzWo1m7JZt_bL6oM4U8f4ecg/edit?gid=339097751#gid=339097751",
    infoLink: "https://finban.io/integrationen/google-sheet",
    imgStyle: {
      width: "3rem",
      height: "4rem",
    },
  },
  {
    id: 2,
    type: 19,
    integration_type: 7,
    hide: true,
    name: "Google Sheets API 2",
    title: "Google Sheets 2",
    des: "Google_Sheets 2_des",
    state: "Google_Sheets 2_state",
    img: Images.transparent?.googleSheetsTransparentLogo,
    exampleLink:
      "https://docs.google.com/spreadsheets/d/1fbLGLaSXOi4OqKTdO5-YzWo1m7JZt_bL6oM4U8f4ecg/edit?gid=339097751#gid=339097751",
    infoLink: "https://finban.io/integrationen/google-sheet",
    imgStyle: {
      width: "3rem",
      height: "4rem",
    },
  },
  {
    id: 3,
    type: 19,
    integration_type: 7,
    name: "Excel import",
    title: "Excel",
    des: "Excel_des",
    state: "Excel_state",
    exampleLink:
      "https://finban.io/wp-content/uploads/2024/09/finban-integration-excel-example.xlsx",
    infoLink: "https://finban.io/integrationen/excel/",
    img: Images.transparent?.ExcelTransparentLogo,
    imgStyle: {
      width: "3rem",
      height: "3rem",
    },
  },
];
const FILE_STORAGE = [
  {
    id: 1,
    type: 19,
    integration_type: 8,
    name: "Google Drive",
    title: "Google Drive",
    des: "Google Drive_des",
    state: "Google Drive_state",
    img: Images.transparent?.GoogleDriveTransparentLogo,
    disabled: true,
    imgStyle: {
      height: "3rem",
    },
  },
];
const MISC = [
  {
    id: 1,
    type: 1,
    integration_type: 9,
    name: "ManualDS",
    title: "Manual_DS_title",
    des: "Manual_DS_des",
    state: "Manual_DS_state",
    icon: <LuSquareChartGantt />,
  },
  {
    id: 2,
    type: 1,
    integration_type: 9,
    name: "Zapier",
    title: "Zapier",
    des: "Zapier_des",
    state: "Zapier_state",
    img: Images.transparent?.ZapierTransparentLogo,
    disabled: true,
  },
];
const IntegrationsOrder = [
  //BANKING
  "FinApi_Account", //1

  //ACCOUNTING
  "Lexoffice", //2
  "SevDesk", //3

  //SPREADSHEET
  "Google Sheets API", //4
  "Google Sheets API 2", //5
  "Excel import", //6

  //ACCOUNTING
  "BuchhaltungsButler", //7
  "Moco", //8
  "GetMyInvoices", //9
  "easybill", //10
  "Xero", //11
  "QuickBooks", //12
  "Zoho Books", //13
  "Zoho Invoice", //14

  //CRM
  "Pipedrive", //15
  "HubSpot", //16
  "Zoho CRM", //17

  //E_COMMERCE
  "Shopify", //18
  "PayPal", //19

  //misc
  "ManualDS", //20
];
const AllIntegrations = [
  ...BANKING, //1
  ...ACCOUNTING, //2
  ...EXPENSE_AND_CARD_MANAGEMENT, //3
  ...CRM, //4
  ...E_COMMERCE, //5
  ...HR_SYSTEMS, //6
  ...SPREADSHEET, //7
  ...FILE_STORAGE, //8
  ...MISC, //9
]?.sort((a, b) => {
  const indexA = IntegrationsOrder.indexOf(a.name);
  const indexB = IntegrationsOrder.indexOf(b.name);
  if (indexA === -1) {
    return 1; // move items not in the nameOrder array to the bottom
  } else if (indexB === -1) {
    return -1; // move items not in the nameOrder array to the bottom
  } else {
    return indexB - indexA; // sort by the order in the nameOrder array
  }
});

const IntegrationsSourceData = {
  0: AllIntegrations, //10 counts
  1: BANKING,
  2: ACCOUNTING,
  3: EXPENSE_AND_CARD_MANAGEMENT,
  4: CRM,
  5: E_COMMERCE,
  6: HR_SYSTEMS,
  7: SPREADSHEET,
  8: FILE_STORAGE,
  9: MISC,
};

const IntegrationsTypeOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "Banking" },
  { value: 2, label: "Accounting" },
  { value: 3, label: "Expense and Card management" },
  { value: 4, label: "CRM" },
  { value: 5, label: "E-Commerce" },
  { value: 6, label: "HR Systems (HRIS)" },
  { value: 7, label: "Spreadsheet" },
  { value: 8, label: "File Storage" },
  { value: 9, label: "Miscellaneous" },
];

const ReportWidgetOptions = [
  {
    uuid: 19,
    type: "eur",
    categories: [
      "General",
      "Cashflow, Income and Expenses",
      "Revenue, ARR & MRR",
    ],
    width: 6,
    height: 5,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 1,
    type: "burn-rate-12-month",
    categories: ["General", "Cashflow, Income and Expenses"],
    width: 6,
    height: 5,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 2,
    type: "expenses-12-month",
    categories: ["General", "Cashflow, Income and Expenses"],
    width: 6,
    height: 5,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 3,
    type: "income-12-month",
    categories: ["General", "Cashflow, Income and Expenses"],
    width: 6,
    height: 5,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 4,
    type: "profit-12-month",
    categories: ["General", "Cashflow, Income and Expenses"],
    width: 6,
    height: 5,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 5,
    type: "cash-development",
    categories: ["General", "Cashflow, Income and Expenses"],
    width: 4,
    height: 2,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  // {
  //   uuid: 6,
  //   type: "cost",
  //   categories: ["Cashflow, Income and Expenses", "Employees"],
  //   width:5,
  //   height:5,
  //   start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
  //   end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  // },
  {
    uuid: 7,
    type: "income-pie",
    categories: ["Cashflow, Income and Expenses"],
    width: 5,
    height: 5,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 8,
    type: "expense-pie",
    categories: ["Cashflow, Income and Expenses"],
    width: 5,
    height: 5,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 9,
    type: "profitability",
    categories: ["General", "Cashflow, Income and Expenses"],
    width: 6,
    height: 3,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 10,
    type: "invoice-status-over-time",
    categories: ["General", "Billings & Collections"],
    width: 4,
    height: 4,
    start_date: format(startOfMonth(subMonths(new Date(), 6)), "yyyy-MM-dd"),
    end_date: format(endOfMonth(addMonths(new Date(), 6)), "yyyy-MM-dd"),
  },
  {
    uuid: 11,
    type: "offer-status-over-time",
    categories: ["General", "Billings & Collections"],
    width: 4,
    height: 4,
    start_date: format(startOfMonth(subMonths(new Date(), 6)), "yyyy-MM-dd"),
    end_date: format(endOfMonth(addMonths(new Date(), 6)), "yyyy-MM-dd"),
  },
  {
    uuid: 12,
    type: "lead-status-over-time",
    categories: ["General", "Sales Performance"],
    width: 4,
    height: 4,
    start_date: format(startOfMonth(subMonths(new Date(), 6)), "yyyy-MM-dd"),
    end_date: format(endOfMonth(addMonths(new Date(), 6)), "yyyy-MM-dd"),
  },
  {
    uuid: 13,
    type: "over-budget-income",
    categories: [
      "General",
      "Cashflow, Income and Expenses",
      "Billings & Collections",
    ],
    width: 4,
    height: 3,
    start_date: format(startOfMonth(subMonths(new Date(), 2)), "yyyy-MM-dd"),
    end_date: format(endOfMonth(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 14,
    type: "over-budget-expense",
    categories: [
      "General",
      "Cashflow, Income and Expenses",
      "Billings & Collections",
    ],
    width: 4,
    height: 3,
    start_date: format(startOfMonth(subMonths(new Date(), 2)), "yyyy-MM-dd"),
    end_date: format(endOfMonth(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 15,
    type: "latest-bank-transactions",
    categories: [
      "General",
      "Cashflow, Income and Expenses",
      "Revenue, ARR & MRR",
    ],
    width: 4,
    height: 4,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 16,
    type: "latest-invoice-transactions",
    categories: ["General", "Billings & Collections"],
    width: 4,
    height: 4,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 17,
    type: "latest-offer-transactions",
    categories: ["General", "Sales Performance", "Billings & Collections"],
    width: 4,
    height: 4,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    uuid: 18,
    type: "latest-lead-transactions",
    categories: ["General", "Sales Performance"],
    width: 4,
    height: 4,
    start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },

  // {
  //   uuid: 17,
  //   type: "recurring",
  //   categories: ["General", "Cashflow, Income and Expenses","Revenue, ARR & MRR"],
  //   width: 6,
  //   height: 6,
  // },
];

const MainTabs = [
  {
    id: 2,
    title: "Tab_Title_Kanban",
    icon: <TbChartAreaLineFilled />,
    value: ["/table"],
  },
  {
    id: 3,
    title: "Tab_Title_List",
    icon: <TbList />,
    value: [
      "/list/bulk",
      "/list/suggestion",
      "/list/bank",
      "/list/all",
      "/list/categorized",
      "/list/uncategorized",
      "/list/open",
      "/list/budget",
      "/list/reconcile",
      "/list/late",
      "/list/issues",
      "/list",
    ],
  },
  {
    id: 5,
    title: "Tab_Title_Employee",
    icon: <IoPeople />,
    value: [
      "/employees",
      "/loans",
      "/cost-units",
      "/clients/recurring-subscriptions",

      "/customers/customers-list",
      "/customers/customers-portfolio-analysis",
      "/customers/top-customers",
      "/customers/merge-customers",

      "/suppliers/suppliers-list",
      "/suppliers/suppliers-portfolio-analysis",
      "/suppliers/top-suppliers-analysis",
      "/suppliers/merge-suppliers",
    ],
  },
  {
    id: 4,
    title: "Tab_Title_Reports",
    icon: <TbChartBubble />,
    value: [
      "/reports/overview",
      // "/reports/balance-sheet",
      // "/reports/revenue/overview",
      // "/reports/expenses/overview",
      // "/reports/report/Income-statement",
      // "/reports/report/success-report",
      // "/reports/report/cost-ratio&returns",
      // "/reports/revenue/recurring-income",
      // "/reports/expenses/recurring-expenses",
      // "/reports/suppliers/suppliers-portfolio-analysis",
      // "/reports/suppliers/top-suppliers",
      // "/reports/suppliers/suppliers-list",
      // "/reports/suppliers/merge-suppliers",
      // "/reports/customers/customers-portfolio-analysis",
      // "/reports/customers/top-customers",
      // "/reports/customers/customers-list",
      // "/reports/customers/merge-customers",
      // "/reports/staff",
      // "/reports/forecast",
    ],
  },
  {
    id: 6,
    title: "Tab_Title_Category",
    icon: <BsFillTagsFill />,
    value: ["/categories"],
  },
];

const StaffRoutes = [
  {
    id: "staff",
    title: "Tab_Title_Employee",
    value: ["/employees"],
    icon: <IoPeople />,
  },
  // {
  //   id: "cost_unit",
  //   title: "Tab_Title_Cost_Unit",
  //   value: ["/cost-units"],
  //   icon: <RiFolderChartLine />,
  // },
  {
    id: "loan",
    title: "Tab_Title_Loan",
    value: ["/loans"],
    icon: <TbBuildingBank />,
  },
  {
    id: "contract",
    title: "Tab_Title_Contract",
    value: ["/clients/recurring-subscriptions"],
    icon: <IoReaderOutline />,
  },
  {
    id: "customer",
    title: "Tab_Title_Contact",
    value: [
      "/customers/customers-list",
      "/customers/customers-portfolio-analysis",
      "/customers/top-customers",
      "/customers/merge-customers",
    ],
    icon: <IoPeopleCircle />,
  },
];

const EmployeeType = [
  {
    uuid: "Full-time",
    value: "Full-time",
    display_name: "Full-time",
    title: "Full-time",
    fullColor: theme.palette.color.purple[600],
    position: 1,
  },
  {
    uuid: "Part-time",
    value: "Part-time",
    display_name: "Part-time",
    title: "Part-time",
    fullColor: theme.palette.color.purple[500],
    position: 2,
  },
  {
    uuid: "Freelancer",
    value: "Freelancer",
    display_name: "Freelancer",
    title: "Freelancer",
    fullColor: theme.palette.color.fuchsia[500],
    position: 3,
  },
  {
    uuid: "Internship",
    value: "Internship",
    display_name: "Internship",
    title: "Internship",
    fullColor: theme.palette.color.indigo[600],
    position: 4,
  },
  {
    uuid: "Apprenticeship",
    value: "Apprenticeship",
    display_name: "Apprenticeship",
    title: "Apprenticeship",
    fullColor: theme.palette.color.sky[600],
    position: 5,
  },
  {
    uuid: "Recruiting Agency Contract",
    value: "Recruiting Agency Contract",
    display_name: "Recruiting Agency Contract",
    title: "Recruiting Agency Contract",
    fullColor: theme.palette.color.violet[600],
    position: 6,
  },
  {
    uuid: "Minijob",
    value: "Minijob",
    display_name: "Minijob",
    title: "Minijob",
    fullColor: theme.palette.color.violet[600],
    position: 7,
  },
  {
    uuid: "Student",
    value: "Student",
    display_name: "Student",
    title: "Student",
    fullColor: theme.palette.color.violet[600],
    position: 8,
  },
  {
    uuid: "Working student",
    value: "Working student",
    display_name: "Working student",
    title: "Working student",
    fullColor: theme.palette.color.violet[600],
    position: 8,
  },
  {
    uuid: "Student in marginal employment",
    value: "Student in marginal employment",
    display_name: "Student in marginal employment",
    title: "Student in marginal employment",
    fullColor: theme.palette.color.violet[600],
    position: 9,
  },
];

const LoanType = [
  {
    uuid: "Bank Loans",
    value: "Bank Loans",
    display_name: "Bank Loans",
    title: "Bank Loans",
    fullColor: theme.palette.color.purple[600],
    position: 1,
  },
  {
    uuid: "Installment Credit",
    value: "Installment Credit",
    display_name: "Installment Credit",
    title: "Installment Credit",
    fullColor: theme.palette.color.purple[500],
    position: 2,
  },
  {
    uuid: "Trade Credit",
    value: "Trade Credit",
    display_name: "Trade Credit",
    title: "Trade Credit",
    fullColor: theme.palette.color.fuchsia[500],
    position: 3,
  },
  {
    uuid: "Asset-Based Lending",
    value: "Asset-Based Lending",
    display_name: "Asset-Based Lending",
    title: "Asset-Based Lending",
    fullColor: theme.palette.color.indigo[600],
    position: 4,
  },
  {
    uuid: "Vendor Financing",
    value: "Vendor Financing",
    display_name: "Vendor Financing",
    title: "Vendor Financing",
    fullColor: theme.palette.color.sky[600],
    position: 5,
  },
  {
    uuid: "Mortgages",
    value: "Mortgages",
    display_name: "Mortgages",
    title: "Mortgages",
    fullColor: theme.palette.color.violet[600],
    position: 6,
  },
];

const EmployeeSalaryType = [
  {
    value: 1,
    display_name: "Monthly",
    title: "Monthly",
    color: theme.palette.color.slate[600],
    position: 1,
  },
  {
    value: 2,
    display_name: "Yearly",
    title: "Yearly",
    color: theme.palette.color.slate[500],
    position: 2,
  },
];

const rowEmployeeRoleData = `[
  {"category": "Leadership", "titles": ["CEO - Chief Executive Officer", "COO - Chief Operating Officer", "CFO - Chief Financial Officer", "CTO - Chief Technology Officer", "CMO - Chief Marketing Officer", "CHRO - Chief Human Resources Officer", "CPO - Chief Product Officer", "CCO - Chief Customer Officer"]},
  {"category": "Misc/Administration", "titles": ["Team Leader", "Manager", "Assistant Manager", "Executive", "Director", "Coordinator", "Administrator", "Controller", "Officer", "Organizer", "Supervisor", "Superintendent"]},
  {"category": "Marketing", "titles": ["Chief marketing officer", "Director of marketing", "Marketing analyst", "Marketing coordinator", "Marketing consultant", "Marketing manager", "Marketing and promotions manager", "Marketing specialist", "Vice president of marketing", "Brand activation manager", "Brand manager", "Brand marketing manager", "Brand strategist", "Director of brand marketing", "Director of brand strategy", "Content director", "Content manager", "Content marketing manager", "Content marketing producer", "Content specialist", "Content strategist", "Content writer"]},
  {"category": "Information Technology", "titles": ["Backend Developer","Frontend Developer","Computer Scientist", "IT Professional", "UX Designer & UI Developer", "SQL Developer", "Web Designer", "Web Developer", "Help Desk Worker/Desktop Support", "Software Engineer", "Data Entry", "DevOps Engineer", "Computer Programmer", "Network Administrator", "Information Security Analyst", "Artificial Intelligence Engineer", "Cloud Architect", "IT Manager", "Technical Specialist", "Application Developer"]},
  {"category": "Sales", "titles": ["Sales Associate", "Retail Worker", "Sales Representative", "Sales Manager", "Real Estate Broker", "Cashier", "Store Manager", "Account Executive", "Account Manager", "Area Sales Manager", "Direct Salesperson", "Director of Inside Sales", "Outside Sales Manager", "Sales Analyst", "Market Development Manager", "B2B Sales Specialist", "Sales Engineer", "Merchandising Associate"]},
  {"category": "Customer service job titles", "titles": ["Virtual Assistant", "Customer Service", "Customer Support", "Concierge", "Help Desk", "Customer Service Manager", "Technical Support Specialist", "Account Representative", "Client Service Specialist", "Customer Care Associate"]},
  {"category": "Finance and Accounting job titles", "titles": ["Credit Authorizer", "Benefits Manager", "Credit Counselor", "Accountant", "Bookkeeper", "Accounting Analyst", "Accounting Director", "Accounts Payable/Receivable Clerk", "Auditor", "Budget Analyst",  "Financial Analyst", "Finance Manager", "Economist", "Payroll Manager", "Payroll Clerk", "Financial Planner", "Financial Services Representative", "Finance Director", "Commercial Loan Officer"]},
  {"category": "Engineering Job titles", "titles": ["Engineer", "Mechanical Engineer", "Civil Engineer", "Electrical Engineer", "Assistant Engineer", "Chemical Engineer", "Chief Engineer", "Drafter", "Engineering Technician", "Geological Engineer", "Biological Engineer", "Maintenance Engineer", "Mining Engineer", "Nuclear Engineer", "Petroleum Engineer", "Plant Engineer", "Production Engineer", "Quality Engineer", "Safety Engineer", "Sales Engineer"]},
  {"category": "Legal Job Titles", "titles": ["Paralegal", "Lawyer / Advocate", "Litigation executive", "Counsel", "Solicitor", "Barrister", "Judge", "Chartered legal executive"]}
]`;

const formattedEmployeeRoleData = JSON.parse(rowEmployeeRoleData).reduce(
  (acc, categoryObj) => {
    const formattedTitles = categoryObj.titles.map((title) => ({
      value: title,
      display_name: title,
      category: categoryObj.category,
    }));
    return acc.concat(formattedTitles);
  },
  []
);

//category
const CategoryType = {
  service: "Service",
  Product: "Product",
  "revenue stream": "Revenue Stream",
  "financing: dept": "Financing: Dept",
  "financing: equity": "Financing: Equity",
  "cost of goods sold (cogs)": "Cost of Goods Sold (COGS)",
  "depreciation & amortization": "Depreciation & Amortization",
  "general & administrative": "General & Administrative",
  interest: "Interest",
  "other non-operating items": "Other Non-Operating Items",
  "other opex": "Other Opex",
  "research & development": "Research & Development",
  "sales & marketing": "Sales & Marketing",
  taxes: "Taxes",
  "money transit": "Money Transit",
};

//gsheet
const SCOPES = "https://www.googleapis.com/auth/spreadsheets.readonly";

//states
const plannedState = ["Planned"];
const leadState = ["Lead - potential", "Lead - won", "Lead - lost"];
const offerState = [
  "Offer draft",
  "Offer sent",
  "Offer accepted",
  "Offer partially calculated",
  "Offer calculated",
  "Offer archived",
  "Offer rejected",
];
const invoiceState = [
  "Invoice draft",
  "Invoice sent",
  "Invoice open",
  "Open",
  "Invoice paid",
  "Partially paid",
  "Sales invoice",
  "Purchase invoice",
];
const miscState = [
  "Refunded",
  "Partially refunded",
  "Ignored",
  "Written-off",
  "On-hold",
  "Canceled",
];
const manualStates = [
  ...plannedState,
  ...leadState,
  ...offerState,
  ...invoiceState,
  "Ignored",
  "Written-off",
  "On-hold",
  "Canceled",
];
const bookedPositionsStates = [
  "Sales credit note",
  "Purchase credit note",
  "Booked",
  "Undefined",
  "Pending",
];
const openPositionsStates = [
  ...leadState,
  ...offerState,
  ...invoiceState,
  "Ignored",
  "Written-off",
  "On-hold",
  "Canceled",
];
const staffState = ["Planned", "Ignored", "Written-off"];
const calculationExcludeStates = [
  "Lead - lost",
  "Offer calculated",
  "Offer archived",
  "Offer rejected",
  "Invoice paid",
  "Booked",
  "Sales credit note",
  "Purchase credit note",
  "Ignored",
  "Written-off",
  "On-hold",
  "Canceled",
  "Pending",
  "Undefined",
  "Refunded",
  "Partially refunded",
];

//constant
export let Constant = {
  FILTER_HEIGHT: "2rem",
  Past_Month: 12,
  DRAWER_WIDTH: "14rem",
  DRAWER_WIDTH_CLOSED: "4rem",
  HEADER_HEIGHT: "4rem",
  BANNER_HEIGHT: "0rem",
  chart_extra_offset: 33, //33,
  table_row_height: 38,
  chart_margin_left: 120, //130
  chart_margin_left_2300: 120, //140
  table_row_height_2300: 34,
  offset: -70, //-75
  offset_2300: -70, //80
  column_width: 140,
  column_width_2200: 140, //165
  column_width_2300: 140, //160
  column_width_2400: 140, //180
  column_width_2500: 140, //200
  column_height: 340,
  column_height_2200: 340, //350
  column_height_2300: 350, //350
  column_height_2400: 350, //360
  column_height_2500: 350, //370
  start_column_extra_width: 50,
  start_column_extra_width_2200: 50, //24
  start_column_extra_width_2300: 50, //24
  start_column_extra_width_2400: 50, //14
  start_column_extra_width_2500: 50, //18
  start_column_skip_count: 1,
  default_column_count: 16,
  isDev: process.env.REACT_APP_MODE === "development",
  isStag: process.env.REACT_APP_MODE === "staging",
  isTest:
    process.env.REACT_APP_MODE === "development" ||
    process.env.REACT_APP_MODE === "test",
  isProd:
    // process.env.REACT_APP_MODE === "staging" ||
    process.env.REACT_APP_MODE === "production",

  // isProd:
  //   process.env.REACT_APP_MODE === "development" ||
  //   process.env.REACT_APP_MODE === "test",
  StateSource,
  IntegrationsDataTypes,
  MainTabs,
  StaffRoutes,
  DefaultSource,
  DefaultSourceByName,

  defaultScenario: {
    Base: {
      position: 0,
      color: "slate",
    },
    Best: {
      position: 1,
    },
  },

  //states
  defaultState: {
    Planned: {
      position: 1,
      color: "purple",
      title: "Planned",
      source: [1, 4],
      state_type: [1, 2],
    },
    "Lead - potential": {
      position: 2,
      color: "orange",
      title: "Lead - potential",
      source: [1, 4],
      state_type: [1],
    },
    "Lead - won": {
      position: 3,
      color: "orange",
      title: "Lead - won",
      source: [1, 4],
      state_type: [1],
    },
    "Lead - lost": {
      position: 4,
      color: "slate",
      title: "Lead - lost",
      source: [1, 4],
      state_type: [1],
    },
    "Offer draft": {
      position: 5,
      title: "Offer draft",
      color: "sky",
      source: [1, 4],
      state_type: [1],
    },
    "Offer sent": {
      position: 6,
      title: "Offer sent",
      color: "sky",
      source: [1, 4],
      state_type: [1],
    },
    "Offer accepted": {
      position: 7,
      title: "Offer accepted",
      color: "sky",
      source: [1, 4],
      state_type: [1],
    },
    "Offer partially calculated": {
      position: 8,
      title: "Offer partially calculated",
      color: "sky",
      source: [1, 4],
      state_type: [1],
    },
    "Offer calculated": {
      position: 9,
      title: "Offer calculated",
      color: "slate",
      isLight: true,
      source: [1, 4],
      state_type: [1],
    },
    "Offer archived": {
      position: 10,
      title: "Offer archived",
      color: "slate",
      isLight: true,
      source: [1, 4],
      state_type: [1],
    },
    "Offer rejected": {
      position: 11,
      title: "Offer rejected",
      color: "slate",
      isLight: true,
      source: [1, 4],
      state_type: [1],
    },
    Open: {
      position: 12,
      color: "green",
      title: "Open",
      source: [1, 4],
      state_type: [1, 2],
    },
    "Sales invoice": {
      position: 13,
      title: "Sales invoice",
      color: "green",
      source: [1, 4],
      state_type: [1],
    },
    "Invoice draft": {
      position: 14,
      title: "Invoice draft",
      color: "green",
      source: [1, 4],
      state_type: [1],
    },
    "Invoice sent": {
      position: 15,
      title: "Invoice sent",
      color: "green",
      source: [1, 4],
      state_type: [1],
    },
    "Invoice open": {
      position: 16,
      title: "Invoice open",
      color: "green",
      source: [1, 4],
      state_type: [1],
    },
    "Partially paid": {
      position: 17,
      title: "Partially paid",
      color: "green",
      source: [1, 4],
      state_type: [1],
    },
    "Invoice paid": {
      position: 18,
      title: "Invoice paid",
      color: "slate",
      isLight: true,
      source: [1, 4],
      state_type: [1],
    },
    Booked: {
      position: 19,
      title: "Booked",
      color: "green",
      source: [1, 2, 4],
      state_type: [1, 2],
    },
    "Sales credit note": {
      position: 20,
      title: "Sales credit note",
      color: "red",
      source: [1, 2, 4],
      state_type: [1],
      state_number_type: [2], //gross_value is negative
    },
    "Purchase invoice": {
      position: 21,
      title: "Purchase invoice",
      color: "red",
      source: [1, 4],
      state_type: [2],
    },
    "Purchase credit note": {
      position: 22,
      title: "Purchase credit note",
      color: "green",
      source: [1, 2, 4],
      state_type: [2],
      state_number_type: [1], //gross_value is positive
    },
    Refunded: {
      position: 23,
      title: "Refunded",
      color: "slate",
      source: [4],
      state_type: [1, 2],
    },
    "Partially refunded": {
      position: 24,
      title: "Partially refunded",
      color: "slate",
      source: [4],
      state_type: [1, 2],
    },
    Ignored: {
      position: 25,
      title: "Ignored",
      color: "slate",
      isLight: true,
      source: [1, 4],
      state_type: [1, 2],
    },
    "Written-off": {
      position: 26,
      title: "Written-off",
      color: "slate",
      isLight: true,
      source: [1, 4],
      state_type: [1, 2],
    },
    "On-hold": {
      position: 27,
      title: "On-hold",
      color: "slate",
      isLight: true,
      source: [1, 4],
      state_type: [1, 2],
    },
    Canceled: {
      position: 28,
      title: "Canceled",
      color: "slate",
      isLight: true,
      source: [1, 4],
      state_type: [1, 2],
    },
    Pending: {
      position: 29,
      title: "Pending",
      color: "red",
      source: [2],
      state_type: [1, 2],
      iconColor: "red",
    },
    Undefined: {
      position: 30,
      title: "Undefined",
      color: "red",
      source: [2],
      state_type: [1, 2],
      iconColor: "red",
    },
  },
  plannedState,
  leadState,
  offerState,
  invoiceState,
  miscState,
  manualStates,
  bookedPositionsStates,
  openPositionsStates,
  bookedStates: ["Booked", "Sales credit note", "Purchase credit note"],
  creditNotes: ["Sales credit note", "Purchase credit note"],
  lateState: invoiceState?.filter((o1) => o1 !== "Invoice paid"),
  staffState,
  excludeCategoryTypes: ["money transit"],
  calculationExcludeStates,
  calculationExcludeStates2: calculationExcludeStates?.filter(
    (o1) =>
      !["Booked", "Sales credit note", "Purchase credit note"]?.includes(o1)
  ),

  ReportCategory: [
    {
      id: 1,
      title: "General",
    },
    {
      id: 2,
      title: "Cashflow, Income and Expenses",
    },
    {
      id: 3,
      title: "Billings & Collections",
    },
    {
      id: 4,
      title: "Bookings & Customers",
    },
    {
      id: 5,
      title: "Employees",
    },
    {
      id: 6,
      title: "Revenue, ARR & MRR",
    },
    {
      id: 7,
      title: "Sales Performance",
    },
  ],
  IntegrationsSource,
  IntegrationsSourceData,
  IntegrationsTypeOptions,
  IntegrationsTypeOptionsByID: _.groupBy(
    IntegrationsTypeOptions,
    ({ value }) => value
  ),
  EmployeeType,
  EmployeeSalaryType,
  EmployeeTypeByTitle: _.groupBy(EmployeeType, ({ title }) => title),
  LoanType,
  LoanTypeByTitle: _.groupBy(LoanType, ({ title }) => title),
  CostType: [
    {
      value: "advances_and_grants",
      display_name: "advances_and_grants",
    },
    {
      value: "bonus",
      display_name: "bonus",
    },
    {
      value: "capital_accumulation_benefits",
      display_name: "capital_accumulation_benefits",
    },
    {
      value: "company_pension_scheme",
      display_name: "company_pension_scheme",
    },
    {
      value: "expense_allowances",
      display_name: "expense_allowances",
    },
    {
      value: "insurance",
      display_name: "insurance",
    },
    {
      value: "monetary_benefits",
      display_name: "monetary_benefits",
    },
    {
      value: "non_monetary_benefits",
      display_name: "non_monetary_benefits",
    },
    {
      value: "supplements_and_allowances",
      display_name: "supplements_and_allowances",
    },
    {
      value: "software",
      display_name: "software",
    },
    {
      value: "tax",
      display_name: "tax",
    },
    {
      value: "technique",
      display_name: "technique",
    },
    {
      value: "training",
      display_name: "training",
    },
  ],
  EmployeeRole: formattedEmployeeRoleData,
  EmployeeRoleByTitle: _.groupBy(
    formattedEmployeeRoleData,
    ({ display_name }) => display_name
  ),
  ReportWidgetOptions,
  ReportWidgetOptionsByType: _.groupBy(ReportWidgetOptions, ({ type }) => type),
  integrations: {
    BANKING,
    ACCOUNTING,
    EXPENSE_AND_CARD_MANAGEMENT,
    E_COMMERCE,
    SPREADSHEET,
    MISC,
    AllIntegrations,
    IntegrationsByType: _.groupBy(AllIntegrations, ({ type }) => type),
    IntegrationsByName: _.groupBy(AllIntegrations, ({ name }) => name),
  },
  OverlayBoxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  CategoryType,
  Random_Color: [
    "lightBlue",
    "orange",
    "red",
    "yellow",
    "green",
    "blue",
    "indigo",
    "amber",
    "lime",
    "emerald",
    "teal",
    "cyan",
    "sky",
    "violet",
    "purple",
    "fuchsia",
    "pink",
    "rose",
  ],
  FinbanMappingFields,
  CurrencyPrefix,
  SCOPES,
  ContactType,
  contact_types_options,
  contact_types_options_by_value,
  transaction_type_options,
  TransactionRuleLevelOptions,
};
