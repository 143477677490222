import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  setIsAutoRulesEnabled,
  setSearchText,
} from "../../../store/slices/rules";
import RulesListViewOverlay from "./RulesListViewOverlay";
import AttentionOverlay from "./AttentionOverlay";
import RuleModel from "./RuleModel";

const RulesCommonView = forwardRef((props, _ref) => {
  const data = useRef(null);
  const payload = useRef({});
  const dispatch = useDispatch();
  //state
  const [open, setOpen] = useState(false);
  const [openRule, setOpenRule] = useState(false);
  const [openRuleList, setOpenRuleList] = useState(false);

  //functions
  const onOpen = (_data) => {
    data.current = _data;
    setOpen(true);
  };

  const onOpenRule = (_payload) => {
    payload.current = _payload;
    setOpenRule(true);
  };

  const openRulesListOverlay = (_payload = {}) => {
    payload.current = _payload;
    dispatch(setIsAutoRulesEnabled(true));
    if (_payload?.searchText) {
      dispatch(setSearchText(_payload?.searchText || ""));
    }
    setOpenRuleList(true);
  };

  const closeRulesListOverlay = () => {
    payload.current = {};
    setOpenRuleList(null);
  };

  useImperativeHandle(_ref, () => ({
    onOpen,
    onOpenRule,
    openRulesListOverlay,
    closeRulesListOverlay,
  }));

  return (
    <>
      {openRule && (
        <RuleModel
          setOpen={setOpenRule}
          open={openRule}
          // transactionsRow
          // preventClose
          // extraPayload
          // rule
          // modalType
          // notEditable
          // callback
          {...payload.current}
        />
      )}
      {open ? (
        <AttentionOverlay
          open={open}
          setOpen={setOpen}
          data={data}
          title="Attention"
          message="category_rule_already_available"
        />
      ) : null}
      {openRuleList ? (
        <RulesListViewOverlay
          open={openRuleList}
          setOpen={setOpenRuleList}
          {...payload.current}
        />
      ) : null}
    </>
  );
});

export default RulesCommonView;
