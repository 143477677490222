import { useTranslation } from "react-i18next";
import { Chip, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import React from "react";

import { getTailwindColor } from "../Helper/data";

const ScenarioChip = ({
  title = null,
  hideBorder = false,
  width = "8rem",
  height = "2rem",
  fontSize = "0.7rem",
  borderRadius = 1,
  isSelected = false,
  onClick,
  showClickCursor = false,
  disabled = false,
  shade = 100, //200
  sx,
  style,
}) => {
  const { t } = useTranslation();
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );

  const item = scenarioByTitle?.[title]?.[0];

  const cursor = !!onClick || showClickCursor;
  const label = item?.title === "Base" ? t(item?.title) : item?.title;
  let selected_shade = shade === 50 ? 100 : shade + 100;

  const onClickChip = () => {
    onClick && onClick(item);
  };

  return (
    <Tooltip key={item?.uuid} title={label} placement="top">
      <span
        style={{
          display: "inline-flex",
          alignItems: "center",
          width: "fit-content",
          height: "fit-content",
          ...style,
        }}
      >
        <Chip
          onClick={onClickChip}
          size="small"
          label={label}
          disabled={disabled}
          sx={{
            width,
            height,
            borderRadius,
            fontWeight: 500,
            display: "inline-flex",
            alignItems: "center",
            color: getTailwindColor(item?.color, 700),
            backgroundColor: getTailwindColor(
              item?.color,
              isSelected ? selected_shade : shade
            ),
            cursor: cursor ? "pointer" : "auto",
            border: !hideBorder
              ? `3px solid ${getTailwindColor(item?.color, isSelected ? 600 : shade)}`
              : "none",
            fontSize: fontSize,
            "& .MuiChip-label": {
              textAlign: "center",
              lineHeight: "normal",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
            "&.Mui-disabled": {
              opacity: 0.5,
              cursor: "disable",
            },
            "&:hover": {
              backgroundColor: getTailwindColor(
                item?.color,
                shade === 50 ? 100 : shade + 100
              ),
              borderColor: getTailwindColor(
                item?.color,
                isSelected ? 700 : shade === 50 ? 100 : shade + 100
              ),
            },
            ...sx,
          }}
        />
      </span>
    </Tooltip>
  );
};
export default React.memo(ScenarioChip);
