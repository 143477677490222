import {
  ClickAwayListener,
  Typography,
  useTheme,
  Tooltip,
  Dialog,
  Button,
  Popper,
  Stack,
  Paper,
  Fade,
  Box,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { PiMagicWandFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { FaCheck } from "react-icons/fa";
import * as React from "react";

import {
  setStageLoadingText,
  setRefreshData,
} from "../../../store/slices/appmain";
import { truncate, updateBatchTransactions } from "../../../Helper/data";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import ComponentLoader from "../../../components/ComponentLoader";
import { setProfile } from "../../../store/slices/settings";
import CategoryChip from "../../../components/CategoryChip";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import Icon from "../../../components/Icon";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import RuleModel from "./RuleModel";

const AttentionOverlay = ({ open, setOpen, title, message, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const transactionsRow = React.useRef(null);
  const extraPayload = React.useRef(null);
  const modalType = React.useRef("edit");
  const rule = React.useRef(null);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const excluded_transaction_titles = useSelector(
    (state) => state.settingsSlice?.profile?.excluded_transaction_titles
  );

  // const use_global_categories = useSelector(
  //   (state) => state.boardSlice?.dataSetData?.use_global_categories
  // );
  // const _categories = useSelector((state) => state.categorySlice?.categories);
  const [openRule, setOpenRule] = React.useState(false);
  const [refersh, setRefresh] = React.useState(false); //keep for render
  const [currentRuleIndex, setCurrentRuleIndex] = React.useState({
    index: -1,
    uuid: null,
    transactions: [],
  });

  // const rule_type = "manual";
  const rule_type = data?.current?.rule_type;
  const isMultipleRules = data?.current?.transaction_rules?.length > 1;
  const isSaveAction =
    isMultipleRules &&
    currentRuleIndex?.index === data.current?.transaction_rules?.length - 1;
  // const categories = React.useMemo(() => {
  //   return _categories?.filter((o1) =>
  //     use_global_categories ? !o1?.dataset : o1?.dataset === dataSetData?.uuid
  //   );
  // }, [_categories, dataSetData?.uuid, use_global_categories]);

  //api
  const updateProfile = async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  const updateCardsBatch = async (array, action) => {
    dispatch(setStageLoadingText("process_transactions_is_being_updated"));
    await updateBatchTransactions(array);
    reset(action);
    onClose();
    dispatch(setStageLoadingText(null));
  };

  const deleteRulesByIdApi = async (id) => {
    await APICall("delete", EndPoints.transactions_rules + `${id}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === "rules",
          });
          queryClient.removeQueries({
            queryKey: ["rules", { uuid: id }],
          });
        }
      }
    );
  };

  const getRulesByIdApi = async (id, signal) => {
    let data = null;
    await APICall("get", EndPoints.transactions_rules + `${id}/`, null, {
      signal,
    }).then((response) => {
      if (response.status === 200 && response) {
        data = response?.data;
      }
    });
    return data;
  };

  const updateRulesByID = async (id, obj) => {
    await APICall("patch", EndPoints.transactions_rules + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          dispatch(setStageLoadingText(null));
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === "rules",
          });
        }
      }
    );
  };

  //query
  const Rules = useQuery({
    queryKey: ["rules", { uuid: currentRuleIndex?.uuid }],
    queryFn: ({ signal }) => {
      const result = getRulesByIdApi(currentRuleIndex?.uuid, signal);
      if (result) {
        return result;
      }
    },
    staleTime: 60000 * 5, //5minute
    refetchOnMount: true,
    enabled: !!currentRuleIndex?.uuid,
  });
  const foundedRule = Rules?.data;

  React.useEffect(() => {
    if (!!open) {
      data.current.transaction_rules = [];
      data.current.transactions = [];
      data.current.updated_data = {};
      data.current.verified_rule = {};

      data?.current?.response?.forEach((o1) => {
        if (
          o1?.transaction_rule?.[0] &&
          !data.current.transaction_rules?.includes(o1?.transaction_rule?.[0])
        ) {
          data.current.transaction_rules.push(o1?.transaction_rule?.[0]);
        }
        if (o1?.uuid && !o1?.state) {
          //there is some update data in response so to filter out them
          data.current.transactions.push(o1?.uuid);
        }
      });
      if (
        data?.current?.payload?.length !== data?.current?.transactions?.length
      ) {
        data.current.updated_data["others"] = data?.current?.payload?.filter(
          (o1) => !data?.current?.transactions.includes(o1?.uuid)
        );
      }
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "rules",
      });
      onNext();
    }
  }, [data, open]);

  // const isCategoryValid = use_global_categories
  //   ? !selectionCategoriesByID?.[foundedRule?.category]?.[0]?.dataset
  //   : selectionCategoriesByID?.[foundedRule?.category]?.[0]?.dataset ===
  //     dataSetData?.uuid;

  //assign category
  const onClickNoOverwrite = () => {
    const action = {
      skip_transaction_rule: true,
    };
    if (data.current?.payload) {
      data.current.newObj = data.current?.oldObj;

      if (isMultipleRules) {
        let array = [];
        data.current?.payload?.forEach((item) => {
          if (currentRuleIndex?.transactions?.includes(item?.uuid)) {
            array.push({
              ...item,
              ...action,
            });
          }
        });
        data.current.updated_data[currentRuleIndex?.uuid] = array;
        data.current.verified_rule[currentRuleIndex?.uuid] =
          "category_rule_already_available_assign_and_keep_rule";
        setRefresh((prev) => !prev);
        onNext();
      } else {
        let newArray = data.current?.payload?.map((item) => {
          if (currentRuleIndex?.transactions?.includes(item?.uuid)) {
            return {
              ...item,
              ...action,
            };
          } else {
            return item;
          }
        });

        updateCardsBatch(newArray, action);
      }
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj, action);
      }
      onClose();
    }
  };

  //assign category and update rule
  const onClickAssignAndUpdate = async () => {
    await updateRulesByID(data?.current?.transaction_rule, {
      category: data.current?.category,
    });
    if (data.current?.payload) {
      const action = {
        //we dont need here, added because  case : had multiple rules for same title with diff category
        skip_transaction_rule: true,
      };
      let newArray = data.current?.payload?.map((item) => {
        if (currentRuleIndex?.transactions?.includes(item?.uuid)) {
          return {
            ...item,
            ...action,
          };
        } else {
          return item;
        }
      });

      await updateCardsBatch(newArray);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj);
      }
    }
    onClose();
  };

  //apply rule
  const onClickKeepRule = async (_foundedRule) => {
    let _rule = _foundedRule || foundedRule;
    if (_rule && data.current?.payload) {
      data.current.newObj = {
        ...data.current?.oldObj,
        category: _rule?.category,
      };
      const action = {
        //we dont need here, added because  case : had multiple rules for same title with diff category
        skip_transaction_rule: true,
      };
      if (isMultipleRules) {
        let array = [];
        data.current?.payload?.forEach((item) => {
          if (currentRuleIndex?.transactions?.includes(item?.uuid)) {
            array.push({
              ...item,
              ...action,
              category: _rule?.category,
            });
          }
        });
        data.current.updated_data[currentRuleIndex?.uuid] = array;
        data.current.verified_rule[currentRuleIndex?.uuid] =
          "category_rule_already_available_no_overwrite";
        setRefresh((prev) => !prev);
        onNext();
      } else {
        let newArray = data.current?.payload?.map((item) => {
          if (currentRuleIndex?.transactions?.includes(item?.uuid)) {
            return {
              ...item,
              ...action,
              category: _rule?.category,
            };
          } else {
            return item;
          }
        });

        updateCardsBatch(newArray, action);
      }
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj);
      }
      onClose();
    }
  };

  //exclude from rule
  const onClickExclude = async () => {
    await deleteRulesByIdApi(data?.current?.transaction_rule);
    const isAlreadyAdded = excluded_transaction_titles?.find(
      (i) => i === data.current?.title
    );
    if (!isAlreadyAdded) {
      const _data = [
        ...(excluded_transaction_titles || []),
        data.current?.title,
      ];
      dispatch(
        setProfile({
          key: "excluded_transaction_titles",
          value: _data,
        })
      );
      await updateProfile({ excluded_transaction_titles: _data });
    }
    let newArray = [];
    const action = {
      overwrite_transaction_rule: true,
    };
    if (data.current?.payload) {
      data.current?.payload?.forEach((item) => {
        newArray.push({
          ...item,
          ...action,
        });
      });
      updateCardsBatch(newArray, action);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj, action);
      }
      onClose();
    }
    data.current.newObj = data.current?.oldObj;
  };

  //add individual rule
  const onClickManualRule = () => {
    modalType.current = "add";
    rule.current = null;
    transactionsRow.current = {
      category: data.current?.category,
      transaction_type: data.current?.transaction_type,
      title: data.current?.title,
    };
    extraPayload.current = { onClickExclude };
    setOpenRule(true);
  };

  //overwrite rule
  const onClickOverwrite = () => {
    let newArray = [];
    const action = {
      overwrite_transaction_rule: true,
    };
    if (data.current?.payload) {
      newArray = data.current?.payload?.map((item) => {
        return {
          ...item,
          ...action,
        };
      });
      data.current.newObj = data.current?.oldObj;
      updateCardsBatch(newArray, action);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj, action);
      }
      onClose();
    }
  };

  //delete rule
  const onClickDelete = async () => {
    await deleteRulesByIdApi(data?.current?.transaction_rule);
    if (data.current?.payload) {
      const action = {
        overwrite_transaction_rule: true,
      };
      let newArray = data.current?.payload?.map((item) => {
        return {
          ...item,
          ...action,
        };
      });
      await updateCardsBatch(newArray);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj);
      }
    }
    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  const reset = async (action) => {
    if (data.current?.callback) {
      data.current?.callback(data.current?.newObj, action);
    }
    dispatch(setRefreshData(Date.now())); //for all transactions list view,

    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      predicate: (query) =>
        (query.queryKey[0] === "transactions" ||
          query.queryKey[0] === "List") &&
        query.queryKey[1]?.dataset === dataSetData?.uuid,
    };
    queryClient.resetQueries(options);
  };

  const onClickHelp = () => {
    window.open("https://finban.io/documentation/rules/", "_blank");
  };

  const onClickSearchRule = () => {
    modalType.current = "edit";
    rule.current = foundedRule;
    transactionsRow.current = null;
    extraPayload.current = { onClickKeepRule };
    setOpenRule(true);
  };

  const onClickManage = () => {
    navigate("/settings/rules");
  };

  const onBack = () => {
    const rule_uuid =
      data.current.transaction_rules?.[currentRuleIndex?.index - 1];
    if (rule_uuid) {
      const transactions = data?.current?.response
        ?.filter((o1) => o1.transaction_rule?.[0] === rule_uuid)
        ?.map((o1) => o1?.uuid);
      setCurrentRuleIndex({
        index: currentRuleIndex?.index - 1,
        uuid: rule_uuid,
        transactions,
      });
    }
  };

  const onNext = (e) => {
    if (isSaveAction && !!e) {
      let payload = [];
      Object.keys(data.current?.updated_data)?.forEach((key) => {
        const array = data.current?.updated_data?.[key] || [];
        payload = [...payload, ...array];
      });
      if (data?.current?.response?.length > 0) {
        if (payload?.length !== data?.current?.response?.length) {
          enqueueSnackbar(t("category_rule_select_rule_action_warning"), {
            variant: "warning",
            autoHideDuration: 5000,
            preventDuplicate: true,
          });
          return null;
        } else {
          updateCardsBatch(payload);
        }
      }
    } else {
      const rule_uuid =
        data.current.transaction_rules?.[currentRuleIndex?.index + 1];
      if (rule_uuid) {
        const transactions = data?.current?.response
          ?.filter(
            (o1) =>
              o1?.transaction_rule?.[0] &&
              o1?.transaction_rule?.[0] === rule_uuid
          )
          ?.map((o1) => o1?.uuid);
        setCurrentRuleIndex({
          index: currentRuleIndex?.index + 1,
          uuid: rule_uuid,
          transactions,
        });
      }
    }
  };

  const isActionSelected = (action) => {
    return data?.current?.verified_rule?.[currentRuleIndex?.uuid] === action;
  };

  return (
    <div>
      {openRule && (
        <RuleModel
          setOpen={setOpenRule}
          open={openRule}
          transactionsRow={transactionsRow.current}
          extraPayload={extraPayload.current}
          rule={rule.current}
          modalType={modalType.current}
          notEditable
        />
      )}

      <Dialog
        open={!!open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ zIndex: 1303 }}
        PaperProps={{
          style: {
            minWidth: "85rem",
            height: "25rem",
            padding: "2rem",
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            borderRadius: 8,
          },
        }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Typography
            variant="div"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.2rem",
              lineHeight: "1.5rem",
              fontWeight: "600",
              color: "color.slate.500",
            }}
          >
            <InfoOutlinedIcon
              sx={{
                color: "color.slate.500",
                mr: "1rem",
              }}
            />

            {t(title)}
          </Typography>

          <Stack direction="row" alignItems={"center"}>
            <Button
              onClick={onClickHelp}
              disableElevation
              disableFocusRipple
              sx={{
                ml: "auto",
                p: 0,
                textTransform: "initial",
                fontSize: "0.875rem",
                fontWeight: "500",
                color: "color.purple.700",
                "&:hover": {
                  color: "color.purple.800",
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
            >
              {t("Help")}
            </Button>
            <Button
              onClick={onClickManage}
              disableElevation
              disableFocusRipple
              sx={{
                ml: "auto",
                p: 0,
                textTransform: "initial",
                fontSize: "0.875rem",
                fontWeight: "500",
                color: "color.purple.700",
                "&:hover": {
                  color: "color.purple.800",
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
            >
              {t("Manage Rules")}
            </Button>
          </Stack>
        </Stack>

        <Typography
          my="1.5rem"
          variant="body1"
          sx={{
            fontSize: "1rem",
            color: "color.description",
          }}
        >
          <Translate i18nkey={message} />
        </Typography>

        <Box
          display={"flex"}
          alignItems={"center"}
          width={"fit-content"}
          p="0.75rem"
          borderRadius={1.5}
        >
          <Typography
            variant="body1"
            fontWeight={"fontWeightMediumBold"}
            mr="0.5rem"
            width={"8rem"}
          >
            {t("Recent action")}:
          </Typography>{" "}
          {data.current?.category ? (
            <CategoryChip
              categoryId={data.current?.category}
              width="8rem"
              showTooltip
            />
          ) : null}
          <span
            style={{
              marginInline: "0.5rem",
              lineHeight: "1rem",
              marginBottom: "1px",
            }}
          >
            <Icon
              icon={<IoIosArrowRoundForward />}
              fontSize={"1.8rem"}
              color="color.slate.600"
            ></Icon>
          </span>
          <Stack
            direction={"row"}
            backgroundColor={"color.slate.50"}
            p={3.5}
            alignItems={"center"}
            borderRadius={theme.borderRadius.main}
            width={"25rem"}
          >
            <Tooltip title={foundedRule?.transaction_title} placement="top">
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: "1rem",
                  color: "color.slate.600",
                }}
              >
                {truncate(foundedRule?.transaction_title, 42)}
              </Typography>
            </Tooltip>
            {foundedRule?.note ? (
              <Tooltip title={foundedRule?.note} placement="top">
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    color: "color.slate.600",
                    ml: "0.5rem",
                  }}
                >
                  {` / ${truncate(foundedRule?.note, 42)}`}
                </Typography>
              </Tooltip>
            ) : null}
          </Stack>
          <TailwindButton
            tooltip={
              "category_rule_already_available_assign_and_keep_rule_tooltip"
            }
            text={t("category_rule_already_available_assign_and_keep_rule")}
            onClick={onClickNoOverwrite}
            icon={
              isActionSelected(
                "category_rule_already_available_assign_and_keep_rule"
              ) ? (
                <FaCheck
                  style={{ marginRight: "0.5rem", fontSize: "1.1rem" }}
                />
              ) : (
                <LocalOfferIcon sx={{ mr: "0.5rem", fontSize: "1.1rem" }} />
              )
            }
            backgroundColor={
              theme.palette.color.green[
                isActionSelected(
                  "category_rule_already_available_assign_and_keep_rule"
                )
                  ? 800
                  : 500
              ]
            }
            sx={{
              borderRadius: 50,
              height: "2.75rem",

              "&:hover": {
                backgroundColor: "color.green.600",
                borderColor: "color.green.600",
              },
            }}
          />
          {rule_type === "manual" || isMultipleRules ? null : (
            <TailwindButton
              variant="outlined"
              tooltip={"category_rule_assign_category_and_update_rule_tooltip"}
              text={t("category_rule_assign_category_and_update_rule")}
              onClick={onClickAssignAndUpdate}
              color={theme.palette.color.green[500]}
              sx={{
                borderRadius: 50,
                height: "2.75rem",
                "&:hover": {
                  backgroundColor: "color.green.600",
                  borderColor: "color.green.600",
                  color: "color.white",
                },
              }}
            />
          )}
        </Box>

        {!Rules?.isFetching ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"fit-content"}
            p="0.75rem"
            borderRadius={1.5}
          >
            <Typography
              variant="body1"
              fontWeight={"fontWeightMediumBold"}
              mr="0.5rem"
              width={"8rem"}
            >
              {rule_type === "manual" ? t("Manual Rule") : t("Saved Rule")}
            </Typography>
            {foundedRule?.category ? (
              <CategoryChip
                categoryId={foundedRule?.category}
                width="8rem"
                showTooltip
              />
            ) : null}
            <span
              style={{
                marginInline: "0.5rem",
                lineHeight: "1rem",
                marginBottom: "1px",
              }}
            >
              <Icon
                icon={<IoIosArrowRoundForward />}
                fontSize={"1.8rem"}
                color="color.slate.600"
              ></Icon>
            </span>
            <Stack
              direction={"row"}
              backgroundColor={"color.slate.50"}
              p={3.5}
              alignItems={"center"}
              borderRadius={theme.borderRadius.main}
              width={"25rem"}
            >
              <Tooltip title={foundedRule?.transaction_title} placement="top">
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    color: "color.slate.600",
                  }}
                >
                  {truncate(foundedRule?.transaction_title, 42)}
                </Typography>
              </Tooltip>
            </Stack>
            <TailwindButton
              tooltip={"category_rule_already_available_no_overwrite_tooltip"}
              text={t("category_rule_already_available_no_overwrite")}
              onClick={() => onClickKeepRule(foundedRule)}
              icon={
                isActionSelected(
                  "category_rule_already_available_no_overwrite"
                ) ? (
                  <FaCheck
                    style={{ marginRight: "0.5rem", fontSize: "1.1rem" }}
                  />
                ) : (
                  <PiMagicWandFill
                    style={{ marginRight: "0.5rem", fontSize: "1.25rem" }}
                  />
                )
              }
              backgroundColor={
                theme.palette.color.green[
                  isActionSelected(
                    "category_rule_already_available_no_overwrite"
                  )
                    ? 800
                    : 500
                ]
              }
              sx={{
                height: "2.75rem",
                borderRadius: 50,
                "&:hover": {
                  backgroundColor: "color.green.600",
                  borderColor: "color.green.600",
                },
              }}
            />
            <TailwindButton
              variant="outlined"
              text={t("Open Rule")}
              onClick={onClickSearchRule}
              color={theme.palette.color.green[500]}
              sx={{
                height: "2.75rem",
                borderRadius: 50,
                "&:hover": {
                  backgroundColor: "color.green.600",
                  borderColor: "color.green.600",
                  color: "color.white",
                },
              }}
            />
          </Box>
        ) : (
          <ComponentLoader loading hideNoDataPlaceholder height="5rem" />
        )}

        <Box
          sx={{
            bottom: "1.5rem",
            position: "absolute",
            mt: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              {!isMultipleRules || currentRuleIndex?.index < 1 ? (
                <TailwindButton
                  type="cancel"
                  text={t("Cancel")}
                  onClick={onClose}
                  sx={{
                    ml: 0,
                    borderRadius: 50,
                  }}
                />
              ) : null}
              {currentRuleIndex?.index > 0 ? (
                <TailwindButton
                  type="cancel"
                  text={t("Back")}
                  onClick={onBack}
                  sx={{
                    ml: 0,
                    borderRadius: 50,
                  }}
                />
              ) : null}
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              {!isMultipleRules ? (
                <MoreView
                  onClickManualRule={onClickManualRule}
                  onClickExclude={onClickExclude}
                  onClickOverwrite={onClickOverwrite}
                  onClickDelete={onClickDelete}
                />
              ) : null}
              {data.current?.transaction_rules?.length > 1 ? (
                <TailwindButton
                  text={`${t(
                    isSaveAction ? "Apply" : "Next"
                  )} (${currentRuleIndex?.index + 1}/${data?.current?.transaction_rules?.length})`}
                  onClick={onNext}
                  sx={{
                    borderRadius: 50,
                  }}
                />
              ) : null}
            </Stack>
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default AttentionOverlay;
const MoreView = ({
  onClickManualRule,
  onClickExclude,
  onClickOverwrite,
  onClickDelete,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const overlayRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  //functions
  const onClickMore = () => {
    if (!anchorEl) {
      setAnchorEl(overlayRef.current);
    } else {
      onClickAway();
    }
  };

  const onClickAway = () => setAnchorEl(null);

  return (
    <Box
      ref={overlayRef}
      sx={{
        position: "relative",
        alignSelf: "flex-end",
      }}
    >
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"top"}
        transition
        disablePortal
        sx={{
          zIndex: 1303,
          inset: "auto !important",
          transform: "none !important",
          top: "-600% !important",
          right: "-12% !important",
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={1}
                sx={{
                  minWidth: "22rem",
                  p: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  border: `1px solid ${theme.palette.color.slate[400]}`,
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
              >
                {" "}
                <TailwindButton
                  variant="outlined"
                  text={t("category_rule_already_available_add_manual")}
                  tooltip={"category_rule_already_available_add_manual_tooltip"}
                  onClick={onClickManualRule}
                  color={theme.palette.color.slate[500]}
                  sx={{
                    borderRadius: 50,
                    width: "max-content",
                    height: "2.75rem",

                    "&:hover": {
                      backgroundColor: "color.slate.200",
                      color: "color.slate.600",
                      borderColor: "color.slate.600",
                    },
                  }}
                />
                <TailwindButton
                  variant="outlined"
                  tooltip={"category_rule_already_available_exclude_tooltip"}
                  text={t("category_rule_already_available_exclude")}
                  onClick={onClickExclude}
                  color={theme.palette.color.red[500]}
                  sx={{
                    my: "1rem",
                    width: "max-content",
                    borderRadius: 50,
                    height: "2.75rem",

                    "&:hover": {
                      backgroundColor: "color.red.200",
                      color: "color.red.600",
                      borderColor: "color.red.600",
                    },
                  }}
                />
                <TailwindButton
                  tooltip={"category_rule_already_available_overwrite_tooltip"}
                  text={t("category_rule_already_available_overwrite")}
                  onClick={onClickOverwrite}
                  backgroundColor={theme.palette.color.red[500]}
                  sx={{
                    width: "max-content",
                    borderRadius: 50,
                    mb: "1rem",
                    height: "2.75rem",

                    "&:hover": {
                      backgroundColor: "color.red.700",
                    },
                  }}
                />
                <TailwindButton
                  tooltip={"category_rule_delete_rule_tooltip"}
                  text={t("category_rule_delete_rule")}
                  onClick={onClickDelete}
                  backgroundColor={theme.palette.color.red[500]}
                  sx={{
                    width: "max-content",
                    borderRadius: 50,
                    mb: "1rem",
                    height: "2.75rem",

                    "&:hover": {
                      backgroundColor: "color.red.600",
                    },
                  }}
                />
                <TailwindButton
                  variant="outlined"
                  text={t("Close")}
                  onClick={onClickMore}
                  color={theme.palette.color.white}
                  sx={{
                    width: "8rem",
                    height: "2.75rem",

                    borderRadius: 50,
                    backgroundColor: "color.slate.500",
                    borderColor: "color.slate.600",
                    "&:hover": {
                      backgroundColor: "color.slate.600",
                    },
                  }}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>

      <TailwindButton
        variant="outlined"
        text={t("More actions")}
        onClick={onClickMore}
        color={theme.palette.color.slate[500]}
        sx={{
          minWidth: "8rem",
          height: "2.75rem",
          borderRadius: 50,
          "&:hover": {
            backgroundColor: theme.palette.color.slate[200],
            color: theme.palette.color.slate[600],
            borderColor: theme.palette.color.slate[600],
          },
        }}
      />
    </Box>
  );
};
