import {
  TbCircleDashedCheck,
  TbCircleDashedMinus,
  TbCircleDashedPlus,
  TbProgressCheck,
  TbBuildingBank,
  TbAlertCircle,
  TbFileInvoice,
  TbCircleCheck,
  TbArrowBack,
  TbArchive,
  TbForbid2,
} from "react-icons/tb";
import { GrDocumentStore, GrMoney } from "react-icons/gr";
import { LuSquareChartGantt } from "react-icons/lu";
import { FaRegPaperPlane } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import React from "react";

import { getTailwindColor } from "../../../../Helper/data";
import Icon from "../../../../components/Icon";

const state_icons = {
  Planned: {
    icon: <LuSquareChartGantt />,
    fontSize: "0.95rem",
  },
  "Lead - potential": {
    icon: <GrMoney />,
    fontSize: "0.95rem",
  },
  "Lead - won": {
    icon: <GrMoney />,
    fontSize: "0.95rem",
  },
  "Lead - lost": {
    icon: <TbForbid2 />,
  },
  "Offer draft": {
    icon: <GrDocumentStore />,
    fontSize: "0.95rem",
  },
  "Offer sent": {
    icon: <FaRegPaperPlane />,
    fontSize: "0.8rem",
  },
  "Offer accepted": {
    icon: <TbCircleDashedCheck />,
  },
  "Offer partially calculated": {
    icon: <TbProgressCheck />,
  },
  "Offer calculated": {
    icon: <TbCircleCheck />,
  },
  "Offer archived": {
    icon: <TbArchive />,
  },
  "Offer rejected": {
    icon: <TbForbid2 />,
  },
  Open: {
    icon: <TbFileInvoice />,
  },
  "Sales invoice": {
    icon: <TbFileInvoice />,
  },
  "Invoice draft": {
    icon: <TbFileInvoice />,
  },
  "Invoice sent": {
    icon: <FaRegPaperPlane />,
    fontSize: "0.8rem",
  },
  "Invoice open": {
    icon: <TbCircleDashedCheck />,
  },
  "Partially paid": {
    icon: <TbProgressCheck />,
  },
  "Invoice paid": {
    icon: <TbCircleCheck />,
  },
  "Sales credit note": {
    icon: <TbCircleDashedMinus />,
  },
  "Purchase invoice": {
    icon: <TbFileInvoice />,
  },
  "Purchase credit note": {
    icon: <TbCircleDashedPlus />,
  },
  Refunded: {
    icon: <TbArrowBack />,
  },
  "Partially refunded": {
    icon: <TbArrowBack />,
  },
  Booked: {
    icon: <TbBuildingBank />,
  },
  Ignored: {
    icon: <TbForbid2 />,
  },
  "Written-off": {
    icon: <TbForbid2 />,
  },
  "On-hold": {
    icon: <TbForbid2 />,
  },
  Canceled: {
    icon: <TbForbid2 />,
  },
  Pending: {
    icon: <TbAlertCircle />,
  },
  Undefined: {
    icon: <TbAlertCircle />,
  },
};
const StateDotView = ({
  count = null,
  title = "",
  styleObj = {},
  isHaveLate = false,
  source,
  color,
  onMouseOver = () => undefined,
  onMouseLeave = () => undefined,
}) => {
  const indicatorType = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.indicatorType
  );
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice?.scenarioByTitle
  );
  let state =
    indicatorType === "scenarios"
      ? scenarioByTitle?.[title]?.[0]
      : stateByTitle?.[title]?.[0];
  let _color = state?.color;
  let iconShade = 500;
  let icon = state_icons?.[title]?.icon;
  if (title === "Booked" && source === 1) {
    icon = <TbCircleCheck />;
  }
  if (color) {
    _color = color;
  }
  if (title === "Invoice paid") {
    _color = "green";
  }
  if (state?.isLight) {
    iconShade = 400;
  }

  return (
    // <Tooltip
    //
    //   title={`${isHaveLate ? `${t(title)} (${t("Late")})` : t(title)} : ${
    //     count || 1
    //   }`}
    //   placement="top"
    // >
    <Box
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      sx={{
        borderRadius: 50,
        zIndex: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontWeight: 500,
        lineHeight: "normal",
        color: getTailwindColor(_color, 600),
        width: "1.5rem",
        height: "1.5rem",
        position: "relative",
        ...styleObj,
      }}
    >
      <Icon
        icon={icon}
        fontSize={state_icons?.[title]?.fontSize || "1.15rem"}
        color={getTailwindColor(_color, iconShade)}
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
        }}
      ></Icon>
    </Box>
    // </Tooltip>
  );
};

export default React.memo(StateDotView);
