import {
  startOfQuarter,
  endOfQuarter,
  startOfWeek,
  subQuarters,
  addQuarters,
  endOfMonth,
  addMonths,
  subMonths,
  endOfWeek,
  subWeeks,
  addWeeks,
  addYears,
  subYears,
  format,
} from "date-fns";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  getDataSetColumnCount,
  getTailwindColor,
} from "../../../../Helper/data";
import { getDataSetDateRange } from "../../../../Helper/functions";
import { GlobalContext } from "../../../../GlobalContextWrapper";
import { Constant } from "../../../../Helper";
import theme from "../../../../theme";
import store from "../../../../store";

const MonthSkipView = ({ color = "slate" }) => {
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const dataSetRef = globalContext?.dataSetRef;

  //redux
  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );
  const end_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.end_date
  );
  const tableType = useSelector((state) => state.boardSlice?.tableType);

  const column_count = useMemo(() => {
    return getDataSetColumnCount({
      tableType,
      start_date,
      end_date,
    });
  }, [end_date, start_date, tableType]);

  //function
  // const setAllowColumnFetch = (date) => {
  //   if (date) {
  //     let inarray = global.allowFetch?.Inflow || [];
  //     let outarray = global.allowFetch?.Outflow || [];
  //     inarray.push(date);
  //     outarray.push(date);
  //     global.allowFetch = { Inflow: inarray, Outflow: outarray };
  //   } else {
  //     global.allowFetch = { Inflow: [], Outflow: [] };
  //     let options = {
  //       predicate: (query) =>
  //         query.queryKey[0] === "transactions" &&
  //         query.queryKey[1]?.apiType === "normal_monthly_transactions",
  //       type: "active",
  //     };
  //     queryClient.invalidateQueries(options);
  //   }
  // };

  const onClickRightArrow = () => {
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    let dataset_start_date = null;
    let dataset_end_date = null;
    if (tableType === "quarterly") {
      dataset_start_date = format(
        addQuarters(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfQuarter(addQuarters(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    } else if (tableType === "weekly") {
      dataset_start_date = format(
        addWeeks(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfWeek(addWeeks(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    } else {
      dataset_start_date = format(
        addMonths(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfMonth(addMonths(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    }

    // setAllowColumnFetch(
    //   format(startOfMonth(new Date(dataset_end_date)), "yyyy-MM-dd")
    // );
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickRightArrow2 = () => {
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    let dataset_start_date = null;
    let dataset_end_date = null;
    if (tableType === "quarterly") {
      dataset_start_date = format(
        startOfQuarter(addYears(new Date(dataSetData?.start_date), 1)), //only 1 year skip
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfQuarter(addQuarters(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    } else if (tableType === "weekly") {
      dataset_start_date = format(
        startOfWeek(addMonths(new Date(dataSetData?.start_date), 1)), //only 1 month skip
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfWeek(addWeeks(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    } else {
      dataset_start_date = format(
        addMonths(new Date(dataSetData?.start_date), column_count),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfMonth(addMonths(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    }
    // setAllowColumnFetch();
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickHome = () => {
    const obj = getDataSetDateRange({
      tableType,
      sub_count: Constant.start_column_skip_count,
      column_count,
    });

    // setAllowColumnFetch();for kanban only did used
    updateDataSetData(obj?.start_date, obj?.end_date);
  };

  const onClickLeftArrow = () => {
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    let dataset_start_date = null;
    let dataset_end_date = null;
    if (tableType === "quarterly") {
      dataset_start_date = format(
        subQuarters(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfQuarter(addQuarters(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    } else if (tableType === "weekly") {
      dataset_start_date = format(
        subWeeks(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfWeek(addWeeks(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    } else {
      dataset_start_date = format(
        subMonths(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfMonth(addMonths(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    }
    // setAllowColumnFetch(dataset_start_date);
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickLeftArrow2 = () => {
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    let dataset_start_date = null;
    let dataset_end_date = null;
    if (tableType === "quarterly") {
      dataset_start_date = format(
        startOfQuarter(subYears(new Date(dataSetData?.start_date), 1)), //only 1 year skip
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfQuarter(addQuarters(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    } else if (tableType === "weekly") {
      dataset_start_date = format(
        startOfWeek(subMonths(new Date(dataSetData?.start_date), 1)), //only 1 month skip
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfWeek(addWeeks(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    } else {
      dataset_start_date = format(
        subMonths(new Date(dataSetData?.start_date), column_count),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfMonth(addMonths(new Date(dataset_start_date), column_count)),
        "yyyy-MM-dd"
      );
    }
    // setAllowColumnFetch();
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const updateDataSetData = (s_date, e_date) => {
    dataSetRef?.current?.updateDataSetData({
      start_date: s_date,
      end_date: e_date,
    });
  };

  return (
    <ButtonGroup
      className={"kanban_4_step"}
      variant="text"
      aria-label="text button group"
      sx={{
        p: "4px",
        borderRadius: theme.borderRadius.main,
        mr: "1rem",
        "& .MuiSvgIcon-root": {
          fontSize: "1.6rem",
          color: getTailwindColor(color, 600),
        },
        "& .MuiButtonBase-root": {
          py: "3px",
          "&:hover": {
            "& svg": {
              color: theme.palette.primary.main,
            },
          },
        },
        border: `1px solid ${getTailwindColor(color, 300)}`,
        backgroundColor: theme.palette.color.white,
      }}
    >
      <Tooltip title={t("tooltip_filter_left_Most_Arrow")} position="top">
        <Button
          onClick={onClickLeftArrow2}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <KeyboardDoubleArrowLeftIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t("tooltip_filter_left_Arrow")} position="top">
        <Button
          onClick={onClickLeftArrow}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <KeyboardArrowLeftIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t("tooltip_filter_home")} position="top">
        <Button
          onClick={onClickHome}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <HomeIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t("tooltip_filter_right_Arrow")} position="top">
        <Button
          onClick={onClickRightArrow}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t("tooltip_filter_right_Most_Arrow")} position="top">
        <Button
          onClick={onClickRightArrow2}
          sx={{
            borderColor: `${getTailwindColor(color, 200)} !important`,
          }}
        >
          <KeyboardDoubleArrowRightIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};
export default MonthSkipView;
