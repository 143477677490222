import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Route, Routes, useLocation } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";

import SubscriptionPaymentSuccessful from "../Pages/Settings/Subscription/SubscriptionPaymentSuccessful";
import RecurringView from "../Pages/Datasets/Employees/Component/RecurringView";
import AccountActivateScreen from "../Pages/Auth/AccountActivateScreen";
import PasswordResetScreen from "../Pages/Auth/PasswordResetScreen";
import PassResetFormScreen from "../Pages/Auth/PassResetFormScreen";
import MainDrawerView from "../components/Header/MainDrawerView";
import initialData, { getTailwindColor } from "../Helper/data";
import DataSetList from "../Pages/Settings/DataSetList/index";
import Categories from "../Pages/Settings/Categories/index";
import DataSource from "../Pages/Settings/DataSource/index";
import CostUnit from "../Pages/Datasets/Employees/CostUnit";
import Transactions from "../Pages/Datasets/Transactions";
import RegisterScreen from "../Pages/Auth/RegisterScreen";
import Subscription from "../Pages/Settings/Subscription";
import Clients from "../Pages/Datasets/Employees/Clients";
import Reports from "../Pages/Datasets/Reports/index";
import General from "../Pages/Settings/General/index";
import Profile from "../Pages/Settings/Profile/index";
import LoginScreen from "../Pages/Auth/LoginScreen";
import Employees from "../Pages/Datasets/Employees";
import Loan from "../Pages/Datasets/Employees/Loan";
import Security from "../Pages/Settings/Security";
import Planning from "../Pages/Datasets/Planning";
import Banner from "../components/Banner/Banner";
import NotFoundPage from "../Pages/NotFoundPage";
import Settings from "../Pages/Settings/index";
import Vats from "../Pages/Settings/Vat/index";
import ProtectedRoute from "./ProtectedRoute";
import Dataset from "../Pages/Datasets/index";
import Rules from "../Pages/Settings/Rules";
import Users from "../Pages/Settings/Users";
import ErrorPage from "../Pages/ErrorPage";
import Header from "../components/Header";
import CommonView from "./CommonView";
import { Constant } from "../Helper";
import Start from "../Pages/Start";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const renderMultiRoutes = ({ element: Element, paths, ...rest }) =>
  paths.map((path) => (
    <Route key={path} path={path} {...rest} element={Element} />
  ));
export default function AppRouter() {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const isMainDrawerOpen = useSelector(
    (state) => state.settingsSlice?.isMainDrawerOpen
  );
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isPageNotFound = useSelector(
    (state) => state.commonSlice.isPageNotFound
  );
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isDevMode = useSelector((state) => state.commonSlice?.isDevMode);

  const isFinbanAccount =
    profile?.email?.includes("@finban.io") ||
    profile?.email?.includes("@e-laborat.de");

  const [showBanner, setShowBanner] = useState(false);

  const allowedRoutes =
    auth?.token &&
    auth?.refreshToken &&
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register") &&
    !location.pathname.includes("/registration/") &&
    !location.pathname.includes("/auth/");
  const showHeader = isAllHeaderApiFetched && allowedRoutes;
  const isStartPage =
    location?.pathname?.includes("/dashboard") || location?.pathname === "/";

  return (
    <Box
      component="div"
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        "& .MuiSkeleton-root": {
          backgroundColor: getTailwindColor("grey", 100),
        },
      }}
    >
      {isDevMode && (
        <ReactQueryDevtools
          initialIsOpen={false}
          position={"left-bottom"}
          buttonPosition={"left-bottom"}
        />
      )}

      {/* <RouterProvider
       router={router}
       fallbackElement={<h1>..Loading</h1>}
       /> */}
      {allowedRoutes ? <CommonView /> : null}
      {showHeader ? (
        <>
          <Banner showBanner={showBanner} setShowBanner={setShowBanner} />
          {!isPageNotFound ? <Header /> : null}
        </>
      ) : null}

      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          height: showHeader
            ? `calc(100% - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT})`
            : "100%",
          overflow: "hidden",
        }}
      >
        <MainDrawerView showHeader={showHeader} isStartPage={isStartPage} />
        <Box
          sx={{
            width: {
              xs:
                showHeader && !isStartPage
                  ? isMainDrawerOpen
                    ? `calc(100% - ${Constant.DRAWER_WIDTH})`
                    : `calc(100% - ${Constant.DRAWER_WIDTH_CLOSED})`
                  : "100%",
              s1650: "100%",
            },
            height: "100%",
          }}
        >
          <SentryRoutes>
            <Route path="/login" element={<LoginScreen />} />

            <Route path="/register" element={<RegisterScreen />} />
            <Route
              path="/register/activate/"
              element={<AccountActivateScreen />}
            />
            <Route
              path="/auth/password_reset/"
              element={<PasswordResetScreen />}
            />
            <Route path="/error" element={<ErrorPage />} />
            <Route
              path="/register/password_reset/"
              element={<PassResetFormScreen />}
            />

            {!isAllHeaderApiFetched ? (
              !auth.token ? (
                <Route path="*" element={<NotFoundPage />} />
              ) : null
            ) : (
              <>
                <Route>
                  {renderMultiRoutes({
                    paths: ["/", "/dashboard"],
                    index: true,
                    element: (
                      <ProtectedRoute>
                        <Start />
                      </ProtectedRoute>
                    ),
                  })}
                </Route>

                <Route
                  path={`/${initialData?.path?.organization}/:id`}
                  element={
                    <ProtectedRoute>
                      <Dataset />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path={`/${initialData?.path?.organization}/:id/table`}
                    element={
                      <ProtectedRoute>
                        <Planning />
                      </ProtectedRoute>
                    }
                  />
                  {/* `/${initialData?.path?.organization}/:id/list/suggestion`,
                        `/${initialData?.path?.organization}/:id/list/categorized`,
                        `/${initialData?.path?.organization}/:id/list/uncategorized`,
                        `/${initialData?.path?.organization}/:id/list/reconcile`,
                        `/${initialData?.path?.organization}/:id/list/issues`, */}
                  <Route>
                    {renderMultiRoutes({
                      paths: [
                        `/${initialData?.path?.organization}/:id/list/bulk`,
                        `/${initialData?.path?.organization}/:id/list/bank`,
                        `/${initialData?.path?.organization}/:id/list/open`,
                        `/${initialData?.path?.organization}/:id/list/budget`,
                        `/${initialData?.path?.organization}/:id/list/late`,
                        `/${initialData?.path?.organization}/:id/list/all`,
                      ],
                      element: (
                        <ProtectedRoute>
                          <Transactions />
                        </ProtectedRoute>
                      ),
                    })}
                  </Route>
                  <Route>
                    {renderMultiRoutes({
                      paths: [
                        `/${initialData?.path?.organization}/:id/reports/overview`,
                      ],
                      element: (
                        <ProtectedRoute>
                          <Reports />
                        </ProtectedRoute>
                      ),
                    })}
                  </Route>
                  <Route
                    path={`/${initialData?.path?.organization}/:id/employees`}
                    element={
                      <ProtectedRoute>
                        <Employees />
                      </ProtectedRoute>
                    }
                  />
                  {Constant?.isProd || !beta_enabled ? null : (
                    <Route
                      path={`/${initialData?.path?.organization}/:id/loans`}
                      element={
                        <ProtectedRoute>
                          <Loan />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  {/* <Route
                    path={`/${initialData?.path?.organization}/:id/cost-units`}
                    element={
                      <ProtectedRoute>
                        <CostUnit />
                      </ProtectedRoute>
                    }
                  /> */}
                  {isFinbanAccount && beta_enabled ? (
                    <Route>
                      {renderMultiRoutes({
                        paths: [
                          `/${initialData?.path?.organization}/:id/customers/customers-list`,
                          `/${initialData?.path?.organization}/:id/customers/customers-portfolio-analysis`,
                          `/${initialData?.path?.organization}/:id/customers/top-customers`,
                          `/${initialData?.path?.organization}/:id/customers/merge-customers`,
                        ],
                        element: (
                          <ProtectedRoute>
                            <Clients />
                          </ProtectedRoute>
                        ),
                      })}
                    </Route>
                  ) : null}
                  <Route>
                    {renderMultiRoutes({
                      paths: [
                        `/${initialData?.path?.organization}/:id/clients/recurring-subscriptions`,
                      ],
                      element: (
                        <ProtectedRoute>
                          <RecurringView />
                        </ProtectedRoute>
                      ),
                    })}
                  </Route>
                  <Route
                    path={`/${initialData?.path?.organization}/:id/categories`}
                    element={
                      <ProtectedRoute>
                        <Categories isMainPage={true} />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <Settings />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="/settings/security"
                    element={
                      <ProtectedRoute>
                        <Security />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings/personal"
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings/users"
                    element={
                      <ProtectedRoute>
                        <Users />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings/general"
                    element={
                      <ProtectedRoute>
                        <General />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path={`/settings/${initialData?.path?.organizations}`}
                    element={
                      <ProtectedRoute>
                        <DataSetList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/settings/vats"
                    element={
                      <ProtectedRoute>
                        <Vats />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings/rules"
                    element={
                      <ProtectedRoute>
                        <Rules />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings/Integrations"
                    element={
                      <ProtectedRoute>
                        <DataSource type={"general"} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings/subscription"
                    element={
                      <ProtectedRoute>
                        <Subscription />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings/subscription/successful-payment/"
                    element={
                      <ProtectedRoute>
                        <SubscriptionPaymentSuccessful />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                <Route path="*" element={<NotFoundPage isLogin />} />
              </>
            )}
          </SentryRoutes>
        </Box>
      </Stack>
    </Box>
  );
}
